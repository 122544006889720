import { createSelector } from 'reselect'
import { TAppState, TSelectorResult } from 'src/store'
import { ILayoutState } from '../types/layout'

/**
 * @deprecated
 */
export const getLayoutPageTitle: TSelectorResult<ILayoutState['pageTitle']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.pageTitle
)

export const getLayoutIsScrollToBottom: TSelectorResult<ILayoutState['isScrollToBottom']> = createSelector(
  (state: TAppState) => state.layout,
  (layout: ILayoutState) => layout.isScrollToBottom
)
