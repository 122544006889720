import { ComponentProps, FC } from 'react'
import { Modal } from 'src/components'
import { Videos } from 'src/modules/videos/components'

interface IProps {
  onClose: ComponentProps<typeof Videos>['onSelectVideo']
}

export const ModalMyVideo: FC<ComponentProps<typeof Modal> & IProps> = ({ ...props }) => {
  return (
    <Modal
      {...props}
      closeBtn
      contentClass="fx-column"
      contentStyles={{
        padding: 0,
        borderRadius: 0,
        overflow: 'auto',
        height: 'var(--window-height, 100vh)'
      }}
    >
      <Videos onSelectVideo={(video) => props.onClose?.(video)}/>
    </Modal>
  )
}
