import { ComponentProps, FC, useMemo } from 'react'
import { useMediaPermissions } from 'src/hooks/useMediaPermissions'
import { IconMediaSetting } from 'src/icons'
import { RecordReadyImg } from 'src/images'
import { Button } from '../button'
import { Modal } from '../modal'
import { ModalCenter } from '../modal-center'

export const ModalGetReadyToRecord: FC<ComponentProps<typeof ModalCenter>> = (props) => {
  const { permissions, requestPermissions } = useMediaPermissions()
  const isGranted = useMemo(
    () => permissions.camera === 'granted' && permissions.microphone === 'granted',
    [permissions]
  )
  const unGrantedScreen = useMemo(() => (
    <div className="fx-column fx-center gap-4">
      <div className="fs-16 fw-600">
        In order to make a VVC to apply for this job, please allow camera and microphone access.
      </div>
      {permissions.camera === 'denied' || permissions.microphone === 'denied'
        ? (
          <ol className="fx-column gap-2 m-0 pl-6 fs-14">
            <li>
              Click the page info icon{' ('}
              <span style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                <IconMediaSetting/>
              </span>{') '}
              in your browser’s address bar
            </li>
            {permissions.camera !== 'granted' && <li>Allow camera</li>}
            {permissions.microphone !== 'granted' && <li>Allow microphone</li>}
          </ol>
        )
        : (
          <Button onClick={requestPermissions}>Allow</Button>
        )}
    </div>
  ), [permissions, requestPermissions])

  const grantedScreen = useMemo(() => (
    <>
      <div className="fs-16 fw-600 py-2 px-1">Get ready to record!</div>
      <Button onClick={props.onClose}>Let’s do this!</Button>
    </>
  ), [props.onClose])

  return (
    <Modal
      {...props}
      center
      closeBtn
      contentStyles={{ padding: 0 }}
    >
      <div
        className="fx-column fx-center p-4 round-4 bg-neutral-white"
        style={{ width: '312px' }}
      >
        <img src={RecordReadyImg} alt="Get ready to record"/>
        {isGranted ? grantedScreen : unGrantedScreen}
      </div>
    </Modal>
  )
}
