import clsx from 'clsx'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi, UploadApi } from 'src/api'
import {
  BreadcrumbHeading,
  Button,
  DatePicker,
  Divider,
  Editor,
  GoogleLocationSearch,
  Input,
  Select,
  Switch,
  useAnalytic
} from 'src/components'
import { GroupRadio } from 'src/components/group-radio'
import { CAMPAIGN_DESCRIPTION_OPTIONS, CAMPAIGN_LOCATION_TYPE_OPTIONS, CAMPAIGN_RESUME_TO_APPLY_OPTIONS, CAMPAIGN_SALARY_RATE_OPTIONS, CAMPAIGN_TRADE_OPTIONS } from 'src/constants'
import { EJobDescriptionType, ELocationType, ESalaryRange, ESalaryRate, ETrackingEvent } from 'src/enums'
import { useBehaviorMapper, useDebounceAnalyticInput, useQueryParams, useUnsubscribe, useUnsubscribeEffect, useValidation } from 'src/hooks'
import { IconWarning } from 'src/icons'
import { EFileUploadKind, ICampaignModel } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { CampaignCompanyVerify } from 'src/partials'
import { CampaignRecordVVC } from 'src/partials/campaign-record-vvc'
import { ModalCampaignDetail } from 'src/partials/modal-campaign-detail'
import { createJobReviewModal } from 'src/partials/modal-create-job-review'
import { DialogService, SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { getApiErrorMessage, getFirstCompany } from 'src/utils'
import { v4 as uuidv4 } from 'uuid'
import { object, string } from 'yup'
import { CompanyInfo } from '../company-info'
import { UploadJDFile } from '../upload-file'
import Style from './style.module.scss'

export const CreateCampaign: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { draftId } = useQueryParams()
  const { analyticInput } = useDebounceAnalyticInput('create_job')
  const { analytic, eventHandler } = useAnalytic('create_job')

  const profile = useBehaviorMapper(AuthModule.profile$)
  const isVerify = useMemo(() => getFirstCompany(profile)?.CompanyUser?.isVerified, [profile])

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<Partial<ICampaignModel> & {
    draftId?: number
    minSalary?: string
    maxSalary?: string
    companyPhoneNo?: string
    linkedIn?: string
    jobDescriptionType?: EJobDescriptionType
    attachmentFile?: File | null
    stage?: 'info' | 'verify'
  }>({
    salaryRate: ESalaryRate.PER_YEAR,
    salaryRange: ESalaryRange.CUSTOM,
    locationType: ELocationType.HYBRID,
    jobDescriptionType: EJobDescriptionType.MANUALLY,
    briefDescription: '',
    requiredResume: true,
    canTrade: false,
    question: 'Besides money, what aspect of work motivates you?',
    shareOnMarket: true,
    stage: 'info'
  })

  useEffect(() => {
    const company = getFirstCompany(profile)
    const patch: Partial<Record<'companyPhoneNo' | 'linkedIn', string>> = {}
    if (company?.CompanyUser?.companyPhoneNo) {
      patch.companyPhoneNo = company.CompanyUser.companyPhoneNo
    }
    if (company?.CompanyUser?.linkedIn) {
      patch.linkedIn = company.CompanyUser.linkedIn
    }
    if (Object.keys(patch).length) {
      setFormData((prev) => ({
        ...prev,
        ...patch
      }))
    }
  }, [profile])

  const schema = useMemo(() => object().shape({
    uploadVideoId: string().required().label('Video'),
    jobTitle: string().required().label('Job title'),
    minSalary: string().required().label('Min'),
    maxSalary: string().required().label('Max'),
    location: string().label('Location').when('locationType', {
      is: ELocationType.REMOTE,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required()
    }),
    closedAt: string().required().label('Expiration date'),
    briefDescription: string().when('jobDescriptionType', (value, schema) =>
      value === EJobDescriptionType.MANUALLY ? string().required().label('Job description') : schema),
    attachmentFile: string().when('jobDescriptionType', (value, schema) =>
      value === EJobDescriptionType.UPLOAD_ATTACHMENT ? string().required().label('Job description').nullable() : schema).nullable(),
    companyPhoneNo: string().when('stage', (value, schema) =>
      value === 'verify' ? string().required().matches(/^([1-9]{1})([0-9]{9,10})$/, 'Invalid phone number').label('Phone') : schema)
  }), [])

  const { errors, validate, reset } = useValidation({
    data: formData,
    schema
  })

  const canPreview = useMemo(() => {
    return (
      formData.uploadVideoId &&
        formData.jobTitle &&
        formData.minSalary &&
        formData.maxSalary &&
        (formData.locationType === ELocationType.REMOTE || formData.location) &&
        formData.jobDescriptionType === EJobDescriptionType.MANUALLY
        ? formData.briefDescription
        : formData.attachmentFile
    )
  }, [formData])

  console.log(canPreview, formData)

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (name === 'jobTitle') {
      analyticInput(ETrackingEvent.INPUT_JOB_TITLE, value)
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [analyticInput])

  const resetForm = useCallback(() => {
    reset()
    setFormData({
      salaryRate: ESalaryRate.PER_YEAR,
      salaryRange: ESalaryRange.CUSTOM,
      locationType: ELocationType.HYBRID,
      jobDescriptionType: EJobDescriptionType.MANUALLY,
      briefDescription: '',
      requiredResume: true,
      canTrade: false,
      question: 'Besides money, what aspect of work motivates you?',
      shareOnMarket: true,
      stage: 'info'
    })
  }, [reset])

  const onSubmitDraft = useCallback(async () => {
    setLoading(true)

    let jdFileUrl = formData.jdFileUrl
    if (formData.attachmentFile && !formData.jdFileUrl) {
      jdFileUrl = await UploadApi.upload({
        kind: EFileUploadKind.JD_CAMPAIGN_FILE,
        file: formData.attachmentFile,
        filename: formData.attachmentFile.name
      })
    }

    const payload = {
      slug: formData.slug || uuidv4(),
      draftId: formData.draftId,
      uploadVideoId: formData.uploadVideoId,
      jobTitle: formData.jobTitle,
      salaryValue: {
        min: Number(formData.minSalary),
        max: Number(formData.maxSalary)
      },
      salaryRate: formData.salaryRate,
      salaryRange: formData.salaryRange,
      locationType: formData.locationType,
      location: formData.location,
      closedAt: formData.closedAt,
      requiredResume: formData.requiredResume,
      canTrade: formData.canTrade,
      specifyTrade: formData.specifyTrade,
      question: formData.question,
      briefDescription: formData.briefDescription,
      jdFileUrl,
      shareOnMarket: formData.shareOnMarket,
      companyPhoneNo: formData.companyPhoneNo,
      linkedIn: formData.linkedIn
    }

    from(CampaignApi.saveDraft(payload))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        setFormData((prev) => ({
          ...prev,
          jdFileUrl,
          draftId: data.id
        }))
      })
  }, [formData, unsubscribe$])

  const onSubmit = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    if (formData.stage === 'info' && !isVerify) {
      reset()
      setFormData((prev) => ({
        ...prev,
        stage: 'verify'
      }))

      return
    }

    setLoading(true)

    let jdFileUrl = formData.jdFileUrl
    if (formData.attachmentFile && !formData.jdFileUrl) {
      jdFileUrl = await UploadApi.upload({
        kind: EFileUploadKind.JD_CAMPAIGN_FILE,
        file: formData.attachmentFile,
        filename: formData.attachmentFile.name
      })
    }

    const payload = {
      slug: formData.slug || uuidv4(),
      draftId: formData.draftId,
      uploadVideoId: formData.uploadVideoId,
      jobTitle: formData.jobTitle,
      salaryValue: {
        min: Number(formData.minSalary),
        max: Number(formData.maxSalary)
      },
      salaryRate: formData.salaryRate,
      salaryRange: formData.salaryRange,
      locationType: formData.locationType,
      location: formData.location,
      closedAt: formData.closedAt,
      requiredResume: formData.requiredResume,
      canTrade: formData.canTrade,
      specifyTrade: formData.specifyTrade,
      question: formData.question,
      briefDescription: formData.briefDescription,
      jdFileUrl,
      shareOnMarket: formData.shareOnMarket,
      companyPhoneNo: formData.companyPhoneNo,
      linkedIn: formData.linkedIn
    }

    from(CampaignApi.create(payload))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        createJobReviewModal(resetForm)
      })
  }, [formData, isVerify, reset, unsubscribe$, validate, resetForm])

  useUnsubscribeEffect(
    (unsubscribe$) => {
      if (!draftId) return

      setLoading(true)
      from(CampaignApi.getDraftDetail(Number(draftId)))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error) => {
            SnackbarService.error(getApiErrorMessage(error))
            return EMPTY
          }),
          finalize(() => setLoading(false))
        )
        .subscribe(({ data }) => {
          setFormData((prev) => ({
            ...prev,
            ...data,
            draftId: data.id,
            jobDescriptionType: data.briefDescription ? EJobDescriptionType.MANUALLY : EJobDescriptionType.UPLOAD_ATTACHMENT,
            jdFileUrl: data.jdFileUrl,
            minSalary: (data.salaryValue as { min?: string })?.min,
            maxSalary: (data.salaryValue as { max?: string })?.max,
            question: data.question || 'Besides money, what aspect of work motivates you?'
          }))
        })
    },
    [draftId]
  )

  return (
    <>
      <Breadcrumbs>
        <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>
      </Breadcrumbs>

      {formData.stage === 'info'
        ? (
          <div className="fx-1 fx-column gap-8 p-8 bg-neutral-20 w-100-p">
            <div className="fx-column gap-3">
              <div className="fs-28 fw-600 txt-black-01">Create a Listing</div>
              <div className="fs-16 fw-600 txt-grey-01">Add Job Details</div>
            </div>

            <div
              className="p-8 bg-neutral-white round-4 fx-column gap-10"
              style={{ maxWidth: 950 }}
            >
              <div className="fx fx-jc-space-between gap-10">
                <div>
                  <CampaignRecordVVC campaign={formData} onChange={setFormData}/>
                  {errors?.getError('uploadVideoId') && (
                    <span className="fs-12 fx fx-ai-center gap-1 txt-negative-500 mt-2">
                      <IconWarning size={16}/>
                      {errors?.getError('uploadVideoId')}
                    </span>
                  )}
                </div>

                <div className="fx-column gap-6">
                  <div className="fx-column gap-2">
                    <div className="fw-700 txt-black-01 lh-20">
                      Job Title<span className="txt-red-02">*</span>
                    </div>
                    <Input
                      className="py-1"
                      name="jobTitle"
                      disabled={loading}
                      value={formData?.jobTitle}
                      onChange={onChange}
                      error={errors?.getError('jobTitle')}
                    />
                  </div>

                  <div className="fx-column gap-2">
                    <div className="fw-700 txt-black-01 lh-20">
                      Salary<span className="txt-red-02">*</span>
                    </div>
                    <div className="fx gap-3">
                      <Input
                        className="py-1"
                        type="currency"
                        name="minSalary"
                        placeholder="Min"
                        disabled={loading}
                        value={formData?.minSalary}
                        onValueChange={minSalary => {
                          analyticInput(ETrackingEvent.INPUT_SALARY_MIN, minSalary ?? '')
                          setFormData((prev) => ({
                            ...prev,
                            minSalary
                          }))
                        }}
                        error={errors?.getError('minSalary')}
                        style={{ width: 130 }}
                      />

                      <div className="pt-4">-</div>

                      <Input
                        className="py-1"
                        type="currency"
                        name="maxSalary"
                        placeholder="Max"
                        disabled={loading}
                        value={formData.maxSalary}
                        onValueChange={maxSalary => {
                          analyticInput(ETrackingEvent.INPUT_SALARY_MAX, maxSalary ?? '')
                          setFormData((prev) => ({
                            ...prev,
                            maxSalary
                          }))
                        }}
                        error={errors?.getError('maxSalary')}
                        style={{ width: 130 }}
                      />

                      <Select
                        height={48}
                        className="ml-4"
                        value={formData.salaryRate}
                        options={CAMPAIGN_SALARY_RATE_OPTIONS}
                        isDisabled={loading}
                        style={{ width: 160 }}
                        onChange={(salaryRate) => {
                          analyticInput(ETrackingEvent.INPUT_SALARY_PERIOD, `${salaryRate}`)
                          setFormData((prev) => ({
                            ...prev,
                            salaryRate: salaryRate as ESalaryRate
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="fx gap-8">
                    <div className="fx-column gap-2" style={{ width: '30%' }}>
                      <div className="fw-700 txt-black-01 lh-20">
                        Workplace Type<span className="txt-red-02">*</span>
                      </div>
                      <Select
                        height={48}
                        options={CAMPAIGN_LOCATION_TYPE_OPTIONS}
                        value={formData.locationType}
                        isDisabled={loading}
                        onChange={(locationType) => {
                          analyticInput(ETrackingEvent.INPUT_WORKPLACE_TYPE, `${locationType}`)
                          setFormData((prev) => ({
                            ...prev,
                            locationType: locationType as ELocationType
                          }))
                        }}
                      />
                    </div>

                    {formData.locationType !== ELocationType.REMOTE && (
                      <div className="fx-column gap-2" style={{ width: '70%' }}>
                        <div className="fw-700 txt-black-01 lh-20">
                          Location<span className="txt-red-02">*</span>
                        </div>
                        <GoogleLocationSearch
                          id="locationInput"
                          disabled={loading}
                          location={formData.location}
                          error={errors?.getError('location')}
                          onChange={(location) => {
                            analyticInput(ETrackingEvent.INPUT_LOCATION, location)
                            setFormData((prev) => ({
                              ...prev,
                              location
                            }))
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="fx-column gap-2">
                    <div className="fw-700 txt-black-01 lh-20">
                      Expiration Date<span className="txt-red-02">*</span>
                    </div>
                    <DatePicker
                      placeholder="mm/dd/yyyy"
                      minDate={new Date()}
                      value={formData?.closedAt ? new Date(formData?.closedAt) : undefined}
                      onChange={closedAt => {
                        analyticInput(ETrackingEvent.INPUT_EXPIRATION_DATE, closedAt.toString())
                        setFormData((prev) => ({ ...prev, closedAt }))
                      }}
                      inputProps={{
                        error: errors?.getError('closedAt'),
                        disabled: loading,
                        style: { width: 80, padding: '4px 0' }
                      }}
                    />
                  </div>

                  <div className="fx gap-8">
                    <div
                      className="fw-700 txt-black-01 lh-20"
                      style={{ width: 185 }}
                    >
                      Require resume to apply?
                      <span className="txt-red-02">*</span>
                    </div>
                    <div className="fx fx-ai-center gap-6">
                      <GroupRadio
                        defaultValue={formData.requiredResume}
                        name="requiredResume"
                        options={CAMPAIGN_RESUME_TO_APPLY_OPTIONS}
                        onChange={(requiredResume) => {
                          analyticInput(ETrackingEvent.RADIO_REQUIRE_RESUME, requiredResume)
                          return setFormData((prev) => ({
                            ...prev,
                            requiredResume: requiredResume as boolean
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="fx gap-8">
                    <div
                      className="fw-700 txt-black-01 lh-20"
                      style={{ width: 185 }}
                    >
                      Is this job in the trades?
                      <span className="txt-red-02">*</span>
                    </div>
                    <div className="fx fx-ai-center gap-6">
                      <GroupRadio
                        defaultValue={formData.canTrade}
                        name="canTrade"
                        options={CAMPAIGN_TRADE_OPTIONS}
                        onChange={(canTrade) => {
                          analyticInput(ETrackingEvent.RADIO_TRADES, canTrade)
                          setFormData((prev) => ({
                            ...prev,
                            canTrade: canTrade as boolean
                          }))
                        }}
                      />
                    </div>
                  </div>

                  {formData.canTrade && (
                    <div>
                      <Input
                        className="py-1"
                        name="specifyTrade"
                        placeholder="Specify trade..."
                        disabled={loading}
                        value={formData?.specifyTrade}
                        onChange={onChange}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div
                className="fx-column round-4"
                style={{ border: '1px solid #D3D3D3' }}
              >
                <div className="fs-16 fw-700 txt-black-01 px-4 py-5">
                  Your applicants will answer this question:
                </div>
                <Divider color="#DADBE7"/>
                <Input
                  name="question"
                  disabled={loading}
                  readOnly
                  value={formData?.question}
                  onChange={onChange}
                  wrapperClassName={clsx(Style.questionInput, 'px-4 py-5')}
                />
              </div>

              <div>
                <div
                  className="fx-column round-4"
                  style={{ border: '1px solid #D3D3D3' }}
                >
                  <div className="fx fx-ai-center gap-6">
                    <div className="fw-700 txt-black-01 px-4 py-5 mr-2">
                      Job Description<span className="txt-red-02">*</span>
                    </div>
                    <GroupRadio
                      defaultValue={formData.jobDescriptionType}
                      name="jobDescriptionType"
                      options={CAMPAIGN_DESCRIPTION_OPTIONS}
                      onChange={(jobDescriptionType) => {
                        if (jobDescriptionType === EJobDescriptionType.MANUALLY) {
                          analytic(ETrackingEvent.RADIO_INPUT_JOB_DESCRIPTION)
                        } else {
                          analytic(ETrackingEvent.RADIO_UPLOAD_ATTACHMENT)
                        }

                        setFormData((prev) => ({
                          ...prev,
                          jobDescriptionType: jobDescriptionType as EJobDescriptionType,
                          briefDescription: '',
                          attachmentFile: null
                        }))
                      }}
                    />
                  </div>

                  {formData.jobDescriptionType === EJobDescriptionType.MANUALLY
                    ? (
                      <Editor
                        className="round-4"
                        disabled={loading}
                        value={formData.briefDescription}
                        onChange={(briefDescription) => {
                          analyticInput(ETrackingEvent.INPUT_JOB_DESCRIPTION, briefDescription)
                          setFormData((prev) => ({
                            ...prev,
                            briefDescription
                          }))
                        }}
                      />
                    )
                    : (
                      <div className="px-5 py-4" style={{ borderTop: '1px solid #E2E2E2' }}>
                        <UploadJDFile
                          campaign={formData}
                          attachmentFile={formData.attachmentFile}
                          onChange={file => setFormData((prev) => ({ ...prev, attachmentFile: file, jdFileUrl: '' }))}
                        />
                      </div>
                    )}
                </div>

                {(errors?.getError('briefDescription') || errors?.getError('attachmentFile')) && (
                  <span className="fs-12 fx fx-ai-center gap-1 txt-negative-500 mt-2">
                    <IconWarning size={16}/>
                    {errors?.getError('briefDescription') || errors?.getError('attachmentFile')}
                  </span>
                )}
              </div>
            </div>

            <CompanyInfo/>
          </div>
        )
        : (
          <CampaignCompanyVerify
            campaign={formData}
            loading={loading}
            errors={errors}
            onChange={setFormData}
          />
        )}

      <div className={Style.action}>
        <div>
          <Switch
            label="Post To KNOWME Candidate Job Marketplace"
            labelClassName="body2-medium txt-black-01"
            checked={formData.shareOnMarket}
            onChange={() => {
              analytic(ETrackingEvent.CHECKBOX_MARKETPLACE, { value: !formData.shareOnMarket })
              setFormData((prev) => ({
                ...prev,
                shareOnMarket: !formData.shareOnMarket
              }))
            }}
          />
        </div>
        <div className="fx fx-ai-center gap-3">
          <Button
            variant="secondary"
            disabled={loading}
            onClick={eventHandler(ETrackingEvent.BTN_SAVE_DRAFT, onSubmitDraft)}
          >
            Save Draft
          </Button>
          <Button
            variant="secondary"
            disabled={loading || !canPreview}
            onClick={() => {
              analytic(ETrackingEvent.BTN_PREVIEW)
              DialogService.open(ModalCampaignDetail, {
                campaign: {
                  ...formData,
                  salaryValue: {
                    min: Number(formData.minSalary),
                    max: Number(formData.maxSalary)
                  },
                  jdFileUrl: formData.attachmentFile?.name,
                  author: profile
                }
              })
            }}
          >
            Preview
          </Button>
          <Button
            variant="label"
            onClick={eventHandler(formData.stage === 'info' && !isVerify ? ETrackingEvent.BTN_NEXT : ETrackingEvent.BTN_CONFIRM, onSubmit)}
            disabled={loading}
          >
            {formData.stage === 'info' && !isVerify ? 'Next' : 'Confirm'}
          </Button>
        </div>
      </div>
    </>
  )
}
