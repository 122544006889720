import { useCallback, useEffect, useState } from 'react'

interface MediaPermissions {
  camera: PermissionState | null
  microphone: PermissionState | null
}

export const useMediaPermissions = () => {
  const [permissions, setPermissions] = useState<MediaPermissions>({
    camera: null,
    microphone: null
  })

  const updatePermissionStatus = useCallback((type: keyof MediaPermissions, status: PermissionStatus) => {
    setPermissions((prev) => ({
      ...prev,
      [type]: status.state
    }))
  }, [])

  const requestPermissions = useCallback(() => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      .then(async (stream) => {
        await new Promise<void>((resolve) => {
          const tracks = stream.getTracks()
          const closeAll = (): void => {
            let hasRunning = false
            for (const track of tracks) {
              if (track.readyState !== 'ended') {
                hasRunning = true
                track.stop()
              }
            }
            if (hasRunning) {
              setTimeout(closeAll, 100)
              return
            }
            resolve()
          }

          closeAll()
        })

        setPermissions({
          camera: 'granted',
          microphone: 'granted'
        })
      })
      .catch((error) => {
        console.log(error)
        setPermissions({
          camera: 'denied',
          microphone: 'denied'
        })
      })
  }, [])

  useEffect(() => {
    const permissionTypes: (keyof MediaPermissions)[] = ['camera', 'microphone']
    const permissionStatuses: Partial<Record<keyof MediaPermissions, PermissionStatus>> = {}

    const watchPermissions = async () => {
      try {
        for (const type of permissionTypes) {
          const status = await navigator.permissions.query({ name: type as PermissionName })
          permissionStatuses[type] = status
          updatePermissionStatus(type, status)
          status.onchange = () => updatePermissionStatus(type, status)
        }
      } catch (error) {
        console.log(error)
      }
    }

    watchPermissions()

    return () => {
      for (const type of permissionTypes) {
        if (permissionStatuses[type]?.onchange) {
          (permissionStatuses[type] as any).onchange = null
        }
      }
    }
  }, [updatePermissionStatus])

  return {
    permissions,
    requestPermissions
  }
}
