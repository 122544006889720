import { ArchiveRoutes } from 'src/modules/archive/routes'
import { EArchiveRoutes } from 'src/modules/archive/routes.enum'
import { AuthRoutes } from 'src/modules/auth/routes'
import { EAuthRoutes } from 'src/modules/auth/routes.enum'
import { CampaignRoutes } from 'src/modules/campaign/routes'
import { ECampaignRoutes } from 'src/modules/campaign/routes.enum'
import { ClaimJobModule } from 'src/modules/claim-jobs/routes'
import { Notfound } from 'src/modules/core/components/404'
import { CoreRoutes } from 'src/modules/core/routes'
import { ECoreRoutes } from 'src/modules/core/routes.enum'
import { HomeRoutes } from 'src/modules/home/routes'
import { EHomeRoutes } from 'src/modules/home/routes.enum'
import { IntrosRoutes } from 'src/modules/intros/routes'
import { EIntrosRoutes } from 'src/modules/intros/routes.enum'
import { LikesRoutes } from 'src/modules/likes/routes'
import { ELikesRoutes } from 'src/modules/likes/routes.enum'
import { PaymentHistoryRoutes } from 'src/modules/payment-history/routes'
import { EPaymentHistoryRoutes } from 'src/modules/payment-history/routes.enum'
import { PlaygroundRoutes } from 'src/modules/playground/routes'
import { EPlayGroundRoutes } from 'src/modules/playground/routes.enum'
import { SettingRoutes } from 'src/modules/setting/routes'
import { ESettingRoutes } from 'src/modules/setting/routes.enum'
import { ShareRoutes } from 'src/modules/share/routes'
import { EShareRoutes } from 'src/modules/share/routes.enum'
import { TalentRoutes } from 'src/modules/talent/routes'
import { ETalentRoutes } from 'src/modules/talent/routes.enum'
import { VibesRoutes } from 'src/modules/vibes/routes'
import { EVibesRoutes } from 'src/modules/vibes/routes.enum'
import { VideosRoutes } from 'src/modules/videos/routes'
import { EVideosRoutes } from 'src/modules/videos/routes.enum'
import { WatchRoutes } from 'src/modules/watch/routes'
import { EWatchRoutes } from 'src/modules/watch/routes.enum'
import { RedirectNotificationRoutes } from '../modules/redirect-notification/routes'
import { ERedirectNotificationRoutes } from '../modules/redirect-notification/routes.enum'
import { IRouterOption } from './interface'

export const routes: IRouterOption[] = [
  ...ArchiveRoutes,
  ...RedirectNotificationRoutes,
  ...AuthRoutes,
  ...CampaignRoutes,
  ...ClaimJobModule.Routes,
  ...CoreRoutes,
  ...HomeRoutes,
  ...IntrosRoutes,
  ...LikesRoutes,
  ...PaymentHistoryRoutes,
  ...SettingRoutes,
  ...ShareRoutes,
  ...VibesRoutes,
  ...WatchRoutes,
  ...PlaygroundRoutes,
  ...TalentRoutes,
  ...VideosRoutes,
  // last route handle 404 error
  {
    path: '*',
    component: Notfound
  }
]

export const ERoutes = {
  ...EArchiveRoutes,
  ...EAuthRoutes,
  ...ECampaignRoutes,
  ...ClaimJobModule.ERoutes,
  ...ECoreRoutes,
  ...ERedirectNotificationRoutes,
  ...EHomeRoutes,
  ...EIntrosRoutes,
  ...ELikesRoutes,
  ...EPaymentHistoryRoutes,
  ...EPlayGroundRoutes,
  ...ESettingRoutes,
  ...EShareRoutes,
  ...EVibesRoutes,
  ...EWatchRoutes,
  ...ETalentRoutes,
  ...EVideosRoutes
}
