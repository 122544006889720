import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconChatIllustration: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 121 } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="121" height="121" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_12905_65382)">
          <ellipse cx="28.5" cy="53" rx="26" ry="22.5" fill="#AFF6C5"/>
          <ellipse cx="87.5" cy="62" rx="26" ry="22.5" fill="#AFF6C5"/>
          <path d="M111.316 43.14C100.616 27.2398 72.4556 27.9723 61.0965 42.8122C54.975 33.8232 42.405 28.5591 32.1578 28.2982C19.3416 27.9718 7.30652 38.0413 5.68827 50.755C4.15214 62.8194 12.1757 74.847 23.9312 77.9787C23.1976 82.5613 21.6937 86.4549 18.1587 89.7006C17.2117 90.5696 18.0184 91.9485 19.1341 92.0415C27.8047 92.7662 36.77 87.9312 40.3058 79.8974C40.3418 79.8161 40.3649 79.7359 40.3858 79.6562C47.594 78.3237 54.6878 74.5672 59.3631 69.0886C61.4843 76.476 69.8968 81.0052 77.3693 80.6852C77.1446 89.9717 87.0493 95.7447 95.5777 94.2714C96.6674 94.083 96.8158 92.6771 96.1861 91.9809C93.4508 88.9553 92.3596 85.6431 91.942 81.7681C110.975 80.6554 122.018 59.0422 111.316 43.14ZM39.4339 76.8347C38.7069 76.9649 38.3834 77.4713 38.3636 78.0233C38.2012 78.1363 38.0521 78.2948 37.9315 78.5175C34.5896 84.6815 29.0029 88.6471 22.21 89.2514C24.7239 85.8961 26.0515 81.8439 25.3996 77.5506C25.3787 77.4126 25.3061 77.3098 25.2145 77.2271C25.1597 77.0079 25.0177 76.8034 24.7487 76.6957C14.3836 72.5394 6.58276 61.5275 8.66274 50.1614C10.6524 39.2885 21.0746 30.8819 32.1578 31.1887C43.2146 31.4945 57.7121 38.2487 61.1172 49.5263C65.4385 63.8371 52.1113 74.5661 39.4339 76.8347ZM91.1279 78.7323C90.34 78.7851 89.8655 79.2979 89.7016 79.8901C89.4919 80.0922 89.3362 80.3716 89.3014 80.7468C88.9357 84.7168 90.3151 88.5927 92.5725 91.8233C85.508 91.9339 80.9103 86.6296 79.1278 79.9121C79.5524 79.0822 79.1842 77.875 77.9847 77.943C70.0759 78.3902 64.4631 73.9305 60.5517 67.5746C63.9138 62.974 65.6197 57.3276 64.493 50.9845C64.0602 48.5476 63.1797 46.283 61.9645 44.1965C62.0459 44.1598 62.1252 44.1133 62.1958 44.0414C73.5187 32.4746 98.1056 29.615 108.436 44.0189C118.851 58.5402 108.478 77.5633 91.1279 78.7323Z" fill="black"/>
          <path d="M20.3494 55.0052C20.1008 55.0943 19.9069 55.3208 19.8107 55.5753C18.8676 55.8341 18.2532 56.7678 18.2699 57.7567C18.292 59.0823 19.4885 59.9749 20.7726 59.8941C22.0939 59.8109 23.2055 58.7549 23.1284 57.3976C23.0588 56.1708 21.7464 54.5066 20.3494 55.0052ZM20.6031 58.1815C19.5402 58.3195 19.5294 57.0824 20.0338 56.4346C20.0723 56.461 20.1038 56.4913 20.1512 56.5124C20.6397 56.7257 21.2178 56.802 21.3192 57.4382C21.3898 57.8815 21.0025 58.1296 20.6031 58.1815Z" fill="black"/>
          <path d="M36.1916 56.111C36.0941 55.9231 35.9061 55.7831 35.6268 55.7797C34.3225 55.7636 33.4641 57.119 33.5212 58.308C33.5852 59.642 34.8211 60.6069 36.1293 60.4992C37.3632 60.3975 38.48 59.4966 38.4032 58.1852C38.3353 57.0197 37.3883 56.0371 36.1916 56.111ZM36.0639 58.7822C34.9112 58.7347 35.3876 57.4239 35.9632 57.0143C36.0757 56.934 36.1507 56.8288 36.2012 56.7162C37.1078 57.1312 37.3593 58.8355 36.0639 58.7822Z" fill="black"/>
          <path d="M55.3962 54.5627C54.2902 54.275 53.1481 54.7252 52.5777 55.6568C52.243 55.5492 51.7668 55.7787 51.739 56.1995L51.6423 57.6572C51.6145 58.0746 51.4752 58.6212 51.6952 59.0058C52.0481 59.6218 53.0767 59.8905 53.7284 59.9663C55.2354 60.1425 56.7066 59.2901 57.1294 57.8035C57.5192 56.4339 56.8272 54.9346 55.3962 54.5627ZM55.1545 57.0974C55.0282 58.0917 53.9865 58.2307 53.174 57.9905C53.1323 57.7003 53.0908 57.4106 53.0492 57.1204C53.4446 57.1806 53.8824 57.0279 54.0491 56.5509C54.1389 56.2929 54.5238 56.2103 54.7649 56.2797C55.1203 56.3825 55.1973 56.7602 55.1545 57.0974Z" fill="black"/>
          <path d="M74.049 57.9063C73.8964 57.4664 73.4271 57.1205 72.9403 57.2805C72.3734 57.4669 71.8064 57.6534 71.2394 57.8398C71.0285 57.9093 70.8919 58.0375 70.7932 58.1843C70.5183 58.3052 70.2705 58.5013 70.0921 58.7651C69.5219 59.6077 69.8423 60.6754 70.6808 61.2019C71.4998 61.7162 72.6126 61.5875 73.3358 60.9749C74.2193 60.2263 74.4152 58.9618 74.049 57.9063ZM71.6491 59.8274C71.3996 59.7947 71.3058 59.6713 71.3056 59.5363C71.4259 59.5568 71.5574 59.5539 71.703 59.5113L72.336 59.3258C72.2503 59.641 72.0453 59.8798 71.6491 59.8274Z" fill="black"/>
          <path d="M90.29 58.0455C90.0444 57.3174 89.5473 56.6646 88.8686 56.2859C88.432 56.0421 87.8596 55.8557 87.3922 56.1278C87.2798 56.1934 87.1725 56.2736 87.0703 56.3641C87.0662 56.3646 87.0632 56.3627 87.0591 56.3627C85.5431 56.5006 84.4857 57.9579 84.6483 59.4464C84.8349 61.1547 86.6385 62.081 88.2297 61.7413C89.8982 61.3856 90.8259 59.6329 90.29 58.0455ZM88.2952 59.2145C88.2031 59.6363 87.7523 59.8061 87.3531 59.7924C86.8693 59.7763 86.5046 59.4958 86.4455 59.0055C86.4229 58.8186 86.4539 58.6449 86.5097 58.4805C86.8124 58.73 87.2419 58.8338 87.6061 58.6043C87.7548 58.5103 87.8893 58.4041 88.0244 58.2985C88.2427 58.5461 88.3638 58.8994 88.2952 59.2145Z" fill="black"/>
          <path d="M103.06 57.1196C102.589 56.9518 102.062 56.9283 101.558 57.0168C101.437 56.9327 101.282 56.9004 101.091 56.9767C100.629 57.1627 100.264 57.4719 99.9868 57.8419C99.9575 57.8747 99.9338 57.9118 99.9065 57.9461C99.8765 57.9906 99.8455 58.0337 99.8182 58.0801C99.6585 58.3151 99.5295 58.5744 99.4747 58.8783C99.4665 58.9238 99.4754 58.9683 99.4715 59.0138C99.3762 59.5555 99.4341 60.1314 99.6698 60.6648C100.415 62.354 102.627 62.3529 103.84 61.1698C105.137 59.9044 104.826 57.7499 103.06 57.1196ZM102.308 59.7586C102.055 60.0287 101.721 60.0972 101.448 59.9621C101.576 59.8324 101.672 59.6754 101.703 59.489C101.725 59.3485 101.84 59.1909 101.707 59.347C101.817 59.2183 101.7 59.3108 101.862 59.2452C101.712 59.3059 102.03 59.235 102.055 59.2178C102.144 59.2208 102.35 59.2389 102.219 59.2173C102.37 59.2423 102.521 59.299 102.513 59.3969C102.503 59.5163 102.388 59.6719 102.308 59.7586Z" fill="black"/>
          <path d="M17.4645 39.5015C13.9301 42.2521 12.0443 46.1118 12.0147 50.0104C12.0117 50.3929 12.7245 50.3843 12.7584 50.0104C13.1095 46.1436 14.9513 42.9516 18.1421 40.0375C18.5422 39.6721 17.9095 39.1553 17.4645 39.5015Z" fill="black" stroke="black" stroke-miterlimit="10"/>
          <path d="M106.977 47.108C105.138 43.3865 101.513 40.2473 97.7329 38.5872C97.4062 38.4437 97.1168 38.898 97.4458 39.0757C101.317 41.1675 103.944 43.8474 106.377 47.4563C106.627 47.8267 107.174 47.5072 106.977 47.108Z" fill="black" stroke="black" stroke-miterlimit="10"/>
          <path d="M55.3811 27.6838C52.9935 24.8106 49.0564 23.1364 45.4075 22.6844C45.0154 22.6361 44.9243 23.2999 45.3137 23.3735C49.1235 24.0931 51.8714 25.8172 54.7575 28.3041C55.1908 28.6774 55.7413 28.1175 55.3811 27.6838Z" fill="black" stroke="black" stroke-miterlimit="10"/>
          <path d="M59.335 25.9613C58.2339 22.4416 54.3638 20.1689 50.8583 19.8033C50.5266 19.7688 50.4451 20.3413 50.7788 20.3867C54.5348 20.8968 56.7816 23.1562 58.607 26.2666C58.8308 26.6475 59.4715 26.3977 59.335 25.9613Z" fill="black" stroke="black" stroke-miterlimit="10"/>
        </g>
        <defs>
          <clipPath id="clip0_12905_65382">
            <rect width="120" height="120" fill="white" transform="translate(0.5 0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  )
}
