import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { ProfileApi } from 'src/api'
import { Avatar, Button, Spinner } from 'src/components'
import { useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { IconCameraStroke } from 'src/icons'
import { SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { getFirstCompany } from 'src/utils'

export const CompanyInfo: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const profile = useBehaviorMapper(AuthModule.profile$)
  const company = useMemo(() => getFirstCompany(profile), [profile])
  const companyUrl = useMemo(() => company?.url || company?.urls?.[0], [company])

  const inputFileRef = useRef<HTMLInputElement>(null)
  const [logo, setLogo] = useState<File>()
  const [logoPreview, setLogoPreview] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (logo) {
      const previewUrl = URL.createObjectURL(logo)
      setLogoPreview(previewUrl)
      return () => URL.revokeObjectURL(previewUrl)
    } else {
      setLogo(undefined)
    }
  }, [logo])

  const uploadLogo = useCallback(async (logo: File) => {
    setIsLoading(true)

    from(ProfileApi.updateCompany({ logo }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setIsLoading(false))
      )
      .subscribe(() => {
        SnackbarService.success('Company logo updated successfully')
      })
  }, [unsubscribe$])

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setLogo(file)
      uploadLogo(file)
    }
    e.target.value = ''
  }, [uploadLogo])

  return (
    <div className="p-8 bg-neutral-white round-4 fx-column gap-6 mb-15" style={{ maxWidth: 950 }}>
      <div className="fs-18 fw-600 txt-black-01">Company Information</div>
      <div className="fx-1 fx fx-ai-center gap-3">
        <div className="relative pl-3 pr-10">
          <input
            ref={inputFileRef}
            type="file"
            accept="image/*"
            className="d-none"
            onChange={onFileChange}
          />
          <div className="fx fx-column fx-ai-center gap-2">
            <div className="relative">
              <Avatar
                size={80}
                fullName={company?.name ?? ''}
                avatarUrl={logoPreview || company?.logo?.url}
                className="txt-neutral-white"
              />
              <Button
                disabled={isLoading}
                variant="icon"
                className="absolute"
                style={{ bottom: 0, right: 0, transform: 'translate(25%, 25%)' }}
                onClick={() => !isLoading && inputFileRef?.current?.click()}
              >
                <IconCameraStroke size={20}/>
              </Button>
            </div>
            {isLoading && <div className="absolute" style={{ top: 20 }}><Spinner/></div>}
          </div>
        </div>

        <div className="fx-column gap-6 py-5 px-10" style={{ borderLeft: '1px solid #E2E2E2' }}>
          <div className="fx fx-ai-center gap-5">
            <div
              className="fs-14 fw-500 txt-neutral-50"
              style={{ minWidth: 80 }}
            >
              Name
            </div>
            <div className="fs-14 fw-400 txt-black-01 fx-1 fx-column">
              {company?.name || <span className="fs-10 txt-neutral-N500">N/A</span>}
            </div>
          </div>

          <div className="fx fx-ai-center gap-5">
            <div
              className="fs-14 fw-500 txt-neutral-50"
              style={{ minWidth: 80 }}
            >
              Website
            </div>
            <div className="fs-14 fw-400 txt-black-01 fx-1 fx-column">
              {companyUrl || <span className="fs-10 txt-neutral-N500">N/A</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
