import clsx from 'clsx'
import { ChangeEvent, ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi, VideoApi } from 'src/api'
import { Button, Input, ModalCenter, VideoPlayer } from 'src/components'
import { useAsRef, useBehaviorMapper, useUnsubscribe, useUnsubscribeEffect, useValidation } from 'src/hooks'
import { IconEdit, IconLinking } from 'src/icons'
import { ICampaignModel, IVideoModel } from 'src/interfaces'
import { browserHistory, ERoutes, generate } from 'src/router'
import { DialogService, SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import {
  StyleUtils,
  VideoUtils
} from 'src/utils'
import { object, string } from 'yup'
import { ModalCampaignDetail } from '../modal-campaign-detail'
import Style from './style.module.scss'

interface IProps {
  video: IVideoModel & { usedForCampaigns?: number }
  onChange?: (values: Partial<IVideoModel>) => void
}

export const ModalMyVideoDetail: FC<ComponentProps<typeof ModalCenter> & IProps> = ({ video, ...props }) => {
  const unsubscribe$ = useUnsubscribe()
  const profile = useBehaviorMapper(AuthModule.profile$)
  const [campaigns, setCampaigns] = useState<ICampaignModel[]>([])
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [formData, setFormData] = useState<Partial<typeof video>>({})

  const onChangeRef = useAsRef(props.onChange)

  const schema = useMemo(() => object().shape({
    title: string().required().label('Title')
  }), [])

  const { errors, validate, reset } = useValidation({
    data: formData,
    schema
  })

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const onSubmit = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    if (!formData.title?.trim() || formData.title?.trim() === video.title?.trim()
    ) {
      return
    }

    setLoading(true)
    const newTitle = formData.title?.trim()
    from(VideoApi.update(video.id, { title: newTitle }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        video.title = newTitle
        onChangeRef.current?.({ title: newTitle })
        setEditing(false)
        SnackbarService.success('Video updated successfully')
      })
  }, [formData.title, onChangeRef, unsubscribe$, validate, video])

  useEffect(() => {
    if (!editing) {
      reset()
      setLoading(false)
      setFormData({})
    } else {
      setFormData({ title: video.title })
    }
  }, [editing, video.title, reset])

  useUnsubscribeEffect((unsubscribe$) => {
    if (!video.id) return

    from(CampaignApi.paginate({ uploadVideoId: video.id }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          browserHistory.push(generate(ERoutes.ERROR_404))
          return EMPTY
        })
      )
      .subscribe(({ data }) => {
        setCampaigns(data)
      })
  }, [video.id])

  return (
    <ModalCenter {...props}>
      <div className={clsx(Style.modalMyVideoDetail, 'fx fx-ai-center fx-jc-center')}>
        <div className="fx gap-6">
          <div
            className="pointer round-3 overflow-hidden"
            style={{
              width: 260,
              aspectRatio: '9/16',
              ...StyleUtils.backgroundCover(video?.urlVideoImageThumbnail)
            }}
          >
            <VideoPlayer
              url={VideoUtils.getVideoSource(video) ?? ''}
              tracks={video?.tracks}
              image={video?.urlVideoImageThumbnail}
            />
          </div>

          <div className="fx fx-column gap-6" style={{ width: 460 }}>
            {editing
              ? (
                <div className="fx fx-ai-center gap-4">
                  <div className="w-full">
                    <Input
                      autoFocus
                      innerError
                      name="title"
                      placeholder="e.g., VVC 1"
                      disabled={loading}
                      value={formData?.title}
                      error={errors?.getError('title')}
                      onChange={onChange}
                    />
                  </div>

                  <div className="fx fx-ai-center gap-2">
                    <Button
                      variant="secondary"
                      disabled={loading}
                      onClick={() => setEditing((prev) => !prev)}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={loading || !formData.title?.trim() || formData.title?.trim() === video.title?.trim()}
                      onClick={onSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              )
              : (
                <div className="fx fx-ai-center gap-4">
                  <div className="fs-28 fw-600 txt-black-01 three-dot-1">{video.title || '---'}</div>
                  <Button variant="icon" onClick={() => setEditing((prev) => !prev)}>
                    <IconEdit/>
                  </Button>
                </div>
              )}

            {/* {video?.urlVideoSource && (
              <Button variant="icon" onClick={() => FileUtils.download(video?.urlVideoSource ?? '', video.title)}>
                <IconDownload/>
              </Button>
            )} */}

            {!!campaigns?.length && (
              <div>
                <div className="fs-16 fw-600 txt-black-01 mb-4">Used For</div>
                <div className="fx fx-column gap-4">
                  {campaigns?.map(item => (
                    <div
                      key={item.id}
                      className="px-4 py-3 bg-neutral-100 round-3 fx fx-ai-center gap-3 pointer"
                      onClick={() => DialogService.open(ModalCampaignDetail, {
                        campaign: {
                          ...item,
                          author: profile
                        }
                      })}
                    >
                      <div className="fs-12 fw-700 txt-black-01">{item.jobTitle}</div>
                      <IconLinking/>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalCenter>
  )
}
