import { Avatar, AvatarGroup, MenuItem, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import moment from 'moment'
import { FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { Button, StyledMenu, Tooltip, useAnalytic } from 'src/components'
import { ECampaignStatus, ETrackingEvent } from 'src/enums'
import { useBehaviorMapper, useElementSize, useUnsubscribe } from 'src/hooks'
import { IconDuplicate, IconLink, IconMore, IconPencilStroke, IconTrashCan } from 'src/icons'
import { ICampaignModelExtended } from 'src/interfaces'
import { DeleteConfirmPopup } from 'src/modules/campaign/components/delete-confirm-popup'
import { ECampaignRoutes } from 'src/modules/campaign/routes.enum'
import { getCampaignShareLink } from 'src/modules/campaign/utils'
import { ModalCampaignDetail } from 'src/partials/modal-campaign-detail'
import { browserHistory, ERoutes, generate } from 'src/router'
import { CampaignMutationService, DialogService, OverlayService, SnackbarService } from 'src/services'
import { AuthModule, LoadingModule } from 'src/store'
import { getApiErrorMessage, getAvatar, getFirstCompany, getFullName, stringAvatar, stringGrayAvatar } from 'src/utils'
import { CampaignOwners } from '../campaign-owners'
import Style from './style.module.scss'

const avatarCompanyStyle = { width: '70px', height: '70px', borderRadius: '6px', fontSize: '10px' }
const avatarSize = { height: '16px', width: '16px' }
const logoCompany = { height: '70px', width: '70px', borderRadius: '6px' }

interface IProps {
  htmlId?: string
  campaign: ICampaignModelExtended
  isSandbox?: boolean
  onAfterDelete?: (deletedItem: { id?: number; draftId?: number }) => void
  onTourTrigger?: () => void
  fetchCount?: () => void
}

export const CardJob: FC<IProps> = ({ htmlId, campaign, isSandbox, onAfterDelete, onTourTrigger, fetchCount }) => {
  const { id, companyName, companyDomain, jobTitle, submissions = [], draftId, slug } = campaign

  const unsubscribe$ = useUnsubscribe()
  const profile = useBehaviorMapper(AuthModule.profile$)
  const loading = useBehaviorMapper(LoadingModule.loading$)
  const { analytic, eventHandler } = useAnalytic('my_jobs')

  const logoUrl = useMemo(() => {
    if (campaign?.logoUrl) {
      return campaign.logoUrl
    }
    const company = getFirstCompany(profile)
    if (company?.logo?.url) {
      return company.logo.url
    }
  }, [campaign, profile])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const timerRef = useRef<any>()

  const goToJob = useCallback((e: any) => {
    e.stopPropagation()
    setAnchorEl(null)

    if (onTourTrigger) {
      return onTourTrigger()
    }

    if (draftId) {
      CampaignMutationService.reset()
      return browserHistory.push(generate(ERoutes.CREATE_CAMPAIGN, { draftId }))
    }

    browserHistory.push(generate([ERoutes.CAMPAIGN_DETAIL, { campaignId: id }]))
  }, [onTourTrigger, draftId, id])

  // const handleClickCard = useCallback((event: MouseEvent<HTMLButtonElement>) => {
  //   event.stopPropagation()
  //   // if (onTourTrigger) {
  //   //   return onTourTrigger()
  //   // }
  //   // if (draftId) {
  //   //   history.push(generate(ERoutes.NEW_CAMPAIGN, { draftId }))
  //   //   return
  //   // }
  //   // history.push(generate([ERoutes.CAMPAIGN_SUBMISSIONS, { id }]))
  //   if (!handleOpenCampaign) return
  //   handleOpenCampaign(campaign)
  // }, [campaign, handleOpenCampaign])

  const onDetails = useCallback(() => {
    LoadingModule.toggle(true)
    analytic(ETrackingEvent.BTN_JOB_DETAILS)
    from(draftId ? CampaignApi.getDraftDetail(draftId) : CampaignApi.getOne(id))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          SnackbarService.error(getApiErrorMessage(error))
          return EMPTY
        }),
        finalize(() => LoadingModule.toggle(false))
      )
      .subscribe(({ data }) => {
        DialogService.open(ModalCampaignDetail, { campaign: { ...data, author: profile } })
      })
  }, [draftId, id, profile, unsubscribe$, analytic])

  const handleOnDelete = useCallback(async () => {
    if (onTourTrigger) {
      return onTourTrigger()
    }

    if (draftId) {
      await CampaignApi.deleteDraft(draftId)
      onAfterDelete?.({ draftId })
      OverlayService.reset()
      return
    }

    if (id) {
      await CampaignApi.archive(id)
      onAfterDelete?.({ id })
      OverlayService.reset()
    }
  }, [id, draftId, onAfterDelete, onTourTrigger])

  const handleDelete = useCallback((e: any) => {
    if (onTourTrigger) {
      return onTourTrigger()
    }

    e.stopPropagation()
    setAnchorEl(null)

    OverlayService.setOverlay({
      content: (
        <DeleteConfirmPopup
          onCancel={() => OverlayService.reset()}
          onDelete={handleOnDelete}
        />
      ),
      open: true
    })
  }, [handleOnDelete, onTourTrigger])

  const handleCopyLink = useCallback(async (e: any) => {
    if (onTourTrigger) {
      return onTourTrigger()
    }

    e.stopPropagation()
    await navigator.clipboard.writeText(getCampaignShareLink(slug))

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    setAnchorEl(null)
  }, [slug, onTourTrigger])

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e: any) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const onDuplicate = async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    from(CampaignApi.replicate(campaign.id))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        })
      ).subscribe(() => {
        fetchCount?.()
        browserHistory.replace(generate(ERoutes.CAMPAIGN_MY_JOBS, { tab: 1 }))
      })
  }

  const titleRef = useRef<HTMLSpanElement>(null)
  const [, titleHeight] = useElementSize(titleRef.current)
  const [reached, setReached] = useState(false)
  const [tooltipTitleEnable, setTooltipTitleEnable] = useState(true)
  const handleOwnerTooltipOpen = useCallback(() => {
    setTooltipTitleEnable(false)
  }, [])

  const handleOwnerTooltipClose = useCallback(() => {
    setTooltipTitleEnable(true)
  }, [])

  useEffect(() => {
    try {
      if (!titleRef.current) {
        return
      }

      const lineHeight = parseInt(window.getComputedStyle(titleRef.current).lineHeight.slice(0, -2)) // to remove px

      const lineCount = Math.ceil(titleHeight / lineHeight)
      const reached = lineCount > 2

      setReached(reached)
    } catch (error) {
      console.log('[card job] error: ', error)
      setReached(true)
    }
  }, [titleRef, titleHeight])

  const tooltipTitleSx = useMemo(() => {
    if (tooltipTitleEnable) {
      return {}
    }

    return {
      tooltip: Style.popperHidden
    }
  }, [tooltipTitleEnable])

  return (
    <div
      id={htmlId}
      className={Style.cardJob}
      onClick={() => [ECampaignStatus.PUBLISHED, ECampaignStatus.PENDING].includes(campaign.status as ECampaignStatus) && browserHistory.push(generate([ECampaignRoutes.CAMPAIGNS_APPLICANTS, { campaignId: campaign.id }]))}
    >
      <div className={Style.header}>
        {logoUrl
          ? <Avatar variant="square" src={logoUrl} sx={logoCompany}/>
          : <Avatar {...stringGrayAvatar(companyName, avatarCompanyStyle)}/>}
        <Tooltip
          title={jobTitle || 'Untitled'}
          placement="top"
          disableFocusListener={!reached}
          disableHoverListener={!reached}
          disableTouchListener={!reached}
          arrow
          classes={tooltipTitleSx}
        >
          <div className="fx flex-column fx-ai-start fx-jc-center ml-3">
            <span
              ref={titleRef}
              className={clsx('body1-bold color-neutral-theme-900 three-dot-2')}
              style={{ lineHeight: 1.4, wordBreak: 'break-word' }}
            >
              {jobTitle || 'Untitled'}
            </span>
            <div className="fx fx-ai-center fx-wrap-wrap">
              {/* TODO: replace with UserAvatar */}
              <AvatarGroup
                max={3}
                total={submissions.length}
                sx={{
                  '& .MuiAvatar-root': {
                    marginLeft: '-4px'
                  },
                  '& .MuiAvatarGroup-avatar': {
                    width: 16,
                    height: 16,
                    fontSize: 8
                  }
                }}
              >
                {submissions.slice(0, 3).map((submission, index) => (
                  <Avatar
                    sizes="16"
                    key={index}
                    alt="avatar"
                    src={getAvatar(submission.author)}
                    {...stringAvatar(getFullName(submission.author), avatarSize)}
                  />
                ))}
              </AvatarGroup>
              <span>
                <span className="body2-regular color-neutral-theme-800 fx-shrink-0">
                  {submissions.length} {(submissions.length === 0 || submissions.length > 1) ? 'Candidates' : 'Candidate'}
                </span>
                {/* {true && <span className="body2-bold color-orange-500">&nbsp;&bull; 2 New</span>} */}
              </span>
            </div>
            <div className="body2-regular">
              Posted {moment(campaign?.createdAt).format('MMM DD')}
            </div>
          </div>
        </Tooltip>
      </div>

      {!isSandbox && (
        <div className="fx fx-ai-center fx-jc-between gap-2">
          <div className={Style.content}>
            <Button
              disabled={loading}
              className="round-16 px-4 py-0"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onDetails()
              }}
            >
              Job Details
            </Button>

            <div className="fx flex-row fx-ai-center">
              {companyDomain && id && (
                <CampaignOwners
                  id={id}
                  onOpen={handleOwnerTooltipOpen}
                  onClose={handleOwnerTooltipClose}
                  companyDomain={companyDomain}
                />
              )}

              <Button
                variant="secondary"
                className={Style.buttonMore}
                onClick={handleOpenMenu}
              >
                <IconMore size={16}/>
              </Button>

              <StyledMenu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                className={Style.menu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                {campaign.status === ECampaignStatus.PUBLISHED && (
                  <MenuItem disableRipple className={Style.menuItem} onClick={eventHandler(ETrackingEvent.BTN_DUPLICATE_JOB, onDuplicate)}>
                    <IconDuplicate size={20}/>
                    <Typography variant="body2-regular">Duplicate</Typography>
                  </MenuItem>
                )}
                {draftId && (
                  <MenuItem disableRipple className={Style.menuItem} onClick={eventHandler(ETrackingEvent.BTN_EDIT_JOB, goToJob)}>
                    <IconPencilStroke size={20}/>
                    <Typography variant="body2-regular">Edit</Typography>
                  </MenuItem>
                )}
                {!draftId && (
                  <MenuItem disableRipple className={Style.menuItem} onClick={eventHandler(ETrackingEvent.BTN_JOB_COPY_LINK, handleCopyLink)}>
                    <IconLink size={20}/>
                    <Typography variant="body2-regular">Copy Link</Typography>
                  </MenuItem>
                )}
                <MenuItem disableRipple className={Style.menuItem} onClick={eventHandler(ETrackingEvent.BTN_DELETE_JOB, handleDelete)}>
                  <IconTrashCan size={20}/>
                  <Typography variant="body2-regular">Delete</Typography>
                </MenuItem>
                {/* {!draftId && !isClosed && (
                  <MenuItem
                    disableRipple
                    className={Style.menuItem}
                    onClick={() => console.log('TODO: close job')}
                  >
                    <IconClose size={20} color="currentColor"/>
                    <Typography variant="body2-regular">
                      Close Job
                    </Typography>
                  </MenuItem>
                )} */}
              </StyledMenu>
            </div>
          </div>

          {campaign.status === ECampaignStatus.PENDING && (
            <div className="fs-12 fw-600 txt-black-01 px-3 py-2 bg-color-orange-300" style={{ borderRadius: '8px 0 8px 0' }}>Pending</div>
          )}
        </div>
      )}
    </div>
  )
}
