import {
  LAYOUT_SET_PAGE_TITLE,
  LAYOUT_SET_SCROLL_TO_BOTTOM
} from '../types'
import { ILayoutAction } from '../types/layout'

/**
 * @deprecated
 */
export const setLayoutPageTitle = (value: string): ILayoutAction => ({
  type: LAYOUT_SET_PAGE_TITLE,
  value
})

export const setLayoutScrollToBottom = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_SCROLL_TO_BOTTOM,
  value
})
