import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CandidateApi, PeopleApi } from 'src/api'
import { Button, DialogConfirmUnMatch, Modal, Textarea } from 'src/components'
import { useBehaviorMapper, useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { IconArrow, IconEmailFilled, IconLinkedInStroke, IconLocationStroke, IconPhoneStroke } from 'src/icons'
import { ICandidateModel, IReactionModel, IVideoModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { DialogService, SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import {
  ReactionUtils,
  convertPhoneNumToInternationalFormat,
  getFullName,
  getLocation,
  removePrefixPhoneNumber,
  renderVideoTitle
} from 'src/utils'
import { TalentImageCard } from '../card/talent-image-card'
import Style from './style.module.scss'

interface IProps extends Pick<ComponentProps<typeof Modal>, 'open' | 'onClose'> {
  lookupId?: string
  pfv?: IVideoModel
  onPrevious?: () => void
  onNext?: () => void
  canPreviousProps?: boolean
  canNextProps?: boolean
  talent?: Partial<ICandidateModel>
}

export const ModalTalentDetail: FC<IProps> = ({
  lookupId,
  pfv,
  onPrevious,
  onNext,
  canPreviousProps,
  canNextProps,
  talent: _talent,
  ...props
}) => {
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  const [talent, setTalent] = useState<Partial<ICandidateModel>>(_talent || {})
  useEffect(() => {
    setTalent((prev) => ({ ...prev, pfv }))
  }, [pfv])
  useEffect(() => {
    if (_talent) {
      setTalent(_talent)
    }
  }, [_talent])

  const [privateNotes, setPrivateNotes] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const isEmphasis = useMemo(
    () => ReactionUtils.isEmphasis(talent?.reaction),
    [talent?.reaction]
  )

  const isContact = useMemo(
    () => Boolean(talent?.userPhoneNo || talent?.email || talent?.linkedInUrl),
    [talent?.email, talent?.linkedInUrl, talent?.userPhoneNo]
  )

  const handleSavePrivateNotes = useCallback(() => {
    if (!talent?.id) return

    from(CandidateApi.note(talent.id, { content: privateNotes }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        })
      )
  }, [talent?.id, privateNotes, unsubscribe$])

  const onToggleLike = useCallback(() => {
    if (!talent?.id) return

    setLoading(true)
    from(PeopleApi.toggleLike({ id: talent?.id }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        setTalent(prev => ({ ...(prev as ICandidateModel), reaction: data as IReactionModel || null }))
      })
  }, [talent?.id, unsubscribe$])

  const onConfirmToggleLike = useCallback(() => {
    if (!isEmphasis) {
      return onToggleLike()
    }

    DialogService.open(DialogConfirmUnMatch, {
      name: getFullName(talent),
      onConfirm: onToggleLike
    })
  }, [isEmphasis, onToggleLike, talent])

  useUnsubscribeEffect((unsubscribe$) => {
    setPrivateNotes('')

    if (!lookupId || !isAuthenticated) return

    from(CandidateApi.detail(lookupId))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          history.push(generate(ERoutes.ERROR_404))
          return EMPTY
        })
      )
      .subscribe(({ data }) => {
        setTalent(prev => ({ ...prev, ...data }))
        setPrivateNotes(data?.privateNote?.content || '')
      })
  }, [lookupId, isAuthenticated])

  return (
    <Modal
      {...props}
      closeBtn
      contentClass="fx-1 fx fx-center"
    >
      <div className={Style.modalTalentDetail}>
        {talent && (
          <div className={Style.videoWrapper} style={{ maxWidth: isEmphasis ? 630 : '100%' }}>
            <div className="fx fx-column gap-6 w-full" style={{ maxWidth: 403 }}>
              <TalentImageCard
                inlinePlayer
                talent={talent}
                loading={loading}
                onToggleLike={onConfirmToggleLike}
              />
              <div className="fw-600 fs-20 lh-24 txt-black-01 three-dot-1 text-center">
                {renderVideoTitle(talent?.pfv)}
              </div>
            </div>
          </div>
        )}

        <div className="fx fx-ai-center gap-14">
          {/* TODO: clear duplicate */}
          {isEmphasis && (
            <div className={Style.contactUser}>
              {isContact && (
                <div className="fx fx-column gap-3 mb-6 txt-grey-01">
                  <div className="fw-500 txt-black-01">Contact</div>

                  {talent?.userPhoneNo && (
                    <div className="fx fx-ai-center gap-2">
                      <IconPhoneStroke/>
                      <div className="fs-12 three-dot-1">
                        {convertPhoneNumToInternationalFormat(removePrefixPhoneNumber(talent?.userPhoneNo))}
                      </div>
                    </div>
                  )}

                  {talent?.email && (
                    <div className="fx fx-ai-center gap-2">
                      <IconEmailFilled/>
                      <div className="fs-12 three-dot-1">{talent?.email}</div>
                    </div>
                  )}

                  {talent?.linkedInUrl && (
                    <div className="fx fx-ai-center gap-2">
                      <IconLinkedInStroke/>
                      <div className="fs-12 three-dot-1">{talent?.linkedInUrl}</div>
                    </div>
                  )}

                  {getLocation(talent) && (
                    <div className="fx fx-ai-center gap-2">
                      <IconLocationStroke/>
                      <div className="fs-12 three-dot-1">{getLocation(talent)}</div>
                    </div>
                  )}
                </div>
              )}

              <div>
                <div className="fs-16 fw-500 txt-black-01 mb-3">Private Notes</div>
                <Textarea
                  label=""
                  placeholder="Only you can see your private notes"
                  value={privateNotes}
                  autoComplete="off"
                  disableResize
                  minRows={4}
                  maxRows={6}
                  style={{ marginTop: 8, fontSize: '14px', background: 'transparent' }}
                  onChange={(event) => setPrivateNotes(event.target.value)}
                  onBlur={handleSavePrivateNotes}
                />
              </div>
            </div>
          )}

          <div className="fx fx-column gap-4">
            <Button
              size={48}
              variant="icon"
              onClick={onPrevious}
              disabled={canPreviousProps}
            >
              <IconArrow pointTo="top" size={30}/>
            </Button>
            <Button
              size={48}
              variant="icon"
              onClick={onNext}
              disabled={canNextProps}
            >
              <IconArrow pointTo="bottom" size={30}/>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
