import clsx from 'clsx'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { PeopleApi } from 'src/api'
import { Button, DialogConfirmUnMatch, Modal, TabsNav, UserAvatar, useAnalytic } from 'src/components'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { IconArrow, IconChat, IconThumbsUp, IconThumbsUpDup, IconThumbsUpFilled } from 'src/icons'
import { ICampaignModel, ICampaignSubmissionModel, IReactionModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { DialogService, SnackbarService } from 'src/services'
import {
  ReactionUtils,
  StyleUtils,
  VideoUtils,
  getAvatar,
  getFullName,
  renderVideoTitle
} from 'src/utils'
import { ETrackingEvent } from '../../enums'
import { mustVerifiedCompany } from '../modal-verify-company'
import { VideoPlayerTracking } from '../video-player-tracking'
import { Detail } from './detail'
import { Message } from './message'
import Style from './style.module.scss'

interface IProps extends Pick<ComponentProps<typeof Modal>, 'open' | 'onClose'> {
  submission: ICampaignSubmissionModel
  onChange?: (id: number, values: Partial<ICampaignSubmissionModel>) => void
  onPrevious?: () => void
  onNext?: () => void
  canPreviousProps?: boolean
  canNextProps?: boolean
  isSlide?: boolean
  campaign?: ICampaignModel
  onRemoveMultiMessage?: (id: number) => void
}

export const ModalApplicantDetail: FC<IProps> = ({
  submission,
  onChange,
  onPrevious,
  onNext,
  canPreviousProps,
  canNextProps,
  isSlide,
  onRemoveMultiMessage,
  campaign,
  ...props
}) => {
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const onChangeRef = useAsRef(onChange)
  const { analytic } = useAnalytic('talent_details')

  const [reaction, setReaction] = useState<IReactionModel | null>(null)
  const [loading, setLoading] = useState(false)

  const video = useMemo(() => submission?.video, [submission?.video])
  const lookupId = useMemo(() => submission?.author?.lookupId, [submission?.author?.lookupId])
  const isEmphasis = useMemo(() => ReactionUtils.isEmphasis(reaction), [reaction])

  const onToggleLike = useCallback(() => {
    if (!submission.authorId) return

    setLoading(true)
    from(PeopleApi.toggleLike({ id: submission.authorId }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        onChangeRef.current?.(submission.id, { peopleReaction: data || null })
        setReaction(data || null)
      })
  }, [submission.authorId, submission.id, unsubscribe$, onChangeRef])

  useEffect(() => {
    setReaction(submission.peopleReaction || null)
  }, [submission])

  const tabItems = useMemo(() => {
    let items = [
      {
        key: 'detail',
        label: 'Detail',
        children: (
          <Detail
            submission={submission}
            onRemoveMultiMessage={(...args) => {
              onRemoveMultiMessage?.(...args)
              props.onClose?.()
            }}
            onChange={onChange}
            campaign={campaign}
          />
        )
      }

    ]

    if (submission?.author?.id) {
      items = [
        ...items,
        {
          key: 'message',
          label: 'Messages',
          children: <Message lookupId={lookupId}/>
        }
      ]
    }

    return items
  }, [submission, onChange, campaign, onRemoveMultiMessage, props, lookupId])

  const handleTabChange = (key: string) => {
    analytic(key === 'detail' ? ETrackingEvent.BTN_TALENT_DETAILS_TAB_DETAILS : ETrackingEvent.BTN_TALENT_DETAILS_TAB_MESSAGES)
  }

  return (
    <Modal
      {...props}
      closeBtn
      contentClass="fx-1 fx fx-center"
    >
      <div className={clsx(Style.modalApplicantDetail, 'p-8 fx fx-ai-center fx-jc-center')}>
        <div className={Style.applicantWrapper}>
          <div className={Style.videoContainer}>
            {submission && (
              <div className="fx fx-column gap-6">
                <div className="w-full relative bg-neutral-900 fx-ai-center fx-jc-center fx-row round-4 overflow-hidden">
                  <div className="absolute b-6 z-3 w-full fx fx-ai-center fx-jc-space-between gap-4 px-4">
                    <div className="fx fx-ai-center gap-4">
                      <UserAvatar
                        size={32}
                        className="round-10"
                        fullName={getFullName(submission?.author)}
                        avatarUrl={getAvatar(submission?.author)}
                      />

                      {submission?.author?.username && (
                        <div className="fs-12 fw-400 txt-neutral-white three-dot-1">@{submission?.author?.username}</div>
                      )}
                    </div>

                    {onToggleLike && (
                      <div className="fx fx-ai-center gap-3">
                        <Button
                          size={32}
                          variant="icon"
                          green={!!reaction}
                          emphasis={isEmphasis}
                          // disabled={loading}
                          onClick={() => !loading && (
                            isEmphasis
                              ? DialogService.open(DialogConfirmUnMatch, {
                                name: getFullName(submission?.author),
                                onConfirm: onToggleLike
                              })
                              : mustVerifiedCompany(onToggleLike)
                          )}
                        >
                          {!reaction
                            ? <IconThumbsUp size={20}/>
                            : isEmphasis
                              ? <IconThumbsUpDup size={20}/>
                              : <IconThumbsUpFilled size={20}/>}
                        </Button>

                        {lookupId && (
                          <Button
                            size={32}
                            variant="icon"
                            onClick={() => history.push(generate(ERoutes.TALENT_MESSAGES, { lookupId }))}
                          >
                            <IconChat size={20}/>
                          </Button>
                        )}
                      </div>
                    )}
                  </div>

                  <div
                    className="w-full pointer"
                    style={{
                      aspectRatio: '9/16',
                      ...StyleUtils.backgroundCover(video?.urlVideoImageThumbnail)
                    }}
                  >
                    <VideoPlayerTracking
                      videoId={video.id}
                      url={VideoUtils.getVideoSource(video) ?? ''}
                      tracks={video?.tracks}
                      image={video?.urlVideoImageThumbnail}
                      onPlay={playing => playing ? analytic(ETrackingEvent.BTN_TALENT_DETAILS_VVC_PLAY) : analytic(ETrackingEvent.BTN_TALENT_DETAILS_VVC_PAUSED)}
                      onScrub={(value) => analytic(ETrackingEvent.BTN_TALENT_DETAILS_VVC_SCRUB, { value })}
                    />
                  </div>
                </div>

                <div className="bold-14 txt-black-01 three-dot-1 text-center">{renderVideoTitle(submission?.video)}</div>
              </div>
            )}
          </div>

          {/* TODO: clear duplicate */}
          <div className="fx fx-ai-center gap-14">
            <div style={{ width: '650px', minHeight: 671 }}>
              <TabsNav
                onChange={handleTabChange}
                className="fx-1 fx-column gap-4"
                defaultActiveKey="detail"
                items={tabItems}
              />
            </div>

            {isSlide && (
              <div className="fx fx-column gap-4">
                <Button
                  size={48}
                  variant="icon"
                  onClick={onPrevious}
                  disabled={canPreviousProps}
                >
                  <IconArrow pointTo="top" size={30}/>
                </Button>
                <Button
                  size={48}
                  variant="icon"
                  onClick={onNext}
                  disabled={canNextProps}
                >
                  <IconArrow pointTo="bottom" size={30}/>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
