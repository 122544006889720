import clsx from 'clsx'
import {
  CSSProperties,
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useState
} from 'react'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { ChromePermissions } from 'src/images'
import { CameraService } from 'src/services/camera.service'
import { DialogService } from 'src/services/dialog.service'
import { LocalStorage } from 'src/utils'
import { ReactVideoRecorder } from '../fork-react-video-recorder'
import { ModalGetReadyToRecord } from '../modal-get-ready-to-record'
import { Tooltip } from '../tooltip'
import Style from './style.module.scss'
import { STORAGE_KEY, isFirstTimeRecord } from './tooltips'

interface IProps extends ComponentProps<typeof ReactVideoRecorder> {
  question?: string
  className?: string
  style?: CSSProperties
  onStop?: (videoBlob: Blob) => any
}

export const VideoRecorder: FC<IProps> = ({
  question,
  className,
  style,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()

  /**
   * TODO: implement loading
   */
  const [videoConstraints, setVideoConstraints] = useState<MediaTrackConstraintSet>()
  const [permissionError, setPermissionError] = useState<Error>()
  const onErrorRef = useAsRef(props.onError)
  useEffect(() => {
    if (permissionError) {
      onErrorRef.current?.(permissionError)
    }
  }, [permissionError, onErrorRef])

  const onReady = useCallback(() => {
    const promise = CameraService.getBestResolution({
      width: 9,
      height: 16
    })

    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          if (error.name === 'NotAllowedError') {
            setPermissionError(error)
          }
          return EMPTY
        })
      )
      .subscribe((constraints) => {
        setVideoConstraints(constraints)
        setPermissionError(undefined)
      })
  }, [unsubscribe$])

  useEffect(() => {
    DialogService.open(ModalGetReadyToRecord, {
      onUnmounted: () => {
        onReady()
      }
    })
    return () => {
      CameraService.stop()
    }
  }, [unsubscribe$, onReady])

  const [isFirstTime, setIsFirstTime] = useState(false)
  useEffect(() => {
    if (!videoConstraints) {
      return
    }
    const isFirstTime = isFirstTimeRecord()
    setIsFirstTime(isFirstTime)
    if (isFirstTime) {
      const onClickAny = () => {
        LocalStorage.setItem(STORAGE_KEY, true)
        setIsFirstTime(false)
        window.removeEventListener('click', onClickAny)
      }
      window.addEventListener('click', onClickAny)
      return () => {
        window.removeEventListener('click', onClickAny)
      }
    }
  }, [videoConstraints])

  return (
    <>
      <div
        className={clsx(Style.recorderVideo, { 'bg-neutral-white': permissionError }, className)}
        style={style}
      >
        {question && videoConstraints && (
          <div className="fx-column fx-ai-center gap-4 p-6 w-100-p bg-black-02 absolute z-1">
            <div className="heading-16 txt-neutral-white">Answer this question:</div>
            <div className="fx fx-center txt-center p-4 round-4 heading-14 bg-neutral-100">
              {question}
            </div>
          </div>
        )}

        {videoConstraints && (
          <>
            <ReactVideoRecorder
              isOnInitially
              showSwitchCamera={false}
              disabledReplay
              {...props}
              isFlipped
              constraints={{
                audio: true,
                video: videoConstraints
              }}
              renderLoadingView={() => <span/>}
              onRecordingComplete={(videoBlob: Blob) => {
                props.onRecordingComplete?.(videoBlob)
                props.onStop?.(videoBlob)
              }}
            />

            {isFirstTime && (
              <Tooltip
                open
                placement="left"
                title="Click Record button to answer."
              >
                <div
                  style={{
                    position: 'absolute',
                    left: '50%',
                    bottom: '64px',
                    transform: 'translate(-50%, 50%)',
                    width: 80,
                    height: 80
                  }}
                />
              </Tooltip>
            )}
          </>
        )}

        {permissionError && (
          <div className="fx fx-column gap-2">
            <ChromePermissions style={{ maxWidth: '100%' }}/>
            <strong className="px-4">KnowMe is blocked from using your camera</strong>
            <div className="px-4 body2-medium">
              1. Click on this icon
              &nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 -960 960 960">
                <path fill="currentColor" d="M700-130q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm-.235-60Q733-190 756.5-213.265q23.5-23.264 23.5-56.5Q780-303 756.735-326.5q-23.264-23.5-56.5-23.5Q667-350 643.5-326.735q-23.5 23.264-23.5 56.5Q620-237 643.265-213.5q23.264 23.5 56.5 23.5ZM120-240v-60h360v60H120Zm140-310q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm-.235-60Q293-610 316.5-633.265q23.5-23.264 23.5-56.5Q340-723 316.735-746.5q-23.264-23.5-56.5-23.5Q227-770 203.5-746.735q-23.5 23.264-23.5 56.5Q180-657 203.265-633.5q23.264 23.5 56.5 23.5ZM480-660v-60h360v60H480Z"/>
              </svg>
              &nbsp;
              in the browser's address bar
            </div>
            <div className="px-4 body2-medium">2. Enable the camera</div>
          </div>
        )}
      </div>
    </>
  )
}
