import { FC } from 'react'
import { BreadcrumbHeading } from 'src/components'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { Information } from '../information'

export const Setting: FC = () => {
  // const { tab } = useQueryParams()
  // const [tabDefault, setTabDefault] = useState(ESettingTabKey.INFORMATION)

  // useEffect(() => {
  //   if ([ESettingTabKey.INFORMATION, ESettingTabKey.NOTIFICATIONS].includes(tab as ESettingTabKey)) {
  //     setTabDefault(tab as ESettingTabKey)
  //   } else {
  //     browserHistory.push(generate(ERoutes.SETTING, { tab: ESettingTabKey.INFORMATION }))
  //   }
  // }, [tab])

  // const items = useMemo(() => {
  //   return [
  //     {
  //       key: ESettingTabKey.INFORMATION,
  //       label: 'Basic Information',
  //       children: <Information/>
  //     },
  //     {
  //       key: ESettingTabKey.NOTIFICATIONS,
  //       label: 'Notifications',
  //       children: <Notifications/>
  //     }
  //   ]
  // }, [])

  return (
    <>
      <Breadcrumbs>
        <BreadcrumbHeading active>Settings</BreadcrumbHeading>
      </Breadcrumbs>

      <section className="fx-1 fx fx-column gap-5 p-8">
        {/* <TabsNav
          className="fx-1 fx-column gap-8"
          defaultActiveKey={tabDefault}
          items={items}
          onChange={(tab) => browserHistory.push(generate(ERoutes.SETTING, { tab }))}
        /> */}
        <Information/>
      </section>
    </>
  )
}
