import PropTypes from 'prop-types'
import styled from 'styled-components'

const Button = styled.button`
  color: black;
  background: #F7FB6F;
  border-radius: 50%;
  width: 62.44px;
  height: 62.44px;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 5;
  :hover {
    background: #F2F92F;
  }
`

const RecWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonBorder = styled.div`
  border: 3px solid #92961d;
  background: transparent;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

const Instructions = styled.div`
  font-family: Arial;
  font-size: 1.4rem;
  color: #ffffff;
  letter-spacing: 1.75px;
  display: flex;
  margin-bottom: 20px;
`

const InstuctionsHighlight = styled.div`
  font-weight: 700;
  color: #dc6547;
  padding: 0 5px;
`

const RecordButton = ({ t, ...props }) => (
  <RecWrapper>
    <ButtonBorder>
      <Button {...props}/>
    </ButtonBorder>
  </RecWrapper>
)

RecordButton.propTypes = {
  t: PropTypes.func
}

export default RecordButton
