import sanitizeHtml from 'sanitize-html'
import { ICampaignModel } from 'src/interfaces'
import { convertEquivalentSalaryRate, formatNumber } from './helpers.utils'
import { getFirstCompany } from './user.utils'

const LOCATION_TYPE = {
  HYBRID: 'Hybrid',
  ON_SITE: 'Onsite',
  REMOTE: 'Remote'
}

type TLocationType = keyof typeof LOCATION_TYPE

export class CampaignUtils {
  static cleanJD(jd?: string) {
    if (!jd) {
      return ''
    }
    return sanitizeHtml(jd).replaceAll('&amp;nbsp;', '&nbsp;')
      .replaceAll('<p><br></p>', '')
      .replaceAll('<p><br/></p>', '')
      .replaceAll('<p><br /></p>', '')
      .replaceAll('&nbsp;', ' ')
      .replaceAll(' ', ' ')
  }

  static getLogoUrl(campaign?: Partial<ICampaignModel>) {
    if (campaign?.logoUrl) {
      return campaign.logoUrl
    }
    const company = getFirstCompany(campaign?.author)
    if (company?.logo?.url) {
      return company.logo.url
    }
  }

  static getCompanyName(campaign?: Partial<ICampaignModel>) {
    const company = getFirstCompany(campaign?.author)
    if (company?.name) {
      return company.name
    }
    return campaign?.companyName
  }

  static getSalaryTxt(
    campaign: Partial<ICampaignModel>,
    options?: { noRate?: boolean },
    defaultTxt = ''
  ): string {
    if (!campaign?.salaryValue) {
      return defaultTxt
    }
    const salaryValue = campaign.salaryValue as {
      min?: number
      max?: number
    }
    const salaryRate = convertEquivalentSalaryRate(campaign.salaryRate)
    const salary = [salaryValue.min, salaryValue.max].filter(Boolean) as number[]
    if (!salary.length) {
      return defaultTxt
    }
    return salary.map((s) => `$${formatNumber(s)}`).join(' - ') + (options?.noRate ? '' : ` / ${salaryRate}`)
  }

  static getQuestion(campaign?: ICampaignModel) {
    return campaign?.question || campaign?.questions?.[0]?.text
  }

  static parse(campaign?: Partial<ICampaignModel>) {
    if (!campaign) {
      return {
        salary: '',
        salaryRate: '',
        location: '',
        locationType: '',
        logoUrl: '',
        companyName: ''
      }
    }

    const locationType = LOCATION_TYPE?.[campaign.locationType as TLocationType] || ''

    return {
      salary: CampaignUtils.getSalaryTxt(campaign, { noRate: true }),
      salaryRate: campaign?.salaryValue ? ` / ${convertEquivalentSalaryRate(campaign?.salaryRate)}` : '',
      location: campaign.location ? `${campaign.location}` : '',
      locationType,
      logoUrl: CampaignUtils.getLogoUrl(campaign),
      companyName: CampaignUtils.getCompanyName(campaign)
    }
  }
}
