import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconDocumentStroke: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 24, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.46891 3.84489C5.90859 3.40521 6.50493 3.1582 7.12673 3.1582H14.437C14.6279 3.1582 14.8111 3.23406 14.9461 3.36909L19.0073 7.43034C19.1424 7.56536 19.2182 7.7485 19.2182 7.93945V18.4987C19.2182 19.1205 18.9712 19.7168 18.5315 20.1565C18.0919 20.5962 17.4955 20.8432 16.8737 20.8432H7.12673C6.50493 20.8432 5.90859 20.5962 5.46891 20.1565C5.02924 19.7168 4.78223 19.1205 4.78223 18.4987V5.5027C4.78223 4.8809 5.02924 4.28457 5.46891 3.84489ZM17.5722 8.0317L14.3447 4.80419V7.1272C14.3447 7.36709 14.44 7.59715 14.6096 7.76678C14.7793 7.93641 15.0093 8.0317 15.2492 8.0317H17.5722ZM12.9047 4.5982V7.1272C12.9047 7.749 13.1517 8.34534 13.5914 8.78501C14.0311 9.22469 14.6274 9.4717 15.2492 9.4717H17.7782V18.4987C17.7782 18.7386 17.6829 18.9687 17.5133 19.1383C17.3437 19.3079 17.1136 19.4032 16.8737 19.4032H7.12673C6.88684 19.4032 6.65678 19.3079 6.48715 19.1383C6.31752 18.9687 6.22223 18.7386 6.22223 18.4987V5.5027C6.22223 5.26281 6.31752 5.03275 6.48715 4.86312C6.65678 4.6935 6.88684 4.5982 7.12673 4.5982H12.9047ZM8.03123 9.56395C8.03123 9.16631 8.35358 8.84395 8.75123 8.84395H10.3757C10.7734 8.84395 11.0957 9.16631 11.0957 9.56395C11.0957 9.9616 10.7734 10.284 10.3757 10.284H8.75123C8.35358 10.284 8.03123 9.9616 8.03123 9.56395ZM8.03123 12.813C8.03123 12.4153 8.35358 12.093 8.75123 12.093H15.2492C15.6469 12.093 15.9692 12.4153 15.9692 12.813C15.9692 13.2106 15.6469 13.533 15.2492 13.533H8.75123C8.35358 13.533 8.03123 13.2106 8.03123 12.813ZM8.03123 16.062C8.03123 15.6643 8.35358 15.342 8.75123 15.342H15.2492C15.6469 15.342 15.9692 15.6643 15.9692 16.062C15.9692 16.4596 15.6469 16.782 15.2492 16.782H8.75123C8.35358 16.782 8.03123 16.4596 8.03123 16.062Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
