import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconUploadStroke: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#0C0C0C' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.53999 2.78999C9.79383 2.53615 10.2054 2.53615 10.4592 2.78999L14.9592 7.28999C15.2131 7.54383 15.2131 7.95539 14.9592 8.20923C14.7054 8.46307 14.2938 8.46307 14.04 8.20923L10.6496 4.81885V13.7496C10.6496 14.1086 10.3586 14.3996 9.99961 14.3996C9.64062 14.3996 9.34961 14.1086 9.34961 13.7496V4.81885L5.95923 8.20923C5.70539 8.46307 5.29383 8.46307 5.03999 8.20923C4.78615 7.95539 4.78615 7.54383 5.03999 7.28999L9.53999 2.78999ZM4.09961 16.7496C4.09961 16.3906 4.39062 16.0996 4.74961 16.0996H15.2496C15.6086 16.0996 15.8996 16.3906 15.8996 16.7496C15.8996 17.1086 15.6086 17.3996 15.2496 17.3996H4.74961C4.39062 17.3996 4.09961 17.1086 4.09961 16.7496Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
