import { ChangeEvent, FC, useCallback, useRef } from 'react'
import { Button } from 'src/components'
import { IconFile } from 'src/icons'
import { ICampaignModel } from 'src/interfaces'
import { SnackbarService } from 'src/services'

interface IProps {
  campaign?: Partial<ICampaignModel>
  attachmentFile?: File | null
  onChange?: (file: File) => void
}

export const UploadJDFile: FC<IProps> = ({ campaign, attachmentFile, onChange, ...props }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onChangeFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }
    if (!file.name?.endsWith('.doc') && !file.name?.endsWith('.docx') && !file.name?.endsWith('.pdf')) {
      return SnackbarService.error('Only .docx and .pdf file are supported')
    }
    onChange?.(file)
  }, [onChange])

  return (
    <>
      {(attachmentFile || campaign?.jdFileUrl)
        ? (
          <div className="fx fx-ai-center gap-3">
            <div
              className="fx gap-2 px-5 py-3 round-3 w-fit bg-neutral-100"
            >
              <IconFile size={16}/>
              <span className="fs-12 fw-500 lh-16 txt-grey-01">
                {attachmentFile?.name || campaign?.jdFileUrl?.split('/').pop()}
              </span>
            </div>

            <Button
              variant="secondary"
              onClick={() => inputRef.current?.click()}
            >
              Replace File
            </Button>
          </div>
        )
        : (
          <div className="fx fx-ai-center gap-3">
            <Button
              {...props}
              variant="secondary"
              onClick={() => inputRef.current?.click()}
            >
              Upload File
            </Button>

            <div className="fs-12 fw-500 lh-16 txt-grey-01">.pdf, .docx, .doc</div>
          </div>
        )}

      <input
        type="file"
        ref={inputRef}
        // accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        accept=".doc,.docx,.pdf"
        style={{ display: 'none' }}
        onChange={(e) => {
          onChangeFile?.(e)
          e.target.value = ''
        }}
      />
    </>
  )
}
