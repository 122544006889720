import { FC, useCallback, useState } from 'react'
import {
  EMPTY,
  catchError,
  finalize,
  from,
  takeUntil
} from 'rxjs'
import { PeopleApi } from 'src/api'
import { Button } from 'src/components'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { IReactionModel, IUserModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { SnackbarService } from 'src/services'
import { renderVideoTitle } from 'src/utils'
import { TalentImageCard } from '../talent-image-card'
import Style from './style.module.scss'

interface IProps {
  talent: IUserModel & { reaction?: IReactionModel; createdReaction?: IReactionModel }
  isDetail?: boolean
  onDetail?: () => void
  onChange?: (values: Partial<IUserModel & { reaction?: IReactionModel; createdReaction?: IReactionModel }>) => void
}

export const TalentCard: FC<IProps> = ({ talent, isDetail, onDetail, onChange }) => {
  const unsubscribe$ = useUnsubscribe()
  const onChangeRef = useAsRef(onChange)
  const [loading, setLoading] = useState(false)

  const onToggleLike = useCallback(() => {
    if (!talent?.id) return

    setLoading(true)
    from(PeopleApi.toggleLike({ id: talent?.id }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        onChangeRef.current?.({ reaction: data as IReactionModel })
      })
  }, [onChangeRef, talent?.id, unsubscribe$])

  return (
    <div key={talent.id} className={Style.talentContainer}>
      <TalentImageCard
        talent={talent}
        loading={loading}
        onToggleLike={onToggleLike}
        onDetail={onDetail}
      />

      <div className="bold-14 txt-black-01 three-dot-1 text-center">{renderVideoTitle(talent?.pfv)}</div>

      {isDetail && (
        <Button
          className="w-fit"
          onClick={() => window.open(generate([ERoutes.TALENT_DETAIL, { lookupId: talent?.lookupId }]), '_blank')}
        >
          Details
        </Button>
      )}
    </div>
  )
}
