import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EMPTY, Observable, catchError, finalize, forkJoin, from, switchMap, takeUntil } from 'rxjs'
import { ProfileApi, UploadApi } from 'src/api'
import { Button, Input, UserAvatar } from 'src/components'
import { useBehaviorMapper, useUnsubscribe, useValidation } from 'src/hooks'
import { IconPencilFilled } from 'src/icons'
import { EFileUploadKind } from 'src/interfaces'
import { SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { getFirstCompanyRole, getFullName } from 'src/utils'
import { object, string } from 'yup'
import { ProfileBlock } from './profile-block'
import { ProfileRow } from './profile-row'

export const AvatarSection: FC = () => {
  const unsubscribe$ = useUnsubscribe()

  const profile = useBehaviorMapper(AuthModule.profile$)
  const fullName = useMemo(() => getFullName(profile), [profile])
  const userType = useMemo(() => getFirstCompanyRole(profile), [profile])

  const [editing, setEditing] = useState(false)
  const [formData, setFormData] = useState<Partial<typeof profile> & { userType?: string }>({})

  const schema = useMemo(() => object().shape({
    firstName: string().required().label('First name'),
    lastName: string().required().label('Last name'),
    userType: string().required().label('Role')
  }), [])
  const { errors, validate, reset } = useValidation({
    data: formData,
    schema
  })

  const [loading, setLoading] = useState(false)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [avatar, setAvatar] = useState<File>()
  const [avatarPreview, setAvatarPreview] = useState<string>()

  useEffect(() => {
    if (!editing) {
      reset()
      setAvatar(undefined)
      setAvatarPreview(undefined)
      setLoading(false)
      setFormData({})
    } else {
      setFormData({ firstName: profile.firstName, lastName: profile.lastName, userType })
    }
  }, [editing, profile.firstName, profile.lastName, userType, reset])

  useEffect(() => {
    if (avatar) {
      const previewUrl = URL.createObjectURL(avatar)
      setAvatarPreview(previewUrl)
      return () => URL.revokeObjectURL(previewUrl)
    } else {
      setAvatarPreview(undefined)
    }
  }, [avatar])

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setAvatar(file)
    }
    e.target.value = ''
  }, [])

  const onSubmit = useCallback(async ({ avatar, ...values }: typeof formData & { avatar?: File }) => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    setLoading(true)
    const stacks: Array<Observable<any>> = [from(ProfileApi.update(values).then(
      () => {
        const companies = AuthModule.profile$.value.companies
        if (companies?.[0].CompanyUser) {
          companies[0].CompanyUser.userType = values.userType
        }
        return AuthModule.updateProfile({
          ...values,
          fullName: [values.firstName, values.lastName].filter(Boolean).join(' '),
          companies
        })
      }
    ))]
    if (avatar) {
      const key = await UploadApi.upload({
        kind: EFileUploadKind.PFP,
        file: avatar
      })

      stacks.push(from(ProfileApi.uploadAvatar({ key })).pipe(
        switchMap(() => from(ProfileApi.getProfile().then(
          ({ data }) => AuthModule.authenticated(data)
        )))
      ))
    }
    forkJoin(stacks)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        setEditing(false)
        SnackbarService.success('Profile updated successfully')
      })
  }, [unsubscribe$, validate])

  return (
    <ProfileBlock
      editable={!editing}
      btnProps={{ onClick: () => setEditing((prev) => !prev) }}
    >
      <div className="fx-1 fx fx-ai-center gap-6">
        <div className="relative">
          <input
            ref={inputFileRef}
            type="file"
            accept="image/*"
            className="d-none"
            onChange={onFileChange}
          />
          <UserAvatar
            size={80}
            fullName={fullName}
            avatarUrl={avatarPreview || profile?.pfp?.url}
            className="rounded"
          />
          <div
            className="round-10"
            style={editing
              ? {
                background: '#00000050',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%'
              }
              : undefined}
          >
            {editing && !loading && (
              <IconPencilFilled
                size={18}
                className="pointer txt-neutral-white"
                style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                onClick={() => inputFileRef?.current?.click()}
              />
            )}
          </div>
        </div>
        {editing
          ? (
            <div className="fx-1 fx fx-column gap-3">
              <ProfileRow
                label="Name"
                valueClass="pr-0-p"
                value={(
                  <div className="fx fx-ai-center gap-2">
                    <Input
                      style={{ width: 'calc(50% - 4px)' }}
                      autoFocus
                      innerError
                      name="firstName"
                      placeholder="e.g., John"
                      disabled={loading}
                      value={formData?.firstName}
                      error={errors?.getError('firstName')}
                      onChange={onChange}
                    />
                    <Input
                      style={{ width: 'calc(50% - 4px)' }}
                      autoFocus
                      innerError
                      name="lastName"
                      placeholder="e.g., Smith"
                      disabled={loading}
                      value={formData?.lastName}
                      error={errors?.getError('lastName')}
                      onChange={onChange}
                    />
                  </div>
                )}
              />
              <ProfileRow
                label="Role"
                valueClass="pr-0-p"
                value={(
                  <Input
                    autoFocus
                    innerError
                    name="userType"
                    placeholder="e.g., Chief Technical Officer"
                    disabled={loading}
                    value={formData?.userType}
                    error={errors?.getError('userType')}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          )
          : (
            <div className="fx-column gap-2">
              <div className="fs-24 fw-500 txt-black-01">{fullName || 'Maria Chavez'}</div>
              {userType ? <div className="fs-14 txt-grey-01">{userType}</div> : <ProfileRow label="Role"/>}
            </div>
          )}
      </div>

      {editing && (
        <div className="fx fx-ai-center fx-jc-flex-end gap-3">
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => setEditing((prev) => !prev)}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => onSubmit({ avatar, ...formData })}
          >
            Save
          </Button>
        </div>
      )}
    </ProfileBlock>
  )
}
