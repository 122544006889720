import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { FC, useMemo } from 'react'
import { MotivatorBadge, Tooltip } from 'src/components'
import { MotivatorsBg, MotivatorsBgLight } from 'src/constants/motivators'
import { EMotivator } from 'src/enums'
import { IconExplanation } from 'src/icons'
import { ICampaignModel } from 'src/interfaces'
import { CampaignUtils, getFirstCompanyRole, getFullName } from 'src/utils'
import { EMotivatorIllustration, isValidMotivators, mapMotivatorToExplanation } from 'src/utils/motivators.utils'
import { CampaignVideoCard } from '../card/campaign-video-card'
import { CampaignInfo } from './campaign-info'
import Style from './style.module.scss'

interface IProps {
  campaign: Partial<ICampaignModel>
  // updateCampaign?: (values: { [K in keyof ICampaignModel]?: ICampaignModel[K] }) => void
}

export const CampaignDetail: FC<IProps> = ({ campaign }) => {
  const video = campaign.uploadVideo ?? campaign.author?.pfv

  const motivators = useMemo(() => {
    return Object.keys(campaign.motivator ?? {})
      .filter(isValidMotivators)
      .sort((key1, key2) => (campaign.motivator?.[key2] as number) - (campaign.motivator?.[key1] as number))
      .slice(0, 3)
  }, [campaign.motivator])

  const jobBriefDescription = useMemo(() => {
    if (!campaign.briefDescription) {
      return campaign.briefDescription
    }

    if (!campaign.motivator || isEmpty(campaign.motivator)) {
      return campaign.briefDescription
    }

    const motivatorsHasQuote = Object.keys(campaign.motivator).filter(key => key.startsWith('quote')).map(key => {
      const motivator = key.replace('quote', '') as EMotivator
      return {
        key: motivator,
        values: Array.ensure(campaign.motivator?.[key] as unknown as string ?? []).slice(1)
      }
    })

    let jd = campaign.briefDescription

    motivatorsHasQuote.forEach(({ key, values }) => {
      values.forEach(quote => {
        try {
          const qTrimDot = quote.replace(/\.$/g, '')
          const pattern = new RegExp(qTrimDot.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, '\\$&'), 'ig')
          jd = jd?.replace(pattern, `<span style="background-color: ${MotivatorsBg[key]}">${qTrimDot}</span>`)
        } catch (error) {
          console.error(error)
        }
      })
    })

    return jd
  }, [campaign])

  return (
    <div className={Style.campaignDetail}>
      <div className="w-100-p p-8 sm-p-4 fx sm-fx-column gap-8 sm-gap-5 bg-neutral-white round-4">
        {video && (
          <div style={{ width: '100%', maxWidth: 340 }} className="fx-column gap-3 mx-auto">
            <div className="fx-column gap-1 p-3 round-3 txt-center" style={{ backgroundColor: '#DCFF8F' }}>
              <div className="heading-16 txt-black-01 three-dot-1">{getFullName(campaign.author)}</div>
              <div className="body-10 txt-black-02 three-dot-1">{getFirstCompanyRole(campaign.author) || '--'}</div>
            </div>
            <div className="fx p-2 round-4" style={{ backgroundColor: '#DCFF8F' }}>
              <CampaignVideoCard campaign={campaign}/>
            </div>
          </div>
        )}

        <CampaignInfo
          loading
          campaign={campaign}
          motivators={motivators}
        />
      </div>

      {!!motivators?.length && (
        <div className="fx fx-column gap-5 bg-neutral-white round-4 w-100-p p-8 sm-p-4">
          <div className="fs-16 fw-600 lh-20 txt-black-01 fx fx-ai-center gap-1">
            <span>IDEAL APPLICANT’S MOTIVATORS</span>
            <Tooltip placement="bottom" title="Powered by KNOWME&trade; Insights">
              <IconExplanation/>
            </Tooltip>
          </div>
          <div className="fx fx-wrap-wrap gap-4">
            {motivators?.map(item => {
              const Illustration = EMotivatorIllustration[item]
              return (
                <div
                  key={item}
                  className="sm-w-100-p-i p-4 fx fx-column gap-3 round-4"
                  style={{ width: 234, backgroundColor: MotivatorsBgLight[item] }}
                >
                  <MotivatorBadge
                    className={clsx(Style.badge, 'fx fx-center pointer mr-auto')}
                    key={item}
                    small
                    value={item}
                  />
                  <div className="fs-12 lh-18 txt-black-01">
                    {mapMotivatorToExplanation(item, campaign).join('\n')}
                  </div>
                  <Illustration className={Style.illustration}/>
                </div>
              )
            })}
          </div>
        </div>
      )}

      {!!jobBriefDescription && (
        <div className="fx fx-column gap-5 bg-neutral-white round-4 w-100-p p-8 sm-p-4">
          <div className="fs-16 fw-600 lh-20 txt-black-01">
            {video ? 'Job Details' : 'From Original Job Description'}
          </div>
          <pre
            className={Style.jobDescription}
            dangerouslySetInnerHTML={{ __html: CampaignUtils.cleanJD(jobBriefDescription) }}
          />
        </div>
      )}
    </div>
  )
}
