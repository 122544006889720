export enum ECampaignRoutes {
  CAMPAIGN_SUBMISSIONS = 'Campaigns.Submissions',
  CAMPAIGN_SUBMISSION_DETAIL = 'Campaigns.SubmissionDetail',
  NEW_CAMPAIGN = 'Campaigns.NewCampaign',
  PREVIEW_CAMPAIGN = 'Campaigns.PreviewCampaign',
  CAMPAIGN_DETAIL = 'Campaigns.Detail',
  CAMPAIGN_MY_JOBS = 'Campaigns.MyJobs',
  CREATE_CAMPAIGN = 'Campaigns.CreateCampaign',

  CAMPAIGNS_PUBLIC = 'PublicCampaigns',
  CAMPAIGNS_PUBLIC_SUBMISSIONS = 'PublicCampaigns.Submissions',

  CAMPAIGNS_PUBLIC_ONBOARDING = 'PublicCampaignOnboarding',
  CAMPAIGNS_PUBLIC_ONBOARDING_SUBMISSIONS = 'PublicCampaignOnboarding.Submissions',

  CAMPAIGNS_APPLICANTS = 'Campaigns.Applicants'
}
