import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconMotivatorLearner = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 300 } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="300" height="257" viewBox="0 0 300 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="299.33" height="257" transform="translate(0 0.00195312)"/>
        <path d="M146.545 118.064C146.545 118.064 145.861 115.213 145.121 114.383C144.312 113.476 143.459 112.738 142.338 112.536C141.216 112.334 139.62 113.434 139.62 113.434C139.62 113.434 137.227 110.897 137.361 108.468C137.496 106.038 138.767 103.845 138.595 102.351C138.423 100.857 136.412 96.3886 138.079 93.6699C139.747 90.9512 141.938 90.7157 143.126 89.2823C144.315 87.849 145.796 84.0402 148.555 83.5691C151.314 83.098 153.415 85.6687 155.27 85.2985C157.124 84.9284 160.272 82.3645 163.839 83.6027C167.405 84.8409 169.162 90.4734 172.213 91.3213C175.264 92.1692 176.423 90.8099 178.412 92.3913C180.401 93.9727 180.685 98.0238 182.711 99.0197C184.738 100.016 191.138 102.398 186.517 108.549C181.896 114.699 177.956 105.85 175.742 105.043C173.529 104.235 170.112 106.166 168.258 105.487C166.403 104.807 164.915 99.0265 162.306 98.7909C159.696 98.5554 157.498 101.489 155.584 100.87C153.669 100.251 151.471 97.5527 151.471 97.5527C151.471 97.5527 149.235 99.8071 149.4 102.283C149.564 104.76 151.396 107.008 150.619 109.188C149.841 111.368 147.523 111.361 146.91 113.293C146.297 115.224 146.545 118.064 146.545 118.064Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M139.303 113.441C139.303 113.441 136.956 115.611 137.453 118.456C137.95 121.302 138.833 121.968 140.155 122.648C141.476 123.329 143.116 123.386 143.116 123.386C143.116 123.386 143.039 128.519 144.833 130.565C146.628 132.611 150.523 136.433 161.178 136.938C171.834 137.442 175.542 135.229 177.03 128.001C178.518 120.774 178.705 107.786 178.705 107.786" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M155.017 108.546C155.017 108.546 156.811 107.766 158.322 107.806C159.832 107.847 161.26 108.775 161.26 108.775" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M170.083 110.161C170.083 110.161 171.145 109.791 172.648 110.417C174.151 111.043 174.921 112.005 174.921 112.005" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M166.435 114.038C166.435 114.038 166.099 117.053 166.6 118.372C167.101 119.691 168.738 121.454 168.275 123.345C167.811 125.236 164.977 125.673 164.977 125.673" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M157.88 126.892C157.88 126.892 158.127 129.53 160.662 129.678C163.197 129.826 164.303 129.038 164.303 129.038" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M159.308 114.246C159.308 114.585 159.174 114.91 158.934 115.15C158.695 115.39 158.37 115.525 158.031 115.525C157.692 115.525 157.367 115.391 157.127 115.152C156.887 114.913 156.752 114.588 156.751 114.249C156.75 113.91 156.884 113.585 157.123 113.345C157.362 113.105 157.686 112.969 158.025 112.968C158.364 112.967 158.689 113.1 158.93 113.339C159.171 113.577 159.307 113.902 159.308 114.24" fill="black"/>
        <path d="M159.308 114.246C159.308 114.585 159.174 114.91 158.934 115.15C158.695 115.39 158.37 115.525 158.031 115.525C157.692 115.525 157.367 115.391 157.127 115.152C156.887 114.913 156.752 114.588 156.751 114.249C156.75 113.91 156.884 113.585 157.123 113.345C157.362 113.105 157.686 112.969 158.025 112.968C158.364 112.967 158.689 113.1 158.93 113.339C159.171 113.577 159.307 113.902 159.308 114.24" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M173.645 116.762C173.645 117.101 173.511 117.426 173.271 117.666C173.032 117.905 172.707 118.04 172.368 118.041C172.029 118.041 171.704 117.907 171.464 117.668C171.224 117.428 171.089 117.104 171.088 116.765C171.087 116.426 171.221 116.101 171.46 115.86C171.699 115.62 172.023 115.485 172.362 115.483C172.701 115.482 173.026 115.616 173.267 115.854C173.507 116.093 173.643 116.417 173.645 116.756" fill="black"/>
        <path d="M173.645 116.762C173.645 117.101 173.511 117.426 173.271 117.666C173.032 117.905 172.707 118.04 172.368 118.041C172.029 118.041 171.704 117.907 171.464 117.668C171.224 117.428 171.089 117.104 171.088 116.765C171.087 116.426 171.221 116.101 171.46 115.86C171.699 115.62 172.023 115.485 172.362 115.483C172.701 115.482 173.026 115.616 173.267 115.854C173.507 116.093 173.643 116.417 173.645 116.756" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.693 131.751L144.362 136.596L145.693 131.751Z" fill="black"/>
        <path d="M145.693 131.751L144.362 136.596" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M159.914 136.934L159.182 140.406L159.914 136.934Z" fill="black"/>
        <path d="M159.914 136.934L159.182 140.406" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M220.127 212.566C220.127 212.566 222.434 211.825 223.062 205.391C223.69 198.958 193.907 165.749 181.719 156.879C169.531 148.01 161.425 145.924 161.425 145.924C161.425 145.924 162.906 142.788 161.799 141.738C160.693 140.689 142.358 135.857 140.968 135.588C139.577 135.319 138.994 140.298 138.994 140.298C138.994 140.298 118.05 138.192 112.801 143.347C107.552 148.501 96.2018 159.322 90.2199 178.003C84.2381 196.684 80.5742 208.581 84.8213 212.767C89.0684 216.952 118.574 219.335 125.109 213.467C131.644 207.599 126.709 197.424 122.731 196.684C118.753 195.943 113.998 194.92 109.123 195.647C104.247 196.374 98.5796 197.612 98.5796 197.612" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M167.281 211.61L173.861 188.191L195.501 209.308" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M109.915 195L118.948 172.995" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M127.367 201.716C127.367 201.716 134.665 203.277 137.775 205.619C140.886 207.961 143.712 211.958 143.712 211.958" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M197.674 202.065C197.674 202.065 203.274 192.725 210.879 195.504C218.483 198.283 221.145 204.286 219.523 207.94C217.9 211.594 216.345 213.202 212.935 214.09C209.526 214.979 206.348 214.501 205.921 213.438C205.495 212.374 206.4 211.54 206.4 211.54C206.4 211.54 204.815 211.042 204.628 210.06C204.441 209.077 205.346 208.195 205.346 208.195C205.346 208.195 203.858 207.745 203.663 206.883C203.469 206.022 203.903 204.898 203.903 204.898C203.903 204.898 196.605 207.758 195.049 205.78C193.494 203.801 197.674 202.065 197.674 202.065Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M197.629 202.018L208.599 198.384" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M203.626 205.02L210.035 203.129" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M205.375 208.135L209.219 207.126" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M206.498 211.594L210.999 210.739" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M200.598 200.692L211.687 184.4C211.687 184.4 213.025 184.319 213.953 184.844C214.88 185.369 215.097 186.547 215.097 186.547L206.887 198.673L200.598 200.692Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M197.853 206.601C197.853 206.601 194.003 212.005 193.442 213.432C192.881 214.858 191.535 217.934 192.081 218.237C192.627 218.539 196.739 215.41 197.427 214.643C198.115 213.876 203.76 205.054 203.76 205.054L200.186 206.225L197.853 206.601Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M126.586 212.401L193.792 211.971" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M199.266 212.32L205.786 212.293" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M195.926 216.373L219.96 226.169L183.963 227.042L161.509 216.484L192.369 215.776" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M229.155 147.078C235.892 154.319 238.24 159.837 236.423 161.479C234.606 163.121 226.844 160.402 222.268 155.826C217.692 151.25 213.505 142.461 215.725 140.335C217.946 138.208 225.295 142.93 229.155 147.078Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M215.359 140.698C215.359 140.698 227.645 130.624 237.993 139.244C248.342 147.865 236.7 161.216 236.7 161.216" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M234.425 136.694C234.425 136.694 239.158 132.771 242.912 136.263C246.666 139.756 242.209 145.059 242.209 145.059" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M241.268 134.662C241.268 134.662 247.967 131.728 252.244 133.006C256.521 134.285 262.952 140.88 264.477 158.901C266.002 176.922 263.774 200.852 263.774 200.852" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M244.438 137.448C244.438 137.448 248.116 135.699 250.374 136.466C252.633 137.233 256.782 140.584 258.42 147.206C261.037 157.785 261.575 168 261.291 177.111C261.007 186.223 260.155 200.597 260.155 200.597" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M237.854 212.727C237.854 212.727 236.675 200.675 257.94 200.675C279.206 200.675 277.741 212.683 277.741 212.683C277.741 212.683 264.817 213.193 257.175 213.408C249.533 213.623 237.854 212.727 237.854 212.727Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M216.085 212.782L237.575 212.392" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M277.715 212.171L295.924 211.876" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M204.918 47.835H197.991V80.2396H204.918V47.835Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M215.228 43.3145H204.981V80.2395H215.228V43.3145Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M223.761 47.0352H215.259V80.2392H223.761V47.0352Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M232.188 44.6943H223.76V80.2395H232.188V44.6943Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M286.386 80.2617H196.403V84.9623H286.386V80.2617Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M283.308 56.2551C283.308 62.3948 280.871 68.2835 276.532 72.6274C272.193 76.9713 266.307 79.4153 260.168 79.4223C254.028 79.4294 248.136 76.999 243.787 72.6651C239.439 68.3312 236.988 62.4482 236.974 56.3085C236.96 50.1688 239.383 44.2746 243.712 39.9207C248.041 35.5667 253.921 33.1092 260.061 33.088C266.2 33.0668 272.097 35.4836 276.456 39.8075C280.815 44.1314 283.28 50.0087 283.308 56.1484" fill="black"/>
        <path d="M283.308 56.2551C283.308 62.3948 280.871 68.2835 276.532 72.6274C272.193 76.9713 266.307 79.4153 260.168 79.4223C254.028 79.4294 248.136 76.999 243.787 72.6651C239.439 68.3312 236.988 62.4482 236.974 56.3085C236.96 50.1688 239.383 44.2746 243.712 39.9207C248.041 35.5667 253.921 33.1092 260.061 33.088C266.2 33.0668 272.098 35.4836 276.456 39.8075C280.815 44.1314 283.28 50.0087 283.308 56.1484" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M278.204 56.3429C278.204 61.1733 276.287 65.8062 272.873 69.2237C269.46 72.6413 264.829 74.564 259.999 74.5696C255.168 74.5752 250.533 72.6631 247.112 69.2534C243.69 65.8437 241.762 61.2153 241.751 56.3849C241.74 51.5545 243.647 46.9173 247.052 43.4919C250.458 40.0664 255.084 38.133 259.915 38.1163C264.745 38.0996 269.385 40.001 272.814 43.4028C276.243 46.8046 278.182 51.4286 278.204 56.2589" fill="white"/>
        <path d="M278.204 56.3429C278.204 61.1733 276.287 65.8061 272.873 69.2237C269.46 72.6413 264.829 74.564 259.999 74.5696C255.168 74.5752 250.533 72.6631 247.112 69.2534C243.69 65.8437 241.762 61.2153 241.751 56.3849C241.74 51.5545 243.647 46.9173 247.052 43.4919C250.458 40.0664 255.084 38.133 259.915 38.1163C264.745 38.0996 269.385 40.001 272.814 43.4028C276.243 46.8046 278.182 51.4286 278.204 56.2589" stroke="black" strokeWidth="0.786745" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M260.148 40.5146L260.116 43.9502L260.148 40.5146Z" fill="white"/>
        <path d="M260.148 40.5146L260.116 43.9502" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M260.148 68.7002L260.116 72.1357L260.148 68.7002Z" fill="white"/>
        <path d="M260.148 68.7002L260.116 72.1357" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M272.686 56.5512L276.121 56.498L272.686 56.5512Z" fill="white"/>
        <path d="M272.686 56.5512L276.121 56.498" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M244.182 56.5512L247.617 56.498L244.182 56.5512Z" fill="white"/>
        <path d="M244.182 56.5512L247.617 56.498" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M266.251 44.1973L259.6 56.8546L266.177 62.3267" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M110.317 35.3145H37.3979V114.237H110.317V35.3145Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M40.7521 29.9626L32.2319 38.6777L34.9266 41.3121L43.4468 32.597L40.7521 29.9626Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M112.828 107.509L104.308 116.224L107.002 118.858L115.522 110.143L112.828 107.509Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M92.9846 75.0314C92.9846 80.4055 90.8514 85.5599 87.0535 89.3621C83.2556 93.1644 78.1037 95.3036 72.7295 95.3098C67.3554 95.316 62.1986 93.1887 58.392 89.3951C54.5853 85.6016 52.4402 80.4522 52.4278 75.0781C52.4154 69.704 54.5368 64.5447 58.3259 60.7337C62.115 56.9227 67.262 54.7716 72.6361 54.7531C78.0102 54.7345 83.1719 56.8499 86.9873 60.6347C90.8027 64.4194 92.9597 69.5639 92.9844 74.9379" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M78.3888 55.3142C78.3888 55.3142 86.7484 46.3506 91.4741 48.7328C96.1997 51.115 86.7335 69.6343 80.2731 78.3421C73.8127 87.0499 63.0753 103.214 56.241 101.168C49.4068 99.1224 57.7664 88.9206 57.7664 88.9206" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M55.703 63.4703C55.703 63.4703 50.3941 53.457 54.1477 50.3346C57.9013 47.2121 71.6446 57.2389 82.4867 70.5496C93.3288 83.8603 97.9498 97.8843 95.4374 100.186C92.925 102.487 82.9054 93.0122 82.9054 93.0122" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M52.3383 70.3343C52.3383 70.3343 44.3825 73.4702 44.3526 77.1444C44.3227 80.8186 61.6102 82.0434 75.9068 79.7016C90.2034 77.3597 98.6378 74.7084 99.2958 72.1781C99.9538 69.6479 92.0279 68.9346 92.0279 68.9346" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M87.5752 49.6611C87.5752 50.3638 87.2963 51.0377 86.7997 51.5349C86.3031 52.032 85.6295 52.3117 84.9269 52.3125C84.2242 52.3133 83.55 52.0352 83.0523 51.5392C82.5546 51.0432 82.2741 50.3699 82.2725 49.6672C82.2708 48.9646 82.5482 48.29 83.0436 47.7917C83.5391 47.2935 84.212 47.0122 84.9147 47.0098C85.6173 47.0074 86.2922 47.2839 86.7911 47.7788C87.2899 48.2736 87.5719 48.9463 87.5752 49.6489" fill="#020000"/>
        <path d="M87.5752 49.6611C87.5752 50.3638 87.2963 51.0377 86.7997 51.5349C86.3031 52.032 85.6295 52.3117 84.9269 52.3125C84.2242 52.3133 83.55 52.0352 83.0523 51.5392C82.5546 51.0432 82.2741 50.3699 82.2725 49.6672C82.2708 48.9646 82.5482 48.29 83.0436 47.7917C83.5391 47.2935 84.212 47.0122 84.9147 47.0098C85.6173 47.0074 86.2922 47.2839 86.7911 47.7788C87.2899 48.2736 87.5719 48.9463 87.5752 49.6489" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M70.1333 94.6641C70.1333 95.3667 69.8544 96.0406 69.3578 96.5378C68.8612 97.0349 68.1876 97.3146 67.485 97.3154C66.7823 97.3162 66.1081 97.0381 65.6104 96.5421C65.1127 96.0461 64.8322 95.3728 64.8306 94.6702C64.829 93.9675 65.1063 93.293 65.6017 92.7947C66.0972 92.2964 66.7701 92.0151 67.4728 92.0127C68.1754 92.0103 68.8503 92.2869 69.3492 92.7817C69.848 93.2766 70.13 93.9492 70.1333 94.6518" fill="#020000"/>
        <path d="M70.1333 94.6641C70.1333 95.3667 69.8544 96.0406 69.3578 96.5378C68.8612 97.0349 68.1876 97.3146 67.485 97.3154C66.7823 97.3162 66.1081 97.0381 65.6104 96.5421C65.1127 96.0461 64.8322 95.3728 64.8306 94.6702C64.829 93.9675 65.1063 93.293 65.6017 92.7947C66.0972 92.2964 66.7701 92.0151 67.4728 92.0127C68.1754 92.0103 68.8503 92.2869 69.3492 92.7817C69.848 93.2766 70.13 93.9492 70.1333 94.6518" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M50.4522 79.7056C50.4522 80.1405 50.2796 80.5577 49.9722 80.8654C49.6649 81.1731 49.2479 81.3463 48.813 81.3468C48.378 81.3473 47.9607 81.1751 47.6526 80.8681C47.3445 80.5611 47.1709 80.1443 47.1699 79.7094C47.1689 79.2745 47.3406 78.8569 47.6473 78.5485C47.9539 78.2401 48.3705 78.066 48.8054 78.0645C49.2403 78.063 49.6581 78.2342 49.9669 78.5405C50.2757 78.8468 50.4502 79.2631 50.4522 79.6981" fill="#020000"/>
        <path d="M50.4522 79.7056C50.4522 80.1405 50.2796 80.5577 49.9722 80.8654C49.6649 81.1731 49.2479 81.3463 48.813 81.3468C48.378 81.3473 47.9607 81.1751 47.6526 80.8681C47.3445 80.5611 47.1709 80.1443 47.1699 79.7094C47.1689 79.2745 47.3406 78.8569 47.6473 78.5485C47.9539 78.2401 48.3705 78.066 48.8054 78.0645C49.2403 78.063 49.6581 78.2342 49.9669 78.5405C50.2757 78.8468 50.4502 79.2631 50.4522 79.6981" stroke="black" strokeWidth="0.618986" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.6424 132.138C17.6424 132.138 16.236 132.899 16.5004 137.248C16.7647 141.597 18.298 142.644 18.298 142.644L69.3476 142.159C69.3476 142.159 67.2897 139.827 67.332 136.868C67.3743 133.908 69.2037 131.729 69.2037 131.729L17.6424 132.138Z" fill="#020000" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M69.0674 131.743L75.9937 131.719" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M69.2427 142.16L75.8729 142.146" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M74.3964 132.093L74.3013 141.867" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.0608 142.698C18.0608 142.698 15.5933 143.856 15.7429 148.405C15.8924 152.954 18.7338 153.984 18.7338 153.984L72.3386 153.587L72.1292 142.153" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M65.9453 142.099C65.9453 142.099 63.8442 144.003 64.0685 148.269C64.2928 152.536 66.4164 153.424 66.4164 153.424" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M60.3965 145.403H19.563V150.773H60.3965V145.403Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.2881 155.406L77.206 154.827C77.206 154.827 78.5594 155.81 78.522 158.414C78.4846 161.018 76.548 162.229 76.548 162.229" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.9194 161.057L76.2486 160.734C76.2486 160.734 77.2132 162.484 77.2057 166.441C77.1982 170.398 75.3962 171.542 75.3962 171.542L10.897 172.114" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.606 154.268L14.7405 160.829" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.264 154.274C19.264 154.274 20.423 155.176 20.5052 157.356C20.5875 159.537 19.249 160.795 19.249 160.795" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.7887 161.267L12.6392 171.886" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.7727 161.206C19.7727 161.206 21.8289 163.333 21.6495 166.832C21.47 170.331 18.9277 171.812 18.9277 171.812" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M72.8767 164.611H25.5454V167.969H72.8767V164.611Z" fill="#020000" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.6937 172.325C17.6937 172.325 15.6898 174.485 15.9366 178.341C16.1833 182.197 17.9928 184.801 17.9928 184.801L75.4858 184.781" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M71.9194 171.875L71.9568 184.445" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M67.2984 171.827C67.2984 171.827 65.0851 173.274 65.3094 178.099C65.5337 182.924 67.3208 184.566 67.3208 184.566" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.2302 172.184C21.2302 172.184 19.0319 173.941 19.2861 178.624C19.5403 183.308 20.976 184.762 20.976 184.762L25.3352 184.748C25.3352 184.748 23.8472 181.753 23.7351 178.793C23.6229 175.832 25.4698 172.043 25.4698 172.043L21.2302 172.184Z" fill="#020000" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M56.8278 171.979C56.8278 171.979 54.6295 173.736 54.8837 178.419C55.138 183.103 56.5736 184.556 56.5736 184.556L60.9329 184.543C60.9329 184.543 59.4449 181.548 59.3327 178.587C59.2206 175.627 61.0675 171.838 61.0675 171.838L56.8278 171.979Z" fill="#020000" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.1626 184.883H71.142C71.142 184.883 72.4056 185.32 72.3383 189.486C72.271 193.651 70.2372 194.075 70.2372 194.075" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.2102 184.943L10.1729 194.028" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.5645 185.118C12.5645 185.118 13.8954 186.37 13.9403 189.62C13.9851 192.87 12.7439 193.954 12.7439 193.954" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M70.5811 194.093L4.29483 194.261C4.29483 194.261 1.08706 194.025 1.5357 202.854C1.98434 211.683 4.07051 211.757 4.07051 211.757L70.7755 212.013C70.7755 212.013 67.3135 211.878 67.1715 202.585C67.0294 193.292 70.5811 194.093 70.5811 194.093Z" fill="#020000" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M70.3511 194.085L76.9535 194.058" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M70.3379 212L83.9142 211.74" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M75.418 194.309L75.332 211.788" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M66.7451 187.948H18.8438V190.858H66.7451V187.948Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.74221 194.179C9.74221 194.179 7.42639 195.863 7.73305 202.534C8.03971 209.206 9.76336 211.652 9.76336 211.652L13.1472 211.632C13.1472 211.632 11.5822 209.234 11.1698 202.601C10.7574 195.968 13.0943 194.312 13.0943 194.312L9.74221 194.179Z" fill="white" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M59.8989 194.42C59.8989 194.42 57.5831 196.104 57.8898 202.776C58.1965 209.447 59.9201 211.893 59.9201 211.893L63.3039 211.874C63.3039 211.874 61.7389 209.475 61.3265 202.842C60.9141 196.209 63.2511 194.553 63.2511 194.553L59.8989 194.42Z" fill="white" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </IconWrapper>
  )
})
