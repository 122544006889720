import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconContactStroke: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5569 12.9872C10.4093 12.8396 10.209 12.7566 10.0001 12.7566C9.79124 12.7566 9.59094 12.8396 9.44325 12.9872C9.29557 13.1349 9.2126 13.3352 9.2126 13.5441C9.2126 13.7529 9.29557 13.9533 9.44325 14.1009C9.59094 14.2486 9.79124 14.3316 10.0001 14.3316C10.209 14.3316 10.4093 14.2486 10.5569 14.1009C10.7046 13.9533 10.7876 13.7529 10.7876 13.5441C10.7876 13.3352 10.7046 13.1349 10.5569 12.9872Z" fill={color}/>
        <path d="M11.5314 6.50026C11.1253 6.09413 10.5745 5.86597 10.0001 5.86597C9.42574 5.86597 8.8749 6.09413 8.46877 6.50026C8.06264 6.9064 7.83447 7.45723 7.83447 8.03159C7.83447 8.18823 7.8967 8.33846 8.00746 8.44923C8.11823 8.55999 8.26845 8.62222 8.4251 8.62222C8.58174 8.62222 8.73197 8.55999 8.84273 8.44923C8.9535 8.33846 9.01572 8.18823 9.01572 8.03159L9.02123 7.93079C9.04783 7.68042 9.16936 7.44975 9.36082 7.28623C9.55228 7.12272 9.79912 7.03878 10.0506 7.05169C10.302 7.0646 10.539 7.17338 10.7127 7.35564C10.8864 7.53791 10.9837 7.77981 10.9845 8.03159C10.9845 8.49149 10.86 8.69939 10.3702 9.18922L10.2371 9.32388C9.64336 9.94207 9.40947 10.3862 9.40947 11.1816C9.40947 11.3382 9.4717 11.4885 9.58246 11.5992C9.69323 11.71 9.84345 11.7722 10.0001 11.7722C10.1567 11.7722 10.307 11.71 10.4177 11.5992C10.5285 11.4885 10.5907 11.3382 10.5907 11.1816C10.5907 10.7217 10.7151 10.5138 11.205 10.024L11.3381 9.8893C11.9318 9.27112 12.1657 8.82697 12.1657 8.03159C12.1657 7.45723 11.9376 6.9064 11.5314 6.50026Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 2.3501C5.77511 2.3501 2.3501 5.77442 2.3501 9.99899C2.3501 11.167 2.61287 12.2997 3.11073 13.3274L2.39118 16.093C2.3368 16.3028 2.33818 16.5233 2.39516 16.7324C2.45215 16.9416 2.56277 17.1322 2.71607 17.2855C2.86937 17.4388 3.06003 17.5494 3.26918 17.6063C3.47834 17.6633 3.69951 17.6645 3.90936 17.6101L6.6753 16.8901C7.71126 17.3901 8.84807 17.6497 10.0006 17.6479C14.2254 17.6476 17.6501 14.2234 17.6501 9.99899C17.6501 5.77442 14.2251 2.3501 10.0001 2.3501ZM3.6501 9.99899C3.6501 6.49258 6.49289 3.6501 10.0001 3.6501C13.5073 3.6501 16.3501 6.49258 16.3501 9.99899C16.3501 13.5054 13.5073 16.3479 10.0001 16.3479L9.99905 16.3479C8.97201 16.3495 7.96003 16.101 7.05065 15.6238L6.82808 15.507L3.67324 16.3283L4.49408 13.1734L4.37667 12.9505C3.90229 12.0501 3.6501 11.0434 3.6501 9.99899Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
