import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconMotivatorMissionDriven = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 300 } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="300" height="257" viewBox="0 0 300 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="299.33" height="257" transform="translate(0 0.00195312)"/>
        <path d="M145.23 62.0311C145.23 62.0311 152.262 65.0667 155.768 60.7513C159.273 56.436 158.954 51.9718 158.369 48.6881C157.785 45.4044 152.042 34.0059 152.042 34.0059C152.042 34.0059 150.146 39.6803 145.869 43.3807C141.592 47.081 139.222 49.9381 139.222 49.9381C139.222 49.9381 135.651 49.819 136.72 54.1344C137.789 58.4498 142.088 56.2475 142.088 56.2475" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M140.291 56.9619L141.206 65.3149" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M139.099 50.0566L140.053 50.6143" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M148.784 47.4883C148.784 47.7202 148.692 47.9426 148.528 48.1066C148.364 48.2707 148.142 48.363 147.91 48.3633C147.678 48.3635 147.456 48.2717 147.292 48.1081C147.127 47.9444 147.035 47.7222 147.034 47.4903C147.034 47.2584 147.125 47.0358 147.289 46.8713C147.452 46.7069 147.674 46.6141 147.906 46.6133C148.138 46.6125 148.361 46.7038 148.525 46.8671C148.69 47.0304 148.783 47.2524 148.784 47.4842" fill="black"/>
        <path d="M148.784 47.4883C148.784 47.7202 148.692 47.9426 148.528 48.1066C148.364 48.2707 148.142 48.363 147.91 48.3633C147.678 48.3635 147.456 48.2717 147.292 48.1081C147.127 47.9444 147.035 47.7222 147.034 47.4903C147.034 47.2584 147.125 47.0358 147.289 46.8713C147.452 46.7069 147.674 46.6141 147.906 46.6133C148.138 46.6125 148.361 46.7038 148.525 46.8671C148.69 47.0304 148.783 47.2524 148.784 47.4842" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M155.448 45.2744C155.448 45.5063 155.356 45.7287 155.192 45.8928C155.028 46.0568 154.806 46.1491 154.574 46.1494C154.342 46.1497 154.119 46.0579 153.955 45.8942C153.791 45.7305 153.698 45.5083 153.698 45.2764C153.697 45.0445 153.789 44.8219 153.952 44.6575C154.116 44.493 154.338 44.4002 154.57 44.3994C154.802 44.3986 155.024 44.4899 155.189 44.6532C155.354 44.8165 155.447 45.0385 155.448 45.2704" fill="black"/>
        <path d="M155.448 45.2744C155.448 45.5063 155.356 45.7287 155.192 45.8928C155.028 46.0568 154.806 46.1491 154.574 46.1494C154.342 46.1497 154.119 46.0579 153.955 45.8942C153.791 45.7305 153.698 45.5083 153.698 45.2764C153.697 45.0445 153.789 44.8219 153.952 44.6575C154.116 44.493 154.338 44.4002 154.57 44.3994C154.802 44.3986 155.024 44.4899 155.189 44.6532C155.354 44.8165 155.447 45.0385 155.448 45.2704" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M152.157 47.1865C152.157 47.1865 153.37 49.2301 154.152 49.9742C154.935 50.7182 155.933 50.996 155.933 50.996L154.709 53.1685" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M150.779 56.2627C150.779 56.2627 151.97 57.3143 153.215 57.2795C154.461 57.2448 155.652 56.3222 155.652 56.3222" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M155.981 41.4255C155.981 41.4255 158.849 30.1036 147.796 28.9953C136.744 27.8869 131.007 32.8394 127.983 39.6157C124.958 46.392 122.994 55.1885 119.097 58.6539C115.2 62.1192 99.1739 66.6227 94.6686 72.9641C90.1634 79.3054 89.6489 83.7528 89.6489 83.7528L101.793 90.1503C101.793 90.1503 102.261 89.1402 103.04 87.9757C103.819 86.8113 105.16 85.6468 105.16 85.6468C105.16 85.6468 104.614 87.4005 104.053 88.7053C103.492 90.01 103.118 91.5393 103.118 91.5393L110.725 95.5658C110.725 95.5658 112.923 88.4668 115.48 86.208C118.037 83.9492 124.163 80.4559 124.163 80.4559" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M140.095 67.7451C140.095 67.7451 139.487 65.8511 140.22 65.5004C140.953 65.1497 144.819 64.9532 146.643 65.1356C148.467 65.318 151.242 64.8831 152.099 65.5425C152.956 66.2019 152.956 68.2362 152.956 68.2362C152.956 68.2362 159.629 65.332 168.889 59.6921C178.148 54.0522 182.576 49.9837 186.286 49.8153C189.996 49.6469 191.96 54.0102 191.96 54.0102C193.208 57.6017 191.43 60.099 189.217 61.993C187.003 63.887 182.529 66.8753 173.939 71.1403C165.35 75.4053 157.68 79.1793 157.68 79.1793C157.68 79.1793 159.862 82.827 160.361 86.152C160.86 89.477 158.943 92.4934 159.208 95.636C159.473 98.7786 161.219 103.338 160.455 105.765C159.691 108.193 151.881 111.097 146.471 111.293C141.062 111.489 134.546 111.461 132.441 110.101C130.337 108.74 131.755 101.781 132.238 98.5121C132.722 95.2432 132.8 90.1925 132.8 90.1925C132.8 90.1925 128.902 100.336 126.003 105.639C123.103 110.942 119.268 123.274 109.712 118.083C100.156 112.892 113.516 100.097 118.52 91.6656C123.524 83.2338 126.237 74.9564 130.835 72.1224C135.434 69.2884 140.095 67.7451 140.095 67.7451Z" fill="black" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M157.739 179.964C157.739 179.964 155.788 181.928 155.92 185.499C156.052 189.071 156.901 189.964 157.111 191.779C157.32 193.595 157.177 196.491 157.177 196.491L160.473 196.521L160.704 191.978C160.704 191.978 165.294 195.491 168.168 196.272C171.202 197.096 176.678 197.242 178.552 196.359C180.425 195.476 179.873 194.149 179.17 193.466C178.386 192.705 175.398 190.033 175.398 190.033C175.398 190.033 175.663 191.323 174.219 191.69C172.774 192.057 168.409 192.027 164.783 188.803C161.156 185.579 157.739 179.964 157.739 179.964Z" fill="black" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M117.043 196.912C117.043 196.912 114.278 197.055 111.975 199.787C109.672 202.52 109.7 203.752 108.634 205.236C107.569 206.721 105.515 208.769 105.515 208.769L107.935 211.007L111.161 207.799C111.161 207.799 112.196 213.486 113.799 215.996C115.491 218.646 119.447 222.435 121.428 223.041C123.408 223.647 123.892 222.294 123.83 221.315C123.762 220.225 123.346 216.238 123.346 216.238C123.346 216.238 122.675 217.371 121.359 216.672C120.043 215.973 116.832 213.016 116.315 208.191C115.798 203.366 117.043 196.912 117.043 196.912Z" fill="black" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M132.285 109.579C132.285 109.579 128.2 117.997 125.753 129.445C123.305 140.893 120.811 151.598 120.811 151.598C120.811 151.598 122.822 153.281 126.205 152.622C129.588 151.963 131.521 148.848 131.521 148.848C131.521 148.848 134.919 151.78 139.565 150.504C144.21 149.227 146.128 146.477 146.128 146.477C146.128 146.477 149.199 149.143 153.766 146.66C158.334 144.176 159.628 141.637 159.628 141.637C159.628 141.637 163.307 143.138 167.329 140.529C171.351 137.919 171.413 134.889 171.413 134.889C171.413 134.889 174.749 136.011 177.025 134.72C179.301 133.43 180.767 131.578 180.767 131.578C180.767 131.578 170.977 122.781 165.817 117.352C160.657 111.922 158.584 107.391 158.584 107.391" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M188.226 50.0404C188.226 50.0404 190.283 47.2976 191.663 44.7863C193.043 42.275 193.526 39.6444 194.555 39.7847C195.584 39.925 195.147 41.2228 194.734 42.5766C194.321 43.9305 193.978 45.5439 193.978 45.5439C193.978 45.5439 195.88 43.2641 196.675 41.9664C197.47 40.6686 198.694 38.487 199.465 38.8658C200.237 39.2446 199.504 41.314 198.748 42.7871C197.992 44.2602 196.893 46.533 196.893 46.533C196.893 46.533 199.458 43.7271 200.276 42.7029C201.094 41.6787 202.684 39.5392 203.48 40.0022C204.275 40.4652 203.706 42.8993 201.928 45.0038C200.151 47.1082 199.037 48.3639 199.037 48.3639C199.037 48.3639 201.102 47.0381 202.303 46.2103C203.503 45.3826 204.672 44.0427 205.389 44.6811C206.106 45.3194 204.664 47.3186 202.887 48.7146C201.11 50.1105 199.06 52.0747 199.06 52.0747C199.06 52.0747 200.533 52.1027 201.601 51.9835C202.669 51.8642 203.924 51.6117 204.088 52.706C204.251 53.8003 201.749 54.3896 200.19 54.8385C198.631 55.2875 197.688 56.3748 195.124 56.8097C192.559 57.2446 191.063 57.434 191.063 57.434" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M106.75 114.203C106.75 114.203 103.398 115.307 100.485 115.821C97.5719 116.335 94.8191 115.927 94.6387 116.986C94.4581 118.046 95.8777 118.052 97.3454 118.1C98.8132 118.149 100.516 118.353 100.516 118.353C100.516 118.353 97.6701 119.459 96.1393 119.805C94.6086 120.152 92.0703 120.623 92.2056 121.51C92.341 122.396 94.6159 122.37 96.308 122.121C98.0002 121.872 100.59 121.554 100.59 121.554C100.59 121.554 97.018 123.135 95.7506 123.596C94.4833 124.057 91.873 124.903 92.0844 125.841C92.2958 126.779 94.8807 127.037 97.5144 125.995C100.148 124.953 101.736 124.278 101.736 124.278C101.736 124.278 99.7836 125.865 98.5922 126.768C97.4006 127.671 95.7122 128.372 96.1212 129.292C96.5303 130.212 98.9559 129.464 100.889 128.184C102.821 126.904 105.401 125.547 105.401 125.547C105.401 125.547 104.971 127.006 104.522 128.017C104.073 129.027 103.433 130.177 104.465 130.706C105.497 131.234 106.857 128.97 107.785 127.587C108.713 126.204 110.081 125.641 111.307 123.264C112.533 120.887 113.185 119.478 113.185 119.478" stroke="black" strokeWidth="1.03817" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M158.173 179.915L164.907 148.222L162.413 142.049" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.884 135.398C174.884 135.398 178.111 143.704 177.768 146.299C177.425 148.895 167.682 178.455 167.682 178.455C167.682 178.455 169.132 183.057 171.408 185.765C173.684 188.472 175.508 190.1 175.508 190.1" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M131.952 149.316C131.952 149.316 131.796 162.645 131.344 164.244C130.892 165.843 117.049 197.129 117.049 197.129" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M147.104 147.254C147.104 147.254 145.202 162.378 143.098 166.474C140.993 170.571 125.435 199.795 124.515 202.194C123.596 204.593 123.409 208.016 123.253 210.359C123.097 212.702 123.222 216.364 123.222 216.364" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M223.372 28.6806L224.077 20.0498L229.379 26.6965L236.677 24.2759L233.921 31.4881L238.198 38.5514L229.6 36.607L224.992 43.7993L223.504 35.1884L216.262 32.6984L223.372 28.6806Z" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M236.969 132.279L237.485 123.635L242.931 130.164L250.174 127.584L247.577 134.855L252.007 141.823L243.369 140.067L238.919 147.359L237.243 138.782L229.948 136.451L236.969 132.279Z" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M204.042 110.485L205.351 104.277L207.37 110.45L212.787 111.874L207.456 114.449L205.609 120.496L203.629 114.287L198.258 112.183L204.042 110.485Z" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M98.8075 171.89L99.8147 167.115L101.367 171.863L105.534 172.959L101.433 174.939L100.013 179.59L98.4897 174.815L94.3589 173.196L98.8075 171.89Z" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M234.376 176.107L236.349 170.096L237.969 176.445L243.205 177.834L237.286 180.502L235.952 185.611L233.924 180.155L229.515 177.744L234.376 176.107Z" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M68.6925 99.2436L70.2468 89.8291L72.9364 98.7872L81.27 100.732L73.5648 103.499L71.6467 111.396L68.45 104.105L60.9873 101.515L68.6925 99.2436Z" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M226.575 57.6895L226.7 64.4798" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M230.15 60.8076L223.36 60.9415" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M195.303 77.9033L195.454 86.129" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M199.632 81.6787L191.407 81.8409" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M84.2583 45.4404L84.4094 53.6661" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M88.5884 49.2158L80.3628 49.378" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.284 20.7363L174.223 25.6023" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M173.981 30.998L173.937 35.2589" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M167.257 27.7148L171.468 27.8587" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M176.638 27.9883L180.65 28.0131" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M171.986 25.8651L170.597 23.8115" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M176.153 26.0037L177.928 24.2031" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M172.069 29.793L170.376 31.4249" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M175.673 29.7236L177.597 31.5143" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M70.6693 161.824L70.5039 155.123" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M70.895 171.393L71.1045 178.277" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M74.2793 166.814L81.9184 166.581" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M67.5827 167.033L61.3105 167.207" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M72.9688 164.042L75.6928 161.132" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M68.7824 169.558L66.0005 172.564" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M72.9697 169.25L76.306 172.336" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M68.3252 164.15L65.5933 161.608" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M38.1992 248.647C38.1992 248.647 51.2317 230.071 74.709 217.753C98.1862 205.435 111.437 204.061 111.437 204.061" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M136.691 178.807C136.691 178.807 143.238 174.374 149.412 172.354C155.585 170.334 160.449 169.66 160.449 169.66" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M127.026 251.509C127.026 251.509 130.58 246.29 137.034 242.165C143.488 238.04 150.721 234.084 150.721 234.084C150.721 234.084 154.65 217.08 168.119 207.231C181.588 197.382 196.335 194.212 196.335 194.212C196.335 194.212 201.23 176.506 214.98 164.272C228.729 152.039 238.396 145.714 238.396 145.714" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M171 168.561C171 168.561 172.62 168.303 174.891 168.471C177.161 168.64 178.991 168.689 178.991 168.689C178.991 168.689 184.79 155.595 199.065 145.833C213.34 136.071 236.204 132.768 236.204 132.768" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M69.65 215.424C69.65 215.424 42.5093 213.165 39.1577 194.674C35.806 176.183 45.4871 165.088 49.9142 157.467C53.9439 150.53 62.662 143.127 57.9893 134.795C53.7338 127.208 43.9248 129.823 34.403 122.351C28.0228 117.345 2.80126 99.9551 60.4811 48.8873C118.161 -2.18052 188.838 8.00208 218.064 20.3336" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M237.379 41.9541C237.379 41.9541 244.742 47.155 244.426 54.9455C243.615 74.9237 233.715 81.5058 236.818 94.2846C239.2 104.097 282.282 115.455 272.398 150.87C265.653 175.037 251.217 195.942 221.816 210.225C192.415 224.507 157.183 227.228 157.183 227.228" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </IconWrapper>
  )
})
