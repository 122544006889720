import { FC } from 'react'
import { useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useBehaviorMapper } from 'src/hooks'
import { IconHome } from 'src/icons'
import { ERoutes } from 'src/router'
import { AuthModule } from 'src/store'
import { NavItem } from '../nav-item'

interface IProps {
  expanded: boolean
}

export const MyJobsNav: FC<IProps> = ({ expanded: sidebarExpanded }) => {
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const { eventHandler } = useAnalytic('side_nav')

  return (
    <nav className="fx-column px-6 relative">
      <NavItem
        mini={!sidebarExpanded}
        route={isAuthenticated ? ERoutes.CAMPAIGN_MY_JOBS : ERoutes.CAMPAIGNS_PUBLIC_ONBOARDING}
        icon={<IconHome className="svg-current-color"/>}
        label="My Jobs"
        onClick={eventHandler(ETrackingEvent.BTN_NAV_MY_JOBS)}
      />
    </nav>
  )
}
