import { Box, Typography, useTheme } from '@mui/material'
import { ChangeEventHandler, FC, FormEventHandler, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PasswordApi } from 'src/api'
import { Button, Input, NotAllowAuthenticated, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useOldValidation, useQueryParams } from 'src/hooks'
import { Logo } from 'src/images'
import { ERoutes, generate } from 'src/router'
import { SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { EventUtils, getApiErrorMessage } from 'src/utils'
import { resetPasswordSchema } from 'src/validation'
import Style from './style.module.scss'

interface IResetPasswordForm {
  newPassword?: string
  confirmPassword?: string
}

export const ResetPassword: FC = () => {
  const { token } = useQueryParams()
  const history = useHistory()
  const theme = useTheme()

  const [formValues, setFormValues] = useState<IResetPasswordForm>({ newPassword: '', confirmPassword: '' })
  const { errors, validate, validateAt } = useOldValidation<IResetPasswordForm>()
  const { eventHandler } = useAnalytic('pswd_reset4')

  const handleChangeInput = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: resetPasswordSchema,
      path: name,
      data: { ...formValues, [name]: value }
    })

    if (name === 'newPassword' && formValues.confirmPassword) {
      // when password change, validate confirmPassword also
      validateAt({
        schema: resetPasswordSchema,
        path: 'confirmPassword',
        data: { ...formValues, newPassword: value }
      })
    }

    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }, [validateAt, formValues])

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(async (e) => {
    try {
      EventUtils.preventDefault(e)
      const isValid = await validate({ schema: resetPasswordSchema, data: formValues })
      if (!isValid || !formValues.newPassword) return

      const { data } = await PasswordApi.resetPasswordRequest({ password: formValues.newPassword, token: token as string })
      AuthModule.authenticated(data.profile)
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    }
  }, [formValues, token, validate])

  const isInValid = useMemo(() =>
    Object.keys(errors)?.length <= 0 ||
    Object.values(errors).some((err) => err) ||
    Object.values(formValues).some((value) => !value)
  , [errors, formValues])

  return (
    <NotAllowAuthenticated>

      <>
        <div className={Style.Container}>
          <div className={Style.RightSide}>
            <div className={Style.STSetPasswordWrapper}>
              <form className={Style.STForm} onSubmit={handleSubmit}>
                <div className={Style.Logo}>
                  <img src={Logo} alt="logo"/>
                </div>
                <div className={Style.SetPasswordHeader}>
                  <Typography variant="h5" color={theme.colors['--color-neutral-theme-700']} className={Style.Title}>Set your new password</Typography>
                </div>
                <div className="fx-column gap-4">
                  <Input
                    isCheckPassword
                    label="Password"
                    id="newPassword"
                    type="password"
                    name="newPassword"
                    placeholder="Enter your password"
                    value={formValues.newPassword || ''}
                    onChange={handleChangeInput}
                    onClick={eventHandler(ETrackingEvent.INPUT_PSWD_NEW)}
                    error={errors.newPassword}
                    required
                  />
                  <Input
                    label="Confirm Password"
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    placeholder="Enter your password"
                    value={formValues.confirmPassword || ''}
                    onChange={handleChangeInput}
                    onClick={eventHandler(ETrackingEvent.INPUT_PASSWORD)}
                    error={errors.confirmPassword}
                    required
                  />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box className={Style.Box}>
                      <Typography
                        variant="body2-medium"
                        color={theme.colors['--color-neutral-theme-400']}
                      >
                        New to KNOWME?
                      </Typography>
                      <Typography
                        variant="body2-medium"
                        component="a"
                        color={theme.colors['--color-blue-500']}
                        onClick={eventHandler(ETrackingEvent.BTN_SIGNUP, () => history.push(generate(ERoutes.SIGN_UP)))}
                        sx={{ cursor: 'pointer' }}
                      >
                        Create account
                      </Typography>
                    </Box>
                    <Button
                      className="round-16"
                      type="submit"
                      disabled={isInValid}
                      onClick={eventHandler(ETrackingEvent.BTN_CHANGE_PSWD)}
                    >
                      Sign in
                    </Button>
                  </Box>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    </NotAllowAuthenticated>
  )
}
