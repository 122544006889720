import { ComponentProps, FC } from 'react'
import { Button, ModalCenter } from 'src/components'

export const ModalVerificationPending: FC<ComponentProps<typeof ModalCenter>> = (props) => {
  return (
    <ModalCenter {...props}>
      <div className="fx-column gap-8">
        <div className="fx-column gap-2">
          <div className="text-center semibold-24 txt-black-01">Verification Pending</div>
          <div className="text-center body-14 txt-black-02">
            You can only create or claim one job before <br/>
            your account is verified. Please try again after <br/>
            your account has been verified.
          </div>
        </div>

        <svg className="fx-as-center" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_12402_187214)">
            <path d="M61.1709 5.0249C53.5809 5.5749 48.7909 12.5149 42.5009 15.9449C34.1809 20.9449 23.3009 20.9349 16.0409 27.7849C12.5109 31.9349 15.2209 37.6549 15.7509 42.3849C20.0309 61.4949 26.0909 80.6249 36.9509 97.0949C42.3809 105.045 50.5609 113.365 61.0009 112.565C71.7909 111.565 79.1409 102.115 84.0809 93.3449C92.1509 78.2949 96.7909 61.4749 98.5309 44.5249C99.3209 39.3749 98.6509 32.6149 92.9609 30.5549C84.0309 28.2749 79.0709 19.8949 72.9409 13.7849C69.4809 10.4949 66.1909 5.8549 61.1909 5.0249L61.1709 5.0249Z" fill="#AFF6C5"/>
            <path d="M55.0411 52.5751C56.4911 53.9051 58.5611 54.3751 60.5211 54.3351C62.3911 54.2951 64.3111 53.7951 65.7211 52.5751C70.1811 48.7351 67.0111 39.9851 61.1511 39.7451C55.2911 39.5051 50.3611 48.2751 55.0311 52.5751L55.0411 52.5751Z" fill="white" stroke="black" stroke-width="0.25" stroke-miterlimit="10"/>
            <path d="M48.2504 69.565C47.5104 70.485 46.7304 71.555 46.8104 72.775C54.6004 75.705 63.3104 75.695 71.1004 72.735C71.4904 72.585 66.8604 66.705 66.4004 66.275C64.7404 64.695 62.6904 62.745 60.4904 62.145C55.5704 60.795 51.1104 66.005 48.2504 69.575L48.2504 69.565Z" fill="white" stroke="black" stroke-width="0.25" stroke-miterlimit="10"/>
            <path d="M59.4297 22.0246C68.2897 30.3546 79.0397 35.7846 90.9797 38.0746C92.2097 38.3146 93.2997 36.2746 91.7597 35.9846C80.1897 33.7646 69.7797 28.3846 61.2097 20.3146C60.3197 19.4746 58.4397 21.0846 59.4397 22.0246L59.4297 22.0246Z" fill="black"/>
            <path d="M59.4398 20.2251C49.7198 26.3551 39.6698 31.9051 29.2898 36.8451C28.0198 37.4551 28.2298 39.4751 29.7098 38.7751C40.2898 33.7351 50.5498 28.1151 60.4598 21.8551C61.7798 21.0251 60.7598 19.3851 59.4398 20.2251Z" fill="black"/>
            <path d="M27.6095 39.1848C29.2195 51.3648 33.2895 63.4748 38.2795 74.6748C42.6495 84.4848 49.4695 92.8448 57.9795 99.3648C58.9495 100.115 60.8295 98.4748 59.7495 97.6548C41.7895 83.8848 32.8295 60.2548 29.9295 38.3448C29.7495 37.0148 27.4495 37.9748 27.6095 39.1848Z" fill="black"/>
            <path d="M60.5599 99.035C62.2099 97.415 63.9899 95.965 65.9199 94.695C67.2199 93.835 66.2099 92.195 64.8999 93.065C62.7899 94.455 60.8199 96.045 59.0099 97.815C57.8499 98.965 59.5299 100.045 60.5599 99.035Z" fill="black"/>
            <path d="M91.0494 37.8447C89.6694 54.1947 84.2794 69.6647 75.1794 83.3147C74.2694 84.6747 76.3994 85.1547 77.1294 84.0547C86.4294 70.0947 91.9894 54.2447 93.3994 37.5247C93.5194 36.0747 91.1594 36.6347 91.0494 37.8447Z" fill="black"/>
            <path d="M18.2601 33.9146C33.4701 29.4247 46.3401 20.2346 58.9801 10.9546C60.2201 10.0446 59.2301 8.39465 57.9601 9.32465C45.5101 18.4647 32.8401 27.5646 17.8401 31.9946C16.4501 32.4046 16.7001 34.3846 18.2601 33.9246L18.2601 33.9146Z" fill="black"/>
            <path d="M59.1307 9.765C71.0907 20.395 84.0207 31.265 100.791 31.865C102.011 31.905 103.121 29.825 101.571 29.775C85.1707 29.185 72.5907 18.435 60.9107 8.055C60.0007 7.245 58.1207 8.855 59.1407 9.765L59.1307 9.765Z" fill="black"/>
            <path d="M17.0198 34.5949C17.8398 63.3949 30.2598 97.7749 57.5398 111.515C58.6198 112.065 60.1698 110.205 58.8598 109.545C32.3098 96.1749 20.1698 62.2749 19.3698 34.2749C19.3298 32.8049 16.9898 33.3849 17.0198 34.5949Z" fill="black"/>
            <path d="M100.74 31.3548C99.8399 47.8048 95.5799 63.6548 88.1399 78.3548C87.4399 79.7348 89.8099 79.6548 90.3599 78.5748C97.8799 63.7248 102.17 47.6548 103.08 31.0348C103.16 29.5748 100.8 30.1448 100.73 31.3548L100.74 31.3548Z" fill="black"/>
            <path d="M59.4002 110.855C64.1302 108.145 68.5302 104.965 72.6102 101.345C73.8402 100.255 72.1302 99.1751 71.0602 100.125C67.1402 103.595 62.9202 106.615 58.3802 109.225C57.0202 110.005 58.0502 111.635 59.4002 110.855Z" fill="black"/>
            <path d="M60.9794 88.8851C65.8194 95.6351 71.4694 101.655 77.8894 106.925C78.8394 107.705 80.7194 106.075 79.6594 105.215C73.3694 100.065 67.8394 94.1751 63.0894 87.5651C62.3394 86.5251 60.2294 87.8551 60.9694 88.8851L60.9794 88.8851Z" fill="black"/>
            <path d="M63.0197 88.5347C65.8897 86.0047 68.6497 83.3547 71.2897 80.5847C72.4097 79.4047 70.7397 78.3147 69.7397 79.3647C67.0997 82.1347 64.3497 84.7947 61.4697 87.3147C60.2397 88.3947 61.9397 89.4847 63.0197 88.5347Z" fill="black"/>
            <path d="M70.0809 80.645C72.8209 83.875 75.8509 86.815 79.1809 89.425C80.1409 90.185 82.0209 88.555 80.9509 87.715C77.7609 85.205 74.8309 82.415 72.2009 79.315C71.3709 78.335 69.2709 79.685 70.0809 80.635L70.0809 80.645Z" fill="black"/>
            <path d="M81.4593 89.0248C88.7493 80.7248 96.6693 73.0748 105.209 66.0848C106.479 65.0448 104.759 63.9648 103.659 64.8648C95.1093 71.8548 87.1893 79.5048 79.9093 87.8048C78.8393 89.0248 80.4993 90.1248 81.4593 89.0248Z" fill="black"/>
            <path d="M104.059 66.3147C106.909 69.6847 109.709 73.0947 112.449 76.5547C113.249 77.5547 115.349 76.2147 114.569 75.2347C111.829 71.7747 109.029 68.3647 106.179 64.9947C105.349 64.0147 103.249 65.3647 104.059 66.3147Z" fill="black"/>
            <path d="M80.6411 106.995C91.9011 96.9247 103.161 86.8447 114.421 76.7747C115.641 75.6847 113.941 74.5947 112.871 75.5547C101.611 85.6247 90.3511 95.7047 79.0911 105.775C77.8711 106.865 79.5711 107.955 80.6411 106.995Z" fill="black"/>
            <path d="M58.4001 39.185C53.1601 40.445 50.6701 45.945 52.3301 50.995C53.8201 55.535 60.2001 56.705 64.1201 54.805C68.0401 52.905 70.0801 47.525 67.9001 43.495C66.4901 40.895 60.0401 36.155 57.7201 40.135C56.9001 41.545 59.0001 42.025 59.6701 40.875C60.0501 40.215 63.2501 42.055 63.6201 42.325C64.7401 43.155 65.7101 44.325 66.1701 45.665C67.0301 48.195 66.1601 51.365 63.8001 52.805C61.6301 54.135 57.6701 53.695 55.8001 51.935C52.7501 49.065 53.9501 42.295 58.2101 41.265C59.4601 40.965 59.9801 38.805 58.4001 39.185Z" fill="black"/>
            <path d="M47.4999 72.3751C49.3299 66.7351 55.8999 62.3951 61.6999 64.0751C66.2699 65.3951 69.0999 69.1551 70.7999 73.4051C71.3099 74.6751 73.5699 73.6851 73.1199 72.5651C70.7599 66.6751 66.1399 61.4851 59.3199 61.4451C53.3199 61.4051 47.0899 66.5951 45.2799 72.1651C44.7999 73.6551 47.1199 73.5651 47.4999 72.3851L47.4999 72.3751Z" fill="black"/>
            <path d="M46.1699 73.2749C53.8399 75.9749 63.3099 77.5049 71.1199 74.4449C72.4499 73.9249 72.2299 71.9149 70.6999 72.5149C63.4799 75.3449 54.5699 73.7949 47.4899 71.3049C46.3499 70.9049 44.7999 72.7849 46.1699 73.2749Z" fill="black"/>
          </g>
          <defs>
            <clipPath id="clip0_12402_187214">
              <rect width="109.68" height="112.57" fill="white" transform="translate(5.16016 3.71484)"/>
            </clipPath>
          </defs>
        </svg>

        <Button onClick={props.onClose}>
          Close
        </Button>
      </div>
    </ModalCenter>
  )
}
