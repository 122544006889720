import clsx from 'clsx'
import { ComponentProps, FC } from 'react'
import { EMotivator } from 'src/enums'
import { IconClose } from 'src/icons'
import Style from './style.module.scss'

interface IProps {
  value: `${EMotivator}`
  small?: boolean
  closeAble?: boolean
  onClick?: ComponentProps<'span'>['onClick']
  onClose?: () => void
  className?: string
}

export const MotivatorBadge: FC<IProps> = (props) => {
  return (
    <span
      className={clsx(Style.motivatorBadge, Style[props.value], props.className)}
      onClick={props.onClick}
    >
      <span className={clsx({ 'fw-semi-bold': !props.small })}>{props.value}</span>
      {props.closeAble && (
        <IconClose
          size={12}
          className="pointer"
          color="currentColor"
          onClick={() => props.onClose?.()}
        />
      )}
    </span>
  )
}
