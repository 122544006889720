import { Typography, useTheme } from '@mui/material'
import { FC, useCallback, useRef, useState } from 'react'
import { Button, Spinner, VideoPlayerOld } from 'src/components'
import { CREDIT_FOR_FIRST_TIME_VVC } from 'src/constants'
import { useBehaviorMapper, useImageFromFile } from 'src/hooks'
import { useIsAtGuestOnboarding } from 'src/hooks/useIsOnboarding'
import { IconCameraPlus, IconConfetti, IconUpload, IconVideo } from 'src/icons'
import { IOverlayProps } from 'src/interfaces/overlay-props.interface'
import { ModalRecorder } from 'src/partials/recorder/modal-recorder'
import { DialogService, OverlayService, ProfileService, SnackbarService } from 'src/services'
import { PopupTourService } from 'src/services/tour/popup.service'
import { allowedAudioTypes, getApiErrorMessage } from 'src/utils'
import Style from './style.module.scss'

export const VVCPopup: FC & IOverlayProps = () => {
  const theme = useTheme()
  const pfvFiles = useBehaviorMapper(PopupTourService.pfvFiles)
  const isAtGuestOnboarding = useIsAtGuestOnboarding()

  const recordResultRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const bgAudioSrc = useImageFromFile(pfvFiles?.placeholder?.file || pfvFiles?.placeholder?.src || undefined)

  const handleSubmitPfv = useCallback(async () => {
    if (!pfvFiles?.file) {
      return
    }

    setLoading(true)

    if (isAtGuestOnboarding) {
      try {
        // TODO: implement the following code block

        // const key = await UploadApi.upload({
        //   kind: pfvFiles?.file?.type.startsWith('audio')
        //     ? EFileUploadKind.AUDIO_VIBE_CHECK
        //     : EFileUploadKind.VIDEO_VIBE_CHECK,
        //   file: pfvFiles?.file,
        //   sessionId: AnalyticApi._session
        // })

        // const { data: { pfv } } = await ProfileApi.uploadOnboardingVideo({
        //   key,
        //   thumbnailOffset: pfvFiles?.thumbnailOffset
        // })

        // GuestOnboardingService.setVvcId(pfv.id)
        // OverlayService.setOverlay({
        //   content: (
        //     <SignUpModal
        //       showVVCText
        //       campaignOnboardingSlug={GuestOnboardingService.createdCampaignSlug$.getValue() ?? undefined}
        //       onboardingPfvId={GuestOnboardingService.vvcId$.getValue() ?? undefined}
        //     />
        //   ),
        //   open: true
        // })
      } catch (error) {
        console.error('failed to upload vvc from vvc-popup', { error })
        SnackbarService.error('Failed to upload VVC, please try later')
      } finally {
        setLoading(false)
      }

      return
    }

    ProfileService
      .uploadPfv(pfvFiles.file, pfvFiles.placeholder, undefined, pfvFiles.thumbnailOffset)
      .then(() => {
        OverlayService.reset()
        PopupTourService.startFlyingCoin()
      })
      .catch((error) => {
        console.error('failed to upload vvc from vvc-popup', { error })
        SnackbarService.error(getApiErrorMessage(error))
      })
  }, [pfvFiles, isAtGuestOnboarding])

  const handleFileSelected = (record: File) => {
    PopupTourService.setPfvFile({
      file: record
    })
  }

  const handleRecord = useCallback(() => {
    OverlayService.reset()
    PopupTourService.hide()
    DialogService.open(ModalRecorder)
  }, [])

  return (
    <div className={Style.container}>
      <div className="d-none">
        <input
          type="file"
          ref={recordResultRef}
          accept={['video/*', ...allowedAudioTypes].join(', ')}
          onChange={(e) => {
            if (!e.target.files?.[0]) return
            handleFileSelected(e.target.files?.[0])
            e.target.value = ''
          }}
        />
      </div>

      <div className={Style.top}>
        <div className={Style.left}>
          <IconConfetti width="61px" height="61px"/>

          <div className={Style.leftText}>
            {pfvFiles?.file
              ? (
                <Typography variant="h5" color={theme.colors['--color-neutral-theme-700']} align="center">
                  You’ve Made it
                </Typography>
              )
              : (
                <Typography variant="h5" color={theme.colors['--color-neutral-theme-700']} align="center">
                  Be the first 100 to create a video vibe check and get <span className="color-cyan-500">{CREDIT_FOR_FIRST_TIME_VVC} free</span> credits
                </Typography>
              )}
            <span className="body2-medium color-neutral-theme-300 text-center">
              {pfvFiles?.file
                ? (
                  <>Submit and be the first 100 to create a video vibe check and get <span className="color-cyan-500">{CREDIT_FOR_FIRST_TIME_VVC} free</span> credits</>
                )
                : (
                  <>With 1 credit, you can unlock the contact
                    information of 1 candidate in our marketplace.  By earning more credits, you can reach out to many of your favorites candidates.
                  </>
                )}
            </span>
          </div>
        </div>
        <div className={Style.right}>
          {!pfvFiles?.file
            ? (
              <div className={Style.rightEmpty}>
                {loading && (
                  <div className={Style.loadingContainer}>
                    <Spinner/>
                  </div>
                )}
                <IconVideo width={60} height={60}/>
                <div className="fx flex-column fx-ai-center gap-2 w-100">
                  <Button
                    className="w-100-p"
                    onClick={handleRecord}
                  >
                    <IconCameraPlus width={18} height={18}/>
                    Record Now
                  </Button>
                  <Button
                    className="w-100-p"
                    variant="secondary"
                    onClick={() => recordResultRef.current?.click?.()}
                  >
                    <IconUpload width={17} height={17}/>
                    Upload
                  </Button>
                </div>
              </div>
            )
            : (
              <div className={Style.rightWithVideo}>
                {loading && (
                  <div className={Style.loadingContainer}>
                    <Spinner/>
                  </div>
                )}
                <VideoPlayerOld
                  forceRender
                  className={Style.video}
                  url={pfvFiles?.file}
                  hideStartEnd
                  image={bgAudioSrc || undefined}
                />
                <div className={Style.videoButtons}>
                  <Button
                    className="w-100-p"
                    onClick={handleRecord}
                  >
                    <IconCameraPlus width={18} height={18}/>
                    Record Again
                  </Button>
                  <Button
                    className="w-100-p"
                    variant="secondary"
                    onClick={() => recordResultRef.current?.click?.()}
                  >
                    <IconUpload width={17} height={17}/>
                    Upload
                  </Button>
                </div>
              </div>
            )}
        </div>
      </div>

      {pfvFiles?.file && (
        <div className={Style.bottom}>
          <Button
            className="w-100-p"
            onClick={handleSubmitPfv}
            disabled={loading}
          >Submit
          </Button>
        </div>
      )}
    </div>
  )
}

VVCPopup.getOverlayState = () => ({
  open: true,
  content: <VVCPopup/>,
  onClose: () => {
    PopupTourService.hideVVCPopup()
    if (!PopupTourService.isShowingPopup$.getValue()) {
      PopupTourService.show()
    }
  }
})
