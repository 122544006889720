import clsx from 'clsx'
import { ComponentProps, forwardRef } from 'react'

export const PageTitle = forwardRef<HTMLDivElement, ComponentProps<'span'>>(function _(props, ref) {
  return (
    <span
      {...props}
      ref={ref}
      className={clsx(props.className, 'bold-24 sm-heading-16 txt-black-01 sm-txt-grey-01')}
    >
      {props.children}
    </span>
  )
})

export const PageTitleSecondary = forwardRef<HTMLDivElement, ComponentProps<'span'>>(function _(props, ref) {
  return (
    <span
      {...props}
      ref={ref}
      className={clsx(props.className, 'body-24 sm-body-16 txt-grey-01')}
    >
      {props.children}
    </span>
  )
})
