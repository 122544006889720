import { useMediaQuery } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { finalize, forkJoin, from, takeUntil } from 'rxjs'
import { CampaignApi, CampaignClaimApi } from 'src/api'
import { BreadcrumbHeading, Tabs, useAnalytic } from 'src/components'
import { ECampaignStatus, ETrackingEvent } from 'src/enums'
import { useBehaviorMapper, useQueryParams, useUnsubscribe } from 'src/hooks'
import { ICampaignModelExtended, ITab } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { ModalVerificationPending } from 'src/partials'
import { ERoutes, browserHistory, generate } from 'src/router'
import { DialogService } from 'src/services'
import { PopupTourService } from 'src/services/tour/popup.service'
import { ShareJobTourService } from 'src/services/tour/share-job-tour.service'
import { AuthModule, CampaignModule, LoadingModule, SidebarModule } from 'src/store'
import { getFirstCompany } from 'src/utils'
import { Sandbox } from '../sandbox'
import { CardJob } from './components/card-job'
import { CreateJobButton } from './components/create-job-button'
import { EmptyJob } from './components/empty-job'
import Style from './style.module.scss'

const campaignStatus = {
  [ECampaignStatus.PUBLISHED]: 'Published',
  [ECampaignStatus.DRAFT]: 'Drafts',
  [ECampaignStatus.UNVERIFIED]: 'Unverified'
}

const tabs: ITab[] = [
  { title: campaignStatus[ECampaignStatus.PUBLISHED] },
  { title: campaignStatus[ECampaignStatus.DRAFT] }
  // { title: campaignStatus[ECampaignStatus.UNVERIFIED] }
]

interface ICount {
  count: number
  countPublished: number
  campaignDraft: number
  countClaimPending?: number
}

export const sandboxTabs = tabs.filter((tab) => tab.title !== campaignStatus[ECampaignStatus.UNVERIFIED])

export const MyJobs = () => {
  const unsubscribe$ = useUnsubscribe()
  const isLayoutLoading = useBehaviorMapper(LoadingModule.loading$)
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const shareJobTourEnabled = useBehaviorMapper(ShareJobTourService.enableTour$)
  const { eventHandler, analytic } = useAnalytic('my_jobs')

  const isMax505 = useMediaQuery('(max-width:505px)')
  const { tab } = useQueryParams()
  const [_tab, setTab] = useState<number>(Number(tab) || 0)
  const [data, setData] = useState<ICampaignModelExtended[]>([])
  const [counts, setCounts] = useState<ICount>()

  const profile = useBehaviorMapper(AuthModule.profile$)
  const isVerify = useMemo(() => getFirstCompany(profile)?.CompanyUser?.isVerified, [profile])

  useEffect(() => {
    setTab((prev) => {
      if (tab === String(prev)) {
        return prev
      }
      return tab ? +tab || 0 : 0
    })
  }, [tab])

  useEffect(() => {
    browserHistory.replace({ search: `?tab=${_tab}` })
  }, [_tab])

  const handleBannerVibeTalent = useCallback(() => {
    eventHandler(ETrackingEvent.BTN_VIBE_TALENT)()
    browserHistory.push('/vibes')
  }, [eventHandler])

  const onTabDefault = useCallback((count: ICount) => {
    const keys: Array<keyof ICount> = ['countPublished', 'campaignDraft', 'countClaimPending']
    const currentKey = keys[0]
    if (!count[currentKey]) {
      for (const _key of Object.keys(count) as typeof keys) {
        if (!keys.includes(_key)) {
          continue
        }
        if (count[_key]) {
          return setTab((prev) => {
            if (count[keys[prev]]) {
              return prev
            }
            return keys.indexOf(_key)
          })
        }
      }
    }
  }, [])

  const fetchCount = useCallback(() => {
    if (!isAuthenticated) {
      return
    }

    LoadingModule.toggle(true)
    from(CampaignModule.fetchCounts())
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => LoadingModule.toggle(false))
      )
      .subscribe(({ data }) => {
        const count = {
          count: data.count || 0,
          countPublished: (data.countPublished || 0) + (data.countPending || 0),
          campaignDraft: data.countDraft || 0,
          countClaimPending: data.countPending || 0
        }

        onTabDefault(count)
        setCounts(count)
      })
  }, [isAuthenticated, onTabDefault, unsubscribe$])

  useEffect(() => {
    fetchCount()
  }, [fetchCount])

  const loadCampaignDraft = useCallback(() => {
    from(CampaignApi.paginate({
      status: ECampaignStatus.DRAFT
    }))
      .pipe(finalize(() => LoadingModule.toggle(false)))
      .subscribe(({ data }) => setData(data))
  }, [])

  const loadCampaignPublished = useCallback(() => {
    forkJoin([
      from(CampaignApi.paginate({
        status: ECampaignStatus.PUBLISHED
      })),
      from(CampaignClaimApi.paginate({ status: 'PENDING' }))
    ])
      .pipe(
        finalize(() => LoadingModule.toggle(false))
      )
      .subscribe(([campaignPublished, campaignPending]) => {
        const campaignPublishedData = campaignPublished.data
        const campaignPendingData = (campaignPending.data.rows || []) as ICampaignModelExtended[]
        setData([...campaignPublishedData, ...campaignPendingData])
      })
  }, [])

  useEffect(() => {
    if (!counts?.count) {
      return
    }

    Number(tab)
      ? loadCampaignDraft()
      : loadCampaignPublished()
  }, [tab, counts?.count, loadCampaignPublished, loadCampaignDraft])

  const removeJobFromList = useCallback((data: { id?: number; draftId?: number }) => {
    PopupTourService.fetchMissionIfShowing()
    fetchCount()

    if (data.draftId) {
      setData(prev => prev.filter(item => item.draftId !== data.draftId))
      return
    }

    if (data.id) {
      setData(prev => prev.filter(item => item.id !== data.id))
    }
  }, [fetchCount])

  useEffect(() => {
    SidebarModule.on()
  }, [])

  const tabsWithCount = useMemo(() => {
    return tabs.reduce<ITab[]>((acc, tab) => {
      // hide unverified tab if no unverified campaign
      if (!counts?.countClaimPending && tab.title === campaignStatus[ECampaignStatus.UNVERIFIED]) {
        return acc
      }
      if (!counts) {
        return [...acc, tab]
      }
      const mapper = {
        [campaignStatus[ECampaignStatus.PUBLISHED]]: `${tab.title} (${counts?.countPublished})`,
        [campaignStatus[ECampaignStatus.DRAFT]]: `${tab.title} (${counts?.campaignDraft})`,
        [campaignStatus[ECampaignStatus.UNVERIFIED]]: `${tab.title} (${counts?.countClaimPending})`
      }
      return [...acc, {
        ...tab,
        title: mapper[tab.title]
      }]
    }, [])
  }, [counts])

  const onCreateJob = useCallback(() => {
    if (!isVerify && counts?.countClaimPending) {
      return DialogService.open(ModalVerificationPending)
    }
    browserHistory.push(generate(ERoutes.CLAIM_JOBS))
  }, [counts?.countClaimPending, isVerify])

  const onChangeTab = useCallback((tab: number) => {
    if (tab === 0) {
      analytic(ETrackingEvent.BTN_TAB_PUBLISHED)
    }

    if (tab === 1) {
      analytic(ETrackingEvent.BTN_TAB_DRAFT)
    }

    setTab(tab)
  }, [analytic])

  if (shareJobTourEnabled) {
    return (
      <>
        <Breadcrumbs>
          <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>
        </Breadcrumbs>

        <Sandbox/>
      </>
    )
  }

  return (
    <>
      <Breadcrumbs>
        <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>
      </Breadcrumbs>

      <div className={Style.container}>
        {!!counts?.count && (
          <div className={Style.headerMyJob}>
            <span className={Style.title}>
              My Jobs <span className={Style.count}>({counts?.count})</span>
            </span>

            <CreateJobButton
              onlyIcon={isMax505}
              text="Create Job Posting"
              disabled={isLayoutLoading}
              onClick={onCreateJob}
            />
          </div>
        )}

        {counts && (
          <div className={Style.tab}>
            <Tabs tab={_tab} tabs={tabsWithCount} setTab={onChangeTab}/>
          </div>
        )}

        {data.length === 0
          ? (
            <EmptyJob
              tab={_tab || 0}
              withBanner={counts?.count === 0}
              onBannerClick={handleBannerVibeTalent}
            />
          )
          : (
            <div className={Style.content}>
              {data.map((item) => (
                <CardJob
                  key={(item.draftId ? 'draft-' : 'posted-') + item.id}
                  campaign={item}
                  onAfterDelete={removeJobFromList}
                  fetchCount={fetchCount}
                />
              ))}
            </div>
          )}
      </div>
    </>
  )
}
