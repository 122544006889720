import { EEnv } from 'src/constants'
import { v4 } from 'uuid'
import { axiosHiringApi } from './axios'

export class AnalyticApi {
  static _session = v4()
  static readonly _prefix = '/analytics'
  private static sessionTimerRef: NodeJS.Timeout

  private static scheduleAResetSession() {
    if (this.sessionTimerRef) {
      clearInterval(this.sessionTimerRef)
    }

    this.sessionTimerRef = setInterval(() => {
      this._session = v4()
    }, 10 * 60 * 1000) // 10 minutes
  }

  static resetSession() {
    this._session = v4()
    this.scheduleAResetSession()
  }

  static setSession(session: string) {
    this._session = session
    this.scheduleAResetSession()
  }

  static create({ data, ...payload }: {
    screen: string
    event: string
    data?: Record<string, unknown>
  }): Promise<void> {
    this.scheduleAResetSession()

    if (EEnv.REACT_APP_ENV !== 'production') {
      return Promise.resolve()
    }
    return axiosHiringApi.post(this._prefix, {
      ...payload,
      data: data || {},
      session: this._session
    })
  }
}
