import clsx from 'clsx'
import { ComponentProps, FC } from 'react'
import Style from './style.module.scss'

interface IProps extends ComponentProps<'span'> {
  count?: number
}

export const Badge: FC<IProps> = ({ count, ...rest }) => {
  if (!count) {
    return null
  }

  return (
    <span
      {...rest}
      className={clsx(Style.badge, 'fx fx-center round-10 txt-neutral-white bg-negative-500', rest.className)}
    >
      {count > 99 ? '99+' : count}
    </span>
  )
}
