import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconReplace: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7422 5.25718C13.4846 3.99952 11.7788 3.29297 10.0002 3.29297L9.99775 3.29297C8.13692 3.29997 6.35078 4.026 5.01289 5.31936L4.59395 5.73831V3.94297C4.59395 3.58398 4.30293 3.29297 3.94395 3.29297C3.58496 3.29297 3.29395 3.58398 3.29395 3.94297V7.30755C3.29395 7.66654 3.58496 7.95755 3.94395 7.95755H7.30853C7.66751 7.95755 7.95853 7.66654 7.95853 7.30755C7.95853 6.94857 7.66751 6.65755 7.30853 6.65755H5.51318L5.92036 6.25037C7.01633 5.19279 8.47829 4.59901 10.0014 4.59297C11.4348 4.59329 12.8094 5.16284 13.823 6.17642C14.8369 7.19029 15.4064 8.56539 15.4064 9.99922C15.4064 10.3582 15.6975 10.6492 16.0564 10.6492C16.4154 10.6492 16.7064 10.3582 16.7064 9.99922C16.7064 8.22061 15.9999 6.51485 14.7422 5.25718Z" fill={color}/>
        <path d="M5.25816 14.7413C4.00049 13.4836 3.29395 11.7778 3.29395 9.99922C3.29395 9.64023 3.58496 9.34922 3.94395 9.34922C4.30293 9.34922 4.59395 9.64023 4.59395 9.99922C4.59395 11.433 5.16353 12.8081 6.1774 13.822C7.19099 14.8356 8.56561 15.4052 9.99902 15.4055L10.0002 16.7055C8.22159 16.7055 6.51583 15.9989 5.25816 14.7413Z" fill={color}/>
        <path d="M16.4598 11.5396C16.7137 11.7934 16.7137 12.205 16.4598 12.4588L14.9194 13.9992L16.4598 15.5396C16.7137 15.7934 16.7137 16.205 16.4598 16.4588C16.206 16.7127 15.7944 16.7127 15.5406 16.4588L14.0002 14.9185L12.4598 16.4588C12.206 16.7127 11.7944 16.7127 11.5406 16.4588C11.2867 16.205 11.2867 15.7934 11.5406 15.5396L13.081 13.9992L11.5406 12.4588C11.2867 12.205 11.2867 11.7934 11.5406 11.5396C11.7944 11.2858 12.206 11.2858 12.4598 11.5396L14.0002 13.08L15.5406 11.5396C15.7944 11.2858 16.206 11.2858 16.4598 11.5396Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
