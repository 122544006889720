import clsx from 'clsx'
import { ComponentProps, FC, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  EMPTY,
  catchError,
  finalize,
  from,
  takeUntil
} from 'rxjs'
import { ProfileApi } from 'src/api'
import { Button, Input, Modal } from 'src/components'
import { useUnsubscribe, useValidation } from 'src/hooks'
import { DialogService, SnackbarService } from 'src/services'
import { EventUtils } from 'src/utils'
import { passwordSchema } from 'src/validation'
import { object, ref, string } from 'yup'
import { ModalAuthentication } from '../authentication'
import Style from './style.module.scss'

interface IProps {
  open: boolean
  onClose: () => void
}

const schema = object().shape({
  currentPassword: string()
    .min(8)
    .required('You need to enter current password')
    .label('Current Password'),
  newPassword: passwordSchema
    .required('You need to enter new password')
    .notOneOf([ref('currentPassword')], 'New password must be different from the current password')
    .label('New Password'),
  newPasswordConfirm: string()
    .min(8)
    .oneOf([ref('newPassword')], 'Passwords must match')
    .required('You need to enter new password confirm')
    .label('Confirm New Password')
})

interface IFormData {
  currentPassword: string
  newPassword: string
  newPasswordConfirm: string
}

export const ModalChangePassword: FC<IProps> = ({ open = false, onClose }) => {
  const unsubscribe$ = useUnsubscribe()

  const [isLoading, setIsLoading] = useState(false)

  const [formdata, setFormData] = useState<IFormData>({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  })

  const { errors, validate } = useValidation({
    data: formdata,
    schema
  })

  const onForgotPassword = useCallback<Required<ComponentProps<typeof Link>>['onClick']>((e) => {
    EventUtils.preventDefault(e)
    onClose()
    DialogService.open(ModalAuthentication, { stage: 'forgot-password', noNavigation: true })
  }, [onClose])

  const onSubmit = async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    setIsLoading(true)

    from(
      ProfileApi.update({
        password: formdata.currentPassword,
        newPassword: formdata.newPassword
      })
    )
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setIsLoading(false))
      )
      .subscribe(() => {
        SnackbarService.success('Update password success!')
        onClose()
      })
  }

  return (
    <Modal
      center
      closeBtn
      open={open}
      onClose={onClose}
    >
      <div className={clsx(Style.content, 'fx-column gap-8')}>
        <h5 className="m-0 mb-2 txt-neutral-700">Change Password</h5>

        <div className="fx-column gap-1">
          <div className="fx fx-ai-center fx-jc-space-between w-100">
            <span className="body-2 txt-neutral-500 fw-bold">
              Current Password
              <span className="txt-negative-500">*</span>
            </span>
            <Link
              to="#"
              className="body-2"
              onClick={onForgotPassword}
            >
              Forgot password
            </Link>
          </div>
          <Input
            type="password"
            variant="secondary"
            placeholder="Enter your password"
            error={errors.getError('currentPassword')}
            value={formdata.currentPassword}
            onChange={(e) => setFormData(prev => ({ ...prev, currentPassword: e.target.value }))}
          />
        </div>

        <div className="fx-column gap-3">
          <div className="fx-column gap-1">
            <span className="body-2 txt-neutral-500 fw-bold">
              New Password
              <span className="txt-negative-500">*</span>
            </span>
            <Input
              isCheckPassword
              type="password"
              variant="secondary"
              name="newPassword"
              placeholder="Enter your password"
              value={formdata.newPassword}
              error={errors.getError('newPassword')}
              onChange={(e) => setFormData(prev => ({ ...prev, newPassword: e.target.value }))}
            />
          </div>
          <div className="fx-column gap-1">
            <span className="body-2 txt-neutral-500 fw-bold">
              Confirm New Password
              <span className="txt-negative-500">*</span>
            </span>
            <Input
              type="password"
              variant="secondary"
              name="newPasswordConfirm"
              placeholder="Confirm your password"
              value={formdata.newPasswordConfirm}
              error={errors.getError('newPasswordConfirm')}
              onChange={(e) => setFormData(prev => ({ ...prev, newPasswordConfirm: e.target.value }))}
            />
          </div>
        </div>
        <div>
          <Button
            className={Style.resetBtn}
            onClick={onSubmit}
            loading={isLoading}
          >
            Change Password
          </Button>
        </div>
      </div>
    </Modal>
  )
}
