import { Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { CommentApi } from 'src/api'
import { Tooltip, UserAvatar } from 'src/components'
import { useDebounce, useUnsubscribeCallback } from 'src/hooks'
import { ICommentSummaryModel } from 'src/interfaces'
import { SnackbarService } from 'src/services'
import { getApiErrorMessage, getAvatar, getFullName } from 'src/utils'
import { STAvatarGroup, STContainer, STDescription, STLeft } from './styled'

interface IProps {
  candidateId: number
  forceRender?: number
}

export const Summary: FC<IProps> = ({ candidateId, forceRender }) => {
  const [summary, setSummary] = useState<ICommentSummaryModel>({
    countShared: 0,
    recentShared: []
  })

  const loadSummary = useUnsubscribeCallback((unsubscribe$, params: {
    candidateId: number
  }) => {
    from(CommentApi.summary(params))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          SnackbarService.error(getApiErrorMessage(error))
          return EMPTY
        })
      )
      .subscribe(({ data }) => setSummary(data))
  }, [])

  useDebounce(() => {
    if (candidateId) {
      loadSummary({ candidateId })
    }
  }, 300, [candidateId, forceRender])

  return (
    summary.countShared
      ? (
        <STContainer>
          <STLeft>
            <STDescription>
              <Typography variant="body1-regular"><pre>Shared to </pre></Typography>
            </STDescription>
            <STAvatarGroup max={5} total={summary.countShared || 0}>
              {summary.recentShared?.map(item => (
                <Tooltip
                  arrow
                  title={getFullName(item.user) || item.email}
                  key={item.id}
                >
                  <UserAvatar
                    fullName={getFullName(item.user)}
                    avatarUrl={getAvatar(item.user)}
                  />
                </Tooltip>
              ))}
            </STAvatarGroup>
          </STLeft>
        </STContainer>
      )
      : null
  )
}
