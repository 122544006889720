import { FC, useCallback } from 'react'
import { Button, useAnalytic } from 'src/components'
import { useBehaviorMapper, useJobTransformedData } from 'src/hooks'
import { Img } from 'src/images'
import { ICampaignModel } from 'src/interfaces'
import { ModalAuthentication } from 'src/partials/authentication'
import { ModalCampaignDetail } from 'src/partials/modal-campaign-detail'
import { browserHistory, ERoutes, generate } from 'src/router'
import { DialogService } from 'src/services'
import { AuthModule } from 'src/store'
import { ETrackingEvent } from '../../../enums'
import Style from './style.module.scss'

interface IProps {
  campaign: ICampaignModel
  onChange?: (campaign: Partial<ICampaignModel>) => void
  onViewDetails?: (campaign: ICampaignModel) => void
}

export const CampaignCard: FC<IProps> = ({
  campaign,
  onChange,
  onViewDetails
}) => {
  const { eventHandler } = useAnalytic('claim_job')
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const profile = useBehaviorMapper(AuthModule.profile$)

  const onClaimJob = useCallback(() => {
    if (!isAuthenticated) {
      DialogService.open(ModalAuthentication, {
        stage: 'sign-in',
        onClose: (isAuthenticated) => {
          if (isAuthenticated) {
            browserHistory.push(generate([ERoutes.CLAIM_JOBS_SLUG, { slug: campaign.slug }]))
          }
        }
      })
      return
    }

    browserHistory.push(generate([ERoutes.CLAIM_JOBS_SLUG, { slug: campaign.slug }]))
  }, [campaign, isAuthenticated])

  const parsedJob = useJobTransformedData(campaign)

  return (
    <div key={campaign.id} className={Style.campaignCardContainer}>
      <div className="fx fx-column gap-5">
        <div className="fx fx-column gap-2">
          <div className="semibold-20 txt-black-01 oneline-text">
            {campaign.jobTitle}
          </div>
        </div>

        <div className="fx fx-column gap-2">
          <div className="fx fx-ai-center fx-wrap-wrap gap-2">
            {campaign.logoUrl && (
              <Img
                src={campaign.logoUrl}
                width={17}
                height={17}
                alt="logo"
                className="round-10 bg-neutral-20"
                style={{ objectFit: 'contain' }}
              />
            )}

            {campaign.companyName && (
              <span
                className="semibold-12 txt-black-01 oneline-text"
                style={{ maxWidth: '110px' }}
              >
                {campaign.companyName}
              </span>
            )}

            {campaign.location && (
              <span className="body-12 lh-16 txt-black-01">
                {campaign.location + ' -'}
              </span>
            )}

            {parsedJob.locationType && (
              <span className="body-12 txt-black-01">
                {parsedJob.locationType}
              </span>
            )}
          </div>

          {parsedJob.salary && (
            <div className="body-12 txt-grey-01">
              {parsedJob.salary}
              {parsedJob.salaryRate}
            </div>
          )}
        </div>

        <div className="fx gap-3">
          <Button
            variant="label"
            onClick={eventHandler(ETrackingEvent.BTN_CLAIM_JOB, onClaimJob)}
          >
            Claim Job
          </Button>
          <Button
            variant="primary"
            onClick={eventHandler(ETrackingEvent.BTN_CLAIM_JOBS_DETAILS, () => DialogService.open(ModalCampaignDetail, {
              campaign: {
                ...campaign,
                author: profile
              }
            }))}
          >
            Details
          </Button>
        </div>
      </div>
    </div>
  )
}
