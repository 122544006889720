import { FC, useMemo } from 'react'
import {
  Button,
  Dot,
  MotivatorBadge,
  Tooltip
} from 'src/components'
import { MotivatorsBg } from 'src/constants'
import { EMotivator } from 'src/enums'
import { IconBookmark, IconExplanation, IconFile } from 'src/icons'
import { Img } from 'src/images'
import { ICampaignModel } from 'src/interfaces'
import { CampaignUtils, getFullName } from 'src/utils'

interface IProps {
  campaign: Partial<ICampaignModel>
  motivators: EMotivator[]
  isApplied?: boolean
  isHeart?: boolean
  loading?: boolean
  handleApply?: () => void
  handleHeart?: () => void
}

export const CampaignInfo: FC<IProps> = ({
  campaign,
  motivators,
  isApplied,
  isHeart,
  loading,
  handleApply,
  handleHeart
}) => {
  const parsedJob = useMemo(() => CampaignUtils.parse(campaign), [campaign])
  const video = campaign.uploadVideo ?? campaign.author?.pfv

  return (
    <div className={`fx-1 gap-8 ${video ? 'fx-column' : 'fx-row'}`}>
      <div>
        <div className="fx fx-column mb-6">
          <div className="fs-14 fw-500 lh-16 txt-black-01 mb-1">Hiring</div>
          <div className="fw-600 fs-24 lh-28 txt-black-01 mb-2">{campaign.jobTitle}</div>
          <div className="fx fx-wrap-wrap gap-1 row-gap-2">
            {motivators.map((motivator) => (
              <MotivatorBadge key={motivator} small value={motivator}/>
            ))}
          </div>
        </div>

        <div className="fx fx-column gap-2 mb-8">
          <div className="fx fx-ai-center fx-wrap-wrap gap-2">
            {parsedJob.logoUrl && (
              <Img
                src={parsedJob.logoUrl}
                width={17}
                height={17}
                alt="logo"
                className="round-10 bg-neutral-20"
                style={{ objectFit: 'contain' }}
              />
            )}

            {parsedJob.companyName && (
              <div className="fw-600 fs-12 txt-black-01">
                {parsedJob.companyName}
              </div>
            )}
          </div>

          {
            parsedJob.salary && (
              <div className="fw-400 fs-12 lh-16 txt-grey-01">
                {parsedJob.salary}
                {parsedJob.salaryRate}
              </div>
            )
          }

          <div className="fx fx-ai-center fx-wrap-wrap gap-2">
            {campaign.location && (
              <div className="fw-400 fs-12 lh-16 txt-grey-01">{campaign.location + ' -'}</div>
            )}

            {parsedJob.locationType && (
              <div className="fw-400 fs-12 lh-16 txt-grey-01">{parsedJob.locationType}</div>
            )}
          </div>
        </div>

        <div className="fx gap-4">
          {isApplied
            ? (
              <Button variant="positive" className="pointer-none">Applied</Button>
            )
            : (
              <Button
                variant={campaign?.inviteApply ? 'label' : 'primary'}
                style={{ width: '154px' }}
                // onClick={handleApply}
              >
                Apply
              </Button>
            )}

          <Button
            variant="icon"
            active={isHeart}
            disabled={loading}
            // onClick={() => mustAuthenticated({ kind: 'save', actionMethod: handleHeart })}
          >
            <IconBookmark color="currentColor"/>
          </Button>
        </div>

        {campaign?.inviteApply && (
          <div className="mt-2 fx fx-ai-center gap-2">
            <Dot color="#FB5230"/>
            <span className="fs-12 lh-18 txt-black-01">{getFullName(campaign.author)} invited you to apply!</span>
          </div>
        )}
      </div>

      <div
        className="fx-column gap-5 p-5 round-3"
        style={{
          background: `linear-gradient(111.87deg, ${motivators
            .map(el => MotivatorsBg[el])
            .join(', ')})`
        }}
      >
        <div className="fs-16 fw-600 lh-20 txt-black-01 fx fx-ai-center gap-1">
          <span>JOB SUMMARY</span>
          <Tooltip placement="bottom" title="Powered by KNOWME&trade; Insights">
            <IconExplanation/>
          </Tooltip>
        </div>
        <div className="fs-12 lh-18 txt-black-01">{campaign.jobSummary}</div>

        {!!campaign.jdFileUrl && (
          <div className="fx gap-2 px-4 py-3 round-3 w-fit bg-neutral-white">
            <IconFile size={16}/>
            <span
              className="fs-12 fw-500 lh-16 txt-black-01 break-word pointer"
              onClick={() => !!campaign.jdFileUrl && window.open(campaign.jdFileUrl, '_blank')}
            >
              {campaign.uploadedJdFile?.filename || campaign.jdFileUrl?.split('/').pop()}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
