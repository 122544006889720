import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC } from 'react'
import { debounceTime } from 'rxjs'
import { Loading } from 'src/components'
import { EPaletteMode, TTheme, toCssVariables } from 'src/constants'
import { useBehaviorMapper } from 'src/hooks'
import { ModalCompleteSignUp } from 'src/partials'
import { ModalSwitchToDesktop } from 'src/partials/modal-switch-to-desktop'
import { DialogService, OverlayService } from 'src/services'
import { AuthModule, LoadingModule } from 'src/store'
import { ClientUtils } from 'src/utils'
import { DialogContainer } from './dialog-container'
import { Overlay } from './overlay'
import { RouterView } from './router-view'
import { SnackbarContainer } from './snackbar-container'
import Style from './style.module.scss'

export * from './overlay'

ClientUtils.onlyClient(() => {
  const bindProfileWatchers = () => {
    AuthModule.profile$
      .pipe(debounceTime(1000))
      .subscribe((profile) => {
        if (profile?.id && !profile.companies?.length) {
          return DialogService.open(ModalCompleteSignUp, {}, { persist: true })
        }
        // if (profile?.id && !profile?.stats?.pVvcWatched) {
        //   return DialogService.open(ModalPIntroduce, {}, { persist: true })
        // }
      })
  }
  if (window.innerWidth < 768) {
    return DialogService.open(ModalSwitchToDesktop, { onUnmounted: bindProfileWatchers }, { persist: true })
  }
  bindProfileWatchers()
})

const createThemeVariables = makeStyles<TTheme>(
  () => ({
    '@global': {
      ':root': () => toCssVariables(EPaletteMode.LIGHT)
    }
  })
)

export const AppLayout: FC = () => {
  const isLayoutLoading = useBehaviorMapper(LoadingModule.loading$)
  const appOverlay = useBehaviorMapper(OverlayService.overlay$)
  createThemeVariables()

  return (
    <div className={clsx(Style.layout, { [Style.loading]: isLayoutLoading })}>
      <DialogContainer/>
      <SnackbarContainer/>

      <main className="fx-1 fx fx-column">
        <RouterView/>
      </main>

      {appOverlay.open && <Overlay/>}

      <Loading show={isLayoutLoading}/>
    </div>
  )
}
