import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconDownloadStroke: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.9" fillRule="evenodd" clipRule="evenodd" d="M10.2496 2.59961C10.6086 2.59961 10.8996 2.89062 10.8996 3.24961V12.1804L14.29 8.78999C14.5438 8.53615 14.9554 8.53615 15.2092 8.78999C15.4631 9.04383 15.4631 9.45539 15.2092 9.70923L10.7092 14.2092C10.4554 14.4631 10.0438 14.4631 9.78999 14.2092L5.28999 9.70923C5.03615 9.45539 5.03615 9.04383 5.28999 8.78999C5.54383 8.53615 5.95539 8.53615 6.20923 8.78999L9.59961 12.1804V3.24961C9.59961 2.89062 9.89062 2.59961 10.2496 2.59961ZM4.34961 16.7496C4.34961 16.3906 4.64062 16.0996 4.99961 16.0996H15.4996C15.8586 16.0996 16.1496 16.3906 16.1496 16.7496C16.1496 17.1086 15.8586 17.3996 15.4996 17.3996H4.99961C4.64062 17.3996 4.34961 17.1086 4.34961 16.7496Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
