import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconDuplicate: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 13.5L5.25 13.5C4.78587 13.5 4.34075 13.3156 4.01256 12.9874C3.68437 12.6592 3.5 12.2141 3.5 11.75L3.5 4.75C3.5 4.28587 3.68437 3.84075 4.01256 3.51256C4.34075 3.18437 4.78587 3 5.25 3L12.25 3C12.7141 3 13.1592 3.18437 13.4874 3.51256C13.8156 3.84075 14 4.28587 14 4.75L14 6.5M8.75 17L15.75 17C16.2141 17 16.6592 16.8156 16.9874 16.4874C17.3156 16.1592 17.5 15.7141 17.5 15.25L17.5 8.25C17.5 7.78587 17.3156 7.34075 16.9874 7.01256C16.6592 6.68437 16.2141 6.5 15.75 6.5L8.75 6.5C8.28587 6.5 7.84075 6.68437 7.51256 7.01256C7.18437 7.34075 7 7.78587 7 8.25L7 15.25C7 15.7141 7.18437 16.1592 7.51256 16.4874C7.84075 16.8156 8.28587 17 8.75 17Z"
          stroke={color}
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </IconWrapper>
  )
}
