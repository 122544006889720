import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconTalentInvite: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 120 } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="120" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_8387_544716)">
          <path d="M7.07016 73.6252C8.10016 78.9252 12.3102 83.4852 17.5202 84.9252C18.5502 94.0552 21.1802 102.995 25.2702 111.215C27.6302 112.265 30.4302 111.915 32.7902 110.875C35.1502 109.835 37.1802 108.165 39.1702 106.515C39.6602 106.105 40.1702 105.675 40.3902 105.085C40.7002 104.275 40.3902 103.365 40.1202 102.545C37.3002 94.3552 35.7702 85.7252 35.6302 77.0652C34.7302 78.1652 33.0102 78.2852 31.7202 77.6852C30.4302 77.0852 29.5002 75.9252 28.7102 74.7452C24.7602 68.8252 23.3302 61.2752 24.8502 54.3252C24.7602 54.7152 17.4502 56.8452 16.6202 57.2152C14.9702 57.9352 13.2402 58.5652 11.8502 59.7252C7.98016 62.9552 6.13016 68.7352 7.08016 73.6152L7.07016 73.6252Z" fill="#AFF6C5"/>
          <path d="M55.79 48.5048C56.29 58.4248 58.5199 68.6548 64.6899 76.4348C68.5699 81.3248 74.8699 85.2148 80.8599 83.4548C84.3399 82.4348 87.08 79.6348 88.88 76.4848C94 67.4948 92.23 56.2348 89.32 46.3048C86.71 37.3948 83.1 28.2548 76.41 21.5848C68.08 13.2848 60.7999 21.3248 57.7799 30.0948C55.7499 35.9848 55.4699 42.3248 55.7799 48.5048H55.79Z" fill="#AFF6C5"/>
          <path d="M58.4501 20.7245C49.2601 33.5645 51.7601 49.9345 57.3001 63.7545C60.8901 72.7145 71.7201 88.4045 83.3101 81.6745C84.6601 80.8845 83.6401 79.2545 82.2901 80.0445C75.8101 83.8145 67.9001 76.6645 64.3901 71.8445C61.0701 67.2945 59.0301 61.8845 57.3801 56.5445C53.7601 44.8145 52.9401 31.9045 60.4101 21.4645C61.3601 20.1345 59.2201 19.6545 58.4601 20.7245H58.4501Z" fill="black"/>
          <path d="M59.9502 21.9549C64.9902 16.2849 73.1902 18.1949 78.1002 22.9349C82.6902 27.3649 85.2402 33.8249 87.1602 39.7649C90.9702 51.5549 95.3202 72.1249 82.3702 79.8049C81.0202 80.6049 82.0502 82.2349 83.3902 81.4349C99.0002 72.1849 93.0902 47.3849 87.6302 33.8349C85.0202 27.3649 81.1902 20.3549 74.4002 17.5449C68.8902 15.2649 62.4302 16.2049 58.4102 20.7249C57.3302 21.9349 58.9902 23.0349 59.9602 21.9449L59.9502 21.9549Z" fill="black"/>
          <path d="M64.1501 23.7752C56.3701 33.3852 57.6901 48.4652 61.4801 59.4652C64.3601 67.8252 73.6201 82.3552 84.0101 74.6052C85.2401 73.6852 84.2501 72.0252 82.9901 72.9752C71.8901 81.2752 63.5501 59.6452 62.0901 52.2252C60.2801 43.0152 59.8801 32.2052 66.1001 24.5252C67.1201 23.2652 64.9601 22.7752 64.1501 23.7852V23.7752Z" fill="black"/>
          <path d="M69.1704 24.7545C76.8104 25.9145 81.8604 39.1945 83.6404 45.4545C85.9304 53.5645 86.0604 62.0245 84.0904 70.2145C83.7204 71.7345 86.0204 71.6445 86.3104 70.4345C88.6104 60.8745 88.2904 50.9745 85.0204 41.6645C82.6704 34.9645 77.9304 23.8845 69.9504 22.6745C68.7204 22.4845 67.6204 24.5345 69.1704 24.7645V24.7545Z" fill="black"/>
          <path d="M60.7502 48.3651C65.4602 47.5851 70.1402 46.6451 74.7902 45.5551C76.0502 45.2651 76.5602 43.1051 74.9802 43.4751C70.3302 44.5651 65.6502 45.4951 60.9402 46.2851C59.6502 46.5051 59.1402 48.6351 60.7502 48.3651Z" fill="black"/>
          <path d="M74.24 44.2853C72.42 46.8153 72.38 50.2353 73.43 53.1053C74.28 55.4353 76.6 57.1753 79.08 55.9753C81.56 54.7753 82.17 51.7753 81.32 49.2953C80.47 46.8153 78.77 43.5453 75.77 43.2453C74.54 43.1253 73.44 45.1853 74.99 45.3353C76.54 45.4853 77.4 46.9453 78.09 48.2053C78.55 49.0453 79.02 49.9553 79.24 50.8953C79.46 51.8353 79.63 55.1753 77.01 53.9453C74.39 52.7153 74.82 46.9253 76.19 45.0253C77.14 43.6953 75 43.2153 74.24 44.2853Z" fill="black"/>
          <path d="M78.1601 54.4646C73.5101 56.5846 69.0101 58.9846 64.6701 61.6846C63.3401 62.5046 64.3601 64.1446 65.6901 63.3146C69.8401 60.7346 74.1301 58.4146 78.5801 56.3846C79.8701 55.7946 79.6501 53.7746 78.1601 54.4546V54.4646Z" fill="black"/>
          <path d="M54.1203 30.045C45.6703 39.615 35.9403 47.745 25.0103 54.345C23.6703 55.155 24.6903 56.785 26.0303 55.975C37.1503 49.265 47.0703 40.995 55.6703 31.265C56.7403 30.045 55.0903 28.955 54.1203 30.045Z" fill="black"/>
          <path d="M70.5603 78.0944C59.0603 73.3344 44.7903 74.3944 32.8303 76.7144C31.5503 76.9644 31.0403 79.1044 32.6403 78.7944C44.2403 76.5444 58.0703 75.4444 69.2403 80.0644C70.3603 80.5244 71.9103 78.6544 70.5603 78.0944Z" fill="black"/>
          <path d="M24.5604 55.2346C21.8804 63.4946 23.4304 74.4846 31.7704 78.9046C32.8404 79.4746 34.3804 77.6246 33.0904 76.9346C25.6304 72.9846 24.4304 62.7246 26.7904 55.4546C27.2704 53.9646 24.9504 54.0546 24.5704 55.2346H24.5604Z" fill="black"/>
          <path d="M25.6001 53.845C21.4801 55.905 17.2201 57.635 12.8301 59.065C11.4601 59.515 11.7001 61.495 13.2501 60.995C17.8501 59.495 22.2901 57.645 26.6201 55.485C28.0301 54.785 26.9801 53.165 25.6001 53.855V53.845Z" fill="black"/>
          <path d="M32.0703 78.8546C33.2903 78.8546 34.3903 76.7646 32.8503 76.7646C31.6303 76.7646 30.5303 78.8546 32.0703 78.8546Z" fill="black"/>
          <path d="M32.1999 76.9047C27.5299 78.3247 22.8699 79.7947 18.2199 81.2947C16.8399 81.7347 17.0899 83.7247 18.6399 83.2247C23.2899 81.7247 27.9499 80.2547 32.6199 78.8347C34.0099 78.4147 33.7599 76.4347 32.1999 76.9047Z" fill="black"/>
          <path d="M12.33 59.5344C5.26 66.6744 8.68 78.1244 16.74 82.8844C17.79 83.5044 19.65 81.8444 18.51 81.1744C11.72 77.1744 7.5 67.2044 13.87 60.7644C15.02 59.6044 13.34 58.5144 12.32 59.5444L12.33 59.5344Z" fill="black"/>
          <path d="M9.05023 64.235C2.72023 70.125 5.68023 80.265 14.3502 81.465C15.5802 81.635 16.6802 79.585 15.1302 79.375C8.28023 78.435 5.44023 70.255 10.6002 65.455C11.8002 64.345 10.1002 63.255 9.05023 64.235Z" fill="black"/>
          <path d="M17.8704 82.3349C18.2204 91.8549 20.1904 100.975 23.7904 109.795C24.2604 110.945 26.3904 109.655 25.9104 108.475C22.4504 99.9949 20.5604 91.1749 20.2104 82.0249C20.1604 80.5549 17.8204 81.1349 17.8604 82.3449L17.8704 82.3349Z" fill="black"/>
          <path d="M25.7702 110.755C29.4802 108.765 33.2902 107.035 37.2302 105.535C38.5702 105.025 38.3402 103.025 36.8102 103.605C32.6702 105.175 28.6502 107.015 24.7502 109.115C23.3702 109.855 24.4102 111.485 25.7702 110.745V110.755Z" fill="black"/>
          <path d="M30.7404 78.6851C30.3404 87.8651 32.1504 96.6751 36.1204 104.965C36.6604 106.095 38.8004 104.795 38.2404 103.645C34.4104 95.6451 32.7004 87.2351 33.0904 78.3751C33.1504 76.9151 30.8004 77.4851 30.7404 78.6951V78.6851Z" fill="black"/>
          <path d="M35.1702 77.4851C34.9202 86.3351 36.5002 94.7851 39.8902 102.965C40.3702 104.115 42.5002 102.825 42.0102 101.645C38.7602 93.8051 37.2802 85.6651 37.5102 77.1751C37.5502 75.7151 35.2002 76.2751 35.1602 77.4951L35.1702 77.4851Z" fill="black"/>
          <path d="M25.48 110.885C27.17 111.355 28.88 111.685 30.62 111.855C31.85 111.975 32.95 109.915 31.4 109.765C29.85 109.615 28.31 109.335 26.8 108.915C26.19 108.745 25.52 108.995 25.17 109.525C24.89 109.955 24.88 110.705 25.49 110.875L25.48 110.885Z" fill="black"/>
          <path d="M31.7003 111.975C35.3003 110.545 38.6603 108.665 41.7403 106.315C42.9603 105.385 41.9703 103.725 40.7203 104.685C37.8103 106.905 34.6803 108.695 31.2803 110.055C29.9503 110.585 30.1803 112.585 31.7003 111.985V111.975Z" fill="black"/>
          <path d="M37.1703 105.355L41.0503 105.275C41.6703 105.265 42.3203 104.965 42.5503 104.335C42.7303 103.835 42.4403 103.165 41.8303 103.185L37.9503 103.265C37.3303 103.275 36.6803 103.575 36.4503 104.205C36.2703 104.705 36.5603 105.375 37.1703 105.355Z" fill="black"/>
          <path d="M85.1702 18.235L85.0502 18.275C84.5302 18.435 83.9502 19.075 84.1002 19.655L84.1402 19.815C84.3402 20.585 85.2602 20.535 85.8102 20.205C88.2902 18.735 90.1602 15.545 92.6402 13.805C96.8102 10.895 104.45 5.98505 108.49 11.455C109.25 12.485 111.36 11.145 110.61 10.135C107.38 5.76505 102.34 6.50505 97.8902 8.58505C95.2602 9.81505 92.7102 11.305 90.4002 13.065C88.4402 14.565 86.8002 17.385 84.7802 18.575L86.4502 18.965L86.4102 18.805L85.4602 20.185L85.5802 20.145C86.9602 19.715 86.7202 17.735 85.1602 18.215L85.1702 18.235Z" fill="black"/>
          <path d="M108.64 11.2944C109.64 19.5744 101.79 23.7744 96.7803 28.9444C94.8403 30.9444 93.6903 33.2944 95.9603 35.4844C96.8403 36.3344 98.7203 34.7244 97.7303 33.7744C95.0403 31.1744 106.68 22.6544 108.28 20.6144C110.64 17.5944 111.41 14.2144 110.95 10.4644C110.79 9.14437 108.49 10.0944 108.63 11.3044L108.64 11.2944Z" fill="black"/>
          <path d="M95.9003 35.3047C98.7903 37.7347 102.13 36.6847 105.4 35.7547C107.03 35.2947 108.8 34.7547 110.44 35.5447C112.93 36.7447 111.99 39.4247 110.41 41.0747C108.38 43.1847 106.01 45.0047 103.62 46.6847C102.35 47.5747 103.35 49.2247 104.64 48.3147C107.83 46.0747 116.83 40.6147 114.14 35.6447C110.63 29.1547 102.1 37.3147 97.6803 33.5947C96.7403 32.8047 94.8603 34.4247 95.9103 35.3047H95.9003Z" fill="black"/>
          <path d="M104.1 46.7051C100.94 48.8451 96.9103 52.7751 97.9503 57.0251C99.0703 61.5951 105.09 60.845 108.65 60.5251C109.97 60.4051 110.47 58.305 108.84 58.445C106.31 58.665 101.13 59.445 100.26 56.175C99.3903 52.905 102.78 49.915 105.11 48.335C106.4 47.465 105.39 45.8251 104.09 46.7051H104.1Z" fill="black"/>
          <path d="M109.23 60.4345C110.58 61.2145 111 62.2245 110.48 63.4545C110.3 64.0545 110.03 64.6045 109.68 65.1145C108.93 66.1745 107.85 66.8645 106.76 67.5445C104.61 68.8845 102.4 70.1445 100.22 71.4345C98.8802 72.2345 99.9002 73.8645 101.24 73.0645C104.94 70.8645 110.34 68.8045 112.29 64.6645C113.64 61.7945 112.89 57.2345 108.8 58.5045C107.42 58.9345 107.66 60.9145 109.22 60.4345H109.23Z" fill="black"/>
        </g>
        <defs>
          <clipPath id="clip0_8387_544716">
            <rect width="109.68" height="112.57" fill="white" transform="translate(5.66016 3.71484)"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  )
}
