import clsx from 'clsx'
import { FC, useCallback, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { EEnv } from 'src/constants'
import { IconSearch, IconWarning } from 'src/icons'
import Style from './style.module.scss'

interface IProps {
  id?: string
  location?: string
  onChange?: (location: string) => void
  error?: string
  disabled?: boolean
  height?: number
}

export const GoogleLocationSearch: FC<IProps> = (props) => {
  const [focus, setFocus] = useState(false)

  const handlePlaceSelected = useCallback((place: any) => {
    props.onChange?.(place?.formatted_address || '')
  }, [props])

  const handleInputChanged = useCallback((e: any) => {
    props.onChange?.(e.target.value)
  }, [props])

  return (
    <div>
      <div
        className={clsx(Style.inputContainer, {
          [Style.focused]: focus,
          [Style.error]: !!props.error,
          [Style.disabled]: !!props.disabled
        })}
        style={{
          height: props.height || 48
        }}
      >
        <Autocomplete
          id={props.id}
          apiKey={`${EEnv.REACT_APP_GOOGLE_API_KEY}&loading=async`}
          options={{
            types: ['(regions)'],
            componentRestrictions: { country: ['us', 'ca', 'jm'] }
          }}
          defaultValue={props.location || ''}
          className={clsx(Style.input)}
          onPlaceSelected={handlePlaceSelected}
          onChange={handleInputChanged}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />

        <IconSearch className={Style.iconSearch}/>
      </div>
      {props.error && (
        <span className="fs-12 fx fx-ai-center gap-1 txt-negative-500 mt-2">
          <IconWarning size={16}/>
          {props.error}
        </span>
      )}
    </div>
  )
}
