import { ComponentProps, FC } from 'react'
import { useHistory } from 'react-router'
import { Button, ModalCenter } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { IVideoModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { DialogService } from 'src/services'
import { AuthModule } from 'src/store'
import { VideoUtils } from 'src/utils'
import { ModalAuthentication } from '../authentication'
import { VideoPlayerTracking } from '../video-player-tracking'

interface IProps {
  video: Partial<IVideoModel>
}

export const ModalViewVideo: FC<ComponentProps<typeof ModalCenter> & IProps> = ({ video, ...props }) => {
  const history = useHistory()
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  return (
    <ModalCenter {...props}>
      <div className="fx fx-ai-center gap-8">
        <VideoPlayerTracking
          videoId={video?.id}
          mini
          autoplay
          className="round-3 overflow-hidden"
          url={VideoUtils.getVideoSource(video) ?? ''}
          tracks={video?.tracks}
          image={video?.urlVideoImageThumbnail}
          style={{ aspectRatio: '9 / 16', width: 324 }}
        />

        {!isAuthenticated && (
          <div style={{ width: 284 }} className="w-full fx fx-column fx-ai-center fx-jc-center">
            <div>To vibe with talent please</div>
            <div className="mt-1"><span className="fw-700">Sign Up</span> or <span className="fw-700">Sign In</span></div>

            <Button className="mt-6 mb-3 w-full" onClick={() => DialogService.open(ModalAuthentication, { stage: 'sign-up', redirectTo: () => history.push(generate(ERoutes.TALENT_VIBE_TALENT)) })}>
              Sign Up
            </Button>

            <Button variant="secondary" className="w-full" onClick={() => DialogService.open(ModalAuthentication, { stage: 'sign-in', redirectTo: () => history.push(generate(ERoutes.TALENT_VIBE_TALENT)) })}>
              Sign In
            </Button>
          </div>
        )}
      </div>
    </ModalCenter>
  )
}
