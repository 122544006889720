import { ComponentProps, ComponentType } from 'react'
import { BehaviorSubject, Subject } from 'rxjs'
import { WithOutNextComplete } from 'types/rxjs'
import { v4 } from 'uuid'

interface IOptions {
  hidden?: boolean
  /**
   * @default false
   * @description Whether to persist the dialog when route changes
   */
  persist?: boolean
}

interface IDialog<T extends ComponentType<any>> {
  id: string
  component: T
  props: ComponentProps<T>
  options: IOptions
  readonly props$: Subject<ComponentProps<T>>
  readonly update$: Subject<IOptions>
  readonly unmount: () => void
}

const _items$ = new BehaviorSubject<IDialog<any>[]>([])

export class DialogService {
  static get items$(): WithOutNextComplete<typeof _items$> {
    return _items$
  }

  static open<T extends ComponentType<any>>(
    component: T,
    props?: Omit<ComponentProps<T>, 'open' | 'onClose'> & Partial<Pick<ComponentProps<T>, 'open' | 'onClose'>> & { onUnmounted?: () => void },
    options?: IOptions
  ) {
    let isUnmounted = false
    const { onUnmounted, ...maskProps } = (props || {}) as any

    const unmount = () => {
      if (isUnmounted) {
        return
      }
      _items$.next(
        _items$.value.filter(
          (item) => item.id !== dialog.id
        )
      )
      isUnmounted = true
      onUnmounted?.()
      return true
    }

    const dialog: IDialog<T> = {
      id: v4(),
      component,
      props: {
        ...props,
        open: true,
        onClose(...args: any[]) {
          !isUnmounted && maskProps?.onClose?.(...args)
          unmount()
        },
        onCancel(...args: any[]) {
          !isUnmounted && maskProps?.onCancel?.(...args)
          unmount()
        },
        onConfirm(...args: any[]) {
          !isUnmounted && maskProps?.onConfirm?.(...args)
          unmount()
        }
      } as IDialog<T>['props'],
      options: { ...options },
      props$: new Subject(),
      update$: new Subject(),
      unmount
    }

    _items$.next([..._items$.value, dialog])

    return {
      options: (value: IOptions) => {
        dialog.options = { ...dialog.options, ...value }
        dialog.update$.next(dialog.options)
      },
      props: (value: Partial<typeof props>) => {
        dialog.props = { ...dialog.props, ...value }
        dialog.props$.next(dialog.props)
      },
      unmount
    }
  }
}
