import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { EMPTY, catchError, finalize, from, switchMap, takeUntil, tap } from 'rxjs'
import { ProfileApi } from 'src/api'
import { Button, Input, InputPhoneNumber } from 'src/components'
import { useBehaviorMapper, useUnsubscribe, useValidation } from 'src/hooks'
import { SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { convertPhoneNumToInternationalFormat, removePrefixPhoneNumber } from 'src/utils'
import { object, string } from 'yup'
import { ProfileBlock } from './profile-block'
import { ProfileRow } from './profile-row'

export const PhoneSection: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const profile = useBehaviorMapper(AuthModule.profile$)
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<Pick<typeof profile, 'linkedInUrl' | 'userPhoneNo'>>({})

  const schema = useMemo(() => object().shape({
    userPhoneNo: string().matches(/^([1-9]{1})([0-9]{9,10})$/, 'Invalid phone number').nullable().label('Phone')
  }), [])

  const { errors, validate, reset } = useValidation({
    data: formData,
    schema
  })

  useEffect(() => {
    reset()
  }, [editing, reset])

  useEffect(() => {
    if (!editing) {
      setLoading(false)
      setFormData({})
    } else {
      setFormData({
        linkedInUrl: profile?.linkedInUrl,
        userPhoneNo: profile?.userPhoneNo
      })
    }
  }, [editing, profile?.linkedInUrl, profile?.userPhoneNo])

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'userPhoneNo'
        ? value.replace(/[^0-9]/g, '') || null
        : value || null
    }))
  }, [])

  const onSubmit = useCallback(async (values: typeof formData) => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    setLoading(true)
    from(ProfileApi.update(values))
      .pipe(
        switchMap(() => ProfileApi.getProfile()),
        tap(({ data }) => AuthModule.updateProfile(data)),
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        setEditing(false)
        SnackbarService.success('Profile updated successfully')
      })
  }, [unsubscribe$, validate])

  return (
    <ProfileBlock
      editable={!editing}
      btnProps={{ onClick: () => setEditing((prev) => !prev) }}
    >
      <ProfileRow
        label="LinkedIn"
        valueClass="overflow-hidden"
        value={editing
          ? (
            <Input
              autoFocus
              innerError
              name="linkedInUrl"
              placeholder="LinkedIn URL"
              disabled={loading}
              value={formData?.linkedInUrl}
              error={errors?.getError('linkedInUrl')}
              onChange={onChange}
            />
          )
          : profile?.linkedInUrl
            ? (
              <Link
                to={profile?.linkedInUrl}
                target="_blank"
                className="oneline-text"
              >
                {profile?.linkedInUrl}
              </Link>
            )
            : null}
      />

      <ProfileRow
        label="Phone"
        value={editing
          ? (
            <InputPhoneNumber
              size="small"
              variant="secondary"
              name="userPhoneNo"
              placeholder="818-888-2828"
              value={formData?.userPhoneNo || ''}
              onChange={onChange}
              error={errors?.getError('userPhoneNo')}
            />
          )
          : profile?.userPhoneNo && convertPhoneNumToInternationalFormat(removePrefixPhoneNumber(profile?.userPhoneNo ?? ''))}
      />

      {editing && (
        <div className="fx fx-ai-center fx-jc-flex-end gap-3">
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => setEditing((prev) => !prev)}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => onSubmit(formData)}
          >
            Save
          </Button>
        </div>
      )}
    </ProfileBlock>
  )
}
