import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconSpinnerProgress: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 78 } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="78" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M50.2988 11.0113C53.9743 12.4293 57.3346 14.5575 60.1878 17.2742C63.0409 19.9909 65.331 23.243 66.9273 26.8447C68.5236 30.4465 69.3949 34.3274 69.4914 38.2659C69.5878 42.2043 68.9076 46.1232 67.4895 49.7988C66.0715 53.4744 63.9433 56.8347 61.2266 59.6879C58.5099 62.541 55.2578 64.8311 51.6561 66.4274C48.0543 68.0237 44.1734 68.895 40.2349 68.9914C36.2965 69.0879 32.3775 68.4077 28.702 66.9896C25.0264 65.5716 21.6661 63.4434 18.8129 60.7267C15.9598 58.01 13.6697 54.7579 12.0734 51.1562C10.4771 47.5544 9.60582 43.6735 9.50935 39.735C9.41288 35.7965 10.0931 31.8776 11.5112 28.202C12.9292 24.5264 15.0574 21.1662 17.7741 18.313C20.4908 15.4599 23.7429 13.1698 27.3446 11.5735C30.9464 9.97716 34.8273 9.1059 38.7658 9.00943C42.7043 8.91297 46.6232 9.59319 50.2988 11.0113L50.2988 11.0113Z" stroke="#DADBE7" strokeWidth="5"/>
        <path d="M50.2988 11.0113C56.2095 13.2917 61.2466 17.3847 64.6881 22.7039" stroke="#F7FB6F" strokeWidth="5" strokeLinecap="round"/>
      </svg>
    </IconWrapper>
  )
}
