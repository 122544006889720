import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconChecked: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 12, color = '#fff' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="12" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 7L4.80952 11L13 1" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </IconWrapper>
  )
}
