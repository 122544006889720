import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconCameraEmpty: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 48, color = '#E2E2E2' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.6378 13.6381C14.868 12.4079 15.5592 10.7392 15.5592 8.99913C15.5592 7.25907 14.868 5.59039 13.6378 4.36016C12.4077 3.12995 10.7394 2.43893 9 2.43893C7.26059 2.43893 5.5923 3.12995 4.36217 4.36016C3.13202 5.59039 2.44082 7.25907 2.44082 8.99913C2.44082 10.7392 3.13202 12.4079 4.36217 13.6381C5.5923 14.8683 7.26059 15.5593 9 15.5593C10.7394 15.5593 12.4077 14.8683 13.6378 13.6381ZM15.364 15.3635C17.0518 13.6756 18 11.3862 18 8.99913C18 6.61203 17.0518 4.32269 15.364 2.63475C13.6761 0.946809 11.3869 -0.00146484 9 -0.00146484C6.61305 -0.00146484 4.32387 0.946809 2.63604 2.63475C0.948211 4.32269 0 6.61203 0 8.99913C0 11.3862 0.948211 13.6756 2.63604 15.3635C4.32387 17.0515 6.61305 17.9997 9 17.9997C11.3869 17.9997 13.6761 17.0515 15.364 15.3635Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.3631 13.6381C23.5933 14.8683 25.2616 15.5593 27.001 15.5593C28.7404 15.5593 30.4087 14.8683 31.6388 13.6381C32.869 12.4079 33.5602 10.7392 33.5602 8.99913C33.5602 7.25907 32.869 5.59039 31.6388 4.36016C30.4087 3.12995 28.7404 2.43893 27.001 2.43893C25.2616 2.43893 23.5933 3.12995 22.3631 4.36016C21.133 5.59039 20.4418 7.25907 20.4418 8.99913C20.4418 10.7392 21.133 12.4079 22.3631 13.6381ZM20.637 15.3635C22.3248 17.0515 24.614 17.9997 27.001 17.9997C29.3879 17.9997 31.6771 17.0515 33.3649 15.3635C35.0528 13.6756 36.001 11.3862 36.001 8.99913C36.001 6.61203 35.0528 4.32269 33.3649 2.63475C31.6771 0.946809 29.3879 -0.00146484 27.001 -0.00146484C24.614 -0.00146484 22.3248 0.946809 20.637 2.63475C18.9492 4.32269 18.001 6.61203 18.001 8.99913C18.001 11.3862 18.9492 13.6756 20.637 15.3635Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28.5 20.4402L6 20.4402C5.05624 20.4402 4.15101 20.8151 3.48349 21.4826C2.81595 22.1502 2.44082 23.0558 2.44082 24.0002L2.44082 42.0013C2.44082 42.9457 2.81595 43.8513 3.48349 44.5189C4.15101 45.1864 5.05624 45.5613 6 45.5613L28.5 45.5613C29.3631 45.5615 30.1972 45.2478 30.8464 44.6788C31.4957 44.1097 31.9161 43.324 32.0292 42.4678L32.4568 39.2319L44.7731 44.7035C44.8582 44.7413 44.9513 44.7573 45.044 44.7499C45.1368 44.7426 45.2262 44.7122 45.3043 44.6615C45.3824 44.6108 45.4466 44.5414 45.4912 44.4594C45.5357 44.3775 45.5591 44.2857 45.5592 44.1924L45.5592 21.8081C45.5591 21.7147 45.5358 21.6228 45.4912 21.5408C45.4466 21.4588 45.3823 21.3893 45.3042 21.3385C45.226 21.2878 45.1364 21.2575 45.0436 21.2502C44.9508 21.2429 44.8577 21.259 44.7726 21.2969L32.4568 26.77L32.0292 23.5337C31.9161 22.6775 31.4957 21.8918 30.8464 21.3227C30.1972 20.7537 29.3631 20.4401 28.5 20.4402ZM48 21.807L48 44.1945C47.9996 44.6941 47.8744 45.1857 47.6358 45.6246C47.3972 46.0636 47.0528 46.436 46.6338 46.7081C46.2148 46.9802 45.7345 47.1433 45.2365 47.1827C44.7385 47.2221 44.2385 47.1365 43.782 46.9337L34.449 42.7874C34.3401 43.6117 34.0621 44.3974 33.6398 45.0984C33.3228 45.6243 32.9246 46.1026 32.4554 46.5139C31.361 47.4731 29.9553 48.0019 28.5 48.0017L6 48.0017C4.4087 48.0017 2.88258 47.3696 1.75736 46.2443C0.632141 45.119 0 43.5928 0 42.0013L0 24.0002C0 22.4087 0.632141 20.8825 1.75736 19.7572C2.88258 18.6319 4.4087 17.9998 6 17.9998L17.25 17.9998L28.5 17.9998C29.9553 17.9996 31.361 18.5284 32.4554 19.4876C32.9246 19.8989 33.3228 20.3772 33.6397 20.9031C34.0621 21.6041 34.3401 22.3898 34.449 23.2141L43.779 19.0678C44.2357 18.8643 44.736 18.7782 45.2344 18.8173C45.7328 18.8563 46.2136 19.0194 46.633 19.2915C47.0524 19.5637 47.3972 19.9364 47.6359 20.3757C47.8746 20.815 47.9998 21.307 48 21.807Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
