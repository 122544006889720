import clsx, { ClassValue } from 'clsx'
import React, { useCallback, useMemo } from 'react'
import ReactSelect, {
  ActionMeta,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
  MenuProps,
  MultiValueRemoveProps,
  OnChangeValue,
  OptionProps,
  Props as ReactSelectProps,
  SingleValue,
  StylesConfig,
  components
} from 'react-select'
import { useAsRef } from 'src/hooks'
import { IconChecked, IconChevronFilled, IconClose } from 'src/icons'
import { StringUtils } from 'src/utils/string.utils'
import Style from './style.module.scss'

interface OptionType {
  key?: React.Key
  value?: string | number | null
  label?: React.ReactNode
  description?: React.ReactNode
}

enum ESelectMenuPlacement {
  BOTTOM = 'bottom',
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',

  TOP = 'top',
  TOP_LEFT = 'topLeft',
  TOP_RIGHT = 'topRight',

  AUTO = 'auto'
}

enum ESelectSize {
  X_SMALL = 'x-small',
  SMALL = 'small',
  X_MEDIUM = 'x-medium',
  MEDIUM = 'medium',
  LARGE = 'large'
}

enum ESelectVariant {
  PRIMARY = 'primary'
}

const SELECT_HEIGHT = {
  [ESelectSize.X_SMALL]: 24,
  [ESelectSize.SMALL]: 28,
  [ESelectSize.X_MEDIUM]: 32,
  [ESelectSize.MEDIUM]: 40,
  [ESelectSize.LARGE]: 48
}

const Option = (optionProps: OptionProps<OptionType>) => {
  const { data, isSelected, isMulti } = optionProps

  return (
    // TODO: Tooltip for long text
    <components.Option {...optionProps}>
      <div className="fx-column gap-1 m-0">
        <div className={clsx('fx-1 fx fx-center-between')}>
          {data.label || data.value}
          {isMulti && isSelected && <IconChecked color="currentColor"/>}
        </div>
        {!!data.description && (
          <div className="fs-12 txt-neutral-300">
            {data.description}
          </div>
        )}
      </div>
    </components.Option>
  )
}

const Menu = (menuProps: MenuProps<OptionType>) => {
  return <components.Menu {...menuProps}>{menuProps.children}</components.Menu>
}

const MultiValueRemove = (props: MultiValueRemoveProps<OptionType>) => {
  return (
    <components.MultiValueRemove {...props}>
      <IconClose size={12} className="transition-none"/>
    </components.MultiValueRemove>
  )
}

const DropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconChevronFilled
        color="#959595"
        style={{
          transform: `rotate(${props.isFocused && !props.isDisabled ? 270 : 90}deg)`,
          transition: 'transform 0.3s'
        }}
      />
    </components.DropdownIndicator>
  )
}

interface IProps
  extends Omit<
    ReactSelectProps<OptionType, boolean>,
    'className' | 'menuPlacement' | 'onChange' | 'options' | 'value'
  > {
  className?: ClassValue
  error?: boolean
  errorMsg?: React.ReactNode
  helperText?: React.ReactNode
  id?: string
  label?: React.ReactNode
  menuPlacement?: `${ReactSelectProps['menuPlacement'] | ESelectMenuPlacement}`
  onChange?: (value: OptionType['value'] | OptionType['value'][] | null, selected?: OptionType[]) => void
  options?: OptionType[]
  required?: boolean
  size?: `${ESelectSize}`
  value?: OptionType['value'] | OptionType['value'][]
  variant?: `${ESelectVariant}`
  noBg?: boolean
  style?: React.CSSProperties | undefined
  styles?: StylesConfig<OptionType, boolean, GroupBase<OptionType>>
  height?: number
}

export const Select: React.FC<IProps> = ({
  size = 'medium',
  label,
  error,
  errorMsg,
  helperText,
  variant = 'primary',
  className,
  menuPlacement = 'bottomLeft',
  value,
  onChange,
  noBg,
  style,
  styles,
  height,
  ...restProps
}) => {
  const id = useMemo(() => restProps.id || Math.random().toString(), [restProps.id])

  const selectedValue = useMemo(
    () =>
      restProps.options?.filter((option) =>
        !Array.isArray(value)
          ? value === option.value
          : value.includes(option.value)
      ),
    [value, restProps.options]
  )

  let convertedMenuPlacement: 'auto' | 'bottom' | 'top' | undefined
  switch (menuPlacement) {
    case ESelectMenuPlacement.AUTO:
    case ESelectMenuPlacement.BOTTOM:
    case ESelectMenuPlacement.TOP:
      convertedMenuPlacement = menuPlacement
      break

    case ESelectMenuPlacement.BOTTOM_LEFT:
    case ESelectMenuPlacement.BOTTOM_RIGHT:
      convertedMenuPlacement = 'bottom'
      break

    case ESelectMenuPlacement.TOP_LEFT:
    case ESelectMenuPlacement.TOP_RIGHT:
      convertedMenuPlacement = 'top'
      break

    default:
      convertedMenuPlacement = 'auto'
      break
  }

  const onChangeRef = useAsRef(onChange)
  const handleOnChange = useCallback(
    (selected: OnChangeValue<OptionType, Required<IProps>['isMulti']>, actionMeta: ActionMeta<OptionType>) => {
      const values = Array.isArray(selected)
        ? selected.map((val) => val.value)
        : ((selected as SingleValue<OptionType>)?.value || null)

      onChangeRef.current?.(values, Array.isArray(selected) ? selected : (selected ? [selected] : []))
    },
    [onChangeRef]
  )

  const selectWrapperClass = useMemo(() => clsx(Style.selectWrapper, className, {
    [Style.large]: size === ESelectSize.LARGE,
    [Style.medium]: size === ESelectSize.MEDIUM,
    [Style.xMedium]: size === ESelectSize.X_MEDIUM,
    [Style.small]: size === ESelectSize.SMALL,
    [Style.xSmall]: size === ESelectSize.X_SMALL
  }), [className, size])

  return (
    <div
      className={clsx(selectWrapperClass, 'fx-column')}
      style={style}
    >
      {Boolean(label) && (
        <label className="fx bold-12 mb-2" htmlFor={id}>
          {label}
          {restProps.required && <div className="txt-negative-500">*</div>}
        </label>
      )}

      <ReactSelect<OptionType, Required<IProps>['isMulti']>
        inputId={id}
        closeMenuOnSelect={!restProps.isMulti}
        hideSelectedOptions={false}
        filterOption={(option, inputValue: string) => {
          if (!inputValue) {
            return true
          }
          const cleanStr = StringUtils.removeVietnameseSigns(inputValue).toLowerCase()
          if (!cleanStr) {
            return true
          }
          const cleanLabel = StringUtils.removeVietnameseSigns(option.label as string).toLowerCase()
          const cleanValue = StringUtils.removeVietnameseSigns(option.value as string).toLowerCase()
          return cleanLabel.startsWith(cleanStr) || cleanValue.startsWith(cleanStr)
        }}
        {...restProps}
        onChange={handleOnChange}
        value={selectedValue}
        menuPlacement={convertedMenuPlacement}
        components={{
          DropdownIndicator,
          Menu,
          MultiValueRemove,
          Option,
          ...restProps.components
        }}
        styles={{
          option: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            minHeight: height || SELECT_HEIGHT[size]
          }),
          control: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            minHeight: height || SELECT_HEIGHT[size]
          }),
          valueContainer: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            height: '100%'
          }),
          ...styles
        }}
        classNames={{
          clearIndicator: () => clsx(Style.clearIndicator),
          control: ({ isFocused, isDisabled }) => clsx(Style.selectControl, {
            [Style.focused]: isFocused,
            [Style.disabled]: isDisabled,
            [Style.error]: error,
            [Style.noBg]: noBg
          }),
          dropdownIndicator: () => clsx(Style.dropdownIndicator),
          indicatorSeparator: () => clsx(Style.selectIndicatorSeparator),
          input: () => clsx(Style.input),
          menu: () => clsx(Style.menuContainer, {
            [Style.left]:
                menuPlacement === ESelectMenuPlacement.BOTTOM_LEFT ||
                menuPlacement === ESelectMenuPlacement.TOP_LEFT,
            [Style.right]:
                menuPlacement === ESelectMenuPlacement.BOTTOM_RIGHT ||
                menuPlacement === ESelectMenuPlacement.TOP_RIGHT
          }),
          menuList: () => clsx(Style.menuList),
          multiValue: () => clsx(Style.multiValue),
          multiValueRemove: () => clsx(Style.multiValueRemove),
          multiValueLabel: () => clsx(Style.multiValueLabel),
          noOptionsMessage: () => clsx(Style.noOptionsMessage),
          option: ({ isDisabled, isSelected, isFocused }) => clsx(Style.option, {
            [Style.disabled]: isDisabled,
            [Style.focused]: isFocused,
            [Style.Selected]: isSelected
          }),
          placeholder: ({ isDisabled }) => clsx(Style.placeholder, {
            [Style.disabled]: isDisabled
          }),
          singleValue: () => clsx(Style.singleValue),
          valueContainer: () => clsx(Style.selectValueContainer, {
            [Style.selectMultiValueContainer]: restProps.isMulti
          }),
          menuPortal: () => selectWrapperClass
        }}
      />

      {!error && Boolean(helperText) && (
        <div className={clsx(Style.helperText)}>
          {helperText}
        </div>
      )}

      {error && Boolean(errorMsg) && (
        <div className={clsx(Style.helperText, Style.helperTextError)}>
          {errorMsg}
        </div>
      )}
    </div>
  )
}
