import clsx from 'clsx'
import { ComponentProps, FC } from 'react'
import Style from './style.module.scss'

export const TeleprompterTextarea: FC<ComponentProps<'textarea'>> = (props) => {
  return (
    <textarea
      {...props}
      className={clsx('p-4 round-4 body-14', props.className, Style.textarea)}
      placeholder={props.placeholder || 'Add your notes here'}
    />
  )
}
