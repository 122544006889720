import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { catchError, EMPTY, from, takeUntil } from 'rxjs'
import { VideoApi } from 'src/api'
import { Button, useAnalytic, VideoPlayer } from 'src/components'
import { useAsRef, useBehaviorMapper, useUnsubscribeEffect } from 'src/hooks'
import { IconCamera, IconCameraEmpty, IconReplace, IconVideoMulti } from 'src/icons'
import { ICampaignModel, IVideoModel } from 'src/interfaces'
import { DialogService } from 'src/services'
import { AuthModule } from 'src/store'
import { VideoUtils } from 'src/utils'
import { ETrackingEvent } from '../../enums'
import { ModalMyVideo } from '../modal-my-video'
import { ModalRecorder } from '../recorder/modal-recorder'

interface IProps {
  campaign: Partial<ICampaignModel>
  onChange: Dispatch<SetStateAction<Partial<ICampaignModel>>>
}

export const CampaignRecordVVC: FC<IProps> = ({ campaign, onChange }) => {
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const { analytic } = useAnalytic('create_job')

  const onRecordVideo = useCallback(() => {
    analytic(ETrackingEvent.BTN_RECORD_VVC)
    DialogService.open(ModalRecorder, {
      onClose: (result) => {
        if (result?.video) {
          onChange((prev) => ({
            ...prev,
            uploadVideo: result.video,
            uploadVideoId: result.video?.id
          }))
        }
      }
    })
  }, [onChange])

  const onSelectVideo = useCallback(() => {
    DialogService.open(ModalMyVideo, {
      onClose: (video?: IVideoModel) => {
        if (video) {
          onChange((prev) => ({
            ...prev,
            uploadVideo: video,
            uploadVideoId: video?.id
          }))
        }
      }
    })
  }, [onChange])

  const onChangeRef = useAsRef(onChange)
  useUnsubscribeEffect((unsubscribe$) => {
    if (!isAuthenticated) {
      onChangeRef.current?.((prev) => ({
        ...prev,
        uploadVideo: undefined,
        uploadVideoId: undefined
      }))
      return
    }
    from(VideoApi.paginate({ order: 'createdAt-DESC', limit: 1 }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError(() => EMPTY)
      )
      .subscribe(({ data }) => {
        const video = data.rows?.[0]
        if (video) {
          onChangeRef.current?.((prev) => ({
            ...prev,
            uploadVideo: video,
            uploadVideoId: video.id
          }))
        }
      })
  }, [isAuthenticated, onChangeRef])

  return (
    campaign.uploadVideoId
      ? (
        <div className="relative">
          <VideoPlayer
            mini
            className="round-3 overflow-hidden"
            url={VideoUtils.getVideoSource(campaign.uploadVideo) ?? ''}
            tracks={campaign.uploadVideo?.tracks}
            image={campaign.uploadVideo?.urlVideoImageThumbnail}
            style={{ aspectRatio: '9 / 16', width: 280 }}
          />

          <div className="fx fx-center gap-2 mt-4">
            <Button className="fx gap-2" variant="label" onClick={onRecordVideo}>
              <IconCamera/>
              Record New
            </Button>
            <Button
              className="fx gap-2"
              variant="secondary"
              onClick={onSelectVideo}
            >
              <IconReplace/>
              Pick New
            </Button>
          </div>
        </div>
      )
      : (
        <div
          className="p-6 bg-neutral-100 fx-column fx-ai-center gap-3 round-4 h-fit"
          style={{ width: 280 }}
        >
          <div className="fx-column fx-ai-center gap-1 txt-black-01">
            <div>Introduce yourself with a</div>
            <div className="fw-700">
              Video Vibe Check<span className="txt-red-02">*</span>
            </div>
          </div>

          {/* <div className="px-4 py-2 round-4 bg-neutral-white fx fx-ai-center gap-2">
          <IconPlayStroke/>
          <div className="fs-12 fw-600 txt-black-01">View Samples</div>
          </div> */
          }

          <div className="px-5 py-10 mt-7 bg-neutral-white round-3 fx-column gap-3">
            <div className="fx fx-ai-center fx-jc-center mb-7">
              <IconCameraEmpty/>
            </div>
            <Button className="fx gap-2" variant="label" onClick={onRecordVideo}>
              <IconCamera/>
              Record New
            </Button>
            <Button className="fx gap-2" variant="secondary" onClick={onSelectVideo}>
              <IconVideoMulti/>
              My Vibe Checks
            </Button>
          </div>
        </div>
      )
  )
}
