import clsx from 'clsx'
import { ComponentProps, FC, Fragment, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Button, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useAppSelector, useBehaviorMapper } from 'src/hooks'
import { IconContactStroke } from 'src/icons'
import { ModalAuthentication } from 'src/partials'
import { PopupContactUs } from 'src/partials/popup-contact-us'
import { ERoutes, useRouteName } from 'src/router'
import { OverlayService } from 'src/services'
import { DialogService } from 'src/services/dialog.service'
import { AuthModule } from 'src/store'
import { getLayoutPageTitle } from 'src/store/selectors'
import { Messages } from './messages'
import { Notification } from './notification'
import Style from './style.module.scss'
import { UserMenu } from './user-menu'

export const Header: FC<ComponentProps<'header'>> = (props) => {
  const history = useHistory()
  const routeName = useRouteName()
  const isForbiddenRoute = useMemo(() => routeName && ([ERoutes.SIGN_IN, ERoutes.SIGN_UP, ERoutes.FORGOT_PASSWORD, ERoutes.RESET_PASSWORD] as string[]).includes(routeName), [routeName])
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const pageTitle = useAppSelector(getLayoutPageTitle)
  const { eventHandler } = useAnalytic('top_nav')

  const openContactForm = useCallback(() => {
    OverlayService.setOverlay({
      open: true,
      content: <PopupContactUs/>
    })
  }, [])

  /**
   * @deprecated
   */
  const subTitles = useMemo(() => {
    const title =
      pageTitle ||
      history.location.pathname
        .split('/')
        .filter(Boolean)
        .join(' ')
        ?.split(/[- ]/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    return title?.split('/')
  }, [pageTitle, history.location.pathname])

  return (
    <header
      {...props}
      className={clsx(Style.header, props.className)}
    >
      <div className="fx fx-1 fx-ai-center gap-3">
        <div className="fx-1 fx fx-ai-center gap-3">
          {subTitles?.map((subTitle: string, index: number) => {
            const isLastTitle = subTitles?.length === index + 1
            return (
              <Fragment key={index}>
                <span className={clsx(isLastTitle ? 'body1-bold' : 'body1-medium')}>
                  {subTitle}
                </span>

                {subTitles.length !== index + 1 && (
                  <div
                    className="bg-neutral-N100"
                    style={{ width: '1px', height: '20px' }}
                  />
                )}
              </Fragment>
            )
          })}
        </div>

        <div id="header-portal" className="fx fx-column"/>

        <Button variant="icon" onClick={eventHandler(ETrackingEvent.BTN_NAV_HELP, openContactForm)}>
          <IconContactStroke size={20} className="txt-black-01"/>
        </Button>

        {!isAuthenticated && !isForbiddenRoute && (
          <>
            <Button onClick={() => DialogService.open(ModalAuthentication, { stage: 'sign-up' })}>
              Sign Up
            </Button>
            <Button variant="secondary" onClick={() => DialogService.open(ModalAuthentication, { stage: 'sign-in' })}>
              Sign In
            </Button>
          </>
        )}

        {isAuthenticated && (
          <>
            <Messages/>
            <Notification/>
            <UserMenu/>
          </>
        )}
      </div>
    </header>
  )
}
