import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconEmployersStroke = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.93041 2.64038C5.27456 2.64038 3.93222 3.98272 3.93222 5.63857C3.93222 6.50371 4.29876 7.28332 4.8847 7.83043C4.80846 7.86397 4.73295 7.89942 4.65826 7.93676C3.95278 8.2895 3.33911 8.80165 2.86586 9.43266C2.66101 9.70579 2.71637 10.0933 2.9895 10.2981C3.26263 10.503 3.65011 10.4476 3.85496 10.1745C4.21305 9.69703 4.67738 9.30951 5.21118 9.04261C5.74499 8.77571 6.3336 8.63676 6.93041 8.63676C7.52722 8.63676 8.11584 8.77571 8.64964 9.04261C9.18344 9.30951 9.64778 9.69703 10.0059 10.1745C10.1226 10.3301 10.3058 10.4218 10.5004 10.4218C10.695 10.4218 10.8782 10.3301 10.995 10.1745C11.353 9.69703 11.8174 9.30951 12.3512 9.04261C12.885 8.77571 13.4736 8.63676 14.0704 8.63676C14.6672 8.63676 15.2558 8.77571 15.7896 9.04261C16.3234 9.30951 16.7878 9.69703 17.1459 10.1745C17.3507 10.4476 17.7382 10.503 18.0113 10.2981C18.2845 10.0933 18.3398 9.70579 18.135 9.43266C17.6617 8.80165 17.048 8.2895 16.3426 7.93676C16.2679 7.89942 16.1924 7.86397 16.1161 7.83043C16.7021 7.28332 17.0686 6.50371 17.0686 5.63857C17.0686 3.98272 15.7263 2.64038 14.0704 2.64038C12.4146 2.64038 11.0722 3.98272 11.0722 5.63857C11.0722 6.50371 11.4388 7.28332 12.0247 7.83043C11.9485 7.86397 11.873 7.89942 11.7983 7.93676C11.318 8.1769 10.8803 8.49092 10.5004 8.86603C10.1206 8.49093 9.68285 8.1769 9.20256 7.93676C9.12787 7.89942 9.05236 7.86397 8.97612 7.83043C9.56206 7.28332 9.9286 6.50371 9.9286 5.63857C9.9286 3.98272 8.58627 2.64038 6.93041 2.64038ZM6.93041 3.87676C5.95739 3.87676 5.1686 4.66555 5.1686 5.63857C5.1686 6.61159 5.95739 7.40038 6.93041 7.40038C7.90343 7.40038 8.69222 6.61159 8.69222 5.63857C8.69222 4.66555 7.90343 3.87676 6.93041 3.87676ZM14.0704 3.87676C13.0974 3.87676 12.3086 4.66555 12.3086 5.63857C12.3086 6.61159 13.0974 7.40038 14.0704 7.40038C15.0434 7.40038 15.8322 6.61159 15.8322 5.63857C15.8322 4.66555 15.0434 3.87676 14.0704 3.87676Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.93222 12.9654C3.93222 11.3095 5.27456 9.9672 6.93041 9.9672C8.58627 9.9672 9.9286 11.3095 9.9286 12.9654C9.9286 13.8305 9.56206 14.6101 8.97612 15.1572C9.05236 15.1908 9.12787 15.2262 9.20256 15.2636C9.68285 15.5037 10.1206 15.8177 10.5004 16.1928C10.8803 15.8177 11.318 15.5037 11.7983 15.2636C11.873 15.2262 11.9485 15.1908 12.0247 15.1572C11.4388 14.6101 11.0722 13.8305 11.0722 12.9654C11.0722 11.3095 12.4146 9.9672 14.0704 9.9672C15.7263 9.9672 17.0686 11.3095 17.0686 12.9654C17.0686 13.8305 16.7021 14.6101 16.1161 15.1572C16.1924 15.1908 16.2679 15.2262 16.3426 15.2636C17.048 15.6163 17.6617 16.1285 18.135 16.7595C18.3398 17.0326 18.2845 17.4201 18.0113 17.6249C17.7382 17.8298 17.3507 17.7744 17.1459 17.5013C16.7878 17.0238 16.3234 16.6363 15.7896 16.3694C15.2558 16.1025 14.6672 15.9636 14.0704 15.9636C13.4736 15.9636 12.885 16.1025 12.3512 16.3694C11.8174 16.6363 11.353 17.0238 10.995 17.5013C10.8782 17.657 10.695 17.7486 10.5004 17.7486C10.3058 17.7486 10.1226 17.657 10.0059 17.5013C9.64778 17.0238 9.18344 16.6363 8.64964 16.3694C8.11584 16.1025 7.52722 15.9636 6.93041 15.9636C6.3336 15.9636 5.74499 16.1025 5.21118 16.3694C4.67738 16.6363 4.21305 17.0238 3.85496 17.5013C3.65011 17.7744 3.26263 17.8298 2.9895 17.6249C2.71637 17.4201 2.66101 17.0326 2.86586 16.7595C3.33911 16.1285 3.95278 15.6163 4.65826 15.2636C4.73295 15.2262 4.80846 15.1908 4.8847 15.1572C4.29876 14.6101 3.93222 13.8305 3.93222 12.9654ZM6.93041 11.2036C5.95739 11.2036 5.1686 11.9924 5.1686 12.9654C5.1686 13.9384 5.95739 14.7272 6.93041 14.7272C7.90343 14.7272 8.69222 13.9384 8.69222 12.9654C8.69222 11.9924 7.90343 11.2036 6.93041 11.2036ZM12.3086 12.9654C12.3086 11.9924 13.0974 11.2036 14.0704 11.2036C15.0434 11.2036 15.8322 11.9924 15.8322 12.9654C15.8322 13.9384 15.0434 14.7272 14.0704 14.7272C13.0974 14.7272 12.3086 13.9384 12.3086 12.9654Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
})
