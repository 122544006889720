import { FC, useCallback, useMemo, useState } from 'react'
import {
  EMPTY,
  catchError,
  finalize,
  from,
  takeUntil
} from 'rxjs'
import { PeopleApi } from 'src/api'
import { Button } from 'src/components'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { IReactionModel } from 'src/interfaces'
import { SnackbarService } from 'src/services'
import { renderVideoTitle } from 'src/utils'
import { TalentImageCard } from '../talent-image-card'
import Style from './style.module.scss'

interface IProps {
  isDetail?: boolean
  isInvite?: boolean
  reaction: IReactionModel
  onDetail?: () => void
  onChange?: (values: Partial<IReactionModel>) => void
}

export const ReactionTalentCard: FC<IProps> = ({ reaction, isDetail, isInvite, onDetail, onChange }) => {
  const unsubscribe$ = useUnsubscribe()
  const onChangeRef = useAsRef(onChange)
  const [loading, setLoading] = useState(false)
  /**
   * Get user from reaction
   * It can be from `toUser` or `user` depends on the context (opposite user or current user)
   */
  const user = useMemo(() => reaction?.toUser || reaction?.user, [reaction?.toUser, reaction?.user])

  const onToggleLike = useCallback(() => {
    if (!user?.id) return

    setLoading(true)
    from(PeopleApi.toggleLike({ id: user.id }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        onChangeRef.current?.({ reaction: data?.reaction })
      })
  }, [onChangeRef, unsubscribe$, user?.id])

  return (
    <div key={reaction.id} className={Style.reactioncontainer}>
      <TalentImageCard
        talent={{ ...user, reaction: reaction?.reaction ? reaction : undefined }}
        loading={loading}
        isInvite={isInvite}
        onToggleLike={onToggleLike}
        onDetail={onDetail}
      />

      <div className="bold-14 txt-black-01 three-dot-1 text-center">{renderVideoTitle(user?.pfv)}</div>

      {isDetail && (
        <Button
          className="w-fit"
          onClick={onDetail}
        >
          Details
        </Button>
      )}
    </div>
  )
}
