import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconVideoMulti: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#0C0C0C' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.6857 14.8449C2.72942 14.8965 2.76423 14.9016 2.77817 14.9016L8.22261 14.9016C8.23655 14.9016 8.27136 14.8965 8.31508 14.8449C8.35988 14.7921 8.40039 14.7021 8.40039 14.5849L8.40039 5.41828C8.40039 5.30111 8.35988 5.21108 8.31508 5.15828C8.27136 5.10676 8.23655 5.10161 8.22261 5.10161L2.77817 5.10161C2.76424 5.10161 2.72942 5.10676 2.6857 5.15828C2.6409 5.21108 2.60039 5.30111 2.60039 5.41828L2.60039 14.5849C2.60039 14.7021 2.6409 14.7921 2.6857 14.8449ZM2.77817 16.1016C1.9286 16.1016 1.40039 15.3264 1.40039 14.5849L1.40039 5.41828C1.40039 4.67685 1.9286 3.90161 2.77817 3.90161L8.22261 3.90161C9.07218 3.90161 9.60039 4.67685 9.60039 5.41828L9.60039 14.5849C9.60039 15.3264 9.07218 16.1016 8.22261 16.1016L2.77817 16.1016Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.31841 8.2041C4.48099 8.11709 4.67826 8.12663 4.83169 8.22891L7.18918 9.80057C7.32828 9.8933 7.41183 10.0494 7.41183 10.2166C7.41183 10.3838 7.32828 10.5399 7.18918 10.6326L4.83169 12.2043C4.67826 12.3066 4.48099 12.3161 4.31841 12.2291C4.15583 12.1421 4.05434 11.9727 4.05434 11.7883L4.05434 8.64494C4.05434 8.46054 4.15583 8.29111 4.31841 8.2041ZM5.05434 9.57919L5.05434 10.854L6.01044 10.2166L5.05434 9.57919Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.6857 14.8449C11.7294 14.8965 11.7642 14.9016 11.7782 14.9016L17.2226 14.9016C17.2365 14.9016 17.2714 14.8965 17.3151 14.8449C17.3599 14.7921 17.4004 14.7021 17.4004 14.5849L17.4004 5.41828C17.4004 5.30111 17.3599 5.21108 17.3151 5.15828C17.2714 5.10676 17.2365 5.10161 17.2226 5.10161L11.7782 5.10161C11.7642 5.10161 11.7294 5.10676 11.6857 5.15828C11.6409 5.21108 11.6004 5.30111 11.6004 5.41828L11.6004 14.5849C11.6004 14.7021 11.6409 14.7921 11.6857 14.8449ZM11.7782 16.1016C10.9286 16.1016 10.4004 15.3264 10.4004 14.5849L10.4004 5.41828C10.4004 4.67685 10.9286 3.90161 11.7782 3.90161L17.2226 3.90161C18.0722 3.90161 18.6004 4.67685 18.6004 5.41828L18.6004 14.5849C18.6004 15.3264 18.0722 16.1016 17.2226 16.1016L11.7782 16.1016Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.2459 8.2041C13.4085 8.11709 13.6058 8.12663 13.7592 8.22891L16.1167 9.80057C16.2558 9.8933 16.3394 10.0494 16.3394 10.2166C16.3394 10.3838 16.2558 10.5399 16.1167 10.6326L13.7592 12.2043C13.6058 12.3066 13.4085 12.3161 13.2459 12.2291C13.0834 12.1421 12.9819 11.9727 12.9819 11.7883L12.9819 8.64494C12.9819 8.46054 13.0834 8.29111 13.2459 8.2041ZM13.9819 9.57919L13.9819 10.854L14.938 10.2166L13.9819 9.57919Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
