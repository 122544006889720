import { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, DialogConfirmUnMatch, Tooltip, UserAvatar } from 'src/components'
import { IconChat, IconInviteJob, IconThumbsUp, IconThumbsUpDup, IconThumbsUpFilled } from 'src/icons'
import { IReactionModel, IUserModel } from 'src/interfaces'
import { mustAuthenticated } from 'src/partials/authentication'
import { ModalInviteJob } from 'src/partials/modal-invite-job'
import { mustVerifiedCompany } from 'src/partials/modal-verify-company'
import { ModalViewVideo } from 'src/partials/modal-view-video'
import { VideoPlayerTracking } from 'src/partials/video-player-tracking'
import { ERoutes, generate } from 'src/router'
import { DialogService } from 'src/services'
import {
  ReactionUtils,
  StyleUtils,
  VideoUtils,
  getAvatar,
  getFullName
} from 'src/utils'

interface IProps {
  talent?: Partial<IUserModel> & { reaction?: IReactionModel; createdReaction?: IReactionModel }
  loading?: boolean
  isInvite?: boolean
  inlinePlayer?: boolean
  onToggleLike?: () => void
  onDetail?: () => void
}

export const TalentImageCard: FC<IProps> = ({
  talent,
  loading,
  isInvite,
  inlinePlayer,
  onToggleLike,
  onDetail
}) => {
  const history = useHistory()
  const video = useMemo(() => talent?.pfv, [talent?.pfv])
  const reaction = useMemo(() => talent?.reaction, [talent?.reaction])
  const lookupId = useMemo(() => talent?.lookupId, [talent?.lookupId])
  const [showPreviewVideo, setShowPreviewVideo] = useState(false)

  const isMatched = useMemo(() => ReactionUtils.isEmphasis(reaction), [reaction])

  const onVideo = useCallback(() => {
    if (onDetail) {
      return onDetail()
    }

    !inlinePlayer && video && DialogService.open(ModalViewVideo, { video })
  }, [inlinePlayer, onDetail, video])

  return (
    <div className="w-full relative bg-neutral-900 fx fx-center round-4 overflow-hidden">
      <div className="absolute b-6 z-3 w-full fx fx-ai-center fx-jc-space-between gap-4 px-4">
        <div className="fx fx-ai-center gap-4">
          <UserAvatar
            size={32}
            className="round-10"
            fullName={getFullName(talent)}
            avatarUrl={getAvatar(talent)}
          />

          <div className="fx-column fw-500 txt-neutral-white">
            {isMatched && (
              <div className="heading-16 video-txt-shadow three-dot-1">
                {getFullName(talent)}
              </div>
            )}
            {talent?.username && (
              <div className="fs-12 fw-500 txt-neutral-white three-dot-1 video-txt-shadow">@{talent?.username}</div>
            )}
          </div>
        </div>

        {onToggleLike && (
          <div className="fx fx-ai-center gap-3">
            <Tooltip title="Like">
              <Button
                size={32}
                variant="icon"
                green={!!reaction}
                emphasis={isMatched}
                // disabled={loading}
                onClick={() => !loading && (
                  isMatched
                    ? DialogService.open(DialogConfirmUnMatch, {
                      name: getFullName(talent),
                      onConfirm: onToggleLike
                    })
                    : mustAuthenticated({
                      kind: 'like',
                      actionMethod: () => mustVerifiedCompany(onToggleLike),
                      redirectTo: () => history.push(generate(ERoutes.TALENT_VIBE_TALENT))
                    })
                )}
              >
                {!reaction
                  ? <IconThumbsUp size={20}/>
                  : isMatched
                    ? <IconThumbsUpDup size={20}/>
                    : <IconThumbsUpFilled size={20}/>}
              </Button>
            </Tooltip>

            {isMatched && (
              <Button
                size={32}
                variant="icon"
                onClick={() => history.push(generate(ERoutes.TALENT_MESSAGES, { lookupId }))}
              >
                <IconChat size={20}/>
              </Button>
            )}

            {isInvite && (
              <Button
                size={32}
                variant="icon"
                onClick={() => DialogService.open(ModalInviteJob, { talent })}
              >
                <IconInviteJob/>
              </Button>
            )}
          </div>
        )}
      </div>

      <div
        className="w-full pointer"
        style={{
          aspectRatio: '9/16',
          ...StyleUtils.backgroundCover(video?.urlVideoImageThumbnail)
        }}
        onMouseEnter={() => !inlinePlayer && setShowPreviewVideo(true)}
        onMouseLeave={() => !inlinePlayer && setShowPreviewVideo(false)}
        onClick={onVideo}
      >
        {(inlinePlayer || showPreviewVideo) && (
          <VideoPlayerTracking
            videoId={video?.id}
            mini={!inlinePlayer}
            autoplay={!inlinePlayer}
            hideControl={!inlinePlayer}
            hidePlayIcon={!inlinePlayer}
            hideStartEnd={!inlinePlayer}
            url={VideoUtils.getVideoSource(video) ?? ''}
            tracks={video?.tracks}
            image={video?.urlVideoImageThumbnail}
          />
        )}
      </div>
    </div>
  )
}
