import { FC, useEffect, useRef } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { AUTH_FALLBACK_KEY } from 'src/constants'
import { useBehaviorMapper } from 'src/hooks'
import { ERoutes, IRouterOption, generate, routes } from 'src/router'
import { AuthModule } from 'src/store'
import { LocalStorage } from 'src/utils'
import { LayoutContainer } from '../layout-container'

const AuthRoute: FC<{
  path: string
  exact?: boolean
  component: IRouterOption['component']
}> = (props) => {
  const location = useLocation()
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const mountedRef = useRef<boolean>(false)

  useEffect(() => {
    if (isAuthenticated) {
      mountedRef.current = true
    }
  }, [isAuthenticated])

  if (isAuthenticated) {
    return (
      <Route
        path={props.path}
        exact={props.exact}
        component={props.component}
      />
    )
  }

  const signInPath = generate(ERoutes.SIGN_IN)
  const onboardingPath = generate(ERoutes.CAMPAIGNS_PUBLIC_ONBOARDING)
  // TODO: consider remove these lines below, since we don't redirect to sign in path anymore (onboarding instead)
  if (!mountedRef.current && location.pathname !== signInPath && location.pathname !== '/') {
    LocalStorage.setItem(AUTH_FALLBACK_KEY, `${location.pathname}${location.search}`)
  }

  if (mountedRef.current) {
    return <Redirect to={signInPath}/>
  }

  return <Redirect to={onboardingPath}/>
}

const RoutesSwitch = () => (
  <Switch>
    {Array.ensure(routes).map(item => {
      if (item.meta?.requireAuth) {
        return <AuthRoute key={item.path} {...item}/>
      }

      return (
        <Route
          key={item.path}
          path={item.path}
          exact={item.exact}
          component={item.component}
        />
      )
    })}
  </Switch>
)

export const RouterView: FC = () => {
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  return (
    <LayoutContainer>
      {isAuthenticated !== null && <RoutesSwitch/>}
    </LayoutContainer>
  )
}
