import { FC } from 'react'
import { PRIVACY_POLICY_URL, TERMS_AND_SERVICE_URL } from 'src/constants'

interface IProps {
  className?: string
}

const year = new Date().getFullYear()

export const PreviewFooter: FC<IProps> = (props) => {
  return (
    <div className={props.className}>
      <div className="fx flex-row gap-4 w-100 fx-jc-center">
        <a href="https://www.facebook.com/knowmebest" target="_blank" rel="noreferrer">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14ZM14 7C17.85 7 21 10.15 21 14C21 17.5 18.4625 20.475 14.9625 21V16.0125H16.625L16.975 14H15.05V12.6875C15.05 12.1625 15.3125 11.6375 16.1875 11.6375H17.0625V9.8875C17.0625 9.8875 16.275 9.7125 15.4875 9.7125C13.9125 9.7125 12.8625 10.675 12.8625 12.425V14H11.1125V16.0125H12.8625V20.9125C9.5375 20.3875 7 17.5 7 14C7 10.15 10.15 7 14 7Z" fill="black"/>
          </svg>
        </a>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14ZM19.3375 10.0625C19.95 9.975 20.475 9.8875 21 9.625C20.65 10.2375 20.125 10.7625 19.5125 11.1125C19.6875 15.225 16.7125 19.6875 11.375 19.6875C9.8 19.6875 8.3125 19.1625 7 18.375C8.4875 18.55 10.0625 18.1125 11.1125 17.325C9.8 17.325 8.75 16.45 8.4 15.3125C8.8375 15.4 9.275 15.3125 9.7125 15.225C8.4 14.875 7.4375 13.65 7.4375 12.3375C7.875 12.5125 8.3125 12.6875 8.75 12.6875C7.525 11.8125 7.0875 10.15 7.875 8.8375C9.3625 10.5875 11.4625 11.725 13.825 11.8125C13.3875 10.0625 14.7875 8.3125 16.625 8.3125C17.4125 8.3125 18.2 8.6625 18.725 9.1875C19.425 9.0125 20.0375 8.8375 20.5625 8.4875C20.3875 9.1875 19.95 9.7125 19.3375 10.0625Z" fill="black"/>
        </svg>
        <a href="https://www.linkedin.com/company/knowmebest" target="_blank" rel="noreferrer">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14ZM7.175 11.6375V21H10.15V11.6375H7.175ZM7 8.6625C7 9.625 7.7 10.325 8.6625 10.325C9.625 10.325 10.325 9.625 10.325 8.6625C10.325 7.7 9.625 7 8.6625 7C7.7875 7 7 7.7 7 8.6625ZM18.025 21H20.825V15.225C20.825 12.3375 19.075 11.375 17.4125 11.375C15.925 11.375 14.875 12.3375 14.6125 12.95V11.6375H11.8125V21H14.7875V16.0125C14.7875 14.7 15.6625 14 16.5375 14C17.4125 14 18.025 14.4375 18.025 15.925V21Z" fill="black"/>
          </svg>
        </a>
        <a href="https://www.instagram.com/the_knowme" target="_blank" rel="noreferrer">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 16.45C12.6875 16.45 11.55 15.4 11.55 14C11.55 12.6875 12.6 11.55 14 11.55C15.3125 11.55 16.45 12.6 16.45 14C16.45 15.3125 15.3125 16.45 14 16.45Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.975 8.05H11.025C10.325 8.1375 9.975 8.225 9.7125 8.3125C9.3625 8.4 9.1 8.575 8.8375 8.8375C8.62979 9.04521 8.53165 9.25292 8.41303 9.50399C8.38176 9.57016 8.34899 9.63952 8.3125 9.7125C8.29896 9.75311 8.28333 9.79581 8.26658 9.84158C8.17504 10.0917 8.05 10.4333 8.05 11.025V16.975C8.1375 17.675 8.225 18.025 8.3125 18.2875C8.4 18.6375 8.575 18.9 8.8375 19.1625C9.04521 19.3702 9.25293 19.4684 9.50399 19.587C9.57022 19.6183 9.63945 19.651 9.7125 19.6875C9.75311 19.701 9.79581 19.7167 9.84158 19.7334C10.0917 19.825 10.4333 19.95 11.025 19.95H16.975C17.675 19.8625 18.025 19.775 18.2875 19.6875C18.6375 19.6 18.9 19.425 19.1625 19.1625C19.3702 18.9548 19.4684 18.7471 19.587 18.496C19.6183 18.4298 19.651 18.3605 19.6875 18.2875C19.701 18.2469 19.7167 18.2042 19.7334 18.1584C19.825 17.9083 19.95 17.5667 19.95 16.975V11.025C19.8625 10.325 19.775 9.975 19.6875 9.7125C19.6 9.3625 19.425 9.1 19.1625 8.8375C18.9548 8.62978 18.7471 8.53164 18.496 8.41302C18.4299 8.38178 18.3604 8.34896 18.2875 8.3125C18.2469 8.29896 18.2042 8.28333 18.1584 8.26658C17.9083 8.17504 17.5667 8.05 16.975 8.05ZM14 10.2375C11.9 10.2375 10.2375 11.9 10.2375 14C10.2375 16.1 11.9 17.7625 14 17.7625C16.1 17.7625 17.7625 16.1 17.7625 14C17.7625 11.9 16.1 10.2375 14 10.2375ZM18.725 10.15C18.725 10.6332 18.3332 11.025 17.85 11.025C17.3668 11.025 16.975 10.6332 16.975 10.15C16.975 9.66675 17.3668 9.275 17.85 9.275C18.3332 9.275 18.725 9.66675 18.725 10.15Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14ZM11.025 6.7375H16.975C17.7625 6.825 18.2875 6.9125 18.725 7.0875C19.25 7.35 19.6 7.525 20.0375 7.9625C20.475 8.4 20.7375 8.8375 20.9125 9.275C21.0875 9.7125 21.2625 10.2375 21.2625 11.025V16.975C21.175 17.7625 21.0875 18.2875 20.9125 18.725C20.65 19.25 20.475 19.6 20.0375 20.0375C19.6 20.475 19.1625 20.7375 18.725 20.9125C18.2875 21.0875 17.7625 21.2625 16.975 21.2625H11.025C10.2375 21.175 9.7125 21.0875 9.275 20.9125C8.75 20.65 8.4 20.475 7.9625 20.0375C7.525 19.6 7.2625 19.1625 7.0875 18.725C6.9125 18.2875 6.7375 17.7625 6.7375 16.975V11.025C6.825 10.2375 6.9125 9.7125 7.0875 9.275C7.35 8.75 7.525 8.4 7.9625 7.9625C8.4 7.525 8.8375 7.2625 9.275 7.0875C9.7125 6.9125 10.2375 6.7375 11.025 6.7375Z" fill="black"/>
          </svg>
        </a>
      </div>

      <div className="fx flex-column w-100 fx-jc-center">
        <div className="fx flex-row fx-jc-center gap-8">
          <a
            href="https://www.knowmebest.com/contact-us"
            target="_blank"
            className="body2-medium color-neutral-theme-900"
            rel="noreferrer"
          >
            Contact Us
          </a>
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            className="body2-medium color-neutral-theme-900"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href={TERMS_AND_SERVICE_URL}
            target="_blank"
            className="body2-medium color-neutral-theme-900"
            rel="noreferrer"
          >
            Terms of Service
          </a>
        </div>

        <div className="fx flex-row fx-jc-center w-100">
          <span
            className="meta-regular color-neutral-theme-300"
          >©{year} Know Me, Inc. All rights reserved. Various trademarks held by their respective owners.
          </span>
        </div>
      </div>
    </div>

  )
}
