import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconLink = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 22, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.06449 9.48629L3.61795 11.9328C2.79102 12.7598 2.32645 13.8813 2.32645 15.0508C2.32645 16.2202 2.79102 17.3418 3.61795 18.1687C4.44488 18.9957 5.56644 19.4602 6.73589 19.4602C7.90535 19.4602 9.02691 18.9957 9.85384 18.1687L12.9718 15.0508C13.471 14.5521 13.8432 13.9409 14.0572 13.2685C14.2712 12.5961 14.3207 11.8822 14.2016 11.1867C14.0825 10.4912 13.7982 9.83446 13.3726 9.27164C12.947 8.70882 12.3925 8.25635 11.7558 7.95226L10.8932 8.81489C10.8038 8.90427 10.7269 9.00197 10.6655 9.10694C11.1573 9.24813 11.6037 9.51523 11.9606 9.88183C12.3175 10.2484 12.5725 10.7018 12.7005 11.1972C12.8284 11.6925 12.8249 12.2127 12.6902 12.7063C12.5555 13.1999 12.2943 13.6498 11.9325 14.0115L8.81452 17.1294C8.26324 17.6807 7.51553 17.9904 6.73589 17.9904C5.95625 17.9904 5.20855 17.6807 4.65726 17.1294C4.10597 16.5781 3.79626 15.8304 3.79626 15.0508C3.79626 14.2711 4.10597 13.5234 4.65726 12.9722L6.25261 11.3768C6.0884 10.7594 6.02396 10.1213 6.06449 9.48629Z"
          fill={color}
        />
        <path
          d="M8.81489 6.73589C8.31568 7.23458 7.94344 7.84579 7.72944 8.51817C7.51544 9.19056 7.46593 9.90448 7.58507 10.6C7.70421 11.2955 7.98852 11.9522 8.41412 12.515C8.83971 13.0778 9.39416 13.5303 10.0309 13.8344L11.1721 12.6932C10.6736 12.5597 10.2191 12.2973 9.85428 11.9324C9.4894 11.5676 9.227 11.1131 9.09344 10.6146C8.95988 10.1162 8.95987 9.59143 9.09342 9.093C9.22696 8.59458 9.48935 8.14008 9.85421 7.7752L12.9722 4.65726C13.5234 4.10597 14.2711 3.79626 15.0508 3.79626C15.8304 3.79626 16.5781 4.10597 17.1294 4.65726C17.6807 5.20854 17.9904 5.95625 17.9904 6.73589C17.9904 7.51553 17.6807 8.26323 17.1294 8.81452L15.5341 10.4099C15.6981 11.0262 15.7613 11.6649 15.7211 12.3014L18.1687 9.85383C18.5782 9.44438 18.903 8.95829 19.1246 8.42331C19.3462 7.88833 19.4602 7.31494 19.4602 6.73589C19.4602 6.15683 19.3462 5.58345 19.1246 5.04847C18.903 4.51349 18.5782 4.0274 18.1687 3.61794C17.7593 3.20849 17.2732 2.88369 16.7382 2.66209C16.2032 2.4405 15.6298 2.32645 15.0508 2.32645C14.4717 2.32645 13.8983 2.4405 13.3634 2.6621C12.8284 2.88369 12.3423 3.20849 11.9328 3.61794L8.81489 6.73589Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
})
