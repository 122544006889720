import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconEmailFilled: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 16, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.48047 3.50001C1.48047 3.21283 1.71328 2.98001 2.00047 2.98001L14.0005 2.98001C14.2877 2.98001 14.5205 3.21283 14.5205 3.50001L14.5205 12C14.5205 12.2705 14.413 12.53 14.2217 12.7213C14.0304 12.9126 13.771 13.02 13.5005 13.02L2.50047 13.02C2.22995 13.02 1.97051 12.9126 1.77922 12.7213C1.58794 12.53 1.48047 12.2705 1.48047 12L1.48047 3.50001ZM2.52047 4.02001L2.52047 11.98L13.4805 11.98L13.4805 4.02001L2.52047 4.02001Z" fill={color}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.61715 3.14864C1.81121 2.93694 2.14015 2.92263 2.35185 3.11669L8.00047 8.2946L13.6491 3.11669C13.8608 2.92263 14.1897 2.93694 14.3838 3.14864C14.5779 3.36034 14.5636 3.68927 14.3518 3.88333L8.35185 9.38333C8.15304 9.56557 7.8479 9.56557 7.6491 9.38333L1.64909 3.88333C1.43739 3.68927 1.42309 3.36034 1.61715 3.14864Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
