import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CandidateApi, PeopleApi } from 'src/api'
import { Button, DialogConfirmUnMatch, Modal } from 'src/components'
import { TabsNav } from 'src/components/tab-nav'
import { useBehaviorMapper, useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { IconArrow } from 'src/icons'
import { ICandidateModel, IReactionModel, IVideoModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { DialogService, SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import {
  ReactionUtils,
  getFullName,
  renderVideoTitle
} from 'src/utils'
import { TalentImageCard } from '../card/talent-image-card'
import { Detail } from './detail'
import { Message } from './message'
import Style from './style.module.scss'

interface IProps extends Pick<ComponentProps<typeof Modal>, 'open' | 'onClose'> {
  lookupId?: string
  pfv?: IVideoModel
  onPrevious?: () => void
  onNext?: () => void
  canPreviousProps?: boolean
  canNextProps?: boolean
  talent?: Partial<ICandidateModel>
}

export const ModalMatchesDetail: FC<IProps> = ({
  lookupId,
  pfv,
  onPrevious,
  onNext,
  canPreviousProps,
  canNextProps,
  talent: _talent,
  ...props
}) => {
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  const [talent, setTalent] = useState<Partial<ICandidateModel>>(_talent || {})
  useEffect(() => {
    setTalent((prev) => ({ ...prev, pfv }))
  }, [pfv])
  useEffect(() => {
    if (_talent) {
      setTalent(_talent)
    }
  }, [_talent])

  const [loading, setLoading] = useState(false)

  const isEmphasis = useMemo(
    () => ReactionUtils.isEmphasis(talent?.reaction),
    [talent?.reaction]
  )

  const onToggleLike = useCallback(() => {
    if (!talent?.id) return

    setLoading(true)
    from(PeopleApi.toggleLike({ id: talent?.id }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        setTalent(prev => ({ ...(prev as ICandidateModel), reaction: data as IReactionModel || null }))
      })
  }, [talent?.id, unsubscribe$])

  const onConfirmToggleLike = useCallback(() => {
    if (!isEmphasis) {
      return onToggleLike()
    }

    DialogService.open(DialogConfirmUnMatch, {
      name: getFullName(talent),
      onConfirm: onToggleLike
    })
  }, [isEmphasis, onToggleLike, talent])

  useUnsubscribeEffect((unsubscribe$) => {
    if (!lookupId || !isAuthenticated) return

    from(CandidateApi.detail(lookupId))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          history.push(generate(ERoutes.ERROR_404))
          return EMPTY
        })
      )
      .subscribe(({ data }) => {
        setTalent(prev => ({ ...prev, ...data }))
      })
  }, [lookupId, isAuthenticated])

  const tabItems = useMemo(() => {
    return [
      {
        key: 'detail',
        label: 'Detail',
        children: <Detail talent={talent}/>
      },
      {
        key: 'message',
        label: 'Messages',
        children: <Message lookupId={lookupId}/>
      }
    ]
  }, [talent, lookupId])

  return (
    <Modal
      {...props}
      closeBtn
      contentClass="fx-1 fx fx-center"
    >
      <div className={Style.modalTalentDetail}>
        {talent && (
          <div className={Style.videoWrapper} style={{ maxWidth: isEmphasis ? 630 : '100%' }}>
            <div className="fx fx-column gap-6 w-full" style={{ maxWidth: 403 }}>
              <TalentImageCard
                inlinePlayer
                talent={talent}
                loading={loading}
                onToggleLike={onConfirmToggleLike}
              />
              <div className="fw-600 fs-20 lh-24 txt-black-01 three-dot-1 text-center">
                {renderVideoTitle(talent?.pfv)}
              </div>
            </div>
          </div>
        )}

        <div className="fx fx-ai-center gap-14">
          <div style={{ width: '650px', minHeight: 700 }}>
            <TabsNav
              className="fx-1 fx-column gap-4"
              defaultActiveKey="detail"
              items={tabItems}
            />
          </div>

          <div className="fx fx-column gap-4">
            <Button
              size={48}
              variant="icon"
              onClick={onPrevious}
              disabled={canPreviousProps}
            >
              <IconArrow pointTo="top" size={30}/>
            </Button>
            <Button
              size={48}
              variant="icon"
              onClick={onNext}
              disabled={canNextProps}
            >
              <IconArrow pointTo="bottom" size={30}/>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
