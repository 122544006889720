import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconEmoji: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#0C0C0C' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99961 3.9001C6.63067 3.9001 3.89961 6.63116 3.89961 10.0001C3.89961 13.369 6.63067 16.1001 9.99961 16.1001C13.3685 16.1001 16.0996 13.369 16.0996 10.0001C16.0996 6.63116 13.3685 3.9001 9.99961 3.9001ZM2.59961 10.0001C2.59961 5.91319 5.9127 2.6001 9.99961 2.6001C14.0865 2.6001 17.3996 5.91319 17.3996 10.0001C17.3996 14.087 14.0865 17.4001 9.99961 17.4001C5.9127 17.4001 2.59961 14.087 2.59961 10.0001Z" fill={color}/>
        <path d="M8.31211 8.59385C8.31211 9.05984 7.93435 9.4376 7.46836 9.4376C7.00237 9.4376 6.62461 9.05984 6.62461 8.59385C6.62461 8.12786 7.00237 7.7501 7.46836 7.7501C7.93435 7.7501 8.31211 8.12786 8.31211 8.59385Z" fill={color}/>
        <path d="M12.5309 9.4376C12.9968 9.4376 13.3746 9.05984 13.3746 8.59385C13.3746 8.12786 12.9968 7.7501 12.5309 7.7501C12.0649 7.7501 11.6871 8.12786 11.6871 8.59385C11.6871 9.05984 12.0649 9.4376 12.5309 9.4376Z" fill={color}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.86167 11.1249C7.17242 10.9452 7.57003 11.0514 7.74977 11.3622C8.23506 12.2012 9.01581 12.7251 9.99961 12.7251C10.9834 12.7251 11.7642 12.2012 12.2494 11.3622C12.4292 11.0514 12.8268 10.9452 13.1376 11.1249C13.4483 11.3047 13.5545 11.7023 13.3748 12.013C12.6929 13.192 11.5147 14.0251 9.99961 14.0251C8.4845 14.0251 7.30635 13.192 6.62445 12.013C6.44471 11.7023 6.55092 11.3047 6.86167 11.1249Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
