import { FC } from 'react'
import { useBehaviorMapper } from 'src/hooks'
import { IconEmptyJob } from 'src/icons'
import { browserHistory, ERoutes, generate } from 'src/router'
import { LoadingModule } from 'src/store'
import { CreateJobButton } from '../create-job-button'

interface IProps {
  tab?: number
  withBanner?: boolean
  onBannerClick?: () => void
}

export const EmptyJob: FC<IProps> = (props) => {
  const isLayoutLoading = useBehaviorMapper(LoadingModule.loading$)

  return (
    <div className="fx flex-column fx-ai-center fx-jc-center fx-1">
      <div className="fx flex-column fx-ai-center fx-jc-center fx-1">
        <IconEmptyJob/>
        <h5 className="neutral-800 text-center mt-12 mb-12">
          {props.tab === 1
            ? 'You Don’t Have Any Draft'
            : <>Start with Creating your First Job Listing <br/> or Claim a Listing</>}
        </h5>

        {!isLayoutLoading && (
          <CreateJobButton
            onClick={() => browserHistory.push(generate(ERoutes.CLAIM_JOBS))}
          />
        )}
      </div>

      {/* {props.withBanner && (<BannerStartVibeTalent onBannerClick={props.onBannerClick}/>)} */}
    </div>
  )
}
