import clsx from 'clsx'
import {
  ComponentProps,
  CSSProperties,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'
import { createPortal } from 'react-dom'
import { useDidMountEffect } from 'src/hooks'
import { IconClose } from 'src/icons'
import { Button } from '../button'
import Style from './style.module.scss'

let modalStack = 0
const modalDefaultIndex = 900
type TEventListenerCallback = (e: KeyboardEvent) => void // EventListener // Parameters<typeof document['addEventListener']>[1]
const escapeListener: {
  listeners: TEventListenerCallback[]
  attach: (listener: TEventListenerCallback) => void
  detach: (listener: TEventListenerCallback) => void
} = {
  listeners: [],
  attach(listener) {
    for (const fn of this.listeners) {
      window?.document?.removeEventListener('keydown', fn)
    }

    this.listeners = [
      listener,
      ...this.listeners.filter((fn) => fn !== listener)
    ]

    for (const fn of this.listeners) {
      window?.document?.addEventListener('keydown', fn)
    }
  },
  detach(listener) {
    this.listeners = this.listeners.filter((fn) => fn !== listener)
    window?.document?.removeEventListener('keydown', listener)
  }
}

interface IProps {
  appendTo?: string
  open?: boolean
  onClose?: () => void
  children?: ReactNode
  className?: string
  contentClass?: string
  contentStyles?: CSSProperties
  noKeyboard?: boolean
  closeBtn?: boolean
  closeBtnProps?: Partial<Omit<ComponentProps<typeof Button>, 'onClick'>>
  center?: boolean
  style?: CSSProperties
}

export const Modal: FC<IProps> = (props) => {
  const [open, setOpen] = useState<boolean>()
  const [opened, setOpened] = useState(false)
  const [zIndex, setZIndex] = useState(modalDefaultIndex)

  const close = useCallback(() => {
    setOpen(() => false)
    props.onClose?.()
  }, [props])

  useEffect(() => {
    setOpen(() => props.open || false)
  }, [props.open])

  useDidMountEffect(() => {
    if (open) {
      return () => {
        props.onClose?.()
      }
    }
  }, [open])

  useEffect(() => {
    if (open) {
      setOpened(true)
      modalStack++
      if (window?.document) {
        window.document.body.style.overflow = 'hidden'
      }
      setZIndex(modalDefaultIndex + modalStack)
      return () => {
        modalStack--
        if (modalStack === 0) {
          window?.document?.body.style.removeProperty('overflow')
        }
      }
    }
  }, [open])

  useEffect(() => {
    if (open) {
      const listener = (event: KeyboardEvent) => {
        event.stopImmediatePropagation()
        if (!props.noKeyboard && event.key === 'Escape') {
          close()
        }
      }
      escapeListener.attach(listener)
      return () => {
        escapeListener.detach(listener)
      }
    }
  }, [close, open, props.noKeyboard])

  return createPortal(
    <div
      className={clsx(
        'fx-column overflow-hidden',
        Style.modal,
        {
          [Style.open]: open,
          [Style.closed]: !open && opened
        },
        props.className
      )}
      style={{
        zIndex,
        display: opened ? undefined : 'none'
      }}
    >
      <div className={clsx('fx-1 fx-column overflow-auto', { 'fx-center': props.center })}>
        <div
          style={props.contentStyles}
          className={clsx('relative bg-neutral-white p-8', props.contentClass, {
            'fx-1': !props.center,
            'round-6': props.center
          })}
        >
          {props.children}

          {props.closeBtn && (
            <Button
              {...props.closeBtnProps}
              variant="icon"
              size={32}
              className={clsx(
                'bg-neutral-white hover-bg-neutral-100',
                Style.closeBtn,
                props.closeBtnProps?.className
              )}
              onClick={close}
            >
              <IconClose size={20}/>
            </Button>
          )}
        </div>
      </div>
    </div>,
    (window?.document?.querySelector(props.appendTo || 'body') || window?.document?.querySelector('body')) as Element
  )
}
