import { Avatar } from '@mui/material'
import clsx from 'clsx'
import { debounce } from 'lodash'
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  Button,
  ErrorMessage,
  Input,
  useAnalytic
} from 'src/components'
import { ETrackingEvent } from 'src/enums'
import {
  ErrorBag,
  useBehaviorMapper,
  useDebounce,
  useDidMountEffect
} from 'src/hooks'
import { IconPlus, IconTrash } from 'src/icons'
import DefaultCampaignAvatar from 'src/images/default-campaign-avatar.svg'
import { ICampaignModel } from 'src/interfaces'
import { TourPopper } from 'src/partials/tour'
import { CampaignMutationService, CampaignService } from 'src/services'
import {
  CreateJobTourService,
  TARGET_COMPANY_DETAILS
} from 'src/services/tour/create-job-tour.service'
import Style from './style.module.scss'

const avatarSx = { width: '80px', height: '80px', borderRadius: '8px' }

export const CampaignInformation: FC<{
  errors?: ErrorBag<ICampaignModel>
}> = ({ errors: externalErrors }) => {
  const { eventHandler } = useAnalytic()
  const [logoUrl, setLogoUrl] = useState<string>()
  const tourEnabled = useBehaviorMapper(CreateJobTourService.enableTour$)
  const currentTourStep = useBehaviorMapper(CreateJobTourService.currentStep$)

  const data = useBehaviorMapper(CampaignMutationService.data$)

  const [loaded, setLoaded] = useState(false)
  const [urls, setUrls] = useState<string[]>(data.companyWebsiteUrls || [])
  const [urlErrors, setUrlErrors] = useState<string[]>([])

  useDidMountEffect(() => {
    if (loaded) return

    setUrls(data.companyWebsiteUrls || [])
    setLoaded(true)
  }, [data.companyWebsiteUrls])

  useDidMountEffect(() => {
    setUrlErrors(
      urls.map((url) =>
        !url || CampaignService.isValidWebsiteUrl(url)
          ? ''
          : 'Invalid website url'
      )
    )
  }, [urls])

  useDebounce(
    () => {
      CampaignMutationService.patchData({ companyWebsiteUrls: urls })
    },
    500,
    [urls]
  )

  useEffect(() => {
    if (data.logoUrl) {
      setLogoUrl(data.logoUrl)
    }

    if (data.logoFile) {
      const url = URL.createObjectURL(data.logoFile)
      setLogoUrl(url)
      return () => {
        URL.revokeObjectURL(url)
      }
    }
  }, [setLogoUrl, data.logoUrl, data.logoFile])

  const debounceSendAnalyticInputCompanyWebsite = useMemo(
    () =>
      debounce((companyUrl: string) => {
        eventHandler({
          key: ETrackingEvent.CAMPAIGN_INPUT_COMPANY_URL,
          contextData: { companyUrl }
        })()
      }, 500),
    [eventHandler]
  )

  const debounceSendAnalyticInputCompanyName = useMemo(
    () =>
      debounce((companyName: string) => {
        eventHandler({
          key: ETrackingEvent.CAMPAIGN_INPUT_COMPANY_NAME,
          contextData: { companyName }
        })()
      }, 500),
    [eventHandler]
  )

  const handleWebsiteUrlChanged = useCallback(
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      debounceSendAnalyticInputCompanyWebsite(value)
      setUrls((urls) => urls.map((v, i) => (i === index ? value : v)))
    },
    [debounceSendAnalyticInputCompanyWebsite]
  )

  const handleOnChangeLogo = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (!file) {
        return
      }

      CampaignMutationService.patchData({ logoFile: file, _ignoreDraft: true })
      await CampaignMutationService.uploadFilesAndSaveDraft({ logoFile: file })

      eventHandler({
        key: ETrackingEvent.CAMPAIGN_BTN_COMPANY_LOGO_UPLOAD,
        contextData: {
          file
        }
      })()
    },
    [eventHandler]
  )

  const handleOnChangeCompanyName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      CampaignMutationService.patchData({ companyName: e.target.value })
      debounceSendAnalyticInputCompanyName(e.target.value)
    },
    [debounceSendAnalyticInputCompanyName]
  )

  return (
    <div className={Style.container}>
      <span className={Style.header}>Company Detail</span>

      <div className={Style.content}>
        <div className={Style.subContent}>
          <div className={Style.companyName} id="companyName">
            <span className={clsx(Style.title, Style.title_required)}>
              Company Name
            </span>
            <Input
              id="companyNameInput"
              placeholder="Enter Company Name"
              value={data.companyName || ''}
              onChange={handleOnChangeCompanyName}
              error={externalErrors?.getError('companyName')}
            />
            <div
              className={Style.companyTarget}
              id={TARGET_COMPANY_DETAILS.getValue()}
            />
            {tourEnabled && currentTourStep === 5 && (
              <TourPopper {...CreateJobTourService.getStepConfig()}/>
            )}
          </div>
          <div className={Style.companyWebsites} id="companyWebsiteUrls">
            <span className={clsx(Style.title, Style.title_required)}>
              Company Website
            </span>
            {urls.map((value, index) => (
              <div key={index} className={Style.companyWebsites_inputWrapper}>
                <Input
                  id="companyWebsiteUrlsInput"
                  placeholder="Enter Company Website"
                  value={value || ''}
                  onChange={handleWebsiteUrlChanged(index)}
                  error={
                    urlErrors.at(index) ||
                    externalErrors?.getError('companyWebsiteUrls[0]')
                  }
                />
                {index > 0 && (
                  <Button
                    variant="icon"
                    className={Style.companyWebsites_buttonRemoveWebsite}
                    onClick={() => setUrls(urls.filter((_, i) => i !== index))}
                  >
                    <IconTrash width={18.5} height={20.25}/>
                  </Button>
                )}
              </div>
            ))}

            {urls?.length === 0 && externalErrors?.getError('companyWebsiteUrls') && (
              <ErrorMessage className="mt-2">
                {externalErrors?.getError('companyWebsiteUrls')}
              </ErrorMessage>
            )}

            {urls.length >= 3
              ? null
              : (
                <span
                  className={clsx(
                    'body2-bold color-neutral-theme-700',
                    Style.companyWebsites_buttonMore
                  )}
                  onClick={() => setUrls([...urls, ''])}
                >
                  <IconPlus width={16} height={16}/>
                  Add Link
                </span>
              )}
          </div>
        </div>
        <div className={Style.logo}>
          <span className={Style.title}>Company Logo</span>
          <div className={Style.logo_content}>
            <Avatar
              sx={avatarSx}
              variant="square"
              src={logoUrl || DefaultCampaignAvatar}
              onError={(err) => console.log(err)}
            />
            <div className={Style.logo_upload}>
              <Button
                field="input"
                className={Style.logo_button}
                variant="secondary"
                inputProps={{
                  accept: 'image/jpeg,image/png',
                  onChange: handleOnChangeLogo
                }}
              >
                Click to Upload
              </Button>
              <span>PNG, JPG (max.800x800px)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
