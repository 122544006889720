import { FC } from 'react'
import { NotAllowAuthenticated } from 'src/components'
import { SignInForm } from 'src/partials'

export const SignIn: FC = () => {
  // const { sharingToken } = useQueryParams()

  /**
   * TODO: cache sharing token for redirecting after login
   */
  // useEffect(() => {
  //   if (sharingToken) {
  //     dispatch(setLayoutSharingToken(sharingToken.toString()))
  //   }
  // }, [sharingToken, dispatch])

  return (
    <NotAllowAuthenticated>
      <div className="fx-1 fx fx-center">
        <SignInForm/>
      </div>
    </NotAllowAuthenticated>
  )
}
