import { useEffect, useMemo, useState } from 'react'
import { useBehaviorMapper } from 'src/hooks'
import { ICampaignModel } from 'src/interfaces'
import { useCampaignSalary } from 'src/modules/campaign/hooks/useCampaignSalary'
import { formatJobLocation } from 'src/modules/campaign/utils'
import { CampaignMutationService } from 'src/services'

export const useJobMediaUrl = () => {
  const data = useBehaviorMapper(CampaignMutationService.data$)

  const [logoUrl, setLogoUrl] = useState<string>()

  useEffect(() => {
    if (data.logoUrl) {
      setLogoUrl(data.logoUrl)
    }

    if (data.logoFile) {
      const url = URL.createObjectURL(data.logoFile)

      setLogoUrl(url)

      return () => {
        URL.revokeObjectURL(url)
      }
    }
  }, [data.logoUrl, data.logoFile])

  return { logoUrl }
}

export const useJobLocation = (): string[] => {
  const data = useBehaviorMapper(CampaignMutationService.data$)
  const location = useMemo(() => formatJobLocation(data), [data])
  return location
}

export const useSalaryValue = () => {
  const data = useBehaviorMapper(CampaignMutationService.data$)
  const salaryValue = useCampaignSalary(data)
  return salaryValue
}

export const useCampaignRequirements = (data?: Pick<ICampaignModel, 'requireLinkedInUrl' | 'requireZipCode' | 'requiredResume'>) => {
  return useMemo(() => [
    data?.requiredResume ? 'Resume' : '',
    data?.requireLinkedInUrl ? 'LinkedIn Profile' : '',
    data?.requireZipCode ? 'Zip Code of Resident' : ''
  ].filter(Boolean).join(', '), [data])
}
