import {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useState
} from 'react'
import { Modal } from 'src/components'
import { useAsRef, useBehaviorMapper } from 'src/hooks'
import { AuthModule } from 'src/store'
import { ForgotPasswordForm } from '../forgot-password'
import { SignInForm } from '../sign-in'
import { SignUpForm } from '../sign-up'

interface IProps {
  noNavigation?: boolean
  signUpProps?: ComponentProps<typeof SignUpForm>
  stage?: 'sign-up' | 'sign-in' | 'forgot-password'
  onSwitch?: (stage: IProps['stage']) => void
  open?: boolean
  onClose?: (isAuthenticated?: boolean) => void
  redirectTo?: () => void
}

export const AuthenticationFlow: FC<Pick<IProps, 'noNavigation' | 'stage' | 'onSwitch'>> = (props) => {
  const [stage, setStage] = useState(props.stage)
  const onSwitch = useCallback((stage: IProps['stage']) => {
    setStage(stage)
  }, [])

  switch (stage) {
    case 'sign-up':
      return <SignUpForm onSwitch={onSwitch}/>
    case 'forgot-password':
      return <ForgotPasswordForm noNavigation={props.noNavigation} onSwitch={onSwitch}/>
    default:
      return <SignInForm onSwitch={onSwitch}/>
  }
}

export const ModalAuthentication: FC<IProps> = (props) => {
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const [stage, setStage] = useState(props.stage)

  const onCloseRef = useAsRef(props.onClose)
  useEffect(() => {
    if (isAuthenticated && stage !== 'forgot-password') {
      onCloseRef.current?.(isAuthenticated)
      props.redirectTo?.()
    }
  }, [isAuthenticated, onCloseRef, stage, props])

  return (
    <Modal
      center
      closeBtn
      open={props.open}
      onClose={props.onClose}
      contentStyles={{
        maxWidth: '100%'
      }}
    >
      <AuthenticationFlow
        noNavigation={props.noNavigation}
        stage={stage}
        onSwitch={setStage}
      />
    </Modal>
  )
}
