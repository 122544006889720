import { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import {
  EMPTY,
  catchError,
  finalize,
  from,
  takeUntil
} from 'rxjs'
import { PeopleApi } from 'src/api'
import { Button, DialogConfirmUnMatch, UserAvatar } from 'src/components'
import { useUnsubscribe } from 'src/hooks'
import { IconChat, IconThumbsUp, IconThumbsUpDup, IconThumbsUpFilled } from 'src/icons'
import { IReactionModel } from 'src/interfaces'
import { mustVerifiedCompany } from 'src/partials/modal-verify-company'
import { ERoutes, generate } from 'src/router'
import { DialogService, SnackbarService } from 'src/services'
import { ReactionUtils, getAvatar, getFullName } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  reaction: IReactionModel
  onChange?: (values: Partial<IReactionModel>) => void
  onDetail?: () => void
}

export const ReactionCard: FC<IProps> = ({ reaction, onChange, onDetail }) => {
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [removed, setRemoved] = useState(false)
  const isEmphasis = useMemo(
    () => !removed && ReactionUtils.isEmphasis(reaction),
    [reaction, removed]
  )

  const lookupId = useMemo(() => reaction?.toUser?.lookupId, [reaction?.toUser?.lookupId])

  const onToggleLike = useCallback(() => {
    if (!reaction.toUserId) return

    setLoading(true)
    from(PeopleApi.toggleLike({ id: reaction.toUserId }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        setRemoved(!data)
        onChange?.({ reaction: data?.reaction })
      })
  }, [onChange, reaction, unsubscribe$])

  const onConfirmToggleLike = useCallback(() => {
    if (removed || !isEmphasis) {
      return onToggleLike()
    }

    DialogService.open(DialogConfirmUnMatch, {
      name: getFullName(reaction?.toUser),
      onConfirm: onToggleLike
    })
  }, [isEmphasis, onToggleLike, reaction?.toUser, removed])

  return (
    <div className={Style.reactionCard}>
      <UserAvatar
        size={70}
        className="round-4"
        fullName={getFullName(reaction?.toUser)}
        avatarUrl={getAvatar(reaction?.toUser)}
      />
      <div className="w-100">
        <div className="p-0 fx fx-jc-space-between gap-4">
          <div className="fx fx-column gap-1">
            <div className="fs-16 fw-500 txt-black-01 three-dot-1">
              {getFullName(reaction?.toUser)}
            </div>
            <div className="fs-12 fw-400 txt-grey-01 three-dot-1">
              {reaction?.toUser?.username ? `@${reaction?.toUser?.username}` : '--'}
            </div>
          </div>
          <div className="fx fx-ai-center gap-3 h-fit">
            <Button
              className="w-fit"
              onClick={onDetail}
            >
              Details
            </Button>

            <Button
              className="fx fx-ai-center gap-1"
              size={32}
              variant={removed || isEmphasis ? 'icon' : 'label'}
              emphasis={isEmphasis}
              // disabled={loading}
              onClick={() => !loading && mustVerifiedCompany(onConfirmToggleLike)}
            >
              {removed
                ? <IconThumbsUp size={20}/>
                : isEmphasis
                  ? <IconThumbsUpDup size={20}/>
                  : <><IconThumbsUpFilled size={20}/> Liked</>}
            </Button>

            {isEmphasis && (
              <Button
                size={32}
                variant="icon"
                onClick={() => history.push(generate(ERoutes.TALENT_MESSAGES, { lookupId }))}
              >
                <IconChat size={20}/>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
