import { FC } from 'react'
import { Button, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useBehaviorMapper } from 'src/hooks'
import { IconBell } from 'src/icons'
import { ModalAuthentication } from 'src/partials'
import { DialogService } from 'src/services/dialog.service'
import { AuthModule } from 'src/store'
import { NotificationBadge } from './notification-badge'

const UnAuNotification: FC = () => {
  const { eventHandler } = useAnalytic('top_nav')

  return (
    <Button
      variant="icon"
      size={32}
      onClick={eventHandler(ETrackingEvent.BTN_NAV_NOTIFICATIONS, () => DialogService.open(ModalAuthentication, { stage: 'sign-up' }))}
    >
      <IconBell size={20} className="txt-black-01"/>
    </Button>
  )
}

export const Notification: FC = () => {
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  return isAuthenticated ? <NotificationBadge/> : <UnAuNotification/>
}
