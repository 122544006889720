import { EmojiClickData } from 'emoji-picker-react'
import {
  FC,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Button, EmojiPicker, FilePicker, Input, useAnalytic } from 'src/components'
import { IconCloseDark, IconFile } from 'src/icons'
import { EventUtils } from 'src/utils'
import { ETrackingEvent } from '../../../enums'
import { useDebounceAnalyticInput } from '../../../hooks'

interface IAttachment {
  file: File
  conversationId: number
  uploading?: boolean
}

interface IProps {
  id?: number | string
  onChooseFiles?: (files: FileList) => void
  className?: string
  iconSlot?: ReactNode
  onSubmitRef?: any
  attachments?: IAttachment[]
  onRemoveFile?: () => void
}

export const ChatEditor: FC<IProps> = ({
  id,
  onChooseFiles,
  onSubmitRef,
  attachments,
  onRemoveFile
}) => {
  const [messageContent, setMessageContent] = useState<string>('')
  const { analytic } = useAnalytic('talent_detail')
  const { analyticInput } = useDebounceAnalyticInput('talent_detail')
  const handleChangeMessage = (message: string) => {
    setMessageContent(message)
    analyticInput(ETrackingEvent.BTN_TALENT_DETAILS_INPUT_MESSAGE, message)
  }

  const insertEmoji = useCallback((data: EmojiClickData) => {
    setMessageContent(content => content + data.emoji)
  }, [])

  const handleSubmit = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if ((messageContent || attachments?.length) && event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey) {
      event.preventDefault()
      event.stopPropagation()
      onSubmitRef.current(messageContent)
      setMessageContent('')
    }
  }, [attachments?.length, messageContent, onSubmitRef])

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [id])

  return (
    <form
      onSubmit={(e) => EventUtils.preventDefault(e)}
      className="px-7 py-8 fx fx-column gap-3"
    >
      <Input
        ref={inputRef}
        autoFocus
        placeholder="Type your message here…"
        value={messageContent}
        onChange={event => handleChangeMessage(event.target.value)}
        onKeyDown={handleSubmit}
      />
      <div className="fx fx-ai-center fx-jc-space-between gap-4">
        <div className="fx fx-ai-center gap-3">
          <FilePicker
            className="pointer"
            inputProps={{ accept: 'image/*,video/*,application/pdf' }}
            onChoose={onChooseFiles}
            style={{ pointerEvents: attachments?.length ? 'none' : 'all' }}
          >
            <Button variant="icon"><IconFile size={20}/></Button>
          </FilePicker>
          <EmojiPicker
            previewConfig={{ showPreview: false }}
            onEmojiClick={insertEmoji}
            onReactionClick={insertEmoji}
          />
          {attachments?.map((item) => (
            <div key={item.file.name} className="relative fx fx-ai-center gap-2 px-4 py-2 bg-neutral-white-02 round-2">
              <IconFile size={16}/>
              <span className="fs-12 fw-500 txt-grey-01 three-dot-1">{item.file.name}</span>
              <div className="absolute pointer" style={{ right: -12, top: -8 }} onClick={onRemoveFile}>
                <IconCloseDark/>
              </div>
            </div>
          ))}
        </div>

        <Button
          onClick={() => {
            analytic(ETrackingEvent.BTN_TALENT_DETAILS_SEND_MESSAGE)
            if (!messageContent && !attachments?.length) return
            onSubmitRef.current(messageContent)
            setMessageContent('')
          }}
        >
          Send
        </Button>
      </div>
    </form>
  )
}
