export enum ENotificationAction {
  COMMENT_CANDIDATE_VIDEO = 'commentCandidateVideo',
  INVITATION_BONUS = 'invitationBonus',
  FIRST_TIME_UPLOADED_PFV = 'firstTimeUploadedPfv',
  SUPER_NOVA = 'supernova',
  NEW_CAMPAIGN_SUBMISSION = 'newCampaignSubmission',

  GO_TO_VIBE_CHECK = 'goToVibeCheck',
  GO_TO_VIBE_TUTORIAL = 'goToVibeTutorial',
  GO_TO_QUIZ = 'goToQuiz',
  GO_TO_DASHBOARD = 'goToDashboard',
  GO_TO_PROFILE_SCREEN = 'goToProfileScreen',
  GO_TO_SUMMARY_SCREEN = 'goToSummaryScreen',
  GO_TO_AUDIO_VIBE_CHECK_SCREEN = 'goToAudioVibeCheckScreen'
}
