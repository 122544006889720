import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconMotivatorEconomic = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 300 } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="300" height="257" viewBox="0 0 300 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="299.33" height="257" transform="translate(0 0.00195312)"/>
        <circle cx="69.8504" cy="83.8704" r="12.1439" stroke="#0C0C0C"/>
        <circle cx="69.9925" cy="74.6356" r="0.5" fill="#0C0C0C" stroke="#0C0C0C" strokeWidth="0.704789"/>
        <circle cx="69.9925" cy="93.1043" r="0.5" fill="#0C0C0C" stroke="#0C0C0C" strokeWidth="0.704789"/>
        <circle cx="79.0848" cy="84.0126" r="0.5" fill="#0C0C0C" stroke="#0C0C0C" strokeWidth="0.704789"/>
        <circle cx="60.6161" cy="84.0126" r="0.5" fill="#0C0C0C" stroke="#0C0C0C" strokeWidth="0.704789"/>
        <circle cx="69.8503" cy="83.8703" r="1.34686" fill="#0C0C0C" stroke="#0C0C0C"/>
        <path d="M65.1621 84.0137H69.14" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M69.9922 84.0126V77.7617" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M137.793 38.1586C138.437 45.4169 141.73 58.218 149.727 58.7141C158.151 59.2367 161.661 42.3394 161.661 37.9844" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M139.356 37.8422L136.941 37.416" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M162.635 37.416C165.148 38.0619 167.125 40.8688 161.092 46.2241" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M136.646 37.416C134.42 37.7096 132.078 41.1154 138.93 46.2241" stroke="#0C0C0C" strokeLinecap="round"/>
        <circle cx="143.76" cy="38.8367" r="0.920658" fill="#0C0C0C" stroke="#0C0C0C"/>
        <circle cx="155.409" cy="38.8367" r="0.920658" fill="#0C0C0C" stroke="#0C0C0C"/>
        <circle cx="143.902" cy="38.6939" r="4.75643" stroke="#0C0C0C"/>
        <circle cx="155.268" cy="38.6939" r="4.75643" stroke="#0C0C0C"/>
        <path d="M148.59 38.2676H150.579" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M147.596 41.3926C147.127 43.9496 147.075 48.195 151.716 45.8572" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M146.602 50.2012C147.549 50.8444 150.011 51.7449 152.284 50.2012" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M159.671 37.416H162.513" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M136.366 23.2413C127.421 27.4533 131.591 34.8244 134.794 37.9834C135.353 37.5622 137.123 37.4569 137.938 37.4569C136.401 26.2248 142.189 23.2412 144.752 22.5392C151.915 21.3107 160.126 23.2413 161.524 28.8573C163.194 35.5662 163.246 37.8079 164.544 37.9834C169.476 34.0521 168.748 26.5173 167.768 23.2413C169.047 19.582 166.191 16.2797 164.544 14.2907C162.897 12.3017 158.379 8.6746 150.343 9.0257C134.444 10.6052 135.492 20.4333 136.366 23.2413Z" fill="#0C0C0C" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M140.919 31.7314C141.629 31.517 143.76 31.2168 146.602 31.7314" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M152.853 32.0166C153.527 31.8021 155.552 31.502 158.251 32.0166" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M125.759 230.056C112.379 223.366 117.778 157.908 125.704 124.027C125.736 123.889 125.865 123.801 126.007 123.815L170.436 128.174C170.564 128.186 170.666 128.276 170.687 128.403C174.725 152.215 182.857 202.723 180.881 216.702C178.993 230.056 176.193 229.183 167.242 230.056C148.041 231.93 147.063 186.059 147.459 159.794C147.464 159.461 147.05 159.405 146.984 159.732C144.171 173.549 147.495 199.771 147.495 212.44C147.495 225.794 142.523 238.438 125.759 230.056Z" fill="#0C0C0C" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M131.925 235.314V238.581C126.668 240.854 117.463 245.997 122.691 248.384C127.919 250.771 136.992 245.685 140.875 242.843V234.035" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M165.177 232.613V240.995C167.734 243.221 175.263 247.473 184.924 246.678C196.999 245.683 177.394 243.41 171.996 233.324" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M170.007 126.878V81.4297C170.007 80.9155 170.459 80.519 170.971 80.573C190.426 82.6262 210.606 70.8591 214.189 67.5324C216.178 65.6859 232.09 47.7856 210.212 55.5982C188.442 63.3722 166.481 64.2689 157.219 62.9526C156.806 62.894 156.511 62.5382 156.511 62.1215V54.8509" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M143.441 55.4201V61.7184C143.441 62.1255 143.162 62.4749 142.762 62.5467C131.576 64.5512 105.98 64.4177 87.6089 48.4965C84.7203 46.3182 76.8142 47.4567 85.0518 62.419C91.8356 74.7408 115.485 80.4066 128.519 79.6789C129.045 79.6496 129.48 80.0869 129.437 80.612L126.11 121.907" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M229.675 55.1738C228.254 57.731 231.568 58.1071 233.226 58.502C233.984 58.7424 235.016 59.841 233.084 62.3128" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M232.231 55.8835C231.237 55.7414 230.127 55.381 229.674 55.1732C229.092 54.9063 228.724 54.0261 228.964 53.3871C229.596 51.7022 232.373 53.0695 234.504 53.8132" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M236.21 51.9659C233.937 51.0188 228.538 48.9824 229.675 52.6094" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M230.953 50.3438C230.669 49.4914 230.47 47.3927 236.494 48.9839C239.761 49.847 239.619 52.4995 239.619 53.8141C239.619 58.5224 235.262 61.4419 233.084 62.313C229.359 63.78 224.025 65.3345 221.719 64.0428" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M222.713 53.3879C223.755 52.0146 226.378 49.0407 228.538 48.1315C232.137 46.1425 238.766 43.5284 236.493 48.9838" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M72.5505 48.5664C73.9712 51.1236 70.6573 51.4996 68.9989 51.8946C68.2412 52.135 67.2089 53.2336 69.141 55.7054" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M69.9932 49.2761C70.9877 49.134 72.0973 48.7736 72.5504 48.5658C73.1323 48.2989 73.5004 47.4187 73.2607 46.7797C72.6289 45.0948 69.8512 46.462 67.7202 47.2058" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M66.0157 45.3584C68.2888 44.4113 73.6873 42.375 72.5508 46.002" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M71.2721 43.7364C71.5562 42.884 71.7551 40.7853 65.7315 42.3764C62.464 43.2395 62.6061 45.8921 62.6061 47.2067C62.6061 51.915 66.9627 54.8344 69.1411 55.7056C72.8658 57.1725 78.1997 58.7271 80.5063 57.4354" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M79.5117 46.7785C78.4699 45.4052 75.8464 42.4313 73.687 41.5221C70.088 39.5331 63.4583 36.919 65.7313 42.3744" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M256.383 110.437C251.647 98.8345 241.097 73.6893 234.505 61.5285M234.505 48.0697C235.026 46.6964 236.465 43.4383 238.057 41.3926M230.813 58.1189L205.523 110.579" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M43.71 96.2314L69.4239 52.4751M70.7038 51.5853L95.564 96.5155M66.726 41.4986C66.063 39.8885 63.8265 36.8763 58.4848 35.2852" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M51.9937 102.481C44.6251 101.078 42.6657 98.1242 43.0445 96.0879C69.412 99.8384 90.0211 97.9347 96.1772 96.372C96.8591 100.804 95.0407 101.913 91.4879 102.481C81.5746 104.066 57.9619 103.617 51.9937 102.481Z" fill="#0C0C0C" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M62.3211 93.3887C51.3581 93.7358 43.8525 94.9168 43.8525 96.3203C43.8525 97.9966 55.365 99.3554 69.5664 99.3554C83.7678 99.3554 95.2804 97.9966 95.2804 96.3203C95.2804 94.9427 87.8922 93.7594 77.238 93.3887" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M96.491 229.466C96.6102 229.532 96.6862 229.588 96.7319 229.63C96.6862 229.672 96.6102 229.728 96.491 229.793C96.1919 229.958 95.7186 230.133 95.0639 230.308C93.7623 230.655 91.8561 230.974 89.4738 231.243C84.7158 231.78 78.1293 232.113 70.8451 232.113C63.5609 232.113 56.9745 231.78 52.2165 231.243C49.8342 230.974 47.928 230.655 46.6264 230.308C45.9717 230.133 45.4984 229.958 45.1993 229.793C45.08 229.728 45.004 229.672 44.9584 229.63C45.004 229.588 45.08 229.532 45.1993 229.466C45.4984 229.301 45.9717 229.126 46.6264 228.952C47.928 228.604 49.8342 228.286 52.2165 228.017C56.9745 227.48 63.5609 227.146 70.8451 227.146C78.1293 227.146 84.7158 227.48 89.4738 228.017C91.8561 228.286 93.7623 228.604 95.0639 228.952C95.7186 229.126 96.1919 229.301 96.491 229.466Z" fill="#0C0C0C" stroke="#0C0C0C"/>
        <path d="M256.741 236.57C256.86 236.636 256.936 236.692 256.982 236.733C256.936 236.775 256.86 236.831 256.741 236.897C256.442 237.062 255.969 237.237 255.314 237.412C254.012 237.759 252.106 238.077 249.724 238.346C244.966 238.883 238.379 239.217 231.095 239.217C223.811 239.217 217.224 238.883 212.466 238.346C210.084 238.077 208.178 237.759 206.876 237.412C206.222 237.237 205.748 237.062 205.449 236.897C205.33 236.831 205.254 236.775 205.208 236.733C205.254 236.692 205.33 236.636 205.449 236.57C205.748 236.405 206.222 236.23 206.876 236.055C208.178 235.708 210.084 235.39 212.466 235.121C217.224 234.583 223.811 234.25 231.095 234.25C238.379 234.25 244.966 234.583 249.724 235.121C252.106 235.39 254.012 235.708 255.314 236.055C255.969 236.23 256.442 236.405 256.741 236.57Z" fill="#0C0C0C" stroke="#0C0C0C"/>
        <path d="M213.904 116.546C204.85 115.704 204.008 112.711 204.813 110.438C221.293 115.325 246.579 112.474 257.092 110.722C257.661 115.126 254.393 115.41 253.825 115.41C253.257 115.41 226.123 117.683 213.904 116.546Z" fill="#0C0C0C" stroke="#0C0C0C"/>
        <path d="M240.766 108.164C249.939 108.566 256.383 109.513 256.383 110.619C256.383 112.088 244.998 113.278 230.953 113.278C216.909 113.278 205.523 112.088 205.523 110.619C205.523 109.513 211.268 108.566 220.44 108.164" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M220.156 105.917C219.139 95.3599 223.827 90.1174 226.73 88.5309C227.067 88.3464 227.451 88.2754 227.835 88.2754H233.496C233.909 88.2754 234.321 88.3595 234.683 88.5602C240.261 91.657 241.765 100.441 241.22 105.917C240.994 110.214 237.953 111.006 235.25 111.006H226.126C220.607 110.78 220.231 107.312 220.156 105.917Z" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M232.516 97.452C232.03 97.2131 231.292 96.9896 230.603 96.9922C229.771 96.9953 229.009 97.3268 228.842 98.3554C228.621 99.712 230.295 100.199 231.291 100.689C232.669 101.367 232.657 102.637 231.827 103.249C231.454 103.524 231.03 103.675 230.603 103.732C229.977 103.816 229.342 103.698 228.842 103.475" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M230.603 96.9911V95.9453" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M230.603 103.732V104.755" stroke="#0C0C0C" strokeLinecap="round"/>
        <rect x="225.345" y="85.3633" width="10.3653" height="2.69371" rx="1.34686" stroke="#0C0C0C"/>
        <path d="M227.117 84.5813L224.56 80.0352" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M233.937 84.5802L236.21 79.75" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M224.56 79.9443C225.325 80.1075 227.016 80.1646 227.658 79.088C228.301 78.0114 229.15 78.3132 229.494 78.5986L230.527 79.5773" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M236.209 79.6753C235.481 79.8112 233.871 79.8588 233.259 78.9617C232.647 78.0645 231.838 78.316 231.51 78.5539L230.527 79.4515" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M141.31 66.3965C141.31 69.085 142.327 73.5541 144.86 75.9528C145.094 76.1749 145.465 76.1236 145.656 75.8625L149.775 70.2125C150.015 69.8844 150.511 69.9059 150.721 70.2535L154.056 75.7731C154.236 76.0716 154.639 76.1391 154.892 75.8994C156.45 74.4244 158.883 71.4064 159.087 68.2433" stroke="#0C0C0C" strokeLinecap="round"/>
        <circle cx="149.975" cy="87.848" r="0.5" fill="#0C0C0C" stroke="#0C0C0C" strokeWidth="0.988921"/>
        <circle cx="149.975" cy="96.0882" r="0.5" fill="#0C0C0C" stroke="#0C0C0C" strokeWidth="0.988921"/>
        <circle cx="149.975" cy="104.326" r="0.5" fill="#0C0C0C" stroke="#0C0C0C" strokeWidth="0.988921"/>
        <circle cx="149.975" cy="112.569" r="0.5" fill="#0C0C0C" stroke="#0C0C0C" strokeWidth="0.988921"/>
      </svg>
    </IconWrapper>
  )
})
