import { UnregisterCallback } from 'history'
import React, { ComponentProps } from 'react'
import { useHistory } from 'react-router'
import { BehaviorSubject } from 'rxjs'
import { ProfileApi } from 'src/api'
import { TourApi } from 'src/api/tour'
import type { TourPopper } from 'src/partials/tour'
import { ERoutes, generate } from 'src/router'
import { AuthModule } from 'src/store'
import { Target } from 'src/utils'
import { WithOutNextComplete } from 'types/rxjs'
import { TARGET_JOB_EDIT_BUTTON } from './share-job-tour.service'

export const TARGET_CLAIMED_JOB_VVC = new Target('target-claimed-job-vvc')

const MAX_JOB_STEPS = 2

const STEP_CONFIGS: ComponentProps<typeof TourPopper>[] = [
  {
    title: 'Your Applicants Await a Video Vibe Check From You',
    content: 'Let’s verify your job by adding a video vibe check to your job description, way to attract even more candidates!',
    currentStep: 0,
    maxStep: MAX_JOB_STEPS,
    targetSelector: TARGET_JOB_EDIT_BUTTON.getSelector(),
    arrowPosition: 'top',
    ctaText: 'Next',
    onCtaClick: () => (document.querySelector(TARGET_JOB_EDIT_BUTTON.getSelector()) as HTMLButtonElement)?.click(),
    shouldScroll: false,
    showMask: true
  },
  {
    title: 'Show Your Human Side',
    content: React.createElement('div', { className: 'fx flex-column gap-4 body2-regular color-neutral-theme-900' },
      React.createElement('div', { className: 'fx flex-column' },
        React.createElement('span', null, '🎬 Lights, Camera, Hire!'),
        React.createElement('span', null, 'Share your story with a captivating video – introduce yourself, talk about the job, and show people why it would be great to work for you.'))
    ),
    currentStep: 1,
    maxStep: MAX_JOB_STEPS,
    targetSelector: TARGET_CLAIMED_JOB_VVC.getSelector(),
    arrowPosition: 'right',
    ctaText: 'Got It',
    onCtaClick: () => {
      FirstTimeClaimedJobTourService.stopTour()
      TourApi.finish({ firstTimeClaimedJob: true })
        .then(() => ProfileApi.getProfile())
        .then(({ data }) => AuthModule.authenticated(data))
    },
    shouldScroll: false,
    showMask: true,
    zIndex: 1000
  }
]

export class FirstTimeClaimedJobTourService {
  public readonly MAX_JOB_STEPS = MAX_JOB_STEPS
  private static _currentStep$ = new BehaviorSubject(0)
  private static _enableTour$ = new BehaviorSubject(false)
  private static _routeListener?: UnregisterCallback = undefined

  public static get currentStep$(): WithOutNextComplete<typeof this._currentStep$> {
    return this._currentStep$
  }

  public static get enableTour$(): WithOutNextComplete<typeof this._enableTour$> {
    return this._enableTour$
  }

  public static nextStep(afterNext?: () => void) {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()

    if (this.isLastStep(currentStep)) {
      return
    }

    this._currentStep$.next(currentStep + 1)
  }

  public static isLastStep(stepIndex: number) {
    return stepIndex === MAX_JOB_STEPS - 1
  }

  public static getStepConfig() {
    return STEP_CONFIGS[this._currentStep$.getValue()]
  }

  public static startTour(history?: ReturnType<typeof useHistory>) {
    this.stopRouteListener()

    const routeNewCampaign = generate(ERoutes.NEW_CAMPAIGN)
    const routeCampaignMyJobs = generate(ERoutes.CAMPAIGN_MY_JOBS)

    this._routeListener = history?.listen((location) => {
      if (this.currentStep$.getValue() === 0 && routeCampaignMyJobs === location.pathname) {
        return
      }

      if (!([routeNewCampaign].some(tourPath => location.pathname.startsWith(tourPath)))) {
        this.stopTour()
      }
    })

    this._currentStep$.next(0)
    this._enableTour$.next(true)
  }

  private static stopRouteListener() {
    if (this._routeListener) {
      this._routeListener()
      this._routeListener = undefined
    }
  }

  public static stopTour() {
    if (!this._enableTour$.getValue()) {
      return
    }

    this.stopRouteListener()
    this._enableTour$.next(false)
    this._currentStep$.next(0)
  }
}
