import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconCamera: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#111111' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.34961 9.9985C2.34961 5.77393 5.77462 2.34961 9.99961 2.34961C14.2246 2.34961 17.6496 5.77393 17.6496 9.9985C17.6496 14.2229 14.2249 17.6471 10.0001 17.6474C8.84758 17.6492 7.71077 17.3896 6.67481 16.8896L3.90887 17.6096C3.69902 17.664 3.47785 17.6628 3.2687 17.6058C3.05954 17.5489 2.86888 17.4383 2.71558 17.285C2.56228 17.1317 2.45166 16.9411 2.39467 16.7319C2.33769 16.5228 2.33631 16.3023 2.39069 16.0925L3.11024 13.3269C2.61238 12.2992 2.34961 11.1665 2.34961 9.9985ZM10.9896 7.60893C10.727 7.34642 10.3709 7.19894 9.9996 7.19894H7.8996C7.5283 7.19894 7.1722 7.34642 6.90965 7.60893C6.6471 7.87144 6.4996 8.22748 6.4996 8.59872V11.3983C6.4996 11.7695 6.6471 12.1256 6.90965 12.3881C7.1722 12.6506 7.5283 12.7981 7.8996 12.7981H9.9996C10.3709 12.7981 10.727 12.6506 10.9896 12.3881C11.2521 12.1256 11.3996 11.7695 11.3996 11.3983V8.59872C11.3996 8.22748 11.2521 7.87144 10.9896 7.60893ZM12.0996 10.8118V9.18522L13.3134 8.03601C13.3879 7.96537 13.4815 7.91813 13.5826 7.90015C13.6837 7.88216 13.7879 7.89421 13.8822 7.9348C13.9765 7.9754 14.0569 8.04276 14.1133 8.12855C14.1697 8.21433 14.1997 8.31478 14.1996 8.41745V11.5795C14.1997 11.6822 14.1697 11.7827 14.1133 11.8684C14.0569 11.9542 13.9765 12.0216 13.8822 12.0622C13.7879 12.1028 13.6837 12.1148 13.5826 12.0968C13.4815 12.0789 13.3879 12.0316 13.3134 11.961L12.0996 10.8118Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
