import { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import { ENotificationAction } from 'src/enums'
import { ERoutes, generate } from 'src/router'
import { useQueryParams } from '../../../../hooks'

export const RedirectNotification: FC = () => {
  const { action } = useQueryParams()
  const history = useHistory()

  useEffect(() => {
    if (!action) return
    if (action === ENotificationAction.GO_TO_DASHBOARD) {
      history.push(generate(ERoutes.CAMPAIGN_MY_JOBS))
      return
    }

    if (action === ENotificationAction.GO_TO_PROFILE_SCREEN) {
      history.push(generate(ERoutes.SETTING))
      return
    }

    history.push(generate(ERoutes.CAMPAIGN_MY_JOBS))
  }, [action, history])

  return (
    <div>
      Please wait...
    </div>
  )
}
