/**
 * @deprecated
 */
export const LAYOUT_SET_PAGE_TITLE = Symbol('LAYOUT_SET_PAGE_TITLE')
export const LAYOUT_SET_SCROLL_TO_BOTTOM = Symbol('LAYOUT_SET_SCROLL_TO_BOTTOM')

/**
 * state
 */
export interface ILayoutState {
  /**
   * deprecated
   */
  pageTitle: string | null
  isScrollToBottom: boolean
}

/**
 * actions
 */
export interface ILayoutAction {
  type: typeof LAYOUT_SET_PAGE_TITLE | typeof LAYOUT_SET_SCROLL_TO_BOTTOM
  value?: boolean | string | null | void
}
