import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconMotivatorCreative = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 300 } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="300" height="257" viewBox="0 0 300 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="299.33" height="257" transform="translate(0 0.00195312)"/>
        <path d="M138.322 77.3574C137.909 76.1815 138.013 76.1815 137.69 75.1781C137.291 73.9479 123.503 74.6418 121.366 60.3807C117.401 60.3807 115.286 57.2711 118.324 55.9844C121.567 54.4403 121.361 51.5369 120.799 49.8212C119.36 45.4341 120.964 40.3259 121.169 39.8359" stroke="#0C0C0C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M124.921 53.7731C124.921 54.3002 125.029 54.7508 125.179 55.0497C125.253 55.1989 125.328 55.2905 125.386 55.3393C125.413 55.3627 125.433 55.3731 125.443 55.3774C125.453 55.3811 125.457 55.3811 125.457 55.3811C125.457 55.3811 125.458 55.3811 125.46 55.3808C125.462 55.3805 125.465 55.3798 125.471 55.3776C125.48 55.3733 125.501 55.3629 125.528 55.3395C125.586 55.2906 125.661 55.1987 125.736 55.0493C125.885 54.7502 125.994 54.2998 125.994 53.7731C125.994 53.2464 125.886 52.7959 125.736 52.4968C125.661 52.3475 125.586 52.2556 125.528 52.2066C125.501 52.1832 125.48 52.1728 125.471 52.1686C125.465 52.1665 125.462 52.1656 125.46 52.1654C125.459 52.165 125.457 52.165 125.457 52.165C125.457 52.165 125.453 52.1649 125.443 52.1688C125.432 52.1731 125.413 52.1835 125.386 52.2068C125.328 52.2556 125.253 52.3473 125.179 52.4965C125.029 52.7954 124.921 53.2459 124.921 53.7731Z" fill="#0C0C0C" stroke="#0C0C0C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M128.674 48.4119C127.78 47.6689 125.458 46.573 123.313 48.1333" stroke="#0C0C0C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M142.993 47.6221C143.78 47.6447 144.851 48.241 142.843 50.4388" stroke="#0C0C0C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M139.812 48.9863C130.794 47.9787 123.337 42.1286 121.169 39.3295C122.037 36.3904 125.072 30.0922 135.91 27.5732C145.881 25.2557 153.251 30.0922 156.286 34.2908C159.321 38.4895 158.888 43.9479 159.321 50.6656C159.755 57.3835 163.224 63.2614 167.126 66.6203C170.023 69.1152 177.386 71.2388 180.132 71.6587L174.496 78.7965C172.761 78.5166 169.539 78.1393 169.293 78.3766C168.859 78.7963 172.328 79.6361 173.629 80.0559L169.727 85.934C166.547 85.6541 158.021 83.4146 150.651 77.5367C143.367 71.7282 141.835 60.0427 142.413 54.4445C145.881 52.7651 148.916 46.887 145.448 44.7877C142.674 43.1083 140.535 46.887 139.812 48.9863Z" fill="#0C0C0C" stroke="#0C0C0C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M156.547 106.276C153.056 110.484 140.405 120.161 131.681 122.264C122.955 124.367 103.76 128.995 101.144 121.422C99.4733 116.591 104.343 114.551 106.378 114.27C112.486 113.569 126.707 110.484 134.735 103.752C139.209 100.001 142.264 95.204 144.218 90.9639" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M125.458 63.9567C125.994 64.4927 127.494 65.5648 129.21 65.5648C131.206 65.5648 132.426 63.4207 132.962 62.3486" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M138.322 77.0818C138.751 77.2894 140.252 77.5802 142.825 77.0818C145.398 76.5834 147.327 75.6282 147.971 75.2129" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M104.017 115.108C102.563 113.121 99.3061 108.925 97.9114 108.031C96.1661 106.914 91.3698 105.052 90.4971 104.307C89.6245 103.562 87.8803 105.797 90.0608 106.914C91.805 107.808 93.695 109.024 94.4219 109.522C93.5503 109.894 92.6777 110.341 90.9335 112.129C88.7529 114.363 89.1882 116.97 93.114 119.577C96.254 121.663 99.4465 122.755 101.337 122.383" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M89.18 104.588L73.2977 92.0756M73.2977 92.0756L68.1959 88.0781L66.4956 89.6771L71.5974 94.0743M73.2977 92.0756L71.5974 94.0743M71.5974 94.0743L91.1526 111.663" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M68.1037 88.0773C67.9675 85.6128 67.8206 81.3493 64.5409 79.501M54.7031 82.0887C57.1626 89.1124 63.8996 89.4389 66.4956 89.6854" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M58.0342 83.8552C57.3041 84.1703 55.6006 83.1989 54.8401 82.6738C53.9276 80.5735 52.65 74.6401 54.8401 67.709C55.6003 70.3344 57.6683 75.9002 59.8583 77.1604C62.5961 78.7357 65.3355 79.1296 66.7033 80.7047C67.6156 81.4923 68.0723 87.0058 66.7033 87.0058C65.3347 87.0058 65.3346 85.8244 63.9658 84.2491C62.597 82.6738 59.8592 80.7047 58.4904 80.7047C57.1215 80.7047 58.9467 83.4615 58.0342 83.8552Z" fill="#0C0C0C" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M138.322 77.3574C136.633 80.0198 134.358 84.1739 132.576 88.6139C131.795 90.5582 131.109 92.5574 130.609 94.5101C130.029 96.7685 129.696 98.9649 129.752 100.942C129.792 102.364 130.031 103.672 130.523 104.809L131.354 106.303" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M137.25 120.239L139.478 127.38C143.487 127.786 152.933 128.192 158.635 126.566C164.338 124.939 169.625 122.364 171.555 121.279L168.339 85.9336" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M24.3462 42.7159L19.4663 39.7695L19.3931 39.7252L75.3633 30.2549L83.2059 32.6788M87.4001 69.8528L83.2202 32.6794C64.0685 36.0025 25.4673 42.6483 24.2786 42.6483L38.6426 165.265L97.5845 153.8L93.2964 119.703M38.6699 165.265L33.1941 161.775L19.3987 39.7019M91.8287 105.268L91.0837 98.9871" stroke="#0C0C0C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M139.573 127.8L126.53 220.145C131.212 222.973 149.601 228.854 165.158 227.133C187.733 224.637 196.428 214.987 204.789 208.165L171.556 121.311" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M160.835 227.443L161.907 239.236" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M169.411 226.372C169.411 226.908 170.483 231.196 168.875 234.948C167.266 238.7 155.476 240.308 155.475 243.524C155.474 249.656 169.885 246.544 176.38 244.596C181.74 242.988 181.383 238.521 181.204 237.628L179.596 224.228" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M150.345 226.371C150.345 228.515 150.372 230.16 149.272 231.731C145.52 237.092 132.12 234.947 134.265 242.452C134.469 243.524 136.316 245.668 142.074 245.668C147.832 245.668 153.407 244.239 155.475 243.524" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M84.7202 73.0691C84.7202 72.8976 85.4353 72.283 85.7923 71.9971" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M88.4164 70.4559C92.9233 67.8019 105.595 64.7235 120.228 73.6405C128.235 78.5201 131.594 83.7813 132.607 88.3884M129.771 100.71C126.987 103.497 121.026 108.656 103.928 105.089C88.6587 101.904 84.0467 90.7581 83.6479 86.3793M87.4001 81.1095C87.6649 80.9768 88.9085 81.2262 89.5442 82.1816C90.1799 83.137 90.1424 84.2561 90.0105 84.3888" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M96.7174 83.3706C94.4061 84.7059 92.1773 82.536 91.3519 81.2842C90.8008 80.0322 89.2882 77.2783 87.6362 76.2768C85.5725 75.0248 85.1598 70.8518 86.8107 70.0172C88.4627 69.1826 87.2234 71.6864 90.9391 74.1901C94.6538 76.6939 99.6077 81.7014 96.7174 83.3706Z" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M86.0099 74.0572C85.5854 73.9224 84.568 73.5718 83.8905 73.248C83.2119 72.9244 82.1946 73.1132 81.77 73.248C81.205 73.3829 80.4986 74.0573 80.4986 74.8666C80.4986 75.8903 81.77 76.4852 81.77 76.4852C81.205 76.755 80.1587 77.5373 80.4986 78.5084C80.8373 79.4795 81.77 79.7223 82.1946 79.7223C81.6285 79.992 80.5833 80.7744 80.922 81.7456C81.2619 82.7166 82.4765 82.9595 83.0425 82.9595C82.618 83.0943 81.8547 83.6878 82.1946 84.9827C82.618 86.6013 86.0099 87.0059 87.7059 87.0059C89.4019 87.0059 90.6733 87.0058 91.0979 85.7918C91.5213 84.5779 89.4019 84.1732 88.1294 84.1734C86.0946 83.8496 85.5854 82.9594 85.5854 82.5547" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M82.0398 83.2533C83.5096 83.0068 88.2384 81.608 87.9178 80.1287C87.5973 78.6494 82.8406 79.6674 81.5038 80.0372C82.9736 79.7906 87.8374 78.3535 87.5169 77.17C87.1159 75.6907 82.1706 76.4512 80.9678 76.8211" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M102.409 77.8932C104.185 77.8932 105.625 76.4533 105.625 74.6771C105.625 72.9008 104.185 71.4609 102.409 71.4609C100.632 71.4609 99.1924 72.9008 99.1924 74.6771C99.1924 76.4533 100.632 77.8932 102.409 77.8932Z" fill="#0C0C0C" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M115.273 82.1823C117.049 82.1823 118.489 80.7423 118.489 78.9661C118.489 77.1899 117.049 75.75 115.273 75.75C113.497 75.75 112.057 77.1899 112.057 78.9661C112.057 80.7423 113.497 82.1823 115.273 82.1823Z" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M123.85 90.7584C125.626 90.7584 127.066 89.3185 127.066 87.5423C127.066 85.7661 125.626 84.3262 123.85 84.3262C122.074 84.3262 120.634 85.7661 120.634 87.5423C120.634 89.3185 122.074 90.7584 123.85 90.7584Z" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M119.561 101.478C121.338 101.478 122.777 100.038 122.777 98.262C122.777 96.4858 121.338 95.0459 119.561 95.0459C117.785 95.0459 116.345 96.4858 116.345 98.262C116.345 100.038 117.785 101.478 119.561 101.478Z" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M106.697 101.478C108.473 101.478 109.913 100.038 109.913 98.262C109.913 96.4858 108.473 95.0459 106.697 95.0459C104.921 95.0459 103.481 96.4858 103.481 98.262C103.481 100.038 104.921 101.478 106.697 101.478Z" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M95.9764 95.0465C97.7526 95.0465 99.1925 93.6066 99.1925 91.8304C99.1925 90.0542 97.7526 88.6143 95.9764 88.6143C94.2002 88.6143 92.7603 90.0542 92.7603 91.8304C92.7603 93.6066 94.2002 95.0465 95.9764 95.0465Z" stroke="#0C0C0C" strokeLinejoin="round"/>
        <path d="M193.841 36.0071C193.841 36.0071 194.429 33.4943 194.091 32.0412C193.752 30.5881 190.058 23.5116 193.664 18.4635C197.27 13.4153 202.082 10.4712 208.867 12.9612C215.651 15.4513 217.344 25.3734 214.165 29.6269C210.986 33.8803 205.835 35.341 204.864 36.1887C203.892 37.0364 201.332 39.9426 201.332 39.9426C201.332 39.9426 199.286 39.814 197.27 38.7998C195.253 37.7856 193.841 36.0071 193.841 36.0071Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M195.297 37.2713C195.297 37.2713 202.758 24.1628 199.462 20.3029C196.165 16.443 196.636 26.4333 202.729 27.8183C208.822 29.2034 210.956 26.509 210.235 25.0786C209.514 23.6481 205.746 24.0871 199.182 39.0271" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M192.413 37.8311C192.413 37.8311 193.884 40.0335 195.753 40.8508C197.622 41.6682 200.492 41.7591 200.492 41.7591" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M191.309 39.8594C191.309 39.8594 192.898 42.2737 194.797 43.0003C196.695 43.7268 199.609 43.9615 199.609 43.9615" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M247.305 69.0893C247.305 69.0893 232.403 61.704 229.801 65.1291C227.199 68.5541 240.749 80.6596 253.049 88.1627C265.35 95.6657 280.564 103.169 283.54 99.8079C286.516 96.447 272.613 85.5617 272.613 85.5617" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M244.933 71.6965C244.933 71.6965 237.314 67.9648 236.607 69.2813C235.899 70.5978 242.58 77.6299 255.109 85.133C267.639 92.636 275.839 96.5748 277.171 95.1085C278.503 93.6421 271.801 88.847 271.801 88.847" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M270.139 72.802C270.139 72.802 261.839 68.7505 254.985 73.155C249.498 76.6812 248.595 80.5957 248.595 80.5957" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M247.846 84.8555C247.846 84.8555 247.825 89.201 249.656 92.594C251.488 95.9869 254.672 98.4487 254.672 98.4487" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M271.697 77.3626C271.697 77.3626 263.247 77.2449 260.666 81.5476C258.086 85.8504 258.606 86.8886 258.606 86.8886" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M258.793 91.5234C258.793 91.5234 259.564 93.921 260.188 95.3874C260.812 96.8537 261.624 97.8384 261.624 97.8384" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M225.614 134.397L219.227 161.447C219.227 161.447 225.408 160.811 229.558 161.447C233.708 162.082 237.829 164.565 237.829 164.565C237.829 164.565 242.98 164.716 247.719 166.199C252.458 167.683 256.372 170.302 256.372 170.302L263.407 143.63C263.407 143.63 258.933 139.634 254.842 138.953C250.75 138.272 244.187 139.301 244.187 139.301C244.187 139.301 239.771 135.124 236.033 134.321C232.295 133.519 225.614 134.397 225.614 134.397Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M244.216 139.423L237.652 164.399" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M246.276 141.996C246.276 141.996 250.926 141.679 254.105 142.496C257.284 143.313 260.022 145.311 260.022 145.311" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M245.072 145.742C245.072 145.742 249.723 145.425 252.902 146.242C256.081 147.059 258.818 149.057 258.818 149.057" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M244.056 149.277C244.056 149.277 248.707 148.959 251.886 149.776C255.064 150.594 257.802 152.592 257.802 152.592" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M243.043 152.76C243.043 152.76 247.694 152.442 250.872 153.26C254.051 154.077 256.789 156.075 256.789 156.075" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M242.038 156.509C242.038 156.509 246.689 156.191 249.868 157.009C253.046 157.826 255.784 159.824 255.784 159.824" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M241.103 160.527C241.103 160.527 245.754 160.209 248.932 161.026C252.111 161.844 254.849 163.842 254.849 163.842" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M227.616 137.608C227.616 137.608 231.969 137.296 234.618 137.773C237.267 138.25 240.343 140.528 240.343 140.528" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M226.684 140.927C226.684 140.927 231.037 140.616 233.686 141.092C236.335 141.569 239.411 143.847 239.411 143.847" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M225.902 145.027C225.902 145.027 230.255 144.715 232.904 145.192C235.553 145.669 238.629 147.947 238.629 147.947" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M224.959 148.844C224.959 148.844 229.313 148.533 231.962 149.009C234.611 149.486 237.687 151.764 237.687 151.764" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M224.083 152.331C224.083 152.331 228.437 152.02 231.086 152.497C233.735 152.974 236.811 155.252 236.811 155.252" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M223.146 156.282C223.146 156.282 227.499 155.97 230.148 156.447C232.797 156.924 235.873 159.202 235.873 159.202" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M219.255 161.377L220.899 166.226C220.899 166.226 224.325 165.301 228.537 165.969C233.358 166.732 237.508 169.394 237.508 169.394C237.508 169.394 243.207 169.638 247.04 170.871C251.265 172.23 257.988 175.366 257.988 175.366L256.135 170.143" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M257.987 175.249L265.064 150.471L263.503 143.792" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M264.513 147.16C265.004 147.384 267.844 147.391 267.291 150.267C267.108 151.219 260.153 177.382 259.445 177.778C258.737 178.174 237.85 171.962 237.85 171.962C237.85 171.962 219.256 167.989 218.9 167.35C218.59 166.791 219.877 163.293 219.877 163.293L220.959 166.427C220.959 166.427 226.031 165.38 230.32 166.47C234.608 167.561 237.403 169.549 237.403 169.549C237.403 169.549 244.981 170.169 247.739 171.203C250.498 172.238 257.979 175.354 257.979 175.354L265.207 150.394L264.513 147.16Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M50.6874 212.159C50.6874 214.26 49.8532 216.276 48.368 217.763C46.8828 219.25 44.868 220.086 42.7664 220.089C40.6648 220.091 38.6482 219.259 37.1595 217.776C35.6709 216.292 34.832 214.279 34.8272 212.177C34.8223 210.075 35.6519 208.058 37.1337 206.567C38.6155 205.077 40.6283 204.236 42.7299 204.229C44.8315 204.221 46.85 205.049 48.3421 206.529C49.8341 208.009 50.6777 210.021 50.6873 212.122" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M37.3576 194.96L40.1674 189.255L45.3914 189.062L48.3052 194.842C48.3052 194.842 50.2825 195.624 51.3231 196.244C52.3638 196.865 54.1953 198.3 54.1953 198.3L60.6473 197.796L63.1033 201.832L60.2935 207.751C60.2935 207.751 60.7306 210.426 60.7306 212.064C60.7306 213.702 60.0854 216.881 60.0854 216.881L63.02 222.767L60.3976 227.017L54.3826 226.342C54.3826 226.342 53.0714 227.188 51.9891 227.777C50.9069 228.365 48.7215 229.2 48.7215 229.2L45.2236 235.202L40.3081 235.353L37.0998 229.722C37.0998 229.722 35.422 228.708 34.4213 228.178C33.4205 227.649 31.4779 226.347 31.4779 226.347L24.7669 226.892L22.1767 222.699L25.2673 216.932C25.2673 216.932 24.6492 213.798 24.6786 212.315C24.7081 210.832 25.2967 207.638 25.2967 207.638L21.9707 202.052L24.4432 198.147L31.419 198.404C31.419 198.404 33.0673 197.224 34.4213 196.497C35.7753 195.77 37.3576 194.96 37.3576 194.96Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M86.3339 201.04C86.3339 202.384 85.8007 203.672 84.8513 204.623C83.902 205.573 82.6142 206.108 81.2709 206.109C79.9276 206.111 78.6386 205.579 77.6871 204.631C76.7356 203.683 76.1994 202.395 76.1963 201.052C76.1932 199.709 76.7235 198.419 77.6706 197.467C78.6177 196.514 79.9043 195.976 81.2476 195.972C82.5909 195.967 83.8811 196.496 84.8348 197.442C85.7885 198.388 86.3276 199.674 86.3338 201.017" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M77.8136 190.047L79.6095 186.4L82.9486 186.277L84.8111 189.972C84.8111 189.972 86.0749 190.471 86.74 190.868C87.4052 191.265 88.5759 192.181 88.5759 192.181L92.6999 191.86L94.2697 194.439L92.4737 198.222C92.4737 198.222 92.7531 199.933 92.7531 200.979C92.7531 202.026 92.3407 204.058 92.3407 204.058L94.2165 207.821L92.5403 210.537L88.6956 210.106C88.6956 210.106 87.8575 210.646 87.1658 211.023C86.474 211.399 85.0771 211.933 85.0771 211.933L82.8413 215.769L79.6994 215.866L77.6487 212.266C77.6487 212.266 76.5764 211.618 75.9367 211.28C75.297 210.941 74.0553 210.109 74.0553 210.109L69.7658 210.457L68.1102 207.777L70.0857 204.091C70.0857 204.091 69.6906 202.088 69.7094 201.14C69.7281 200.192 70.1045 198.15 70.1045 198.15L67.9785 194.58L69.5589 192.084L74.0177 192.248C74.0177 192.248 75.0713 191.494 75.9367 191.029C76.8021 190.565 77.8136 190.047 77.8136 190.047Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M54.3262 70.9607C54.3262 70.9607 55.1171 61.531 58.2806 54.9271C61.4442 48.3231 65.6901 43.1213 66.7515 43.5173C67.813 43.9133 62.7762 50.924 64.0666 51.5127C65.357 52.1014 65.7941 48.5158 67.0637 48.9974C68.3333 49.4791 64.3788 57.1962 63.5255 60.8139C62.6722 64.4316 61.8605 72.6732 60.8406 72.5555C59.8208 72.4377 60.6325 68.6595 60.1746 68.4775C59.7167 68.2955 60.0081 70.9393 59.3005 70.8857C58.5928 70.8322 59.4878 63.479 58.4471 63.6289C57.4065 63.7787 55.9912 70.7252 55.4084 71.0356C54.8257 71.346 54.3262 70.9607 54.3262 70.9607Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M242.267 75.8484C242.267 75.8484 249.93 60.7662 264.056 67.6741C277.924 74.4554 271.024 91.3034 269.321 92.6088" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M240.982 80.084C240.982 80.084 238.703 91.38 248.939 97.2123C258.241 102.513 266.163 95.8193 266.163 95.8193" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </IconWrapper>
  )
})
