import { SxProps } from '@mui/material'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import { useAsRef } from 'src/hooks'
import { ITab } from 'src/interfaces'
import { LocalStorage } from 'src/utils'
import { STContainer, STTab, STTabs } from './styled'

const TABS_PREV_TAB = '@tabs:prevTab'

interface IProps {
  tab?: number
  tabs: ITab[]
  setTab?: (value: number) => void
  sx?: SxProps
  variant?: 'standard' | 'scrollable' | 'fullWidth'
}

export const Tabs: FC<IProps> = memo((props) => {
  const setTabRef = useAsRef(props.setTab)
  const [tab, setTab] = useState(LocalStorage.getItem<number>(TABS_PREV_TAB) || 0)
  const variant = props.variant || 'fullWidth'

  const handleChange = useCallback(
    (event: React.SyntheticEvent, value: number) => {
      LocalStorage.setItem(TABS_PREV_TAB, tab)

      setTab(value)
      setTabRef.current?.(value)
    },
    [setTabRef, tab]
  )

  useEffect(() => {
    setTab(props.tab || 0)

    return () => {
      LocalStorage.removeItem(TABS_PREV_TAB)
    }
  }, [props.tab])

  return (
    <STContainer>
      <STTabs
        value={tab}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant={variant}
        sx={props.sx}
      >
        {props.tabs.map((tab, index) => (
          <STTab key={index} label={tab.title}/>
        ))}
      </STTabs>
    </STContainer>
  )
})
