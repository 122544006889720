import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconZeroApplicant = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 120, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_12904_63376)">
          <path d="M33.62 82.7401C33.62 82.7401 32.52 17.9801 36.63 16.6101C40.74 15.2401 77.8 12.8201 78 15.6701C78.2 18.5201 72.37 35.7401 80.43 41.3401C88.49 46.9401 91.18 43.4201 91.49 49.6801C91.8 55.9401 93.13 80.0001 91.49 81.5701C89.85 83.1401 73.28 90.5801 74.45 86.0801C75.62 81.5801 64.26 74.6201 56.6 74.6201C48.94 74.6201 33.63 82.7401 33.63 82.7401H33.62Z" fill="#AFF6C5" stroke="#AFF6C5" stroke-width="3" stroke-linecap="round"/>
          <path d="M30.1697 82.46C26.8697 82 19.0897 80.31 17.2997 84.44C16.1897 86.99 16.6097 91.02 16.4797 93.76C16.3397 96.95 15.6097 101.11 16.3597 104.23C17.5597 109.15 25.4897 107.44 29.3197 107.54C37.6697 107.77 46.0197 108.14 54.3697 108.21C68.1997 108.32 82.7397 102.65 95.5397 97.86C100.03 96.18 104.76 93.5 106.37 88.66C107.59 84.99 106.4 80.73 102.45 79.51C99.5197 78.6 96.2497 79.6 93.3897 80.27C89.2297 81.25 85.0697 82.23 80.9197 83.25C77.1797 84.17 73.5797 85.58 69.8497 86.48C65.5397 87.51 60.7797 86.97 56.3697 86.92C55.1497 86.91 54.0397 89 55.5897 89.01C60.3397 89.05 65.4797 89.66 70.1297 88.5C76.6397 86.88 83.0297 84.77 89.5797 83.2C92.5297 82.5 95.9297 81.16 98.9997 81.34C108.26 81.89 103.79 91.27 99.2097 94.05C93.4397 97.54 85.8797 98.97 79.4997 101.03C72.8797 103.16 66.0997 105.49 59.1197 106.01C51.8097 106.56 44.2497 105.82 36.9297 105.62C33.6697 105.53 30.4097 105.44 27.1597 105.35C25.5197 105.31 22.8797 105.6 21.2997 105.19C18.3497 104.42 18.6497 102.92 18.6497 100.25C18.6497 96.67 18.7897 93.09 19.0397 89.52C19.2897 85.95 18.5297 84.65 23.8597 84.03C25.6297 83.82 27.6497 84.28 29.3897 84.53C30.6197 84.7 31.7197 82.66 30.1697 82.44V82.46Z" fill="black"/>
          <path d="M73.6603 87.1399C76.2003 84.8199 77.7703 81.4399 76.0903 78.0699C73.7903 73.4799 67.4203 74.0499 63.1703 73.8299C57.8003 73.5399 51.3303 72.5899 46.0303 73.8599C39.7503 75.3599 36.7003 82.2099 29.6903 82.3799C28.3503 82.4099 27.8803 84.4999 29.5003 84.4599C37.0403 84.2799 40.5903 76.9499 47.5003 75.7199C51.8103 74.9599 57.0203 75.65 61.4103 75.87C63.3603 75.97 65.3403 75.9999 67.2703 76.2199C72.0003 76.7499 78.0603 80.4899 72.1103 85.9299C70.9003 87.0299 72.6003 88.1199 73.6603 87.1499V87.1399Z" fill="black"/>
          <path d="M28.8899 84.12C29.4899 91.43 28.2699 98.84 28.6799 106.17C28.7599 107.63 31.0899 107.06 31.0299 105.85C30.6099 98.33 31.8199 90.76 31.2099 83.27C31.0999 81.95 28.7899 82.9 28.8899 84.11V84.12Z" fill="black"/>
          <path d="M91.9403 22.4798C91.0803 21.9298 89.6903 21.3998 88.6703 21.7898C87.0503 22.3998 86.2503 24.0398 86.0203 25.6698C85.5003 29.2298 86.4203 35.0798 89.9603 37.0698C95.1803 39.9898 95.9103 24.8398 91.6803 22.4898" stroke="#AFF6C5" stroke-width="3" stroke-linecap="round"/>
          <path d="M107.12 18.1799C103.61 13.2199 99.2401 9.68988 92.8001 10.2199C86.1801 10.7699 79.0701 12.9599 75.3001 18.7799C74.5801 19.8899 73.9701 21.1499 73.5501 22.4199C73.5001 22.7199 73.4301 23.0099 73.3701 23.3099C73.1201 24.0799 72.9301 24.8499 72.7901 25.6399C72.7201 26.0099 72.6701 26.3799 72.6201 26.7499C72.6001 26.8699 72.5701 26.9699 72.5401 27.0799C72.5201 27.2999 72.51 27.5199 72.5 27.7399C72.5 27.8699 72.4801 27.9999 72.4801 28.1299C72.4801 28.3599 72.4801 28.5899 72.4801 28.8199C72.4801 29.1799 72.45 29.5399 72.46 29.9099C72.46 29.9999 72.49 30.0699 72.52 30.1499C72.61 31.4599 72.8301 32.7599 73.1901 34.0199C74.1301 38.2699 76.43 42.1099 80.25 43.8799C80.43 43.9799 80.5901 44.0699 80.6601 44.0999C82.4101 44.8899 84.41 45.2699 86.35 45.3599C87.07 45.3499 87.78 45.3699 88.49 45.3999C88.53 45.3999 88.56 45.3899 88.6 45.3799C91.03 45.5499 93.6101 44.9399 96.0101 44.2099C96.4101 44.1099 96.81 44.0199 97.21 43.8999C97.34 43.8599 97.44 43.7999 97.53 43.7299C97.75 43.6599 97.9801 43.5799 98.1901 43.5099C98.2501 43.4899 98.29 43.4499 98.34 43.4299C101.86 42.1199 105.06 40.1099 107.26 37.0699C111.79 30.8099 111.53 24.3699 107.14 18.1699L107.12 18.1799ZM102.67 38.7599C97.86 42.8199 88.32 44.2199 82.45 42.1899C80.85 41.6399 78.55 40.5499 77.5401 39.1799C73.1301 33.1999 73.7601 24.3099 78.1901 18.5199C81.3301 14.4199 88.34 12.5599 93.31 12.3499C97.11 12.1899 99.98 13.4999 102.67 16.0699C109.61 22.6899 110.27 32.3399 102.67 38.7599Z" fill="black"/>
          <path d="M90.2205 17.4898C84.9005 18.0898 84.7205 25.7898 85.3505 29.7498C85.9605 33.5798 87.4305 38.2998 92.2105 37.9198C96.1805 37.5998 97.4305 33.2498 97.5505 29.9098C97.7105 25.6098 96.1105 16.8298 90.2105 17.4898H90.2205ZM95.0405 34.2298C93.4505 37.5898 89.6605 37.6298 87.8405 34.4498C86.4305 31.9998 86.0205 27.0698 86.2105 24.2698C86.3605 21.9998 88.0105 18.4798 90.6305 18.3598C92.1805 18.2898 93.7405 19.7398 94.5005 20.9598C96.3805 23.9798 96.5505 31.0398 95.0405 34.2298Z" fill="black"/>
          <path d="M90.9596 21.4398C90.4896 21.5098 90.0296 21.9498 89.7296 22.2898C88.1196 24.1798 87.9396 28.3598 88.5196 30.6698C88.9396 32.3398 91.1296 34.2498 92.5796 32.5098C94.1896 30.5798 93.6696 26.9798 93.1496 24.6998C92.9096 23.6398 92.4196 21.2198 90.9496 21.4298L90.9596 21.4398ZM92.4796 30.5998C92.3496 31.2298 92.0596 32.3398 91.3296 32.4698C90.2296 32.6698 89.7196 31.4998 89.5296 30.6098C89.1796 28.9598 89.0596 25.0798 89.8196 23.5898C89.9696 23.2898 90.7496 22.3098 91.0696 22.3198C91.3196 22.3298 91.4696 22.8698 91.5596 23.0798C92.2196 24.7398 92.8096 28.8798 92.4696 30.5898L92.4796 30.5998Z" fill="black"/>
          <path d="M31.3205 82.5799C31.4305 66.1499 31.9505 49.7199 32.9305 33.3199C33.2005 28.7399 32.2005 21.8199 38.2205 20.0299C41.7805 18.9699 46.1505 19.6599 49.8105 19.7199C58.3205 19.8699 66.8305 20.0199 75.3405 20.1699C76.5605 20.1899 77.6705 18.1099 76.1205 18.0799C65.8605 17.8999 55.5705 17.4999 45.3105 17.5499C40.1305 17.5799 33.2905 17.2899 31.5305 23.2699C30.4005 27.0999 30.7005 31.6499 30.4705 35.5999C30.1705 40.8499 29.9105 46.0999 29.7005 51.3499C29.2805 61.8599 29.0505 72.3699 28.9805 82.8899C28.9805 84.3599 31.3205 83.7899 31.3305 82.5699L31.3205 82.5799Z" fill="black"/>
          <path d="M88.3798 44.93C87.7498 57.2 87.3798 69.43 87.7398 81.72C87.7798 83.19 90.1198 82.61 90.0898 81.4C89.7298 69.11 90.0898 56.88 90.7298 44.61C90.8098 43.15 88.4498 43.72 88.3798 44.93Z" fill="black"/>
        </g>
        <defs>
          <clipPath id="clip0_12904_63376">
            <rect width="120" height="120" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  )
})
