import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { catchError, EMPTY, from, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { Textarea } from 'src/components/textarea'
import { useUnsubscribe } from 'src/hooks'
import { IconEmailFilled } from 'src/icons'
import { IconLinkedInStroke } from 'src/icons/icon-linkedin-stroke'
import { IconLocationStroke } from 'src/icons/icon-location-stroke'
import { IconPhoneStroke } from 'src/icons/icon-phone-stroke'
import { ICandidateModel } from 'src/interfaces'
import { SnackbarService } from 'src/services'
import {
  convertPhoneNumToInternationalFormat,
  removePrefixPhoneNumber
} from 'src/utils/helpers.utils'
import { ReactionUtils } from 'src/utils/reaction.utils'
import { getLocation } from 'src/utils/user.utils'

interface IProps {
  talent: Partial<ICandidateModel>
}

export const Detail: FC<IProps> = ({
  talent
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [privateNotes, setPrivateNotes] = useState<string>('')

  const isEmphasis = useMemo(
    () => ReactionUtils.isEmphasis(talent?.reaction),
    [talent?.reaction]
  )
  const isContact = useMemo(
    () => Boolean(talent?.userPhoneNo || talent?.email || talent?.linkedInUrl),
    [talent?.email, talent?.linkedInUrl, talent?.userPhoneNo]
  )

  const onSavePrivateNotes = useCallback(() => {
    if (!talent?.id) return

    from(CandidateApi.note(talent.id, { content: privateNotes }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        })
      )
  }, [talent?.id, privateNotes, unsubscribe$])

  useEffect(() => {
    setPrivateNotes(talent?.privateNote?.content || '')
  }, [talent])

  return (
    <div className="w-100-p round-3" style={{ border: '1px solid #D3D3D3' }}>
      {isEmphasis && (
        <div className="fx fx-column gap-6 p-6">
          {isContact && (
            <div className="fx fx-column gap-3 txt-grey-01">
              <div className="fw-500 txt-black-01">Contact</div>

              {talent?.userPhoneNo && (
                <div className="fx fx-ai-center gap-2">
                  <IconPhoneStroke/>
                  <div className="fs-12 three-dot-1">
                    {convertPhoneNumToInternationalFormat(removePrefixPhoneNumber(talent?.userPhoneNo))}
                  </div>
                </div>
              )}

              {talent?.email && (
                <div className="fx fx-ai-center gap-2">
                  <IconEmailFilled/>
                  <div className="fs-12 three-dot-1">{talent?.email}</div>
                </div>
              )}

              {talent?.linkedInUrl && (
                <div className="fx fx-ai-center gap-2">
                  <IconLinkedInStroke/>
                  <div className="fs-12 three-dot-1">{talent?.linkedInUrl}</div>
                </div>
              )}

              {getLocation(talent) && (
                <div className="fx fx-ai-center gap-2">
                  <IconLocationStroke/>
                  <div className="fs-12 three-dot-1">{getLocation(talent)}</div>
                </div>
              )}
            </div>
          )}

          {/* {talent?.resume?.url && (
            <div className="fx fx-column gap-3">
              <div className="fw-500 txt-black-01">Resume</div>
              <div
                className={clsx('fx gap-2 w-fit px-5 py-3 round-3 pointer', Style.resume)}
                onClick={() => FileUtils.download(
                  talent.resume?.url || '',
                  talent.resume?.filename
                )}
              >
                <IconFile size={16}/>
                <div className="fs-12 txt-grey-01 ">
                  {talent.resume?.filename}
                </div>
              </div>
            </div>
          )} */}

          <div>
            <div className="fs-16 fw-500 txt-black-01 mb-3">Private Notes</div>
            <Textarea
              label=""
              placeholder="Only you can see your private notes"
              value={privateNotes}
              autoComplete="off"
              disableResize
              minRows={10}
              maxRows={15}
              style={{ marginTop: 8, fontSize: '14px', background: 'transparent' }}
              onChange={(event) => setPrivateNotes(event.target.value)}
              onBlur={onSavePrivateNotes}
            />
          </div>
        </div>
      )}
    </div>
  )
}
