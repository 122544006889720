import clsx from 'clsx'
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { CampaignApi } from 'src/api'
import { Button, Switch, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useBehaviorMapper } from 'src/hooks'
import { TourPopper } from 'src/partials/tour'
import { CampaignMutationService, CampaignService, SnackbarService } from 'src/services'
import { CreateJobTourService, TARGET_POST_JOB, TARGET_SHOW_ON_KNOWME } from 'src/services/tour/create-job-tour.service'
import { getApiErrorMessage } from 'src/utils'
import { DialogArchiveCancel } from '../dialog-archive-cancel'
import { TooltipPostToKnowme } from '../tooltip-post-to-know-me'
import Style from './style.module.scss'

interface IProps {
  onSubmit?: () => void
}

export const ActionButtons: FC<IProps> = ({ onSubmit }) => {
  const router = useHistory()
  const { eventHandler } = useAnalytic()
  const [openArchive, setOpenArchive] = useState(false)
  const { campaignId } = useParams<{ campaignId: string }>()
  const tourEnabled = useBehaviorMapper(CreateJobTourService.enableTour$)
  const currentTourStep = useBehaviorMapper(CreateJobTourService.currentStep$)

  const data = useBehaviorMapper(CampaignMutationService.data$)

  const previewEnabled = useMemo(
    () => CampaignService.isDataValid(data),
    [data]
  )

  const goToPreview = useCallback(() => {
    if (!data.jobSummary && !data.id) {
      CampaignApi.fetchSummary({
        jobTitle: data.jobTitle,
        jdFile: data.jdFile,
        jdFileUrl: data.jdFileUrl ?? undefined,
        location: data.location,
        briefDescription: data.briefDescription,
        salaryRange: data.salaryRange,
        salaryRate: data.salaryRate,
        salaryValue: data.salaryValue
      }).then(res => {
        CampaignMutationService.patchData({ jobSummary: res.data.summary })
      })
    }

    router.push('/campaigns/summary/preview')
    eventHandler(ETrackingEvent.CAMPAIGN_COMPANY_JOB_PREVIEW)()
  }, [eventHandler, router, data])

  const handlePrimaryButtonClick = useCallback(() => {
    if (tourEnabled && CreateJobTourService.isLastStep(currentTourStep)) {
      const fieldError = CreateJobTourService.validateBeforeCreatingJob()
      if (fieldError !== null) {
        SnackbarService.error(<span><span className="meta-bold color-negative-400">[{fieldError}]</span> is missing.</span>)
        return
      }
    }

    if (onSubmit) {
      eventHandler(ETrackingEvent.CAMPAIGN_COMPANY_JOB_SUBMIT)()
    }

    return onSubmit?.()
  }, [currentTourStep, eventHandler, onSubmit, tourEnabled])

  const handleArchive = useCallback(() => {
    CampaignMutationService.archive(+campaignId)
      .then(() => router.push('/campaigns'))
      .catch((error) => SnackbarService.error(getApiErrorMessage(error)))
  }, [campaignId, router])

  const handleOnCheckJobMarketplace = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    CampaignMutationService.patchData({ shareOnMarket: e.target.checked })
    eventHandler({
      key: ETrackingEvent.CAMPAIGN_INPUT_JOB_MARKETPLACE,
      contextData: { shareOnMarket: e.target.checked }
    })()
  }, [eventHandler])

  return (
    <>
      <div className={Style.actionWrapper}>

        <div className={Style.switchContainer}>
          <Switch
            htmlId={TARGET_SHOW_ON_KNOWME.getValue()}
            label="Post To KnowMe Jobs Marketplace"
            labelClassName={clsx(Style.switchLabel, 'body2-medium color-neutral-theme-900')}
            switchClassName={Style.switch}
            checked={data.shareOnMarket}
            onChange={handleOnCheckJobMarketplace}
          />
          <TooltipPostToKnowme/>
          {tourEnabled && currentTourStep === 3 && (<TourPopper {...CreateJobTourService.getStepConfig()}/>)}
        </div>

        <div className={Style.right}>
          <Button className={Style.button} variant="secondary" disabled={!previewEnabled} onClick={goToPreview}>Preview</Button>
          {!campaignId && (
            <Button
              className={Style.button}
              id={TARGET_POST_JOB.getValue()}
              onClick={handlePrimaryButtonClick}
            >
              Create Job
            </Button>
          )}

          {tourEnabled && currentTourStep === 4 && (<TourPopper {...CreateJobTourService.getStepConfig()}/>)}
          <DialogArchiveCancel
            type="archive"
            open={openArchive}
            onClose={() => setOpenArchive(false)}
            onAgree={handleArchive}
          />
        </div>
      </div>
    </>
  )
}
