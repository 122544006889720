import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconMotivatorCompetitive = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 300 } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="300" height="257" viewBox="0 0 300 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="299.33" height="257" transform="translate(0 0.00195312)"/>
        <g clipPath="url(#clip0_5967_92492)">
          <path d="M19.0074 137.968L18.9946 137.973C18.1099 137.82 17.7669 137.432 17.6108 137.065C17.4304 136.642 17.4526 136.143 17.4933 135.712C17.6852 135.002 18.0665 134.461 19.0323 133.448C19.3559 133.108 19.7487 132.712 20.2172 132.24C21.1418 131.307 22.3618 130.077 23.9288 128.395C26.3531 125.793 27.4727 123.424 27.9231 121.337C28.3723 119.257 28.1463 117.496 27.9445 116.149L27.9356 116.089L27.9129 116.034C26.9344 113.639 27.0017 111.13 27.4507 109.043C27.8137 107.355 28.4167 105.986 28.8753 105.218C30.7613 106.889 33.6931 108.517 36.737 109.979C39.423 111.268 42.2427 112.45 44.5851 113.431C45.0702 113.634 45.5349 113.829 45.9737 114.014C54.6792 127.861 47.7101 140.578 43.1495 145.959C42.3619 146.651 41.4095 146.706 40.5284 146.506C39.8787 146.359 39.3108 146.08 38.9612 145.869C38.9706 145.837 38.9795 145.804 38.9879 145.772C39.0428 145.561 39.0911 145.306 39.1339 145.018C39.2198 144.442 39.2889 143.702 39.3412 142.854C39.4459 141.156 39.4851 138.994 39.4496 136.787C39.4141 134.579 39.3037 132.316 39.1073 130.415C39.0092 129.466 38.8889 128.6 38.744 127.873C38.6011 127.157 38.4275 126.539 38.2085 126.11L37.5273 124.773L37.2704 126.251C37.0275 127.648 36.18 128.959 34.8931 130.184C33.6075 131.407 31.9148 132.515 30.0485 133.506C26.3147 135.489 21.9719 136.963 19.0074 137.968L19.0074 137.968Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M14.7061 212.184C17.7971 210.734 25.3212 207.928 30.6894 208.298C31.4505 208.328 32.5999 208.65 31.1088 209.686C29.6176 210.723 28.872 211.26 28.6857 211.398C27.9091 212.046 27.1478 213.415 30.3166 213.711C34.2775 214.081 45.4613 210.056 67.5956 213.016" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M28.9795 104.709C38.3467 92.3055 50.7088 88.5526 57.9731 86.2627" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M57.9727 86.2627C58.6312 89.0191 60.5853 92.2419 64.09 91.7967" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M64.0901 91.8604C64.1963 92.7721 64.1539 96.4402 62.4971 100.638" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M56.189 107.063C52.4294 110.753 51.7921 111.389 46.4395 113.87" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M62.9209 101.784C64.9826 100.002 71.3548 97.3945 75.0507 98.9847" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M57.5903 93.7051C62.3695 97.2672 61.796 99.5571 63.5802 103.437" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M75.0502 98.9834C75.1776 100.446 74.7988 102.686 73.6483 104.327C72.7561 105.599 69.3789 108.079 66.4476 109.034C65.4356 109.363 60.0331 111.105 57.9088 108.906C56.1883 107.125 53.6394 103.754 51.9189 100.701" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M64.2178 93.7686C64.9612 94.8923 68.844 96.631 78.9376 95.1043" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M78.9375 95.168C79.0007 95.5709 79.0892 96.402 78.9375 97.0126" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M74.9873 98.9853C75.5184 98.858 76.8735 98.3237 78.046 97.4587C79.2185 96.5936 81.3383 96.971 82.2517 97.2678C82.8676 97.4374 83.73 98.0947 82.2517 99.3669" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M79.5747 98.3488C80.6793 98.3276 82.7226 98.5905 82.0599 99.8118C81.7413 100.321 81.0276 100.283 80.2119 100.13" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M78.3644 99.8116C79.214 99.6631 80.2123 99.8116 80.6584 100.638C80.9357 101.153 80.7858 101.529 80.4035 101.674C79.8937 101.783 79.9806 101.518 79.5114 101.338C79.2352 101.253 78.6193 101.325 78.3644 101.783L76.5801 103.119C76.0279 103.543 74.6685 103.755 74.0312 103.883" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M78.9375 95.1047C79.0649 95.1047 81.3589 94.8503 82.506 93.9597C83.4236 93.2473 85.5222 93.5357 86.4567 93.7689C86.8815 93.9174 87.5782 94.3923 86.9665 95.1047" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M82.8894 95.7977C83.7815 95.8683 84.7374 95.4486 84.7374 96.3772C84.7374 97.2041 83.7815 96.9399 83.3992 96.8224C83.123 96.7376 82.8002 96.7334 82.1885 97.1405" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M84.3545 94.5332C85.2253 94.5332 86.9034 94.7622 86.6485 95.6782C86.3936 96.5941 85.0554 95.9231 84.3545 95.7958" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M82.0173 61.8026C82.1129 62.7384 82.4308 63.8743 83.2791 65.1735L83.6977 64.9001L83.2791 65.1735C84.218 66.6113 84.5989 68.1042 84.1989 69.3562C83.8067 70.5838 82.6047 71.7546 79.9761 72.4342L79.9761 72.4342C78.958 72.6974 77.2599 72.8535 75.5335 72.4499C73.8188 72.049 72.099 71.1035 70.9585 69.1789L70.4807 68.3725L70.0771 69.2185C69.3369 70.7703 67.999 73.1329 66.4023 75.1608C65.6041 76.1746 64.7514 77.0916 63.8872 77.7809C63.2323 78.3032 62.5906 78.6789 61.9773 78.8777C61.9594 78.3038 61.8384 77.6399 61.5226 77.056C61.2904 76.6267 60.9482 76.2332 60.4603 75.9638C60.1049 75.7676 59.69 75.647 59.2134 75.6154C59.3721 73.2475 60.1039 70.0269 61.8086 67.4666C62.7209 66.0965 63.9037 64.9271 65.4161 64.1681C66.9251 63.4107 68.7913 63.0471 71.0983 63.3307C71.7768 63.4141 72.656 63.6245 73.5803 63.8456C73.8412 63.908 74.1056 63.9713 74.3701 64.0328C74.9592 64.1698 75.544 64.2964 76.0638 64.3751C76.5712 64.4519 77.0685 64.4918 77.4613 64.4247C78.8155 64.1934 79.8679 63.5746 80.7225 62.9149C81.148 62.5865 81.5291 62.244 81.8701 61.9357L81.9135 61.8964C81.9486 61.8647 81.9832 61.8334 82.0173 61.8026Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M59.0763 75.8154C58.0308 76.7488 56.3258 79.4779 59.031 81.5731C58.8632 82.8445 59.3057 85.937 62.418 88.136C66.3084 90.8845 69.5443 91.6183 71.4779 91.0452C73.4115 90.4721 76.5342 88.1664 77.7867 85.3466" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M76.0859 84.6712L78.3488 85.3285C78.8668 85.1283 80.1536 84.0422 78.7749 83.0962C78.2544 82.7955 77.167 81.9557 76.9821 81" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M79.9717 72.8174C80.1454 73.3063 80.3542 74.5112 79.7998 75.4197" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M70.2812 76.5067C70.4971 76.2707 71.0926 75.7472 71.7464 75.541" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M73.3465 79.5991C73.2886 79.7577 73.2001 79.8642 73.1287 79.9165C73.1189 79.9237 73.1103 79.9293 73.1028 79.9338C73.1001 79.9258 73.0973 79.9162 73.0945 79.9047C73.0736 79.8191 73.0745 79.681 73.1324 79.5224C73.1903 79.3639 73.2788 79.2574 73.3502 79.2051C73.36 79.1979 73.3686 79.1922 73.3761 79.1878C73.3788 79.1958 73.3817 79.2054 73.3845 79.2169C73.4053 79.3024 73.4044 79.4406 73.3465 79.5991ZM73.0653 79.9497C73.0653 79.9495 73.0667 79.9491 73.0695 79.9491C73.0666 79.9498 73.0652 79.9498 73.0653 79.9497ZM73.1183 79.967C73.1205 79.9688 73.1213 79.97 73.1212 79.9702C73.1212 79.9703 73.1201 79.9694 73.1183 79.967ZM73.4137 79.1719C73.4137 79.172 73.4122 79.1724 73.4094 79.1724C73.4123 79.1717 73.4137 79.1717 73.4137 79.1719ZM73.3606 79.1545C73.3585 79.1527 73.3576 79.1515 73.3577 79.1514C73.3578 79.1512 73.3588 79.1521 73.3606 79.1545Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M79.9183 78.9651L79.9183 78.965C79.9431 78.8972 79.954 78.8356 79.9566 78.7841C79.9251 78.8254 79.8934 78.8799 79.8685 78.9481L79.9183 78.9651ZM79.9183 78.9651C79.8934 79.0333 79.8617 79.0878 79.8302 79.129M79.9183 78.9651L79.8302 79.129M79.8302 79.129C79.8328 79.0776 79.8437 79.0159 79.8684 78.9482L79.8302 79.129ZM80.0596 78.6917C80.0596 78.6918 80.0585 78.6923 80.0564 78.6928C80.0586 78.6918 80.0596 78.6916 80.0596 78.6917ZM79.9394 78.6499C79.9382 78.6482 79.9377 78.6472 79.9378 78.6471C79.9378 78.647 79.9384 78.6479 79.9394 78.6499ZM79.8491 79.2662C79.849 79.2662 79.8484 79.2654 79.8475 79.2633C79.8486 79.265 79.8491 79.2661 79.8491 79.2662ZM79.7304 79.2204C79.7282 79.2213 79.7272 79.2216 79.7272 79.2215C79.7272 79.2214 79.7283 79.2209 79.7304 79.2204Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M74.769 80.1953C74.1689 81.8382 72.5948 82.6741 71.3138 82.2078C70.0329 81.7416 69.3675 80.0904 69.9676 78.4475C70.5678 76.8047 72.1418 75.9686 73.4228 76.4348C74.7037 76.9012 75.3691 78.5524 74.769 80.1953Z" stroke="#0C0C0C"/>
          <path d="M80.8582 79.3566C80.5632 80.1643 80.0539 80.7943 79.4834 81.1657C78.9128 81.5368 78.3 81.6402 77.7647 81.4453C77.2293 81.2505 76.827 80.7776 76.6298 80.1269C76.4325 79.4761 76.449 78.6667 76.7442 77.8591C77.0392 77.0514 77.5484 76.4215 78.1191 76.0501C78.6896 75.6789 79.3024 75.5756 79.8378 75.7704C80.3731 75.9653 80.7754 76.4381 80.9726 77.0888C81.1698 77.7397 81.1533 78.549 80.8582 79.3566Z" stroke="#0C0C0C"/>
          <path d="M75.083 78.5889L76.4939 78.3799" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M77.9248 75.0702C78.1571 74.84 78.7557 74.4398 79.2913 74.6792" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M65.4907 76.9834L69.7571 78.7029" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M59.8681 78.2129C59.5868 78.4251 59.0961 78.9868 59.3849 79.536" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M64.1544 89.3799L62.9437 91.7971L64.1544 89.3799ZM60.2674 86.0723L58.6743 88.1077L60.2674 86.0723Z" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M173.597 190.941L173.596 190.962L173.597 190.983C173.66 192.428 173.699 196.874 173.343 203.001C173.166 206.042 172.709 208.249 172.105 209.731C171.501 211.213 170.796 211.864 170.178 212.026C169.198 212.154 167.789 212.117 166.683 211.634C166.133 211.394 165.679 211.053 165.376 210.589C165.077 210.129 164.899 209.507 164.968 208.651C165.164 206.216 164.772 202.952 164.383 200.051C164.337 199.706 164.291 199.367 164.245 199.034C164.098 197.954 163.961 196.944 163.863 196.058C163.734 194.885 163.683 193.993 163.753 193.47L163.753 193.47C164.03 191.41 163.103 190.066 162.491 189.598L157.072 184.797L156.145 183.976L156.242 185.21C156.547 189.133 156.041 191.909 155.524 193.22L155.524 193.22L155.521 193.23C154.421 196.202 152.525 200.661 150.415 204.376C149.359 206.235 148.261 207.888 147.194 209.072C146.661 209.664 146.147 210.126 145.663 210.438C145.179 210.751 144.75 210.897 144.377 210.902C142.496 210.928 140.972 210.351 139.902 209.608C138.829 208.862 138.279 208.002 138.191 207.466C138.23 206.228 138.717 204.66 139.561 202.951C140.41 201.231 141.6 199.408 142.987 197.694C144.345 196.02 144.695 193.644 144.597 191.522C144.5 189.413 143.953 187.418 143.36 186.4C139.142 177.626 140.016 169.258 141.093 165.751C152.681 172.427 159.173 170.851 161.897 168.774C161.941 168.929 161.995 169.088 162.054 169.249C162.249 169.773 162.526 170.352 162.859 170.963C163.527 172.186 164.441 173.569 165.435 174.947C167.424 177.702 169.765 180.476 171.185 181.959C172.21 183.029 172.844 184.72 173.206 186.466C173.564 188.198 173.638 189.908 173.597 190.941Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M158.807 216.319C158.895 216.072 159.089 215.892 159.442 215.776C159.809 215.656 160.305 215.623 160.884 215.668C161.896 215.746 163.041 216.051 163.92 216.361C163.869 217.181 163.98 217.852 164.221 218.401C164.375 218.751 164.576 219.04 164.805 219.28C163.488 219.054 162.245 218.749 161.223 218.377C160.294 218.04 159.593 217.664 159.176 217.279C158.773 216.906 158.706 216.604 158.807 216.319Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M128.987 214.588C129.006 214.647 129.086 214.788 129.345 215.007L128.987 214.588ZM128.987 214.588C128.984 214.58 128.982 214.579 128.982 214.577M128.987 214.588L128.982 214.577M128.982 214.577C128.982 214.574 128.985 214.572 128.995 214.559M128.982 214.577L128.995 214.559M128.995 214.559C129.039 214.507 129.151 214.421 129.383 214.327M128.995 214.559L129.383 214.327M129.383 214.327C129.605 214.237 129.894 214.157 130.236 214.089M129.383 214.327L130.236 214.089M130.236 214.089C131.503 213.835 133.296 213.778 134.597 213.786M130.236 214.089L134.597 213.786M134.597 213.786C134.781 214.21 135.085 214.676 135.426 215.136C135.88 215.749 136.447 216.407 137.018 217.031C137.469 217.524 137.928 218 138.343 218.42C135.037 217.427 132.908 216.661 131.548 216.103C129.933 215.44 129.435 215.082 129.345 215.007L134.597 213.786Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M144.062 212.121C142.788 213.834 142.754 214.739 143.55 214.898L145.94 215.533C147.772 216.008 149.174 217.658 147.135 219.182C145.096 220.705 142.278 219.523 140.112 219.182C138.134 217.106 134.002 213.973 135.187 212.121L136.225 210.173" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M164.95 212.335C164.768 213.976 164.512 217.962 164.95 218.586C165.498 219.368 173.858 222.278 176.067 220.852C177.399 219.993 177.242 218.445 176.537 217.883C175.755 217.258 173.954 216.476 171.917 216.555C170.05 216.626 170.614 214.29 170.953 213.353" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M161.572 139.44C161.55 137.914 160.909 134.581 158.513 133.461C156.117 132.342 154.945 130.789 154.307 130.153C153.818 129.602 152.6 128.83 151.631 130.153C151.037 130.965 150.856 132.205 151.054 133.461C151.258 134.765 151.868 136.085 152.842 136.96" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M162.4 153.752C164.337 161.589 162.923 165.499 161.968 168.001C156.997 172.39 146.292 168.001 142.518 165.901C138.206 163.503 139.559 160.442 142.518 151.144C144.38 145.292 148.076 137.786 151.053 133.461" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M154.116 145.99C159.128 151.312 170.543 161.498 176.1 159.666C181.657 157.834 185.637 156.146 186.933 155.532C187.188 154.281 187.417 151.524 186.295 150.506" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M159.532 138.104C162.697 140.436 168.661 146.491 171.13 149.68C172.213 151.08 173.296 151.525 176.1 151.271C178.769 151.028 183.916 150.316 186.296 150.507" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M161.458 137.913C164.622 140.245 169.553 145.474 172.021 148.663C173.105 150.062 174.188 150.508 176.992 150.253C179.66 150.011 183.802 150.062 186.182 150.253" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M186.423 150.509C186.953 150.233 187.99 149.021 187.888 147.392C187.612 146.883 187.238 145.866 187.697 145.611C188.271 145.293 189.163 145.802 189.418 147.456" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M190.247 148.472C191.033 147.788 192.681 146.741 193.282 147.507C193.343 147.585 193.329 147.695 193.267 147.773L192.541 148.679" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M189.163 147.9C189.842 146.819 191.151 145.61 193.241 146.119C193.432 146.204 193.713 146.501 193.305 147.009" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M190.055 150.126C190.682 149.432 191.876 148.393 192.54 148.678C192.661 148.73 192.798 148.927 192.896 149.132C192.959 149.269 192.912 149.426 192.799 149.526L191.967 150.253L190.629 151.525C189.227 152.988 187.845 154.897 187.06 155.469" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M181.849 157.823C169.795 178.663 176.106 206.551 196.983 218.584C217.861 230.616 244.557 223.475 256.609 202.636C268.663 181.795 261.511 155.147 240.633 143.115C225.025 134.12 206.499 135.655 192.937 145.804" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M159.482 133.905C159.737 133.142 159.674 133.142 159.874 132.491C160.12 131.692 168.643 132.143 169.965 122.886C172.416 122.886 173.723 120.867 171.845 120.032C169.84 119.03 169.967 117.145 170.315 116.031C170.872 114.57 170.889 113.042 170.761 112.724" stroke="#0C0C0C" strokeLinecap="round"/>
          <mask id="path-48-inside-1_5967_92492" fill="white">
            <path d="M167.894 119.213C168.106 119.213 168.276 118.957 168.276 118.641C168.276 118.325 168.106 118.068 167.894 118.068C167.682 118.068 167.512 118.325 167.512 118.641C167.512 118.957 167.682 119.213 167.894 119.213Z"/>
          </mask>
          <path d="M167.894 119.213C168.106 119.213 168.276 118.957 168.276 118.641C168.276 118.325 168.106 118.068 167.894 118.068C167.682 118.068 167.512 118.325 167.512 118.641C167.512 118.957 167.682 119.213 167.894 119.213Z" fill="#0C0C0C"/>
          <path d="M167.894 120.213C168.437 120.213 168.807 119.884 168.996 119.601C169.19 119.311 169.276 118.969 169.276 118.641H167.276C167.276 118.629 167.278 118.573 167.333 118.491C167.383 118.415 167.563 118.213 167.894 118.213V120.213ZM169.276 118.641C169.276 118.313 169.19 117.971 168.996 117.681C168.807 117.398 168.437 117.068 167.894 117.068V119.068C167.563 119.068 167.383 118.867 167.333 118.791C167.278 118.709 167.276 118.653 167.276 118.641H169.276ZM167.894 117.068C167.351 117.068 166.981 117.398 166.792 117.681C166.598 117.971 166.512 118.313 166.512 118.641H168.512C168.512 118.653 168.51 118.709 168.455 118.791C168.405 118.867 168.226 119.068 167.894 119.068V117.068ZM166.512 118.641C166.512 118.969 166.598 119.311 166.792 119.601C166.981 119.884 167.351 120.213 167.894 120.213V118.213C168.226 118.213 168.405 118.415 168.455 118.491C168.51 118.573 168.512 118.629 168.512 118.641H166.512Z" fill="#0C0C0C" mask="url(#path-48-inside-1_5967_92492)"/>
          <path d="M166.364 116.54C166.768 116.1 167.817 115.449 168.785 116.375" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M163.688 125.064C164.453 126.209 165.281 126.527 166.874 125.764" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M157.444 117.05C157.274 117.433 157.139 118.301 157.952 118.704" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M149.27 113.317L149.27 113.317C149.848 109.932 151.682 108.111 153.643 107.189C155.635 106.252 157.77 106.24 158.885 106.52L159.122 106.579L159.314 106.429C161.072 105.058 163.381 103.616 165.793 102.807C168.208 101.996 170.662 101.84 172.777 102.934C174.068 103.602 174.688 104.366 174.922 105.118C175.158 105.878 175.032 106.726 174.63 107.619C173.832 109.392 172.03 111.142 170.556 112.235C169.818 112.437 168.594 112.642 167.253 112.616C165.856 112.588 164.385 112.309 163.209 111.56C162.538 111.133 161.922 110.921 161.386 111.04C160.798 111.17 160.5 111.643 160.364 112.104C160.228 112.563 160.215 113.11 160.253 113.624C160.286 114.071 160.361 114.524 160.451 114.923L159.737 116.188C159.477 115.601 159.107 115.042 158.652 114.635C158.27 114.294 157.798 114.035 157.256 114.002C156.703 113.969 156.156 114.176 155.647 114.623L155.647 114.623C155.141 115.068 154.944 115.723 154.913 116.371C154.883 117.023 155.016 117.735 155.241 118.394C155.467 119.055 155.796 119.692 156.181 120.193C156.524 120.639 156.96 121.042 157.462 121.194C157.315 121.456 156.974 121.84 156.473 122.295C155.572 123.115 154.302 124.035 153.248 124.663C151.071 121.708 148.452 118.095 149.27 113.317Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M153.301 125.001C154.002 126.231 154.537 127.978 154.384 130.217" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M73.2758 86.9149C73.1839 87.1781 73.0242 87.3767 72.8547 87.4905C72.6852 87.6043 72.5247 87.6238 72.3988 87.5801C72.273 87.5363 72.1596 87.4214 72.0977 87.2272C72.0356 87.0328 72.0342 86.7781 72.1261 86.5149C72.218 86.2518 72.3777 86.0531 72.5473 85.9392C72.7168 85.8255 72.8771 85.8059 73.003 85.8497C73.129 85.8936 73.2424 86.0083 73.3043 86.2027C73.3663 86.3971 73.3677 86.6518 73.2758 86.9149Z" stroke="#0C0C0C"/>
          <path d="M74.9868 152.67L120.739 158.586" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M120.739 158.523V109.735" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M120.739 109.734L75.1143 104.646" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M120.676 109.797L137.626 95.4219" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M137.69 95.4219V143.065" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M120.803 158.523L137.69 143.002" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M137.69 95.3582L92.5107 90.7783" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M75.1138 104.646C75.1138 105.472 75.1349 136.726 74.9873 152.67" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M75.1143 104.582L79.1925 101.338" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M87.2212 94.6586L92.4464 90.7148" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M74.9872 151.271L71.3096 150.738C71.1276 150.711 70.9441 150.698 70.7603 150.698H67.5228C66.9009 150.698 66.2883 150.547 65.7382 150.257L65.1853 149.966C64.5933 149.654 63.9298 149.502 63.2608 149.527L59.9877 149.646C59.405 149.668 58.8493 149.401 58.5028 148.932C58.1698 148.483 57.6427 148.217 57.0825 148.217H52.1108L74.9872 151.271ZM74.9872 138.358H74.008C73.5212 138.358 73.0417 138.239 72.6115 138.012C72.3279 137.862 72.022 137.759 71.7056 137.706L71.0364 137.595L74.9872 138.358ZM74.9872 141.03H72.5062C71.7953 141.03 71.0987 140.831 70.4947 140.457C69.8908 140.083 69.194 139.885 68.4833 139.885H66.5758L74.9872 141.03ZM74.9872 143.701L70.2261 143.478C69.7924 143.457 69.3653 143.363 68.9632 143.2L68.4224 142.98C68.0445 142.826 67.6405 142.747 67.2325 142.747H66.0854C65.6786 142.747 65.3021 142.532 65.0959 142.182C64.9053 141.859 64.568 141.649 64.1931 141.62L60.5859 141.348L74.9872 143.701ZM74.9234 147.836L63.6363 146.656C63.0562 146.595 62.5001 146.392 62.0183 146.064C61.5788 145.765 61.0773 145.569 60.5512 145.491L56.6351 144.91L74.9234 147.836Z" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M110.99 113.424L116.152 113.933V119.403" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M84.3544 149.618L79.1929 149.109V143.639" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M250.621 159.349C252.576 161.106 253.285 162.338 254.189 164.692" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M255.272 170.417C255.272 169.018 255.209 168.7 254.826 167.428" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M57.9727 86.2627C58.3974 86.3475 59.2981 86.6698 59.502 87.026" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M64.0906 91.7962C64.0906 91.6054 63.9759 91.1219 63.5171 90.7148" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M235.361 221.784C241.018 219.539 247.037 216.006 251.675 210.704C257.244 210.332 266.261 210.1 273.997 210.962C277.917 211.398 281.472 212.112 284.075 213.21C286.704 214.319 288.193 215.743 288.341 217.512C288.396 218.179 287.978 218.871 286.827 219.577C285.69 220.275 283.968 220.896 281.754 221.439C277.337 222.521 271.115 223.252 264.097 223.722C252.289 224.513 238.298 224.559 227.025 224.321C229.552 223.778 232.412 222.954 235.361 221.784Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M159.482 133.905C159.737 133.142 159.674 133.142 159.874 132.491C160.12 131.692 168.643 132.143 169.965 122.886C172.416 122.886 173.723 120.867 171.845 120.032C169.84 119.03 169.967 117.145 170.315 116.031C170.872 114.57 170.889 113.042 170.761 112.724" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M168.132 118.65C168.132 118.783 168.096 118.884 168.059 118.942C168.021 118.884 167.985 118.783 167.985 118.65C167.985 118.517 168.021 118.416 168.059 118.358C168.096 118.416 168.132 118.517 168.132 118.65ZM168.022 118.985C168.022 118.985 168.023 118.984 168.025 118.983C168.023 118.985 168.022 118.985 168.022 118.985ZM168.093 118.983C168.095 118.984 168.096 118.985 168.096 118.985C168.096 118.985 168.095 118.985 168.093 118.983ZM168.096 118.315C168.096 118.315 168.095 118.316 168.093 118.317C168.095 118.315 168.096 118.315 168.096 118.315ZM168.025 118.317C168.023 118.316 168.022 118.315 168.022 118.315C168.022 118.315 168.023 118.316 168.025 118.317Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M166.364 116.54C166.768 116.1 167.817 115.449 168.785 116.375" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M163.688 125.064C164.453 126.209 165.281 126.527 166.874 125.764" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M157.444 117.05C157.274 117.433 157.139 118.301 157.952 118.704" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M149.27 113.317L149.27 113.317C149.848 109.932 151.682 108.111 153.643 107.189C155.635 106.252 157.77 106.24 158.885 106.52L159.122 106.579L159.314 106.429C161.072 105.058 163.381 103.616 165.793 102.807C168.208 101.996 170.662 101.84 172.777 102.934C174.068 103.602 174.688 104.366 174.922 105.118C175.158 105.878 175.032 106.726 174.63 107.619C173.832 109.392 172.03 111.142 170.556 112.235C169.818 112.437 168.594 112.642 167.253 112.616C165.856 112.588 164.385 112.309 163.209 111.56C162.538 111.133 161.922 110.921 161.386 111.04C160.798 111.17 160.5 111.643 160.364 112.104C160.228 112.563 160.215 113.11 160.253 113.624C160.286 114.071 160.361 114.524 160.451 114.923L159.737 116.188C159.477 115.601 159.107 115.042 158.652 114.635C158.27 114.294 157.798 114.035 157.256 114.002C156.703 113.969 156.156 114.176 155.647 114.623L155.647 114.623C155.141 115.068 154.944 115.723 154.913 116.371C154.883 117.023 155.016 117.735 155.241 118.394C155.467 119.055 155.796 119.692 156.181 120.193C156.524 120.639 156.96 121.042 157.462 121.194C157.315 121.456 156.974 121.84 156.473 122.295C155.572 123.115 154.302 124.035 153.248 124.663C151.071 121.708 148.452 118.095 149.27 113.317Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M153.301 125.001C154.002 126.231 154.537 127.978 154.384 130.217" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M38.0897 147.454C36.4215 150.611 39.7966 151.985 42.4272 152.355C44.7904 152.687 47.1817 152.687 47.432 151.441C47.6822 150.195 46.0973 150.029 44.7904 150.029C43.2867 150.029 42.288 149.863 42.705 147.62" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M35.8269 151.435C35.8269 151.435 35.8269 151.435 35.8269 151.435C35.8229 151.422 35.8197 151.411 35.8172 151.401C35.8289 151.397 35.8425 151.394 35.8584 151.391C35.9374 151.374 36.0442 151.362 36.1831 151.358C36.4221 151.35 36.7016 151.363 37.0243 151.378C37.0839 151.381 37.145 151.384 37.2075 151.386C37.5259 151.401 37.8739 151.413 38.2195 151.4C38.5667 151.703 39.1088 152.023 39.6988 152.289C39.2952 152.279 38.8823 152.252 38.4804 152.208C37.7366 152.128 37.0519 151.995 36.5436 151.823C36.2885 151.737 36.0976 151.647 35.97 151.562C35.8505 151.482 35.8274 151.434 35.8269 151.435Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M18.2741 139.376C18.0192 139.63 17.2971 140.266 16.9997 140.521C16.4687 140.924 16.4883 141.678 17.5732 142.938C18.6138 144.147 19.1387 144.731 18.6138 145.546C17.4668 147.327 13.7284 143.616 12.8788 141.666C12.4895 140.966 12.4627 139.16 15.4704 137.531" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M10.9456 146.391C10.9455 146.391 10.9467 146.389 10.9498 146.387C10.9473 146.389 10.9458 146.391 10.9456 146.391ZM11.2094 146.259C11.4263 146.18 11.7452 146.101 12.1547 146.03C13.1864 145.851 14.5589 145.762 15.5924 145.734C15.6973 145.83 15.8136 145.91 15.9242 145.976C15.9479 145.99 15.9721 146.004 15.9966 146.017C15.2958 146.152 14.536 146.273 13.8124 146.347C13.1119 146.418 12.4636 146.444 11.9429 146.401C11.6079 146.373 11.3694 146.32 11.2094 146.259ZM10.9699 146.114C10.9693 146.114 10.969 146.113 10.9691 146.113C10.9691 146.113 10.9694 146.114 10.9699 146.114Z" fill="#0C0C0C" stroke="#0C0C0C"/>
          <path d="M37.7266 172.898V170.227" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M35.3345 171.71L36.7937 172.897" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M38.7007 172.898L39.7954 171.159" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M93.5625 188.928V184.984" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M90.0078 187.174L92.1758 188.927" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M95.0088 188.928L96.6353 186.361" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M218.048 101.402V97.8398" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M214.904 99.8193L216.822 101.402" stroke="#0C0C0C" strokeLinecap="round"/>
          <path d="M219.329 101.402L220.767 99.084" stroke="#0C0C0C" strokeLinecap="round"/>
        </g>
        <path d="M266.791 61.8513L270.679 60.2555L261.309 57.226L219.637 57.9604V62.6681L266.791 61.8513Z" fill="#0C0C0C" stroke="#0C0C0C"/>
        <path d="M211.749 58.0605H206.814L211.749 63.9524V58.0605Z" fill="#0C0C0C" stroke="#0C0C0C"/>
        <path d="M176.063 68.8596C178.875 67.6767 185.721 65.3837 190.607 65.6864C191.299 65.7113 192.345 65.9727 190.988 66.8197C189.631 67.6652 188.953 68.1036 188.783 68.2177C188.077 68.7455 187.384 69.8639 190.267 70.1054C193.872 70.4082 204.048 67.1225 224.188 69.5396" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M212.708 65.6581V37.4502H218.677V64.6654" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M212.708 18.095V20.3284H218.677V18.095C218.677 17.8208 218.431 17.5986 218.126 17.5986H213.259C212.954 17.5986 212.708 17.8208 212.708 18.095Z" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M207.966 20.8387L208.911 36.8548C208.929 37.1493 209.176 37.3769 209.471 37.3692L246.669 36.3957C246.801 36.3923 246.926 36.3417 247.023 36.2533L254.443 29.4939C254.672 29.2849 254.682 28.927 254.464 28.7059L246.299 20.4227C246.196 20.3185 246.056 20.2598 245.909 20.2598H208.512C208.197 20.2598 207.948 20.5247 207.966 20.8387Z" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M238.558 65.2591V61.7852" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M242.006 63.7158L239.903 65.2597" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M237.154 65.2588L235.576 62.998" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M248.477 53.8441V50.3701" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M251.924 52.2998L249.821 53.8437" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M247.073 53.8438L245.495 51.583" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M192.27 56.6566V53.1826" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M195.718 55.1123L193.615 56.6562" stroke="#0C0C0C" strokeLinecap="round"/>
        <path d="M190.866 56.6563L189.288 54.3955" stroke="#0C0C0C" strokeLinecap="round"/>
        <defs>
          <clipPath id="clip0_5967_92492">
            <rect width="278.594" height="185.865" fill="white" transform="translate(10.3188 54.5859)"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  )
})
