import { AxiosRequestConfig } from 'axios'
import { IPaginationQuery, IVideoModel, TAxiosResponsePagination } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class VideoApi {
  static readonly _prefix = '/videos'

  static paginate(
    params?: IPaginationQuery,
    config?: AxiosRequestConfig
  ): TAxiosResponsePagination<IVideoModel & { usedForCampaigns?: number }> {
    return axiosHiringApi.get(this._prefix, {
      ...config,
      params: params || config?.params
    })
  }

  static create(
    payload: {
      key: string // S3 upload key
      title?: string
      transcription?: string
      thumbnailOffset?: number
    },
    config?: AxiosRequestConfig
  ) {
    return axiosHiringApi.post<IVideoModel>(this._prefix, payload, config)
  }

  static update(
    id: IVideoModel['id'],
    data: Partial<Pick<IVideoModel, 'title'>>,
    config?: AxiosRequestConfig
  ) {
    return axiosHiringApi.put<void>(`${this._prefix}/${id}`, data, config)
  }
}
