import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconLocationStroke: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 16, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_12436_78930)">
          <path fillRule="evenodd" clipRule="evenodd" d="M8.50047 2.01998C7.3123 2.01998 6.17279 2.49198 5.33263 3.33214C4.49247 4.1723 4.02047 5.31181 4.02047 6.49998C4.02047 8.56275 5.17263 10.443 6.39286 11.8463C6.99663 12.5406 7.60173 13.1005 8.056 13.4866C8.22964 13.6342 8.38068 13.7559 8.50047 13.8496C8.62025 13.7559 8.7713 13.6342 8.94494 13.4866C9.39921 13.1005 10.0043 12.5406 10.6081 11.8463C11.8283 10.443 12.9805 8.56275 12.9805 6.49998C12.9805 5.31181 12.5085 4.1723 11.6683 3.33214C10.8281 2.49198 9.68864 2.01998 8.50047 2.01998ZM8.50047 14.5C8.20227 14.926 8.20212 14.9259 8.20196 14.9258L8.20035 14.9246L8.19661 14.922L8.18382 14.9129C8.17296 14.9051 8.15749 14.894 8.13772 14.8796C8.09818 14.8507 8.04141 14.8086 7.96986 14.754C7.82682 14.6446 7.62442 14.4847 7.38244 14.279C6.89921 13.8683 6.25431 13.2719 5.60807 12.5287C4.32831 11.057 2.98047 8.93721 2.98047 6.49998C2.98047 5.03599 3.56204 3.63195 4.59724 2.59675C5.63244 1.56155 7.03647 0.97998 8.50047 0.97998C9.96446 0.97998 11.3685 1.56155 12.4037 2.59675C13.4389 3.63195 14.0205 5.03599 14.0205 6.49998C14.0205 8.93721 12.6726 11.057 11.3929 12.5287C10.7466 13.2719 10.1017 13.8683 9.6185 14.279C9.37652 14.4847 9.17412 14.6446 9.03108 14.754C8.95953 14.8086 8.90276 14.8507 8.86322 14.8796C8.84345 14.894 8.82798 14.9051 8.81712 14.9129L8.80432 14.922L8.80059 14.9246L8.7994 14.9255C8.79924 14.9256 8.79867 14.926 8.50047 14.5ZM8.50047 14.5L8.79867 14.926C8.61962 15.0513 8.38101 15.0511 8.20196 14.9258L8.50047 14.5ZM8.50047 5.01998C7.68309 5.01998 7.02047 5.6826 7.02047 6.49998C7.02047 7.31736 7.68309 7.97998 8.50047 7.97998C9.31785 7.97998 9.98047 7.31736 9.98047 6.49998C9.98047 5.6826 9.31785 5.01998 8.50047 5.01998ZM5.98047 6.49998C5.98047 5.10822 7.10871 3.97998 8.50047 3.97998C9.89223 3.97998 11.0205 5.10822 11.0205 6.49998C11.0205 7.89174 9.89223 9.01998 8.50047 9.01998C7.10871 9.01998 5.98047 7.89174 5.98047 6.49998Z" fill={color}/>
        </g>
        <defs>
          <clipPath id="clip0_12436_78930">
            <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  )
}
