import { BehaviorSubject } from 'rxjs'
import { UploadApi } from 'src/api'
import { RECORD_FILE_NAME } from 'src/constants'
import { IPlaceholder } from 'src/interfaces'
import { FileUtils } from 'src/utils'
import { WithOutNextComplete } from 'types/rxjs'

export const RecorderModule = new (
  class {
    // private readonly _questions$ = new BehaviorSubject<ICampaignQuestionModel[]>([])

    // get questions$(): WithOutNextComplete<typeof this._questions$> {
    //   return this._questions$
    // }

    // getQuestions() {
    //   if (this.questions$.getValue().length) {
    //     return Promise.resolve(this.questions$.getValue())
    //   }
    //   return CampaignApi.getAllQuestions().then((response) => {
    //     this._questions$.next(response.data)
    //     return response.data
    //   })
    // }

    private startRecordingAt?: Date
    private stopRecordingAt?: Date

    startRecording() {
      this.startRecordingAt = new Date()
      this.stopRecordingAt = undefined
    }

    stopRecording() {
      this.stopRecordingAt = new Date()
    }

    get recordingDuration() {
      return this.stopRecordingAt && this.startRecordingAt
        ? Math.floor((this.stopRecordingAt.getTime() - this.startRecordingAt.getTime()) / 1000)
        : 0
    }

    private readonly _recordResult$ = new BehaviorSubject<File | Blob | undefined>(undefined)

    get recordResult$(): WithOutNextComplete<typeof this._recordResult$> {
      return this._recordResult$
    }

    private get recordResult() {
      return this.recordResult$.getValue()
    }

    private set recordResult(value) {
      this._recordResult$.next(value)
    }

    setRecordResult(result: File | Blob) {
      this.recordResult = result instanceof File
        ? result
        : new File([result], RECORD_FILE_NAME, { type: result.type })
    }

    downloadRecordResult() {
      const recordedFile = this.recordResult
      if (recordedFile) {
        const file = recordedFile instanceof File
          ? recordedFile
          : new File([recordedFile as Blob], RECORD_FILE_NAME, { type: recordedFile.type })
        FileUtils.download(file, RECORD_FILE_NAME)
      }
    }

    /**
     * @deprecated
     */
    private readonly _placeholder$ = new BehaviorSubject<IPlaceholder | undefined>(undefined)

    /**
     * @deprecated
     */
    get placeholder$(): WithOutNextComplete<typeof this._placeholder$> {
      return this._placeholder$
    }

    /**
     * @deprecated
     */
    private get placeholder() {
      return this.placeholder$.getValue()
    }

    /**
     * @deprecated
     */
    private set placeholder(value) {
      if (value?.file) {
        this._placeholder$.next({ file: value?.file })
      } else {
        this._placeholder$.next(value)
      }
    }

    /**
     * @deprecated
     */
    setPlaceholder(placeholder: IPlaceholder) {
      this.placeholder = placeholder
    }

    /**
     * @deprecated
     */
    removePlaceholder() {
      this.placeholder = undefined
    }

    /**
     * @deprecated
     */
    private placeholders: IPlaceholder[] = []
    /**
     * @deprecated
     */
    async randomPlaceholder() {
      if (!this.placeholders.length) {
        await UploadApi.getPlaceholders().then(({ data }) => {
          this.placeholders = data.placeholders
        })
      }
      this.placeholder = this.placeholders.random()
    }

    readonly teleprompter$ = new BehaviorSubject<{ text: string; speed: number }>({
      text: '',
      speed: 30
    })

    private get teleprompter() {
      return this.teleprompter$.getValue()
    }

    private set teleprompter(value) {
      this.teleprompter$.next(value)
    }

    setTeleprompter(teleprompter: Partial<typeof this.teleprompter>) {
      this.teleprompter = {
        ...this.teleprompter,
        ...teleprompter
      }
    }

    reset() {
      this.recordResult = undefined
      this.placeholder = undefined
      this.teleprompter = { text: '', speed: 30 }
      this.startRecordingAt = undefined
      this.stopRecordingAt = undefined
    }
  }
)()
