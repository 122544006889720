import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { catchError, EMPTY, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { Button } from 'src/components/button'
import { Textarea } from 'src/components/textarea'
import { useAsRef, useDebounceAnalyticInput, useUnsubscribe } from 'src/hooks'
import { IconEmailFilled, IconTrashCan } from 'src/icons'
import { IconFile } from 'src/icons/icon-file'
import { IconLinkedInStroke } from 'src/icons/icon-linkedin-stroke'
import { IconLocationStroke } from 'src/icons/icon-location-stroke'
import { IconPhoneStroke } from 'src/icons/icon-phone-stroke'
import { ICampaignModel, ICampaignSubmissionModel } from 'src/interfaces/models/campaign.model'
import { SnackbarService } from 'src/services'
import { FileUtils } from 'src/utils/file.utils'
import {
  convertPhoneNumToInternationalFormat,
  removePrefixPhoneNumber
} from 'src/utils/helpers.utils'
import { getLocation } from 'src/utils/user.utils'
import { useAnalytic } from '../../../components'
import { ETrackingEvent } from '../../../enums'
import Style from './style.module.scss'

interface IProps {
  submission: ICampaignSubmissionModel
  campaign?: ICampaignModel
  onRemoveMultiMessage?: (id: number) => void
  onChange?: (id: number, values: Partial<ICampaignSubmissionModel>) => void
}

export const Detail: FC<IProps> = ({
  submission,
  campaign,
  onRemoveMultiMessage,
  onChange
}) => {
  const unsubscribe$ = useUnsubscribe()
  const onChangeRef = useAsRef(onChange)
  const { eventHandler } = useAnalytic('talent_detail')
  const { analyticInput } = useDebounceAnalyticInput('talent_detail')

  const [privateNotes, setPrivateNotes] = useState<string>('')

  const handlePrivateNotes = (privateNotes: string) => {
    setPrivateNotes(privateNotes)
    analyticInput(ETrackingEvent.BTN_TALENT_DETAILS_INPUT_NOTES, privateNotes)
  }

  const userPhoneNo = useMemo(() => {
    if (submission?.author?.id) {
      return submission?.author?.userPhoneNo
    }

    return submission?.metadata?.phone
  }, [submission?.author?.id, submission?.author?.userPhoneNo, submission?.metadata?.phone])

  const email = useMemo(() => {
    if (submission?.author?.id) {
      return submission?.author?.email
    }

    return submission?.metadata?.email
  }, [submission?.author?.id, submission?.author?.email, submission?.metadata?.email])

  const location = useMemo(() => {
    if (submission?.author?.id) {
      return getLocation(submission?.author)
    }

    return getLocation(submission?.metadata)
  }, [submission?.author, submission?.metadata])

  const onSavePrivateNotes = useCallback(() => {
    if (!submission?.id || !submission.campaignId) return

    from(CampaignApi.writeNote(submission.campaignId, submission.id, privateNotes))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        })
      )
      .subscribe(({ data }) => {
        onChangeRef.current?.(submission.id, { privateNote: data || '' })
      })
  }, [
    submission.campaignId,
    submission.id,
    onChangeRef,
    privateNotes,
    unsubscribe$
  ])

  useEffect(() => {
    setPrivateNotes(submission.privateNote?.content || '')
  }, [submission])

  return (
    <div className="w-100-p round-3" style={{ border: '1px solid #D3D3D3' }}>
      <div
        className="fx fx-column gap-1 p-6"
        style={{ borderBottom: '1px solid #D3D3D3' }}
      >
        <div className="fw-500 txt-black-01">Applying For</div>
        <div className="heading-5 txt-black-01">{campaign?.jobTitle}</div>
      </div>

      <div className="fx fx-column gap-6 p-6">
        <div className="fx fx-column gap-3 txt-grey-01">
          <div className="fw-500 txt-black-01">Contact</div>
          <div className="fx fx-ai-center gap-2">
            <IconPhoneStroke/>
            <div className="fs-12 three-dot-1">
              {userPhoneNo
                ? convertPhoneNumToInternationalFormat(
                  removePrefixPhoneNumber(userPhoneNo)
                )
                : '---'}
            </div>
          </div>
          <div className="fx fx-ai-center gap-2">
            <IconEmailFilled/>
            <div className="fs-12 three-dot-1">
              {email || '---'}
            </div>
          </div>
          <div className="fx fx-ai-center gap-2">
            <IconLinkedInStroke/>
            <div className="fs-12 three-dot-1">
              {submission?.author?.linkedInUrl || '---'}
            </div>
          </div>
          <div className="fx fx-ai-center gap-2">
            <IconLocationStroke/>
            <div className="fs-12 three-dot-1">
              {location || '---'}
            </div>
          </div>
        </div>

        {submission?.resumeFileUrl && (
          <div className="fx fx-column gap-3">
            <div className="fw-500 txt-black-01">Resume</div>
            <div
              className={clsx('fx gap-2 w-fit px-5 py-3 round-3 pointer', Style.resume)}
              onClick={eventHandler(ETrackingEvent.BTN_TALENT_DETAILS_RESUME, () => FileUtils.download(
                submission.resumeFileUrl,
                submission.resumeFileName
              ))}
            >
              <IconFile size={16}/>
              <div className="fs-12 txt-grey-01 ">
                {submission?.resumeFileName}
              </div>
            </div>
          </div>
        )}

        <div>
          <div className="fs-16 fw-500 txt-black-01 mb-3">Private Notes</div>
          <Textarea
            label=""
            placeholder="Only you can see your private notes"
            value={privateNotes}
            autoComplete="off"
            disableResize
            minRows={4}
            maxRows={6}
            style={{
              marginTop: 8,
              fontSize: '14px',
              background: 'transparent'
            }}
            onChange={(event) => handlePrivateNotes(event.target.value)}
            onBlur={onSavePrivateNotes}
          />
        </div>

        {onRemoveMultiMessage && (
          <Button
            variant="secondary"
            className="fx fx-ai-center gap-2 w-fit"
            onClick={() => onRemoveMultiMessage(submission.id)}
          >
            <IconTrashCan/>
            Remove from Multi-Message
          </Button>
        )}
      </div>
    </div>
  )
}
