import { Typography } from '@mui/material'
import { ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AuthApi, ProfileApi, ViewApi } from 'src/api'
import { Button, Input, NotAllowAuthenticated, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useOldValidation } from 'src/hooks'
import { SnackbarService } from 'src/services'
import { AuthModule, LoadingModule } from 'src/store'
import { getApiErrorMessage } from 'src/utils'
import { signInSchema } from 'src/validation'
import { STContainer, STForm } from './styled'

export const LandingPage: FC = () => {
  const history = useHistory()
  const { sharingToken } = useParams<{sharingToken: string}>()

  const { errors, validate, removeError } = useOldValidation<{password: string; email: string}>()
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const { eventHandler } = useAnalytic('share_landing')

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    e.persist()
    const { value } = e.target
    removeError()
    setPassword(value)
  }

  const loadCandidate = useCallback(async (sharingToken: string) => {
    try {
      const { data } = await ViewApi.share(sharingToken)
      setEmail(data.email || '')
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
      history.push('/')
    }
  }, [history])

  useEffect(() => {
    if (sharingToken) {
      /**
       * TODO: cache sharing token for redirecting after login
       */
      // dispatch(setLayoutSharingToken(sharingToken))
      loadCandidate(sharingToken)
    }
  }, [loadCandidate, sharingToken])

  const handleNext = async (e: any) => {
    try {
      e.preventDefault()
      LoadingModule.toggle(true)
      const isValid = await validate({ schema: signInSchema, data: { email, password } })
      if (!isValid || !sharingToken || !password || !email) return

      const payload = {
        email,
        password
      }

      await AuthApi.login(payload)
      ProfileApi.getProfile().then(({ data }) => AuthModule.authenticated(data))
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    } finally {
      LoadingModule.toggle(false)
    }
  }

  return (
    <NotAllowAuthenticated>
      <STContainer>
        <Typography variant="h3">Enter your password</Typography>
        <STForm onSubmit={handleNext}>
          <Input
            required
            type="password"
            label="Password"
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={handleChange}
            onClick={eventHandler(ETrackingEvent.INPUT_PASSWORD)}
            autoComplete="off"
            error={errors.password}
          />
          <Button
            className="w-100-p"
            onClick={eventHandler(ETrackingEvent.BTN_PSWD_NEXT, handleNext)}
          >
            Next
          </Button>
        </STForm>
      </STContainer>
    </NotAllowAuthenticated>
  )
}
