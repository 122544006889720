import { Typography } from '@mui/material'
import { ChangeEventHandler, Dispatch, FC, FormEvent, useCallback, useState } from 'react'
import { AuthApi } from 'src/api'
import { Button, Input, RequiredAsterisk, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useDebounceAnalyticInput, useValidation } from 'src/hooks'
import { Logo } from 'src/images'
import { EAuthView } from 'src/interfaces'
import { registerPasswordSchema } from 'src/validation'
import Style from './style.module.scss'

interface ISetAccountInformation {
  companyName: string
  companyUrl: string
  userType: string
  password: string
  confirmPassword: string
}

type TSignUpPayload = Parameters<typeof AuthApi.signUpProfile>[0]

interface IProps {
  setView: Dispatch<EAuthView>
  signUpInfo: TSignUpPayload
  onSubmit: (data: Omit<ISetAccountInformation, 'confirmPassword'>) => Promise<void>
  companyName?: string
  companyUrl?: string
}

export const SetPassword: FC<IProps> = ({ onSubmit, companyName, companyUrl }) => {
  const { eventHandler } = useAnalytic('signup2')
  const { analyticInput } = useDebounceAnalyticInput('signup2')
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState<ISetAccountInformation>({
    companyName: companyName || '',
    companyUrl: companyUrl || '',
    userType: '',
    password: '',
    confirmPassword: ''
  })
  const { errors, validate } = useValidation({
    data: formValues,
    schema: registerPasswordSchema
  })

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const { name, value } = e.target

    if (name === 'companyName') {
      analyticInput(ETrackingEvent.INPUT_COMPANY_NAME, value)
    }

    if (name === 'companyUrl') {
      analyticInput(ETrackingEvent.INPUT_COMPANY_URL, value)
    }

    setFormValues((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const onNext = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    setLoading(true)
    onSubmit(formValues).finally(() => setLoading(false))
  }, [formValues, onSubmit, validate])

  return (
    <form className={Style.STForm} onSubmit={onNext}>
      <img src={Logo} alt="logo" width={48}/>
      <div className={Style.LoginHeader}>
        <Typography className={Style.Title}>Enter your Account Information</Typography>
      </div>
      <div className={Style.FormInput}>
        <Input
          label={<>Company<RequiredAsterisk/></>}
          id="companyName"
          name="companyName"
          disabled={!!companyName}
          value={formValues.companyName || ''}
          onChange={handleChange}
          error={errors.getError('companyName')}
          required
        />
        <Input
          label={<>Company URL<RequiredAsterisk/></>}
          id="companyUrl"
          name="companyUrl"
          value={formValues.companyUrl || ''}
          disabled={!!companyUrl}
          onChange={handleChange}
          error={errors.getError('companyUrl')}
          required
        />
        <Input
          label={<>Position<RequiredAsterisk/></>}
          id="userType"
          name="userType"
          value={formValues.userType || ''}
          onChange={handleChange}
          error={errors.getError('userType')}
          required
        />
        <Input
          label={<>Password<RequiredAsterisk/></>}
          id="password"
          type="password"
          name="password"
          value={formValues.password || ''}
          onChange={handleChange}
          onClick={eventHandler(ETrackingEvent.INPUT_PASSWORD)}
          error={errors.getError('password')}
          required
          isCheckPassword
        />
        <Input
          label={<>Confirm your password<RequiredAsterisk/></>}
          id="confirmPassword"
          type="password"
          name="confirmPassword"
          value={formValues.confirmPassword || ''}
          onChange={handleChange}
          onClick={eventHandler(ETrackingEvent.INPUT_CONFIRM_PASSWORD)}
          error={errors.getError('confirmPassword')}
          required
        />

        <Button
          type="submit"
          className="round-16"
          disabled={errors.hasError() || loading}
          onClick={eventHandler(ETrackingEvent.BTN_CREATE_ACCOUNT)}
        >
          create account
        </Button>
      </div>
    </form>
  )
}
