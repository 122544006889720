import { useCallback, useEffect, useMemo } from 'react'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators'
import { useAnalytic } from 'src/components'
import { ETrackingEvent } from '../enums'

export const useDebounceAnalyticInput = (screenName?: string) => {
  const { analytic } = useAnalytic(screenName)

  const inputSubject = useMemo(() => new Subject<{ eventName: ETrackingEvent; value: string}>(), [])
  useEffect(() => {
    inputSubject.pipe(
      debounceTime(500),
      distinctUntilChanged((prev, next) => prev.eventName === next.eventName && prev.value === next.value),
      switchMap(({ eventName, value }) => {
        return analytic(eventName, { value })
      })
    ).subscribe()
  }, [analytic, inputSubject])

  const analyticInput = useCallback((eventName: ETrackingEvent, value: any) => {
    inputSubject.next({ eventName, value })
  }, [inputSubject])

  return {
    analyticInput
  }
}
