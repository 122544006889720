import { Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { CreateJobButton } from 'src/modules/campaign/components/my-jobs/components/create-job-button'
import { browserHistory, ERoutes, generate } from 'src/router'
import { Illustration } from './illustration'
import Style from './style.module.scss'

export const ViewJobCompleted: FC = (props) => {
  const theme = useTheme()

  return (
    <div className={Style.container}>
      <Illustration/>
      <div className={Style.content}>
        <Typography variant="h5" color={theme.colors['--color-neutral-theme-700']} textAlign="center">
          Let's kick-start your hiring journey.
        </Typography>
        <span className="meta-regular color-neutral-theme-900 text-center">
          Create and share your job post, and let candidates apply effortlessly.
        </span>
      </div>
      <CreateJobButton onClick={() => browserHistory.push(generate(ERoutes.CLAIM_JOBS))}/>
    </div>
  )
}
