import { IRouterOption } from 'src/router'
import { ClaimJobsBySlug } from './[slug]'
import { ClaimJobs } from './claim-jobs'
import { ERoutes } from './routes.enum'

const Routes: IRouterOption[] = [
  {
    path: '/claim-jobs',
    component: ClaimJobs,
    name: ERoutes.CLAIM_JOBS,
    exact: true,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/claim-jobs/:slug',
    component: ClaimJobsBySlug,
    name: ERoutes.CLAIM_JOBS_SLUG,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
]

export const ClaimJobModule = {
  ERoutes,
  Routes
}
