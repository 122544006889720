import { getMonth, getYear } from 'date-fns'
import { FC } from 'react'
import { ReactDatePickerProps } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import { IconChevronFilled } from 'src/icons'
import Style from './style.module.scss'

const years = Array.from({ length: 2099 - 1920 + 1 }, (_, i) => 1920 + i)
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

type RenderCustomHeaderProps = Parameters<NonNullable<ReactDatePickerProps['renderCustomHeader']>>[0]

interface IProps extends RenderCustomHeaderProps {
  minDate?: Date
  maxDate?: Date
}

export const DatePickerHeader: FC<IProps> = (
  {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    minDate,
    maxDate
  }
) => {
  let yearList = years
  const minYear = minDate?.getFullYear()
  const maxYear = maxDate?.getFullYear()

  if (maxYear) {
    yearList = yearList.filter(year => year <= maxYear)
  }

  if (minYear) {
    yearList = yearList.filter(year => year >= minYear)
  }

  return (
    <div className="fx fx-center-between gap-2">
      <div className="fx gap-2">
        <select
          className={Style.monthSelect}
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))}
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <select
          className={Style.yearSelect}
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(+value)}
        >
          {yearList.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className="fx">
        <button
          className={Style.buttonPrevious}
          type="button"
          disabled={prevMonthButtonDisabled}
          onClick={decreaseMonth}
        >
          <IconChevronFilled
            rotate={180}
            className="svg-current-color"
          />
        </button>
        <button
          className={Style.buttonNext}
          type="button"
          disabled={nextMonthButtonDisabled}
          onClick={increaseMonth}
        >
          <IconChevronFilled
            rotate={0}
            className="svg-current-color"
          />
        </button>
      </div>
    </div>
  )
}
