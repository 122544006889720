import { ClickAwayListener } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { ProfileApi } from 'src/api'
import { useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useBehaviorMapper } from 'src/hooks'
import { IconSignOut, IconTrashCan, IconUser } from 'src/icons'
import { ERoutes, generate } from 'src/router'
import { AuthModule } from 'src/store'
import { LocalStorage, SessionStorage, StyleUtils, abbreviateFullName, getAvatar, getFullName } from 'src/utils'
import Style from './style.module.scss'

export const UserMenu: FC = () => {
  const history = useHistory()
  const profile = useBehaviorMapper(AuthModule.profile$)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { eventHandler } = useAnalytic('top_nav')

  const fullName = useMemo(() => getFullName(profile) || profile.email, [profile])
  const handleOpenDropdown = useCallback(() => setIsOpen((prev) => !prev), [])

  // const handleCW = useCallback(() => {
  //   window.open(EEnv.REACT_APP_CANDIDATE_WEB_DNS, '_blank')
  // }, [])

  const avatarUrl = useMemo(() => getAvatar(profile), [profile])

  const onDeleteAccount = useCallback(() => {
    Promise.all([
      ProfileApi.deletePhil(),
      AuthModule.signOut()
    ]).finally(() => {
      LocalStorage.clear()
      SessionStorage.clear()
      window.location.reload()
    })
  }, [])

  return (
    <div className={clsx(Style.wrapper)} onClick={eventHandler(ETrackingEvent.BTN_NAV_PROFILE, handleOpenDropdown)}>
      <div className={Style.avatar} style={StyleUtils.backgroundCover(avatarUrl)}>
        {avatarUrl ? '' : abbreviateFullName(fullName)}
      </div>

      {isOpen && (
        <ClickAwayListener onClickAway={handleOpenDropdown}>
          <div className={clsx(Style.menu)}>
            <div
              className={Style.item}
              onClick={() => history.push(generate(ERoutes.SETTING))}
            >
              <IconUser/>
              Settings
            </div>

            {/* <div
              className={Style.item}
              onClick={() => history.push(generate(ERoutes.SETTING, { tab: ESettingTabKey.INFORMATION }))}
            >
              <IconUser/>
              <span>Basic Information</span>
            </div> */}

            {/* <div
              className={Style.item}
              onClick={() => history.push(generate(ERoutes.SETTING, { tab: ESettingTabKey.NOTIFICATIONS }))}
            >
              <IconBell/>
              <span>Notification Settings</span>
            </div> */}

            {/* <div
              className={Style.item}
              onClick={eventHandler(ETrackingEvent.BTN_OPTIONS_PAYMENTS, () => handleRedirect('/payment-history'))}
            >
              <IconPayment className={Style.icon} width={16}/>
              <span>Payments</span>
            </div> */}

            {/* <div
              className={Style.item}
              onClick={eventHandler(ETrackingEvent.BTN_OPTIONS_INVITE, () => OverlayService.setOverlay({
                open: true,
                content: <Invite/>
              }))}
            >
              <IconInviteFriend className={Style.icon} width={16}/>
              <span>Invite Friends</span>
            </div> */}

            {/* <div
              className={Style.item}
              onClick={eventHandler(ETrackingEvent.BTN_OPTIONS_INVITE, handleCW)}
            >
              <IconLogo width={24}/>
              <span>KnowMe Jobs Marketplace</span>
              <IconExternalLink className="w-4 h-4 svg-color-primary-400"/>
            </div> */}

            <div className={Style.item} onClick={eventHandler(ETrackingEvent.BTN_OPTIONS_SIGNOUT, () => AuthModule.signOut())}>
              <IconSignOut width={20}/>
              <span>Sign out</span>
            </div>

            {['phil@knowmehub.com', 'phil288@gmail.com', 'danh@knowmehub.com'].includes(profile.email?.toLowerCase() as string) && (
              <div
                className={Style.item}
                style={{
                  opacity: 0,
                  padding: 0,
                  height: '20px'
                }}
                onClick={onDeleteAccount}
              >
                <IconTrashCan color="red"/>
                Delete Account
              </div>
            )}
          </div>
        </ClickAwayListener>
      )}
    </div>
  )
}
