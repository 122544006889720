import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconMotivatorAltruistic = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 300 } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="300" height="257" viewBox="0 0 300 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="299.33" height="257" transform="translate(0 0.00195312)"/>
        <path d="M231.862 31.6158C231.862 31.6158 231.197 23.3483 231.517 22.8225C231.837 22.2967 235.78 20.96 238.453 20.6115C241.126 20.2631 245.684 20.3328 246.078 20.9093C246.472 21.4858 247.643 30.051 248 30.0573C248.357 30.0637 248.998 28.2961 250.439 27.8843C251.88 27.4726 252.878 27.8083 253.42 28.5875C253.962 29.3668 253.925 30.8175 253.925 30.8175C253.925 30.8175 258.114 25.6037 257.202 19.1417C256.291 12.6798 250.649 5.19161 240.19 6.54735C229.731 7.90308 224.816 15.4166 225.789 22.1827C226.762 28.9487 231.862 31.6158 231.862 31.6158Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M231.985 31.6726C231.985 31.6726 233.02 42.4868 241.582 42.075C250.144 41.6632 250.464 33.8266 250.464 33.8266C250.464 33.8266 252.491 33.4805 253.085 32.7577C253.638 32.0866 253.864 30.792 253.864 30.792" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M236.592 29.3799C236.592 29.3799 235.829 33.2127 236.716 33.9539C237.602 34.6951 238.576 34.1503 238.576 34.1503" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M238.563 37.349C238.563 37.349 239.611 37.8178 240.596 37.6784C241.582 37.5391 242.863 36.5508 242.863 36.5508" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M241.094 42.1705L241.291 46.2251C241.291 46.2251 243.435 47.4668 245.677 47.2007C247.919 46.9346 249.619 44.9327 249.619 44.9327L249.249 38.0146" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M239.221 45.0586C239.221 45.0586 228.67 48.2608 226.719 53.4572C224.768 58.6536 220.935 70.1216 218.984 73.6337C217.033 77.1457 210.064 89.2946 210.064 89.2946C210.064 89.2946 211.876 92.4124 213.548 93.8459C215.221 95.2794 217.66 96.4262 217.66 96.4262C217.66 96.4262 225.674 88.6137 228.461 83.9548C231.249 79.296 233.207 76.2185 233.207 76.2185" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M251.033 43.751C251.033 43.751 259.607 44.3338 263.5 49.7568C267.393 55.1797 272.764 68.2049 274.686 81.0781C276.607 93.9512 275.474 100.971 275.474 100.971C275.474 100.971 273.01 101.959 270.645 101.68C268.28 101.401 264.88 99.9316 264.88 99.9316C264.88 99.9316 264.288 87.4639 263.254 82.5985C262.219 77.7331 260.1 69.7 260.1 69.7" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M233.097 66.4053L233.796 94.3666" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M259.528 64.5762C259.528 64.5762 260.612 76.6258 260.932 80.0974C261.252 83.5691 261.399 93.3601 261.399 93.3601" stroke="black" strokeWidth="0.84383" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M235.062 48.3065L242.232 54.4264L245.977 49.6369L250.732 53.7041L256.128 46.7607" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M233.883 94.4798C233.883 94.4798 229.004 115.006 226.491 132.846C223.978 150.686 224.126 170.781 224.126 170.781C224.126 170.781 228.955 171.82 231.616 172.124C234.277 172.428 238.958 172.301 238.958 172.301C238.958 172.301 240.239 144.933 240.88 137.737C241.521 130.54 246.005 110.166 246.005 110.166C246.005 110.166 247.335 131.553 247.187 139.105C247.04 146.657 246.3 172.2 246.3 172.2C246.3 172.2 249.454 172.454 253.298 172.479C257.141 172.504 260.738 172.175 260.738 172.175C260.738 172.175 263.005 147.746 262.857 131.047C262.709 114.347 261.33 93.6182 261.33 93.6182C261.33 93.6182 256.469 94.9035 248.129 95.2773C239.789 95.6511 233.883 94.4798 233.883 94.4798Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M241.841 27.976C241.841 28.1226 241.783 28.2632 241.679 28.3669C241.575 28.4707 241.435 28.529 241.288 28.5292C241.142 28.5294 241.001 28.4713 240.897 28.3678C240.793 28.2644 240.735 28.1239 240.734 27.9773C240.734 27.8307 240.792 27.69 240.895 27.586C240.999 27.482 241.139 27.4234 241.286 27.4229C241.432 27.4223 241.573 27.4801 241.677 27.5833C241.781 27.6865 241.84 27.8269 241.841 27.9735" fill="black"/>
        <path d="M241.841 27.976C241.841 28.1226 241.783 28.2632 241.679 28.3669C241.575 28.4707 241.435 28.529 241.288 28.5292C241.142 28.5294 241.001 28.4713 240.897 28.3678C240.793 28.2644 240.735 28.1239 240.734 27.9773C240.734 27.8307 240.792 27.69 240.895 27.586C240.999 27.482 241.139 27.4234 241.286 27.4229C241.432 27.4223 241.573 27.4801 241.677 27.5833C241.781 27.6865 241.84 27.8269 241.841 27.9735" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M234.901 28.725C234.901 28.8716 234.843 29.0122 234.739 29.116C234.636 29.2197 234.495 29.278 234.349 29.2782C234.202 29.2784 234.061 29.2203 233.958 29.1169C233.854 29.0134 233.795 28.8729 233.795 28.7263C233.795 28.5797 233.852 28.439 233.956 28.335C234.059 28.2311 234.2 28.1724 234.346 28.1719C234.493 28.1714 234.634 28.2291 234.738 28.3323C234.842 28.4356 234.901 28.5759 234.901 28.7225" fill="black"/>
        <path d="M234.901 28.725C234.901 28.8716 234.843 29.0122 234.739 29.116C234.636 29.2197 234.495 29.278 234.349 29.2782C234.202 29.2784 234.061 29.2203 233.958 29.1169C233.854 29.0134 233.795 28.8729 233.795 28.7263C233.795 28.5797 233.852 28.439 233.956 28.335C234.059 28.2311 234.2 28.1724 234.346 28.1719C234.493 28.1714 234.634 28.2291 234.738 28.3323C234.842 28.4356 234.901 28.5759 234.901 28.7225" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M247.017 62.1932C247.017 62.3712 246.946 62.5419 246.82 62.6679C246.694 62.7938 246.524 62.8647 246.346 62.8649C246.168 62.8651 245.997 62.7946 245.871 62.669C245.745 62.5433 245.674 62.3727 245.673 62.1947C245.673 62.0167 245.743 61.8458 245.869 61.7196C245.994 61.5934 246.165 61.5221 246.343 61.5215C246.521 61.5209 246.692 61.5909 246.818 61.7163C246.944 61.8417 247.016 62.0121 247.017 62.1901" fill="black"/>
        <path d="M247.017 62.1932C247.017 62.3712 246.946 62.5419 246.82 62.6679C246.694 62.7938 246.524 62.8647 246.346 62.8649C246.168 62.8651 245.997 62.7946 245.871 62.669C245.745 62.5433 245.674 62.3727 245.673 62.1947C245.673 62.0167 245.743 61.8458 245.869 61.7196C245.994 61.5934 246.165 61.5221 246.343 61.5215C246.521 61.5209 246.692 61.5909 246.818 61.7163C246.944 61.8417 247.016 62.0121 247.017 62.1901" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M247.77 74.5535C247.77 74.7316 247.699 74.9023 247.573 75.0282C247.447 75.1542 247.277 75.225 247.099 75.2252C246.921 75.2254 246.75 75.155 246.624 75.0293C246.498 74.9037 246.427 74.7331 246.426 74.5551C246.426 74.3771 246.496 74.2062 246.622 74.0799C246.747 73.9537 246.918 73.8825 247.096 73.8818C247.274 73.8812 247.445 73.9513 247.571 74.0767C247.697 74.202 247.769 74.3724 247.77 74.5505" fill="black"/>
        <path d="M247.77 74.5535C247.77 74.7316 247.699 74.9023 247.573 75.0282C247.447 75.1542 247.277 75.225 247.099 75.2252C246.921 75.2254 246.75 75.155 246.624 75.0293C246.498 74.9037 246.427 74.7331 246.426 74.5551C246.426 74.3771 246.496 74.2062 246.622 74.0799C246.747 73.9537 246.918 73.8825 247.096 73.8818C247.274 73.8812 247.445 73.9513 247.571 74.0767C247.697 74.202 247.769 74.3724 247.77 74.5505" stroke="black" strokeWidth="0.84383" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M248.16 86.9803C248.16 87.1583 248.09 87.329 247.964 87.455C247.838 87.5809 247.667 87.6518 247.489 87.652C247.311 87.6522 247.141 87.5817 247.014 87.4561C246.888 87.3304 246.817 87.1599 246.817 86.9818C246.816 86.8038 246.887 86.6329 247.012 86.5067C247.138 86.3805 247.308 86.3092 247.486 86.3086C247.664 86.308 247.835 86.3781 247.962 86.5034C248.088 86.6288 248.159 86.7992 248.16 86.9772" fill="black"/>
        <path d="M248.16 86.9803C248.16 87.1583 248.09 87.329 247.964 87.455C247.838 87.5809 247.667 87.6518 247.489 87.652C247.311 87.6522 247.141 87.5817 247.014 87.4561C246.888 87.3304 246.817 87.1599 246.817 86.9818C246.816 86.8038 246.887 86.6329 247.012 86.5067C247.138 86.3805 247.308 86.3092 247.486 86.3086C247.664 86.308 247.835 86.3781 247.962 86.5034C248.088 86.6288 248.159 86.7992 248.16 86.9772" stroke="black" strokeWidth="0.84383" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M266.22 100.679C266.22 100.679 265.904 102.773 265.627 104.077C265.35 105.381 265.232 106.566 265.864 106.724C266.496 106.882 267.603 104.63 267.603 104.63C267.603 104.63 267.484 107.791 267.366 109.174C267.247 110.557 266.18 113.046 267.287 113.323C268.393 113.599 269.144 110.833 269.262 110.043C269.381 109.253 269.657 107.396 269.657 107.396" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M268.59 112.453C268.59 112.453 268.274 114.349 269.262 114.468C270.25 114.586 270.921 111.939 271.079 110.438C271.237 108.936 271.435 107.514 271.435 107.514" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M270.687 112.624C270.687 112.624 271.001 113.756 271.909 113.756C272.818 113.756 273.016 111.465 273.055 110.121C273.095 108.778 273.213 107.355 273.213 107.355" stroke="black" strokeWidth="0.84383" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M273.002 112.243C273.002 112.243 273.213 112.966 274.003 112.769C274.794 112.571 274.912 110.556 274.912 107.237C274.912 103.918 274.794 101.429 274.794 101.429" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M170.547 184.838H262.747L263.194 249.992L111.875 249.88L111.54 212.107L170.516 211.944L170.547 184.838Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.012 192.102L174.068 188.303H177.812" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M259.011 241.745L259.064 245.545L255.321 245.651" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M237.378 184.615C237.378 184.615 236.596 178.468 239.948 178.021C243.301 177.574 246.766 176.345 247.548 175.339C248.33 174.333 247.995 171.763 247.995 171.763H258.165V184.838" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M216.425 184.148C216.425 184.148 215.643 178.001 218.995 177.554C222.348 177.107 225.812 175.878 226.595 174.872C227.377 173.866 227.042 171.296 227.042 171.296H237.212V184.371" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M155.684 56.821C155.684 56.821 156.746 57.6592 157.36 59.2797C157.975 60.9001 158.143 61.4589 158.143 61.4589L164.122 58.665C164.122 58.665 163.842 56.9328 163.339 55.927C162.836 54.9212 162.389 53.8595 162.389 53.8595C162.389 53.8595 163.898 54.6976 164.569 55.8711C165.239 57.0445 165.574 57.9386 165.574 57.9386L173.621 54.1947C173.621 54.1947 173.006 51.5685 172 50.3391C170.995 49.1098 163.73 42.5162 155.013 46.2041C146.296 49.8921 146.129 56.9887 146.576 60.5649C147.023 64.1411 149.146 66.3762 149.034 68.9466C148.923 71.517 146.296 72.7463 146.296 72.7463C146.296 72.7463 147.582 76.0431 152.667 76.6019C157.751 77.1607 162.278 73.0257 162.278 73.0257C162.278 73.0257 160.657 73.1374 159.372 72.2434C158.087 71.3494 157.584 70.5671 157.584 70.5671C157.584 70.5671 155.069 72.3552 153.058 69.3378C151.046 66.3203 154.566 64.0852 155.796 65.0351C157.025 65.9851 155.684 64.9234 155.684 64.9234C155.684 64.9234 156.494 60.7325 155.684 56.821Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M160.545 74.4781C160.545 74.4781 165.63 78.6689 171.33 74.4222C177.029 70.1755 173.956 59.5027 172.224 54.9766" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M168.424 60.8438C168.424 60.8438 169.262 62.576 170.045 63.3583C170.827 64.1406 171.442 64.0847 171.665 64.4758C171.889 64.867 170.771 66.8227 170.771 66.8227" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M166.689 69.7797C166.689 69.7797 167.637 70.9651 169.218 70.8071C170.798 70.649 171.588 69.7402 171.588 69.7402" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M165.267 61.6367C165.267 61.8277 165.191 62.0109 165.056 62.146C164.921 62.2812 164.738 62.3572 164.547 62.3574C164.356 62.3576 164.172 62.282 164.037 62.1472C163.902 62.0124 163.826 61.8294 163.825 61.6384C163.825 61.4474 163.9 61.264 164.035 61.1286C164.169 60.9931 164.352 60.9167 164.543 60.916C164.734 60.9154 164.918 60.9905 165.053 61.1251C165.189 61.2596 165.266 61.4424 165.267 61.6334" fill="black"/>
        <path d="M165.267 61.6367C165.267 61.8277 165.191 62.0109 165.056 62.146C164.921 62.2812 164.738 62.3572 164.547 62.3574C164.356 62.3576 164.172 62.282 164.037 62.1472C163.902 62.0124 163.826 61.8294 163.825 61.6384C163.825 61.4474 163.9 61.264 164.035 61.1286C164.169 60.9931 164.352 60.9167 164.543 60.916C164.734 60.9154 164.918 60.9905 165.053 61.1251C165.189 61.2596 165.266 61.4424 165.267 61.6334" stroke="black" strokeWidth="0.641312" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M171.346 59.2978C171.346 59.4888 171.27 59.672 171.135 59.8072C171 59.9423 170.817 60.0183 170.626 60.0185C170.435 60.0188 170.252 59.9432 170.116 59.8083C169.981 59.6735 169.905 59.4905 169.904 59.2995C169.904 59.1085 169.979 58.9252 170.114 58.7897C170.249 58.6543 170.432 58.5778 170.622 58.5772C170.813 58.5765 170.997 58.6517 171.133 58.7862C171.268 58.9207 171.345 59.1035 171.346 59.2945" fill="black"/>
        <path d="M171.346 59.2978C171.346 59.4888 171.27 59.672 171.135 59.8072C171 59.9423 170.817 60.0183 170.626 60.0185C170.435 60.0188 170.252 59.9432 170.116 59.8083C169.981 59.6735 169.905 59.4905 169.904 59.2995C169.904 59.1085 169.979 58.9252 170.114 58.7897C170.249 58.6543 170.432 58.5778 170.622 58.5772C170.813 58.5765 170.997 58.6517 171.133 58.7862C171.268 58.9207 171.345 59.1035 171.346 59.2945" stroke="black" strokeWidth="0.641312" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M75.689 76.7412C75.689 76.9322 75.6131 77.1154 75.4782 77.2505C75.3432 77.3857 75.1601 77.4617 74.9691 77.4619C74.7781 77.4621 74.5948 77.3865 74.4595 77.2517C74.3242 77.1169 74.248 76.9339 74.2476 76.7429C74.2471 76.5519 74.3225 76.3685 74.4572 76.2331C74.5918 76.0976 74.7748 76.0212 74.9658 76.0205C75.1568 76.0199 75.3402 76.095 75.4758 76.2295C75.6114 76.3641 75.6881 76.5469 75.689 76.7379" fill="black"/>
        <path d="M75.689 76.7412C75.689 76.9322 75.6131 77.1154 75.4782 77.2505C75.3432 77.3857 75.1601 77.4617 74.9691 77.4619C74.7781 77.4621 74.5948 77.3865 74.4595 77.2517C74.3242 77.1169 74.248 76.9339 74.2476 76.7429C74.2471 76.5519 74.3225 76.3685 74.4572 76.2331C74.5918 76.0976 74.7748 76.0212 74.9658 76.0205C75.1568 76.0199 75.3402 76.095 75.4758 76.2295C75.6114 76.3641 75.6881 76.5469 75.689 76.7379" stroke="black" strokeWidth="0.641312" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M82.98 73.8828C82.98 74.0738 82.9041 74.257 82.7692 74.3921C82.6342 74.5273 82.4511 74.6033 82.2601 74.6035C82.0691 74.6037 81.8858 74.5281 81.7505 74.3933C81.6153 74.2585 81.539 74.0755 81.5386 73.8845C81.5381 73.6935 81.6135 73.5101 81.7482 73.3747C81.8829 73.2392 82.0658 73.1628 82.2568 73.1621C82.4478 73.1615 82.6312 73.2366 82.7668 73.3711C82.9024 73.5057 82.9791 73.6885 82.98 73.8795" fill="black"/>
        <path d="M82.98 73.8828C82.98 74.0738 82.9041 74.257 82.7692 74.3921C82.6342 74.5273 82.4511 74.6033 82.2601 74.6035C82.0691 74.6037 81.8858 74.5281 81.7505 74.3933C81.6153 74.2585 81.539 74.0755 81.5386 73.8845C81.5381 73.6935 81.6135 73.5101 81.7482 73.3747C81.8829 73.2392 82.0658 73.1628 82.2568 73.1621C82.4478 73.1615 82.6312 73.2366 82.7668 73.3711C82.9024 73.5057 82.9791 73.6885 82.98 73.8795" stroke="black" strokeWidth="0.641312" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M162.39 78.7252C166.692 78.5575 167.586 78.334 167.921 78.7252C168.257 79.1163 168.424 81.4632 168.424 81.4632C168.424 81.4632 172.895 81.5191 174.683 83.6983C176.471 85.8776 181.835 103.926 181.835 103.926C181.835 103.926 191.726 99.7354 195.19 100.518C198.655 101.3 199.549 102.418 200.499 104.541C201.448 106.664 201.281 108.62 199.549 110.073C197.816 111.526 195.849 112.176 191.384 113.243C186.919 114.31 180.834 115.258 178.938 114.903C177.041 114.547 175.342 113.085 175.342 113.085C175.342 113.085 176.862 117.84 175.856 119.796C174.85 121.751 168.369 123.428 163.116 123.707C157.863 123.987 150.543 124.992 148.252 122.031C145.961 119.069 148.588 114.655 148.811 110.464C149.035 106.273 149.146 102.306 149.146 102.306C149.146 102.306 146.52 111.246 143.279 117.114C140.038 122.981 137.356 129.91 129.701 124.992C122.045 120.075 133.333 108.508 136.183 103.367C139.032 98.2267 144.341 86.3246 150.04 83.9219C155.74 81.5191 156.411 81.4073 156.411 81.4073C156.411 81.4073 156.075 79.0884 156.494 78.781C156.913 78.4737 161.132 78.7252 162.39 78.7252Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M157.799 75.9434L157.839 78.7092L157.799 75.9434Z" fill="black"/>
        <path d="M157.799 75.9434L157.839 78.7092" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M167.044 76.2598L167.123 78.9071L167.044 76.2598Z" fill="black"/>
        <path d="M167.044 76.2598L167.123 78.9071" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M148.553 121.778C148.553 121.778 144.997 130.787 143.101 139.875C141.204 148.962 139.308 156.628 139.308 156.628C139.308 156.628 158.589 160.737 174.947 150.859C191.305 140.981 192.175 135.212 192.175 135.212C192.175 135.212 184.035 130.155 181.269 127.626C178.503 125.097 174.947 120.672 174.947 120.672" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M147.368 157.259L149.501 142.087" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M170.838 152.596L166.57 139.32" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M187.265 141.602L177.871 133.394" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M180.321 146.986L173.13 136.555" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M158.747 156.311L158.036 140.98" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M183.64 144.932L178.74 169.982" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M192.095 135.844C192.095 135.844 195.177 136.713 196.046 139.953C196.916 143.193 188.144 169.034 188.144 169.034C188.144 169.034 191.305 174.328 193.202 175.909C195.098 177.489 196.995 178.912 196.995 178.912" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M178.661 170.062C178.661 170.062 184.351 176.225 187.433 177.964C190.515 179.702 194.229 180.73 195.414 180.098C196.6 179.465 196.442 178.438 196.442 178.438C196.442 178.438 201.657 180.809 200.788 182.626C199.919 184.444 198.575 184.602 197.627 184.602C196.679 184.602 187.907 184.76 187.907 184.76L182.454 180.177V184.681L178.74 184.602C178.74 184.602 178.898 182.705 178.424 179.939C177.95 177.174 177.16 176.146 177.476 173.855C177.792 171.563 178.661 170.062 178.661 170.062Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M143.18 188.157C143.18 188.157 142.547 195.269 144.444 198.588C146.341 201.907 149.027 206.333 151.872 206.096C153.309 205.976 152.267 202.54 152.267 202.54C152.267 202.54 156.06 208.94 154.875 209.889C153.69 210.837 150.845 210.6 147.447 208.703C144.049 206.807 141.441 203.251 141.204 201.907C140.967 200.564 139.545 197.166 139.545 197.166L137.332 201.512L134.645 200.169C134.645 200.169 135.672 198.114 136.067 196.218C136.463 194.321 137.411 192.425 138.438 191.002C139.465 189.58 143.18 188.157 143.18 188.157Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M150.319 157.457C150.319 157.457 150.766 166.174 150.319 167.962C149.872 169.75 143.055 188.861 143.055 188.861" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M165.407 155.11C165.407 155.11 163.619 165.727 162.501 168.633C161.384 171.539 151.884 192.884 151.884 192.884C151.884 192.884 151.437 195.231 151.549 197.913C151.661 200.595 152.22 202.607 152.22 202.607" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M197.153 101.35C197.745 100.481 199.524 97.6752 200.906 96.4503C202.289 95.2255 203.988 94.5538 204.621 94.8303C205.253 95.1069 205.45 95.3045 205.45 95.3045" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M201.776 98.1495C201.776 98.1495 204.818 95.1862 205.767 95.2652C206.715 95.3443 206.833 95.1071 207.189 95.4627C207.545 95.8183 207.742 96.253 207.742 96.253" stroke="black" strokeWidth="0.84383" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M203.277 99.2555C203.277 99.2555 205.608 97.2008 206.675 96.7267C207.742 96.2525 208.413 95.8969 208.927 96.2921C209.441 96.6872 209.362 97.675 209.362 97.675" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M204.7 100.56C204.7 100.56 206.32 99.335 207.308 98.6633C208.295 97.9916 209.244 97.5965 209.678 98.1497C210.113 98.7028 208.69 99.9672 207.821 100.599C206.952 101.232 204.976 102.812 204.976 102.812C204.976 102.812 206.794 101.666 207.387 102.259C207.979 102.852 207.268 104.076 205.371 105.104C203.475 106.131 201.262 106.763 201.262 106.763" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M204.578 94.7619C204.578 94.7619 206.589 93.1972 207.819 92.5826C209.048 91.9679 210.724 90.9062 210.724 90.9062" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M206.589 104.429C206.589 104.429 206.869 104.932 207.372 104.932C207.875 104.932 211.786 102.418 213.351 100.574C214.915 98.7298 216.703 96.1035 216.703 96.1035" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M207.742 102.417L210.271 100.441" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M67.2126 79.9584C67.2126 79.9584 64.9209 79.0101 64.1306 81.5388C63.3404 84.0676 65.0789 86.3593 66.1853 86.4383C67.2916 86.5174 68.3189 86.0432 68.3189 86.0432L68.9511 96.3953L73.9338 101.69L78.907 96.7732L78.5717 92.0794" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M72.9023 91.0224C72.9023 91.0224 79.0662 93.6302 82.7803 90.0741C86.4945 86.518 86.4154 82.1717 85.7832 77.6674C85.151 73.163 84.5979 71.1084 84.5979 71.1084" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M64.131 84.7796C64.131 84.7796 59.8637 84.5425 58.9154 82.646C57.9671 80.7494 59.1524 78.6157 59.0734 77.0353C58.9944 75.4548 55.7544 72.689 56.0705 68.4217C56.3866 64.1544 60.812 62.1788 60.812 62.1788C60.812 62.1788 61.1477 59.6796 62.6196 58.0893C63.7806 56.8349 63.8939 56.8842 66.2646 56.094C68.6353 55.3037 72.4285 59.3339 73.8509 59.2549C75.2733 59.1759 79.6196 57.2793 81.7533 58.6227C83.8869 59.9661 84.045 64.0754 85.5464 64.7076C87.0479 65.3397 88.5493 63.6012 88.5493 63.6012C88.5493 63.6012 88.7864 65.4188 88.5493 67.6314C88.3123 69.8441 86.5737 71.0294 84.5981 71.1085C82.6225 71.1875 82.3064 69.528 80.884 68.9748C79.4616 68.4217 78.2762 70.2392 76.6957 70.3973C75.1153 70.5553 74.246 69.2119 72.1124 68.0266C69.9787 66.8412 67.45 69.0539 67.45 69.0539C67.45 69.0539 65.6083 70.2877 65.7759 73.6963C65.9436 77.1048 66.8376 75.7638 67.3964 77.7195C67.9552 79.6753 67.2847 80.0664 67.2847 80.0664" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M77.9999 76.8369C77.9999 77.7688 77.63 78.6627 76.9714 79.322C76.3128 79.9814 75.4194 80.3524 74.4874 80.3534C73.5555 80.3545 72.6612 79.9856 72.0011 79.3278C71.3409 78.6699 70.969 77.7769 70.9668 76.845C70.9647 75.913 71.3325 75.0183 71.9896 74.3575C72.6467 73.6966 73.5393 73.3236 74.4712 73.3203C75.4031 73.3171 76.2983 73.684 76.9599 74.3403C77.6215 74.9966 77.9956 75.8887 77.9999 76.8207" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M85.5067 73.8276C85.5067 74.6055 85.1917 75.3515 84.6309 75.9018C84.0701 76.4522 83.3094 76.7618 82.5158 76.7627C81.7223 76.7636 80.9608 76.4557 80.3987 75.9066C79.8367 75.3575 79.5199 74.6122 79.5181 73.8344C79.5162 73.0565 79.8295 72.3098 80.389 71.7582C80.9485 71.2066 81.7085 70.8953 82.502 70.8926C83.2956 70.8899 84.0578 71.1961 84.6211 71.7439C85.1845 72.2917 85.503 73.0363 85.5067 73.8141" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M77.9204 75.6125L79.5799 74.8223" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M71.0061 78.7344L67.292 80.0778" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M79.6899 76.6016L82.7632 80.8483L81.031 82.6923" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M76.728 85.3185C76.728 85.3185 78.2926 86.4361 79.969 86.1567C81.6453 85.8773 82.3717 84.7598 82.3717 84.7598" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M68.5718 96.3057C68.5718 96.3057 69.1306 125.642 68.3483 140.17C67.566 154.698 66.8955 162.633 66.8955 162.633L52.0318 161.069L56.0551 121.954L40.7444 156.822C40.7444 156.822 34.7096 155.928 31.5804 154.587C28.4512 153.246 26.6631 150.563 26.6631 150.563C26.6631 150.563 40.4091 121.171 44.4324 113.684C48.4556 106.196 54.1552 99.0437 58.849 97.8144C63.5427 96.585 68.5718 96.3057 68.5718 96.3057Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M91.983 120.242C91.983 120.242 92.7653 125.495 93.5476 129.742C94.3299 133.988 97.459 160.251 97.459 160.251L84.3835 161.927C84.3835 161.927 83.1542 144.605 82.8189 131.418C82.4837 118.231 79.3545 96.8292 79.3545 96.8292C79.3545 96.8292 84.7188 95.097 90.3066 101.467C95.8945 107.837 100.029 123.595 100.029 123.595L101.929 129.996L112.36 130.391C112.36 130.391 114.201 131.166 114.754 136.065C115.307 140.965 113.625 143.193 113.625 143.193L95.6074 142.718" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M64.946 96.6484L61.7061 111.584L64.709 113.876L61.8641 117.274L68.3441 139.242" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M83.5572 97.1855L87.5084 112.042L85.4538 114.097L87.4294 115.598L83.1621 137.962" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M68.8491 106.552L72.8724 103.87" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M75.7783 103.702L80.081 106.273" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M76.4486 113.817C76.4486 114.098 76.3369 114.368 76.138 114.567C75.9392 114.766 75.6695 114.878 75.3881 114.878C75.1067 114.879 74.8367 114.767 74.6375 114.569C74.4382 114.37 74.3258 114.1 74.3252 113.819C74.3246 113.538 74.4356 113.268 74.634 113.068C74.8324 112.868 75.1018 112.756 75.3832 112.755C75.6646 112.754 75.9348 112.865 76.1346 113.063C76.3343 113.261 76.4473 113.53 76.4486 113.812" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M76.5579 125.974C76.5579 126.255 76.4463 126.525 76.2474 126.724C76.0486 126.923 75.7788 127.035 75.4975 127.035C75.2161 127.036 74.9461 126.924 74.7468 126.726C74.5475 126.527 74.4352 126.258 74.4346 125.976C74.4339 125.695 74.545 125.425 74.7434 125.225C74.9418 125.026 75.2112 124.913 75.4926 124.912C75.774 124.911 76.0442 125.022 76.2439 125.22C76.4437 125.418 76.5566 125.688 76.5579 125.969" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M76.8841 137.534C76.8841 137.816 76.7724 138.086 76.5736 138.285C76.3747 138.484 76.105 138.596 75.8237 138.596C75.5423 138.596 75.2723 138.485 75.073 138.286C74.8737 138.088 74.7614 137.818 74.7607 137.537C74.7601 137.255 74.8712 136.985 75.0695 136.786C75.2679 136.586 75.5374 136.474 75.8188 136.473C76.1001 136.472 76.3704 136.582 76.5701 136.781C76.7699 136.979 76.8828 137.248 76.8841 137.529" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M76.9862 148.496C76.9862 148.778 76.8745 149.047 76.6756 149.247C76.4768 149.446 76.2071 149.558 75.9257 149.558C75.6443 149.558 75.3743 149.447 75.175 149.248C74.9758 149.05 74.8634 148.78 74.8628 148.499C74.8621 148.217 74.9732 147.947 75.1716 147.748C75.37 147.548 75.6394 147.436 75.9208 147.435C76.2022 147.434 76.4724 147.544 76.6722 147.743C76.8719 147.941 76.9849 148.21 76.9862 148.491" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M55.1225 161.743L54.9648 237.588C54.9648 237.588 59.6956 238.553 63.9534 238.746C68.2111 238.939 71.365 238.746 71.365 238.746L75.3073 169.655L80.1958 238.939C80.1958 238.939 85.0843 239.325 89.342 238.939C93.5997 238.553 96.4382 237.202 96.4382 237.202L93.452 160.773L84.0855 162.138L83.5837 150.743C83.5837 150.743 80.6846 152.79 75.7226 152.927C70.7605 153.063 67.9171 151.221 67.9171 151.221L67.3039 163.503L55.1225 161.743Z" fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M58.2291 238.496C58.2291 238.496 56.9647 242.605 57.5969 246.478C58.2291 250.35 58.3081 250.271 58.3081 250.271C58.3081 250.271 62.7335 250.745 68.2652 250.587C73.6389 250.433 78.2222 250.113 78.2222 250.113C78.2222 250.113 78.5383 245.529 74.9822 244.107C71.4261 242.684 70.3198 242.289 68.1071 238.97" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M83.2793 239.286C83.2793 239.286 82.6471 240.708 82.8052 244.343C82.9632 247.978 83.4373 250.033 83.4373 250.033C83.4373 250.033 87.9417 250.033 94.8168 250.033C101.692 250.033 105.248 249.638 105.248 249.638C105.248 249.638 104.932 245.924 103.43 244.185C101.929 242.447 99.7953 242.051 97.0294 241.261C94.2636 240.471 93.3944 237.784 93.3944 237.784" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M126.782 120.976C126.818 120.911 120.417 124.301 117.856 128.613C116.643 130.657 116.212 131.786 116.703 132.383C117.194 132.98 117.453 132.647 117.453 132.647" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M120.951 127.729C120.951 127.729 118.86 130.121 117.909 131.896C116.959 133.671 116.615 134.414 117.387 134.992C118.158 135.57 118.639 135.178 118.639 135.178" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M122.23 129.849C122.23 129.849 120.139 132.241 119.189 134.016C118.238 135.791 117.894 136.534 118.666 137.112C119.437 137.69 119.918 137.298 119.918 137.298" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M123.854 131.431C123.854 131.431 122.166 133.121 121.216 134.895C120.266 136.67 119.922 137.414 120.693 137.992C121.465 138.57 122.361 137.654 122.361 137.654C122.361 137.654 122.863 136.827 124.321 135.062C125.779 133.298 127.067 132.355 127.067 132.355C127.067 132.355 125.156 134.916 126.102 135.572C127.047 136.228 127.917 135.764 129.401 133.548C130.885 131.332 131.326 130.301 131.326 130.301L133.109 126.557" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M28.5412 153.075C28.5525 153.003 24.7139 157.216 23.8201 162.151C23.3966 164.489 23.3871 165.698 24.0557 166.086C24.7244 166.474 24.8511 166.071 24.8511 166.071" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.4115 160.241C26.4115 160.241 25.2871 163.213 25.0165 165.208C24.7459 167.203 24.6831 168.02 25.6079 168.292C26.5327 168.564 26.8458 168.029 26.8458 168.029" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M28.35 161.781C28.35 161.781 27.2256 164.753 26.9549 166.748C26.6843 168.743 26.6216 169.56 27.5464 169.832C28.4712 170.105 28.7843 169.569 28.7843 169.569" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30.4255 162.697C30.4255 162.697 29.4336 164.87 29.163 166.865C28.8924 168.86 28.8296 169.677 29.7544 169.949C30.6792 170.221 31.1987 169.05 31.1987 169.05C31.1987 169.05 31.3804 168.1 32.1305 165.937C32.8806 163.775 33.7586 162.442 33.7586 162.442C33.7586 162.442 32.8619 165.508 33.9773 165.793C35.0927 166.077 35.7459 165.339 36.3623 162.744C36.9787 160.149 37.6672 156.682 37.6672 156.682" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M113.384 131.139C113.384 131.139 115.228 130.189 116.178 129.91C117.128 129.63 117.296 129.407 117.296 129.407L117.743 129.016" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M114.781 141.142C114.781 141.142 120.928 140.806 123.331 139.465C125.733 138.124 127.633 135.554 127.633 135.554" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M129.309 201.489C129.309 201.489 125.398 203.277 122.604 203.948" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M130.986 203.725C130.986 203.725 128.975 205.177 126.181 206.407" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </IconWrapper>
  )
})
