import {
  ILayoutAction,
  ILayoutState,
  LAYOUT_SET_PAGE_TITLE,
  LAYOUT_SET_SCROLL_TO_BOTTOM
} from '../types'

const initState: ILayoutState = {
  pageTitle: null,
  isScrollToBottom: false
}

export const reducer = (state = initState, action: ILayoutAction) => {
  switch (action.type) {
    case LAYOUT_SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.value
      }
    case LAYOUT_SET_SCROLL_TO_BOTTOM:
      return {
        ...state,
        isScrollToBottom: action.value
      }
    default:
      return state
  }
}
