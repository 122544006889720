import { UnregisterCallback } from 'history'
import React, { ComponentProps } from 'react'
import { useHistory } from 'react-router'
import { BehaviorSubject, finalize, from, take, tap } from 'rxjs'
import type { TourPopper } from 'src/partials/tour'
import { ViewJobCompleted } from 'src/partials/tour'
import { CampaignModule, LoadingModule } from 'src/store'
import { Target } from 'src/utils'
import { WithOutNextComplete } from 'types/rxjs'
import { OverlayService } from '../overlay.service'
import { PopupTourService } from './popup.service'

export const TARGET_CARD_JOB = new Target('target-card-job')
export const TARGET_JOB_TITLE = new Target('target-job-title')
export const TARGET_JOB_EDIT_BUTTON = new Target('target-job-edit-button')
export const TARGET_JOB_SHARE_BUTTON = new Target('target-job-share-button')
export const TARGET_JOB_SEARCH_SORT = new Target('target-job-search-sort')
export const TARGET_JOB_VIEW_BY = new Target('target-job-view-by')

const MAX_STEP_TOTAL = 6

const STEP_CONFIGS: ComponentProps<typeof TourPopper>[] = [
  {
    title: 'Let’s take a look at your job',
    content: 'Once you create a job, this is how it looks. Head over and explore it now.',
    currentStep: 0,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_CARD_JOB.getSelector(),
    arrowPosition: 'left',
    // tip: 'Click on this job',
    ctaText: 'Next',
    onCtaClick: () => {
      (document.querySelector(TARGET_CARD_JOB.getSelector()) as HTMLButtonElement)?.click()
    },
    shouldScroll: false,
    onExitClick: () => ShareJobTourService.stopTour(true)
  },
  {
    title: 'Preview Your Job',
    content: 'Click the Job Label to view its details. This is how your job listing appears to potential applicants when you share the job link.',
    currentStep: 1,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_JOB_TITLE.getSelector(),
    arrowPosition: 'top',
    ctaText: 'Next',
    onCtaClick: () => {
      ShareJobTourService.nextStep()
    },
    onBack: () => {
      ShareJobTourService.previousStep()
    },
    // tip: 'Click on this Job Label',
    shouldScroll: false,
    onExitClick: () => ShareJobTourService.stopTour(true)
  },
  {
    title: 'Edit Your Job',
    content: 'Tweak your job details whenever you like.',
    currentStep: 2,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_JOB_EDIT_BUTTON.getSelector(),
    arrowPosition: 'top',
    shouldScroll: false,
    ctaText: 'Next',
    onCtaClick: () => {
      ShareJobTourService.nextStep()
    },
    onBack: () => {
      ShareJobTourService.previousStep()
    },
    onExitClick: () => ShareJobTourService.stopTour(true)
  },
  {
    title: 'Share Your Job',
    content: 'Copy link and share your job on popular platforms or even your own channel to attract a flood of applications.',
    currentStep: 3,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_JOB_SHARE_BUTTON.getSelector(),
    // tip: 'Click on this icon to copy & share',
    ctaText: 'Next',
    onCtaClick: () => {
      ShareJobTourService.nextStep()
    },
    onBack: () => {
      ShareJobTourService.previousStep()
    },
    shouldScroll: false,
    arrowPosition: 'top',
    onExitClick: () => ShareJobTourService.stopTour(true)
  },
  {
    title: 'Search & Sort!',
    content: 'When applicants start flooding in, take control by searching or sorting them by newest or oldest. Manage your applicants effortlessly!',
    currentStep: 4,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_JOB_SEARCH_SORT.getSelector(),
    shouldScroll: false,
    arrowPosition: 'top',
    ctaText: 'Next',
    onCtaClick: () => {
      ShareJobTourService.nextStep()
    },
    onBack: () => {
      ShareJobTourService.previousStep()
    },
    onExitClick: () => ShareJobTourService.stopTour(true)
  },
  {
    title: 'Switch Applicant View Mode',
    content: 'Switch applicant view mode to Video Vibe Check (VVC) or access applicant\'s insights for a quick overview of their information.',
    currentStep: 5,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_JOB_VIEW_BY.getSelector(),
    shouldScroll: false,
    arrowPosition: 'right',
    width: '379px',
    ctaText: 'End Tour',
    onCtaClick: (history, dispatch) => {
      LoadingModule.toggle(true)
      ShareJobTourService.nextStep(() => {
        from(CampaignModule.fetchCounts())
          .pipe(
            take(1),
            finalize(() => {
              LoadingModule.toggle(false)
            })
          )
          .subscribe(res => {
            if (res.data.count === 0) {
              OverlayService.setOverlay({
                open: true,
                content: React.createElement(ViewJobCompleted)
              })
            }
          })
      })
    },
    onBack: () => {
      ShareJobTourService.previousStep()
    },
    onExitClick: () => ShareJobTourService.stopTour(true)
  }
]

export class ShareJobTourService {
  private static _currentStep$ = new BehaviorSubject(0)
  private static _enableTour$ = new BehaviorSubject(false)
  private static _routeListener?: UnregisterCallback = undefined

  public static get currentStep$(): WithOutNextComplete<typeof ShareJobTourService._currentStep$> {
    return ShareJobTourService._currentStep$
  }

  public static get enableTour$(): WithOutNextComplete<typeof ShareJobTourService._enableTour$> {
    return ShareJobTourService._enableTour$
  }

  public static nextStep(onAfterNext?: () => void) {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()
    this._currentStep$.next(currentStep + 1)

    if (currentStep + 1 === MAX_STEP_TOTAL) {
      PopupTourService.completeMission('share-job')
        .pipe(
          take(1),
          tap(() => {
            this.stopTour()
          }),
          finalize(() => {
            onAfterNext?.()
          })
        ).subscribe()
    }
  }

  public static goToStep(step: number) {
    if (!this._enableTour$.getValue()) {
      return
    }

    this._currentStep$.next(step)
  }

  public static previousStep() {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()
    this._currentStep$.next(currentStep - 1)
  }

  public static startTour(history?: ReturnType<typeof useHistory>) {
    PopupTourService.hide()
    this._currentStep$.next(0)
    this._enableTour$.next(true)
    OverlayService.reset()

    this.stopRouteListener()

    this._routeListener = history?.listen((location) => {
      if (!(['/campaigns', '/recording'].some(tourPath => location.pathname.startsWith(tourPath)))) {
        this.stopTour(true)
      }
    })
  }

  private static stopRouteListener() {
    if (this._routeListener) {
      this._routeListener()
      this._routeListener = undefined
    }
  }

  public static stopTour(showPopup = false) {
    if (!this._enableTour$.getValue()) {
      return
    }

    this.stopRouteListener()

    if (showPopup) {
      PopupTourService.show()
    }

    this._enableTour$.next(false)
    this._currentStep$.next(0)
  }

  public static getStepConfig() {
    return STEP_CONFIGS[this._currentStep$.getValue()]
  }
}
