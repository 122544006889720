import clsx from 'clsx'
import {
  ComponentProps,
  FC,
  InputHTMLAttributes,
  memo,
  useEffect,
  useMemo,
  useState
} from 'react'
import InputMask from 'react-input-mask'
import Style from './style.module.scss'

enum EComponentType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  INTERMEDIATE = 'intermediate'
}

enum EComponentSize {
  SMALL = 'small',
  REGULAR = 'regular',
  LARGE = 'large'
}

type TInputSize = `${Exclude<EComponentSize, EComponentSize.LARGE>}`
type TInputType = `${Exclude<EComponentType, EComponentType.TERTIARY>}`

interface IProps {
  autoFocus?: boolean
  variant?: TInputType
  size?: TInputSize
  value?: string
  onChange: ComponentProps<'input'>['onChange']
  error?: any
  errors?: any
}

export const InputPhoneNumber: FC<
  Omit<InputHTMLAttributes<Element>, keyof IProps> & IProps
> = memo(function InputPhoneNumber({
  autoFocus = false,
  onChange,
  size = EComponentSize.REGULAR,
  variant = EComponentType.PRIMARY,
  error,
  errors,
  children,
  ...props
}) {
  const [mounted, setMounted] = useState(false)
  const [focus, setFocus] = useState(false)

  const errorMessage = useMemo(
    () =>
      error ||
      (Array.isArray(errors) && errors[0]) ||
      (Object.isObject(errors) && Object.values(errors)[0]),
    [error, errors]
  )

  useEffect(() => {
    if (mounted && autoFocus) {
      window?.document?.getElementById?.('input-phone-number')?.focus()
      return
    }

    if (!mounted) {
      setMounted(true)
    }
    return () => {
      if (mounted) {
        setMounted(false)
      }
      window?.document?.getElementById?.('input-phone-number')?.blur()
    }
  }, [autoFocus, mounted])

  return (
    <div className={clsx('fx-column gap-2', Style.inputPhoneNumber)}>
      <div
        className={clsx(
          'fx-row w-100 fx-ai-center gap-2',
          Style.container,
          {
            'bg-neutral-100 round-2': variant === 'primary',
            'bg-neutral-white round-3': variant === 'secondary',
            'px-4': size === EComponentSize.REGULAR,
            'py-2 px-4': size === EComponentSize.SMALL,
            'not-allow': props.disabled,
            [Style.primary]: variant === 'primary',
            [Style.secondary]: variant === 'secondary',
            [Style.regular]: size === EComponentSize.REGULAR,
            [Style.small]: size === EComponentSize.SMALL,
            [Style.error]: !!errorMessage,
            [Style.disabled]: props.disabled,
            [Style.focus]: focus
          },
          props.className
        )}
      >
        {/* <span
          className={clsx({
            'body-1': variant === 'primary',
            'body-2': variant === 'secondary'
          })}
        >(+1)
        </span> */}
        <InputMask
          name="number"
          {...props}
          mask="999-999-9999"
          className={clsx(
            'fx-1 w-100 txt-neutral-700 bg-transparent p-0 border-none outline-none',
            Style.main,
            {
              'body-1': variant === 'primary',
              'body-2': variant === 'secondary',
              [Style.primary]: variant === 'primary',
              [Style.secondary]: variant === 'secondary',
              [Style.regular]: size === EComponentSize.REGULAR,
              [Style.small]: size === EComponentSize.SMALL
            }
          )}
          type="tel"
          maskChar={null}
          onChange={onChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </div>

      {Boolean(errorMessage) && <span className="mb-0 txt-negative-500 meta">{errorMessage}</span>}
    </div>
  )
})
