import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconBell: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0004 2.81274C13.0378 2.81274 15.5179 5.2088 15.6222 8.24953L15.6254 8.43774V11.5105L16.6604 13.8775C16.7122 13.9959 16.7389 14.1238 16.7389 14.2531C16.7389 14.7708 16.3192 15.1906 15.8014 15.1906L12.2504 15.1917C12.2504 16.4343 11.243 17.4417 10.0004 17.4417C8.80213 17.4417 7.82264 16.505 7.75421 15.3239L7.75005 15.19L4.20654 15.1906C4.07803 15.1906 3.9509 15.1642 3.83304 15.113C3.35813 14.9067 3.14037 14.3545 3.34665 13.8796L4.37539 11.5112V8.43766C4.37584 5.32157 6.88945 2.81274 10.0004 2.81274ZM11.1251 15.19L8.87539 15.1917C8.87539 15.813 9.37907 16.3167 10.0004 16.3167C10.5852 16.3167 11.0657 15.8705 11.1202 15.3L11.1251 15.19ZM10.0004 3.93774C7.51027 3.93774 5.50075 5.94343 5.50039 8.43774V11.745L4.4924 14.0656H15.5148L14.5004 11.7457L14.5005 8.44739L14.4977 8.27849C14.4144 5.85338 12.4316 3.93774 10.0004 3.93774Z" fill={color} stroke={color} strokeWidth="0.2"/>
      </svg>
    </IconWrapper>
  )
}
