import { string } from 'yup'

const Pattern: Record<string, RegExp> = {
  mustHaveLetterAndNumber: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].+$/,
  mustHaveLowercaseAndUppercase: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d].+$/,
  mustHaveSpecialCharacter: /^(?=.*?[#?!@$%^&*-]).+$/,
  mustHaveNumber: /^(?=.*\d).+$/,
  mustHaveUppercase: /^(?=.*[A-Z]).+$/
}

// export const editProfileSchema = object().shape({
//   companyName: string().required('This field is required.'),
//   companyUrls: array().of(string().required('This field is required.').matches(RE_URL_WITH_OUT_PROTOCOL, 'Follow the format example.com')),
//   scheduleUrl: string().nullable(true),
//   bio: string().nullable(true).max(150)
// })

// export const editInformationSchema = object().shape({
//   firstName: string().required('This field is required.'),
//   lastName: string().required('This field is required.')
//   // username: string().required('This field is required.'),
//   // email: string().required('This field is required.').test(
//   //   'error_email_domain',
//   //   'Enter your work email',
//   //   (email) => email ? validCompanyEmail(email) : false
//   // )
// })

// export const changePasswordSchema = object().shape({
//   currentPassword: string().required('This field is required.').min(8, 'Current password must be at least 8 characters.'),
//   newPassword: string().required('Please enter your password.')
//     .min(8, 'Password must be at least 8 characters.')
//     .test(
//       'at-least-one-letter-and-number',
//       'Password must be at least 1 letter and 1 number.',
//       (newPassword) => newPassword ? Pattern.mustHaveLetterAndNumber.test(newPassword) : false
//     )
//     .test(
//       'at-least-one-lowercase-and-uppercase',
//       'Password must be at least 1 lowercase and 1 uppercase.',
//       (newPassword) => newPassword ? Pattern.mustHaveLowercaseAndUppercase.test(newPassword) : false
//     ),
//   confirmPassword: string().required('Please retype your password.')
//     .when('newPassword', (val, schema) => {
//       return val
//         ? string().required('Please retype your password.')
//           .oneOf([ref('newPassword'), ''], 'Password does not match')
//         : schema
//     })
// })

export const passwordSchema = string().required('Please enter your password.')
  .min(8, 'Password must be at least 8 characters.')
  // .test(
  //   'at-least-one-letter-and-number',
  //   'Password must be at least 1 letter and 1 number.',
  //   (password) => password ? PATTERN.mustHaveLetterAndNumber.test(password) : false)
  // .test(
  //   'at-least-one-lowercase-and-uppercase',
  //   'Password must be at least 1 lowercase and 1 uppercase.',
  //   (password) => password ? PATTERN.mustHaveLowercaseAndUppercase.test(password) : false)
  // .test(
  //   'at-least-one-special-character',
  //   'Password must be at least 1 special character (#?!@$%^&*-)',
  //   (password) => password ? PATTERN.mustHaveSpecialCharacter.test(password) : false)
  .test(
    'at-least-one-uppercase',
    'Password must be at least 1 uppercase.',
    (password) => password ? Pattern.mustHaveUppercase.test(password) : false)
  .test(
    'at-least-one-number',
    'Password must be at least 1 number',
    (password) => password ? Pattern.mustHaveNumber.test(password) : false)
