import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconVideoTutorial: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.3501 9.9985C2.3501 5.77393 5.77511 2.34961 10.0001 2.34961C14.2251 2.34961 17.6501 5.77393 17.6501 9.9985C17.6501 14.2229 14.2254 17.6471 10.0006 17.6474C8.84807 17.6492 7.71126 17.3896 6.6753 16.8896L3.90936 17.6096C3.69951 17.664 3.47834 17.6628 3.26918 17.6058C3.06003 17.5489 2.86937 17.4383 2.71607 17.285C2.56277 17.1317 2.45215 16.9411 2.39516 16.7319C2.33818 16.5228 2.3368 16.3023 2.39118 16.0925L3.11073 13.3269C2.61287 12.2992 2.3501 11.1665 2.3501 9.9985ZM10.99 7.60893C10.7275 7.34642 10.3714 7.19894 10.0001 7.19894H7.90009C7.52879 7.19894 7.17269 7.34642 6.91014 7.60893C6.64759 7.87144 6.50009 8.22748 6.50009 8.59872V11.3983C6.50009 11.7695 6.64759 12.1256 6.91014 12.3881C7.17269 12.6506 7.52879 12.7981 7.90009 12.7981H10.0001C10.3714 12.7981 10.7275 12.6506 10.99 12.3881C11.2526 12.1256 11.4001 11.7695 11.4001 11.3983V8.59872C11.4001 8.22748 11.2526 7.87144 10.99 7.60893ZM12.1001 10.8118V9.18522L13.3139 8.03601C13.3884 7.96537 13.482 7.91813 13.5831 7.90015C13.6842 7.88216 13.7884 7.89421 13.8827 7.9348C13.977 7.9754 14.0573 8.04276 14.1138 8.12855C14.1702 8.21433 14.2002 8.31478 14.2001 8.41745V11.5795C14.2002 11.6822 14.1702 11.7827 14.1138 11.8684C14.0573 11.9542 13.977 12.0216 13.8827 12.0622C13.7884 12.1028 13.6842 12.1148 13.5831 12.0968C13.482 12.0789 13.3884 12.0316 13.3139 11.961L12.1001 10.8118Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
