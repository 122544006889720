import { Tooltip } from '@mui/material'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, take } from 'rxjs'
import { UploadApi } from 'src/api'
import { Button, Divider, Editor, ErrorMessage, GoogleLocationSearch, Input, useAnalytic } from 'src/components'
import { ELocationType, ETrackingEvent } from 'src/enums'
import { ErrorBag, useBehaviorMapper } from 'src/hooks'
import { IconInfo } from 'src/icons'
import { ICampaignModel } from 'src/interfaces'
import { ModalRecorder } from 'src/partials/recorder/modal-recorder'
import { TourPopper } from 'src/partials/tour'
import { CampaignMutationService, DialogService, SnackbarService } from 'src/services'
import { CreateJobTourService, TARGET_DESCRIPTION, TARGET_ENTER_JOB_DETAIL } from 'src/services/tour/create-job-tour.service'
import { getApiErrorMessage, isEmptyBriefDescription } from 'src/utils'
import { AttachedFile } from '../attached-file'
import { IntroduceYourselfVideo } from '../introduce-yoursefl-video'
import { WorkplaceType } from '../workplace-type'
import { Salary } from './salary'
import Style from './style.module.scss'

/**
 * @deprecated
 */
export const CampaignDetailForm: FC<{
  errors?: ErrorBag<ICampaignModel>
  otherErrors?: {
    briefDescription?: boolean
    uploadVideo?: boolean
  }
  focusedErr?: string
}> = ({ errors: externalErrors, otherErrors, focusedErr }) => {
  const { eventHandler } = useAnalytic()
  // const unsubscribe$ = useUnsubscribe()
  const tourEnabled = useBehaviorMapper(CreateJobTourService.enableTour$)
  const currentTourStep = useBehaviorMapper(CreateJobTourService.currentStep$)
  const data = useBehaviorMapper(CampaignMutationService.data$)

  const [fileName, setFileName] = useState<string>()
  // const [questions, setQuestions] = useState<Awaited<ReturnType<typeof CampaignApi.getQuestions>>['data']>([])
  const [errors, setErrors] = useState(new ErrorBag<ICampaignModel>())

  const handleJdFileChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    e.target.value = ''
    if (!file) {
      return
    }
    if (!file.name?.endsWith('.doc') && !file.name?.endsWith('.docx') && !file.name?.endsWith('.pdf')) {
      return SnackbarService.error('Only .docx and .pdf file are supported')
    }

    CampaignMutationService.patchData({ jdFile: file, _ignoreDraft: true })
    const { jdFileUrl } = await CampaignMutationService.uploadFilesAndSaveDraft({ jdFile: file })
    CampaignMutationService.patchData({ jdFileUrl, _ignoreDraft: true })

    eventHandler({
      key: ETrackingEvent.CAMPAIGN_INPUT_JOB_ATTACH_FILE,
      contextData: { jdFile: file }
    })()

    if (/\.pdf$/i.test(file.name)) {
      return
    }

    setErrors((prev) => prev.clearError('briefDescription'))

    from(UploadApi.fetchContent(file))
      .pipe(
        take(1),
        catchError((error: AxiosError) => {
          setErrors((prev) => prev.setError('briefDescription', getApiErrorMessage(error)))
          return EMPTY
        }),
        finalize(() => {})
      )
      .subscribe(({ data }) => {
        if (data.content) {
          const content = data.content
            .replace(/<h1/g, '<strong').replace(/<\/h1>/g, '</strong>')
            .replace(/<h2/g, '<strong').replace(/<\/h2>/g, '</strong>')
            .replace(/<h3/g, '<strong').replace(/<\/h3>/g, '</strong>')
            .replace(/<h4/g, '<strong').replace(/<\/h4>/g, '</strong>')
            .replace(/<h5/g, '<strong').replace(/<\/h5>/g, '</strong>')
            .replace(/<h6/g, '<strong').replace(/<\/h6>/g, '</strong>')

          const briefDescription = isEmptyBriefDescription(content) ? '' : content
          return CampaignMutationService.patchData({ briefDescription })
        }

        setErrors((prev) => prev.setError('briefDescription', 'Your attachment has no content.'))
      })
  }, [eventHandler])

  const onRecordVideo = useCallback(() => {
    eventHandler(ETrackingEvent.CAMPAIGN_BTN_HM_VIDEO_CREATE)()
    DialogService.open(ModalRecorder, {
      onClose: (result) => {
        if (result?.video) {
          CampaignMutationService.patchData({
            uploadVideo: result.video,
            uploadVideoId: result.video?.id
          })
        }
      }
    })
  }, [eventHandler])

  const {
    debounceSendAnalyticJobTitle,
    debounceSendAnalyticJobLocation,
    debounceSetJobDescriptionAnalytic
  } = useMemo(() => ({
    debounceSeAnalyticJobName: debounce((jobName: string) => {
      eventHandler({
        key: ETrackingEvent.CAMPAIGN_INPUT_JOB_NAME,
        contextData: { jobName }
      })()
    }, 500),
    debounceSendAnalyticJobTitle: debounce((jobTitle: string) => {
      eventHandler({
        key: ETrackingEvent.CAMPAIGN_INPUT_JOB_TITLE,
        contextData: { jobTitle }
      })()
    }, 500),
    debounceSendAnalyticJobLocation: debounce((location: string) => {
      eventHandler({
        key: ETrackingEvent.CAMPAIGN_INPUT_JOB_LOCATION,
        contextData: { location }
      })()
    }, 500),
    debounceSetJobDescriptionAnalytic: debounce((briefDescription: string) => {
      eventHandler({
        key: ETrackingEvent.CAMPAIGN_INPUT_JOB_DESCRIPTION,
        contextData: { briefDescription }
      })()
    }, 500),
    debounceSendJobSummaryAnalytic: debounce((jobSummary: string) => {
      eventHandler({
        key: ETrackingEvent.CAMPAIGN_INPUT_JOB_SUMMARY,
        contextData: { jobSummary }
      })()
    }, 500)
  }), [eventHandler])

  const handleOnChangeJobTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const jobTitle = e.target.value
    const toPatchData: Partial<ICampaignModel> = { jobTitle }
    CampaignMutationService.patchData(toPatchData)
    debounceSendAnalyticJobTitle(e.target.value)
  }, [debounceSendAnalyticJobTitle])

  const handleOnChangeJobLocation = useCallback((location: string) => {
    CampaignMutationService.patchData({ location })
    debounceSendAnalyticJobLocation(location)
  }, [debounceSendAnalyticJobLocation])

  const handleOnChangeLocationType = useCallback((value: ELocationType) => {
    const patchedData: Partial<ICampaignModel> = { locationType: value }
    if (value === ELocationType.REMOTE) {
      patchedData.location = ''
    }

    CampaignMutationService.patchData(patchedData)

    eventHandler({
      key: ETrackingEvent.CAMPAIGN_CHECKBOX_JOB_REMOTE,
      contextData: { locationType: value }
    })()
  }, [eventHandler])

  const handleOnChangeBriefDescription = useCallback((description: string, _: any, source: string) => {
    if (source !== 'user') {
      return
    }

    const briefDescription = isEmptyBriefDescription(description) ? '' : description

    CampaignMutationService.patchData({ briefDescription })
    debounceSetJobDescriptionAnalytic(description)
  }, [debounceSetJobDescriptionAnalytic])

  const handleOnCheckJobResume = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    CampaignMutationService.patchData({ requiredResume: e.target.checked })
    eventHandler({
      key: ETrackingEvent.CAMPAIGN_CHECKBOX_JOB_RESUME,
      contextData: { requiredResume: e.target.checked }
    })()
  }, [eventHandler])

  // const handleOnCheckJobLinkedin = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   CampaignMutationService.patchData({ requireLinkedInUrl: e.target.checked })
  //   eventHandler({
  //     key: ETrackingEvent.CAMPAIGN_CHECKBOX_JOB_LINKEDIN,
  //     contextData: { requireLinkedInUrl: e.target.checked }
  //   })()
  // }, [eventHandler])

  // const handleOnCheckJobZip = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   CampaignMutationService.patchData({ requireZipCode: e.target.checked })
  //   eventHandler({
  //     key: ETrackingEvent.CAMPAIGN_CHECKBOX_JOB_ZIP,
  //     contextData: { requireZipCode: e.target.checked }
  //   })()
  // }, [eventHandler])

  // useEffect(() => {
  //   from(CampaignApi.getQuestions())
  //     .pipe(takeUntil(unsubscribe$))
  //     .subscribe(({ data }) => setQuestions(data))
  // }, [unsubscribe$])

  // useEffect(() => {
  //   if (!questions.length) {
  //     return
  //   }
  //   const question = CampaignMutationService.data$.getValue().question
  //   if (!question) {
  //     CampaignMutationService.patchData({ question: questions[0]?.text })
  //   }
  // }, [questions])

  useEffect(() => {
    if (data.uploadedJdFile) {
      return setFileName(data.uploadedJdFile.filename)
    }

    if (data.jdFile) {
      return setFileName(data.jdFile.name)
    }

    if (data.jdFileUrl) {
      return setFileName(data.jdFileUrl.split('/').pop())
    }

    setFileName(undefined)
  }, [data])

  return (
    <div className={Style.container}>
      <span className={Style.header}>
        Job Detail
      </span>
      <div className={Style.content}>
        <div className={Style.campaignWrapper}>
          <div id="uploadVideo">
            <IntroduceYourselfVideo
              video={data.uploadVideo}
              onRecordVideo={onRecordVideo}
            />
            {
              otherErrors?.uploadVideo && (
                <ErrorMessage className="mt-2">
                  This field is required
                </ErrorMessage>
              )
            }
          </div>
          <div className="fx-1 gap-6 fx flex-column relative">
            <div className={Style.jobDetailTarget} id={TARGET_ENTER_JOB_DETAIL.getValue()}/>
            {tourEnabled && currentTourStep === 2 && (<TourPopper {...CreateJobTourService.getStepConfig()}/>)}

            <div className={Style.campaignTitle} id="jobTitle">
              <span className={clsx(Style.title, Style.title_required)}>Job Title</span>
              <Input
                id="jobTitleInput"
                placeholder="Job Title"
                error={externalErrors?.getError('jobTitle')}
                value={data.jobTitle || ''}
                onChange={handleOnChangeJobTitle}
                height={56}
                className="py-1"
              />
            </div>

            <div className={Style.salary} id="salaryValue">
              <span className={clsx(Style.title, Style.title_required)}>Salary</span>
              <div className="fx-column gap-2">
                <Salary
                  id="salaryValueInput"
                  rate={data.salaryRate}
                  range={data.salaryRange}
                  value={data.salaryValue}
                  onChange={(salary) => CampaignMutationService.patchData({
                    salaryValue: salary.value,
                    salaryRange: salary.range,
                    salaryRate: salary.rate
                  })}
                  error={externalErrors?.getError('salaryValue') || externalErrors?.getError('salaryValue.min') || externalErrors?.getError('salaryValue.max')}
                />
              </div>
            </div>

            <div className="fx flex-row fx-ai-flex-start gap-8" id="location">
              <WorkplaceType
                value={data.locationType}
                onChange={handleOnChangeLocationType}
              />
              {data.locationType !== ELocationType.REMOTE && (
                <div className={Style.location}>
                  <div className="fx flex-row fx-ai-center gap-2">
                    <span className={clsx(Style.title, Style.title_required)}>Location</span>
                    <Tooltip
                      title="Picking a specific city or metro area can help make your job more discoverable by job seekers in those areas, while still remaining visible to everyone in your country or region."
                      classes={{ popper: Style.popper, tooltip: clsx('meta-medium', Style.tooltip), arrow: Style.tooltipArrow }}
                      placement="top"
                      arrow
                    >
                      <IconInfo
                        className={Style.iconInfo}
                      />
                    </Tooltip>
                  </div>

                  <GoogleLocationSearch
                    id="locationInput"
                    location={data.location}
                    onChange={handleOnChangeJobLocation}
                    error={externalErrors?.getError('location')}
                  />
                </div>
              )}
            </div>

            <div className="fx fx-ai-center gap-8">
              <div
                className={clsx(Style.title, Style.title_required)}
                style={{
                  width: '100%',
                  maxWidth: '190px'
                }}
              >
                Require resume to apply?
              </div>
              <div className="fx fx-ai-center gap-6">
                <label className="fx fx-ai-center gap-3 heading-14 pointer">
                  <input
                    className="m-0"
                    type="radio"
                    id="no"
                    checked={data.requiredResume}
                    onChange={(e) => handleOnCheckJobResume({
                      ...e,
                      target: { ...e.target, checked: true }
                    })}
                  />
                  Yes
                </label>
                <label className="fx fx-ai-center gap-3 heading-14 pointer">
                  <input
                    className="m-0"
                    type="radio"
                    id="no"
                    checked={!data.requiredResume}
                    onChange={(e) => handleOnCheckJobResume({
                      ...e,
                      target: { ...e.target, checked: false }
                    })}
                  />
                  No
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className={Style.bordering}>
          <span className={clsx(Style.title, 'px-4 py-5')}>Your applicants will answer this question:</span>
          <Divider color="#DADBE7"/>
          <Input
            className="fs-16"
            wrapperClassName={clsx(Style.questionInput, 'px-4 py-5')}
            value={data.question || ''}
            readOnly
            onChange={e => CampaignMutationService.patchData({ question: e.target.value })}
            error={externalErrors?.getError('question')}
          />
        </div>

        <div className={clsx(Style.jd, 'fx fx-column gap-6 pt-4 relative')}>
          <div className={Style.jobDescriptionTarget} id={TARGET_DESCRIPTION.getValue()}/>

          {tourEnabled && currentTourStep === 4 && (<TourPopper {...CreateJobTourService.getStepConfig()}/>)}

          <div className="fx fx-column" id="briefDescription">
            <div className="fx fx-column gap-1 px-4 mb-4">
              <span className={clsx(Style.title, Style.title_required)}>Job Description</span>
            </div>

            <Editor
              focused={focusedErr === 'briefDescriptionInput'}
              value={data.briefDescription}
              onChange={handleOnChangeBriefDescription}
              className={clsx(Style.jdEditor, fileName !== undefined && Style.jdEditorWithAttachment)}
            />

            <div className={clsx(Style.jdAttachmentWrapper, fileName !== undefined && Style.jdWithAttachment)}>
              {fileName !== undefined
                ? (
                  <AttachedFile
                    fileName={fileName}
                    url={data.jdFileUrl}
                    onRemove={() => CampaignMutationService.patchData({
                      jdFile: undefined,
                      jdFileUrl: null,
                      uploadedJdFile: undefined
                    })}
                  />
                )
                : (
                  <Button
                    field="input"
                    inputProps={{
                      // accept: 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      accept: '.doc,.docx,.pdf',
                      onChange: handleJdFileChange
                    }}
                    variant="secondary"
                    className={Style.jd_attach_button}
                  >
                    <span className="text-decorator-underline">Attach file</span>
                  </Button>
                )}
            </div>

            {(errors.hasError('briefDescription') || otherErrors?.briefDescription) && (
              <ErrorMessage className="px-4 my-2">
                {errors.getError('briefDescription') || 'This field is required'}
              </ErrorMessage>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
