import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconZeroLike = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 160, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10160_168344)">
          <path d="M18.7208 91.8133C19.4675 122.493 21.2142 140.187 31.6675 143.28C42.1208 146.373 44.3608 139.48 53.5875 141.12C62.8275 142.747 78.0008 148.453 98.1075 148.453C118.214 148.453 136.414 109.307 114.401 86.2266C108.948 80.5066 98.9608 82.2399 96.4142 78.2533C88.7075 66.1466 95.1875 49.5333 80.6808 50.0933C61.3875 50.8266 75.7742 73.0666 51.8675 78.0933C27.9608 83.1066 18.4408 80.4533 18.7208 91.8133Z" fill="#AFF6C5"/>
          <path d="M22.4284 82.0933C21.7484 102.613 21.6551 123.147 22.1617 143.68C22.2151 145.64 25.3217 144.88 25.2951 143.253C24.8017 122.72 24.8951 102.187 25.5617 81.6666C25.6284 79.7199 22.4817 80.4666 22.4284 82.0933Z" fill="black"/>
          <path d="M23.5976 145.08C29.7709 145.867 40.5576 147.787 44.9976 141.827C50.0776 135 48.1043 122.6 47.8376 114.707C47.6509 109.293 47.3843 103.867 47.0243 98.4533C46.7309 94.0933 47.5443 86.9333 44.9576 83.3066C41.0643 77.8399 30.3443 79.1599 24.8243 80.0266C23.0909 80.2933 22.4243 83.1333 24.5709 82.7999C28.1176 82.2399 31.7576 81.8666 35.3309 82.4399C40.2643 83.2399 42.0243 84.1999 43.1043 88.9999C44.7709 96.3599 44.3043 104.987 44.5976 112.48C44.8509 118.867 45.2109 125.347 44.6509 131.72C44.1976 136.84 42.3709 141.787 36.6376 142.88C32.9576 143.587 28.3443 142.76 24.6376 142.293C22.9976 142.08 21.5309 144.813 23.5976 145.08Z" fill="black"/>
          <path d="M46.1182 90.8533C54.1715 90.1733 61.4382 86.4267 66.5982 80.1734C69.4915 76.68 71.5848 72.5467 72.8782 68.2C73.7315 65.32 74.2248 48.0667 79.9315 50.1867C81.4382 50.7467 83.5048 48.24 81.6915 47.56C74.7982 45 71.8915 51.9334 71.3848 57.5333C70.0382 72.4934 63.0782 86.6667 46.3715 88.0667C44.6115 88.2134 43.9448 91.0267 46.1182 90.84V90.8533Z" fill="black"/>
          <path d="M80.1594 50.0267C94.7061 53.96 90.1594 70.9733 86.3461 81.0933C85.6128 83.04 88.7194 82.9333 89.3061 81.3867C93.5461 70.1067 97.6794 51.68 81.9061 47.4C80.3728 46.9867 78.3061 49.52 80.1461 50.0267H80.1594Z" fill="black"/>
          <path d="M80.56 84.0667C87.9867 83.5334 95.4267 83.0534 102.867 83.0667C108.813 83.0667 119.347 81.8667 123.653 86.92C124.76 88.2134 127.56 86.4267 126.48 85.16C122.2 80.1334 115.053 80.56 108.96 80.36C99.5734 80.0534 90.1734 80.6267 80.8134 81.2934C79.0401 81.4267 78.3867 84.2267 80.56 84.0667Z" fill="black"/>
          <path d="M123.571 86.7866C124.291 90.6133 124.531 95 119.571 95.7333C115.971 96.2666 112.171 96.0266 108.544 95.8C106.904 95.6933 105.437 98.4533 107.504 98.5866C112.384 98.9066 119.611 99.88 123.891 96.92C127.611 94.3466 127.397 89.6533 126.651 85.68C126.317 83.9066 123.264 85.1866 123.557 86.8L123.571 86.7866Z" fill="black"/>
          <path d="M117.082 98.7866C120.136 102.427 122.522 107.76 116.709 110.293C112.882 111.973 108.069 111.893 103.989 111.653C102.349 111.56 100.882 114.32 102.949 114.44C108.589 114.773 115.962 114.867 120.536 110.96C125.349 106.827 123.496 101.293 119.909 97.0266C118.816 95.72 116.002 97.5066 117.082 98.7866Z" fill="black"/>
          <path d="M112.639 114.88C117.919 117.24 117.505 124.253 111.732 125.693C108.025 126.627 103.799 126.36 100.012 126.413C98.3987 126.427 96.9187 129.227 98.972 129.2C104.772 129.133 113.239 130.16 117.505 125.253C121.359 120.813 119.559 114.56 114.385 112.253C112.905 111.587 110.852 114.08 112.625 114.88H112.639Z" fill="black"/>
          <path d="M108.825 129.653C111.852 131.347 113.852 135.013 112.305 138.387C110.545 142.213 105.412 142.013 101.825 141.787C100.185 141.68 98.7184 144.44 100.785 144.573C106.078 144.907 112.572 144.52 115.118 139.04C117.332 134.293 114.892 129.44 110.572 127.027C109.158 126.24 107.105 128.693 108.812 129.653H108.825Z" fill="black"/>
          <path d="M102.199 142.08C94.106 142.16 86.026 142.227 77.9327 142.307C69.8393 142.387 59.2527 143.333 55.0127 134.48C54.2927 132.973 51.4393 134.707 52.186 136.24C55.4793 143.107 62.4527 144.907 69.5727 145.107C80.0793 145.4 90.6527 144.96 101.159 144.853C102.773 144.84 104.253 142.04 102.199 142.067V142.08Z" fill="black"/>
          <path d="M54.0159 134.253C51.6559 134.187 49.3092 134.12 46.9492 134.053C46.1226 134.027 45.2426 134.493 44.9492 135.307C44.6959 136 45.1092 136.827 45.9092 136.84C48.2692 136.907 50.6159 136.973 52.9759 137.04C53.8026 137.067 54.6826 136.6 54.9759 135.787C55.2292 135.093 54.8159 134.267 54.0159 134.253Z" fill="black"/>
          <path d="M122.584 29.9734C121.438 29.2401 119.584 28.5334 118.224 29.0534C116.064 29.8667 114.998 32.0534 114.691 34.2267C113.998 38.9734 115.224 46.7734 119.944 49.4267C126.904 53.3201 127.878 33.1201 122.238 29.9867" stroke="#AFF6C5" strokeWidth="4" strokeLinecap="round"/>
          <path d="M142.825 24.24C138.145 17.6267 132.319 12.92 123.732 13.6267C114.905 14.36 105.425 17.28 100.399 25.04C99.4388 26.52 98.6254 28.2 98.0654 29.8934C97.9988 30.2934 97.9054 30.68 97.8254 31.08C97.4921 32.1067 97.2388 33.1334 97.0521 34.1867C96.9588 34.68 96.8921 35.1734 96.8254 35.6667C96.7988 35.8267 96.7588 35.96 96.7188 36.1067C96.6921 36.4 96.6788 36.6934 96.6654 36.9867C96.6654 37.16 96.6388 37.3334 96.6388 37.5067C96.6388 37.8134 96.6388 38.12 96.6388 38.4267C96.6388 38.9067 96.5988 39.3867 96.6121 39.88C96.6121 40 96.6521 40.0934 96.6921 40.2C96.8121 41.9467 97.1054 43.68 97.5854 45.36C98.8388 51.0267 101.905 56.1467 106.999 58.5067C107.239 58.64 107.452 58.76 107.545 58.8C109.879 59.8534 112.545 60.36 115.132 60.48C116.092 60.4667 117.039 60.4934 117.985 60.5334C118.039 60.5334 118.079 60.5201 118.132 60.5067C121.372 60.7334 124.812 59.92 128.012 58.9467C128.545 58.8134 129.079 58.6934 129.612 58.5334C129.785 58.48 129.919 58.4 130.039 58.3067C130.332 58.2134 130.639 58.1067 130.919 58.0134C130.999 57.9867 131.052 57.9334 131.119 57.9067C135.812 56.16 140.079 53.48 143.012 49.4267C149.052 41.08 148.705 32.4934 142.852 24.2267L142.825 24.24ZM136.892 51.68C130.479 57.0934 117.759 58.96 109.932 56.2534C107.799 55.52 104.732 54.0667 103.385 52.24C97.5054 44.2667 98.3454 32.4134 104.252 24.6934C108.439 19.2267 117.785 16.7467 124.412 16.4667C129.479 16.2534 133.305 18 136.892 21.4267C146.145 30.2534 147.025 43.12 136.892 51.68Z" fill="black"/>
          <path d="M120.289 23.32C113.195 24.12 112.955 34.3867 113.795 39.6667C114.609 44.7734 116.569 51.0667 122.942 50.56C128.235 50.1334 129.902 44.3334 130.062 39.88C130.275 34.1467 128.142 22.44 120.275 23.32H120.289ZM126.715 45.64C124.595 50.12 119.542 50.1734 117.115 45.9334C115.235 42.6667 114.689 36.0934 114.942 32.36C115.142 29.3334 117.342 24.64 120.835 24.48C122.902 24.3867 124.982 26.32 125.995 27.9467C128.502 31.9734 128.729 41.3867 126.715 45.64Z" fill="black"/>
          <path d="M121.281 28.5867C120.654 28.6801 120.041 29.2667 119.641 29.7201C117.494 32.2401 117.254 37.8134 118.027 40.8934C118.587 43.1201 121.507 45.6667 123.441 43.3467C125.587 40.7734 124.894 35.9734 124.201 32.9334C123.881 31.5201 123.227 28.2934 121.267 28.5734L121.281 28.5867ZM123.307 40.8001C123.134 41.6401 122.747 43.1201 121.774 43.2934C120.307 43.5601 119.627 42.0001 119.374 40.8134C118.907 38.6134 118.747 33.4401 119.761 31.4534C119.961 31.0534 121.001 29.7467 121.427 29.7601C121.761 29.7734 121.961 30.4934 122.081 30.7734C122.961 32.9867 123.747 38.5067 123.294 40.7867L123.307 40.8001Z" fill="black"/>
        </g>
        <defs>
          <clipPath id="clip0_10160_168344">
            <rect width="160" height="160" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  )
})
