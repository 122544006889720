import clsx from 'clsx'
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'
import { Modal, VideoPlayerOld } from 'src/components'
import { IconChevronLeft, IconChevronRight } from 'src/icons'
import { IVideoModel } from 'src/interfaces'
import { getVideoSource } from 'src/utils'
import { SAMPLE_VIDEO } from './constants'
import Style from './style.module.scss'

const SamplePlayers: FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [firstLoadedVideoRatio, setFirstLoadedVideoRatio] = useState(false)

  useEffect(() => {
    if (!firstLoadedVideoRatio) {
      setFirstLoadedVideoRatio(true)
    }
  }, [firstLoadedVideoRatio])

  return (
    <div className="fx-1 fx fx-center">
      <div
        className={Style.VideoPlayerCtn}
        style={{
          aspectRatio: '418 / 744',
          width: 418
        }}
      >
        <button
          className={clsx([
            Style.PrevBtn,
            { [Style.NoShow]: currentSlide === 0 }
          ])}
          onClick={() => setCurrentSlide(currentSlide - 1)}
        >
          <IconChevronLeft className="txt-neutral-black"/>
        </button>
        <button
          className={clsx([
            Style.NextBtn,
            { [Style.NoShow]: currentSlide === SAMPLE_VIDEO.length - 1 }
          ])}
          onClick={() => setCurrentSlide(currentSlide + 1)}
        >
          <IconChevronRight className="txt-neutral-black"/>
        </button>

        {SAMPLE_VIDEO.map((video, index) => (
          <SliderComponent
            key={index}
            index={index}
            currentSlide={currentSlide}
            video={video}
            className={clsx({
              [Style.VideoPlayerSlidePrev]: index < currentSlide,
              [Style.VideoPlayerSlideNext]: index > currentSlide
            })}
          />
        ))}
      </div>
    </div>
  )
}

const SliderComponent = (props: {
  video: Partial<IVideoModel>
  className?: string
  index: number
  currentSlide: number
}) => {
  const video = props.video

  const style = useMemo(() => {
    return {
      zIndex: props.index < props.currentSlide
        ? props.index
        : 1000 - props.index
    }
  }, [props.index, props.currentSlide])

  return (
    <div
      style={style}
      className={clsx([
        Style.VideoPlayerSlide,
        props.className
      ])}
    >
      {props.index !== props.currentSlide && <div className={Style.Overlay}/>}
      <VideoPlayerOld
        className={Style.videoPlayerOverride}
        style={{ aspectRatio: '418 / 744' }}
        isPlay={props.index === props.currentSlide}
        url={getVideoSource(video)}
        tracks={video?.tracks}
        image={video?.urlVideoImageThumbnail}
        animatedImage={video?.urlVideoAnimatedImage}
        mimeType={video?.internalSourceMetadata?.mimeType}
      />
    </div>
  )
}

export const ModalRecorderSample: FC<ComponentProps<typeof Modal>> = (props) => {
  return (
    <Modal
      {...props}
      closeBtn
      contentClass="fx"
      // contentStyles={{ background: 'unset' }}
    >
      <SamplePlayers/>
    </Modal>
  )
}
