import clsx from 'clsx'
import { ComponentProps, CSSProperties, FC } from 'react'
import { IconChecked } from 'src/icons'
import Style from './style.module.scss'

interface IProps {
  label?: string
  checkBoxContentStyle?: CSSProperties
}

export const Checkbox: FC<ComponentProps<'input'> & IProps> = ({ className, checkBoxContentStyle, ...props }) => {
  return (
    <label className={clsx(Style.customCheckboxWrapper, className)}>
      <input
        {...props}
        type="checkbox"
        className={Style.customCheckbox}
      />
      <div className={Style.checkboxMark} style={checkBoxContentStyle}>
        {props.checked && <IconChecked/>}
      </div>
      {props.label && <div className="fs-12 fw-600 txt-neutral-black ml-2">{props.label}</div>}
    </label>
  )
}
