import { FC } from 'react'
import { Select } from 'src/components'
import { ELocationType } from 'src/enums'
import Style from './style.module.scss'

interface IOption {
  label: string
  value: ELocationType
  description: string
}

const OPTIONS: IOption[] = [
  {
    label: 'On-site',
    description: 'Employees come to work in-person.',
    value: ELocationType.ON_SITE
  },
  {
    label: 'Hybrid',
    description: 'Employees work on-site and off-site.',
    value: ELocationType.HYBRID
  },
  {
    label: 'Remote',
    description: 'Employees work off-site.',
    value: ELocationType.REMOTE
  }
]

interface IProps {
  value?: ELocationType
  onChange: (value: ELocationType) => void
}

/**
 * @deprecated
 */
export const WorkplaceType: FC<IProps> = (props) => {
  return (
    <div className={Style.container}>
      <span className={Style.title}>Workplace Type</span>
      <Select
        options={OPTIONS}
        value={props.value}
        onChange={(value) => props.onChange(value as ELocationType)}
        size="large"
        height={48}
      />
    </div>
  )
}
