import styled from 'styled-components'
import { IconDisconnected } from '../icons/i-disconnected'

const SVGWrapper = styled.div`
  max-height: 100%;
  max-width: 100%;
  margin-bottom: 140px;
`

const DisconnectedView = () => (
  <SVGWrapper>
    <IconDisconnected/>
  </SVGWrapper>
)

export default DisconnectedView
