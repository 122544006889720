import { IVideoModel } from 'src/interfaces'

export const pIntroduceVideo: Readonly<Partial<IVideoModel>[]> = Object.freeze([
  {
    raw: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/phil-hiring-side.mov',
    urlVideoAnimatedImage: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/phil-hiring-side.mov/2025-03-29-0203/animated-100x.gif',
    urlVideoImageThumbnail: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/phil-hiring-side.mov/2025-03-29-0203/thumbnail-00001.jpg',
    urlVideoSource: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/phil-hiring-side.mov-watermark.mp4',
    urlVideoStandardQuality: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/phil-hiring-side.mov/2025-03-29-0203/hls/master.m3u8',
    tracks: [
      {
        label: 'American English',
        kind: 'subtitles',
        src: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/phil-hiring-side.mov-track.vtt',
        srcLang: 'en-US',
        default: true
      }
    ]
  }
])
