import { AxiosRequestConfig } from 'axios'
import { ECampaignStatus } from 'src/enums'
import {
  ICampaignDraftModel,
  ICampaignModel,
  ICampaignOwnerModel,
  ICampaignSubmissionModel,
  IPaginationQuery,
  TAxiosResponseData,
  TAxiosResponsePagination
} from 'src/interfaces'
import { axiosHiringApi } from './axios'

interface ICampaignUpload {
  /**
   * @deprecated
   */
  logoUrl?: string
  jdFileUrl?: string | null
}

export class CampaignApi {
  static readonly _prefix = '/campaigns'
  static cacheCompanyOwners = new Map<string, { owners: ICampaignOwnerModel[]; total: number }>()

  static readonly SUMMARY_EFFECT_FIELDS = [
    'jobTitle',
    'jdFile',
    'jdFileUrl',
    'location',
    'briefDescription',
    'salaryRange',
    'salaryRate',
    'salaryValue'
  ] as (keyof ICampaignModel)[]

  static paginate(
    params?: {
      status?: ECampaignStatus
      uploadVideoId?: number
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<(ICampaignModel & {
    submissions: ICampaignModel['submissions']
    draftId?: number
    slug?: string
  })[]> {
    return axiosHiringApi.get(this._prefix, {
      ...config,
      params: params || config?.params
    })
  }

  static archive(id: number): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${this._prefix}/${id}`)
  }

  static count(): TAxiosResponseData<{
    count?: number
    countDraft?: number
    countPending?: number
    countPublished?: number
  }> {
    return axiosHiringApi.get(`${this._prefix}/count`)
  }

  static create(
    payload: Omit<Partial<ICampaignModel>, 'id'> & ICampaignUpload & {
      draftId?: number
      linkedIn?: string
      companyPhoneNo?: string
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<ICampaignModel> {
    return axiosHiringApi.post(this._prefix, payload, config)
  }

  static saveDraft(
    payload: ICampaignDraftModel & ICampaignUpload,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<{ id: number }> {
    return axiosHiringApi.post(`${this._prefix}/draft`, payload, config)
  }

  static getDraftDetail(
    id: number,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<ICampaignDraftModel> {
    return axiosHiringApi.get(`${this._prefix}/draft/${id}`, config)
  }

  static deleteDraft(
    id: number,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${this._prefix}/draft/${id}`, config)
  }

  static preCreate(
    payload: Omit<ICampaignModel, 'id'> & ICampaignUpload,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}?dryRun=true`, payload, config)
  }

  static getOne(
    id: number,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<ICampaignModel> {
    return axiosHiringApi.get(`${this._prefix}/${id}`, config)
  }

  static replicate(
    id: number,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<ICampaignDraftModel> {
    return axiosHiringApi.get(`${this._prefix}/${id}/replicate`, config)
  }

  static update(
    id: number,
    payload: Omit<ICampaignModel, 'id'>,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<void> {
    return axiosHiringApi.patch(`${this._prefix}/${id}`, payload, config)
  }

  static fetchSummary(
    payload: {
      jobTitle?: string
      jdFile?: File | Blob
      jdFileUrl?: string
      location?: string
      briefDescription?: string
      salaryRange?: ICampaignModel['salaryRange']
      salaryRate?: ICampaignModel['salaryRate']
      salaryValue?: ICampaignModel['salaryValue']
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<{ summary: string }> {
    const formData = new FormData()
    for (const field of CampaignApi.SUMMARY_EFFECT_FIELDS) {
      const value = payload[field as keyof typeof payload]
      if (value) {
        formData.append(
          field,
          value instanceof File
            ? value
            : typeof value === 'string'
              ? value
              : JSON.stringify(value)
        )
      }
    }

    return axiosHiringApi.post(`${this._prefix}/summary`, formData, {
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  static updateSummary(
    id: number,
    jobSummary: string,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<void> {
    return axiosHiringApi.patch(`${this._prefix}/${id}/summary`, { jobSummary }, config)
  }

  private static questions: { id: number; text: string }[]
  static getQuestions(): TAxiosResponseData<{ id: number; text: string }[]> {
    if (this.questions) {
      return Promise.resolve({ data: this.questions }) as TAxiosResponseData<{ id: number; text: string }[]>
    }

    return axiosHiringApi.get(`${this._prefix}/questions`).then((res) => {
      this.questions = res.data
      return res
    })
  }

  static preUpdate(
    id: number,
    payload: Omit<ICampaignModel, 'id'>,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<void> {
    return axiosHiringApi.patch(`${this._prefix}/${id}?dryRun=true`, payload, config)
  }

  static inviteEmails(id: number, payload: { emails: string[]; message?: string }): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}/${id}/invite-emails`, payload)
  }

  static paginateSubmission(
    campaignId: number,
    params: IPaginationQuery,
    config?: AxiosRequestConfig
  ): TAxiosResponsePagination<ICampaignSubmissionModel> {
    return axiosHiringApi.get(`${this._prefix}/${campaignId}/submission`, {
      ...config,
      params: params || config?.params
    })
  }

  static getDetailSubmission(
    campaignId: number,
    submissionId: number
  ): TAxiosResponseData<ICampaignSubmissionModel> {
    return axiosHiringApi.get(`${this._prefix}/${campaignId}/submission/${submissionId}`)
  }

  static writeNote(
    campaignId: number,
    submissionId: number,
    content: string
  ): TAxiosResponseData<ICampaignSubmissionModel> {
    return axiosHiringApi.post(`${this._prefix}/${campaignId}/submission/${submissionId}/note`, { content })
  }

  static action(
    params: {
      campaignId: number
      submissionId: number
      action: string
      videoId: number
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<ICampaignSubmissionModel> {
    return axiosHiringApi.put(`${this._prefix}/${params.campaignId}/submission/${params.submissionId}/${params.action}`, { videoId: params.videoId }, config)
  }

  static publicCampaign(
    hash: string,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<ICampaignModel & { sameCompany?: boolean }> {
    return axiosHiringApi.get(`${this._prefix}/${hash}`, config)
  }

  static publicPaginateSubmission(hash: string, params: IPaginationQuery): TAxiosResponsePagination<ICampaignSubmissionModel> {
    return axiosHiringApi.get(`${this._prefix}/${hash}/submission`, { params })
  }

  static publicGetDetailSubmission(
    hash: string,
    submissionId: number
  ): TAxiosResponseData<ICampaignSubmissionModel> {
    return axiosHiringApi.get(`${this._prefix}/${hash}/submission/${submissionId}`)
  }

  static verifyAccount(
    hash: string,
    payload: { email: string; sessionId?: string }
  ): TAxiosResponseData<{ accountExisted: boolean; sameCompany: boolean } | undefined> {
    return axiosHiringApi.post(`${this._prefix}/${hash}/verify-account`, payload)
  }

  static getSharedOwners(id: number, companyDomain: string): TAxiosResponseData<{ owners: ICampaignOwnerModel[]; total: number }> {
    if (this.cacheCompanyOwners.has(companyDomain)) {
      return Promise.resolve({
        data: this.cacheCompanyOwners.get(companyDomain)
      }) as TAxiosResponseData<{ owners: ICampaignOwnerModel[]; total: number }>
    }

    return axiosHiringApi.get(`${this._prefix}/${id}/shared-owners`).then(res => {
      this.cacheCompanyOwners.set(companyDomain, res.data)
      return res
    })
  }

  static getSeedSubmissions(config?: AxiosRequestConfig): TAxiosResponseData<ICampaignSubmissionModel[]> {
    return axiosHiringApi.get(`${this._prefix}/onboarding-submissions`, config)
  }

  static createOnboarding(
    payload: ICampaignDraftModel & ICampaignUpload,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<{ slug: string }> {
    return axiosHiringApi.post(`${this._prefix}/onboarding`, payload, config)
  }

  static batchMessages(
    payload: {
      campaignId: number
      submissionIds: [number, ...number[]]
      message: string
    } & Either<void, {
      fileUrl: string
      fileName: string
      fileType: string
    }>,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<{ slug: string }> {
    return axiosHiringApi.post(`${this._prefix}/batch-messages`, payload, config)
  }
}
