import { ProfileApi, UploadApi } from 'src/api'
import { EFileUploadKind, IPlaceholder } from 'src/interfaces'

export class ProfileService {
  /**
   * @deprecated
   */
  static async uploadPfv(source: File | Blob, placeholder?: IPlaceholder, transcription?: string, thumbnailOffset?: number) {
    const payload: Parameters<typeof ProfileApi.uploadVideo>[0] = {
      key: '',
      transcription,
      thumbnailOffset
    }

    payload.key = await UploadApi.upload({
      kind: source.type.startsWith('audio')
        ? EFileUploadKind.AUDIO_VIBE_CHECK
        : EFileUploadKind.VIDEO_VIBE_CHECK,
      file: source
    })

    if (source.type.startsWith('audio')) {
      if (placeholder?.file) {
        // case 1: placeholder is image uploaded by user
        payload.photoKey = await UploadApi.upload({
          kind: EFileUploadKind.AUDIO_VIBE_PHOTO,
          file: placeholder.file
        })
      } else {
        // case 2: placeholder is image from library
        payload.photoKey = placeholder?.key
      }
    }

    const { data: { pfv } } = await ProfileApi.uploadVideo(payload)

    return pfv
  }
}
