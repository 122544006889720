import { FC } from 'react'
import { Badge, Button, useAnalytic } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { IconChat } from 'src/icons'
import { browserHistory, ERoutes, generate } from 'src/router'
import { MessengerService } from 'src/services/messenger.service'
import { ETrackingEvent } from '../../../../enums'

export const Messages: FC = () => {
  const { eventHandler } = useAnalytic('top_nav')
  const count = useBehaviorMapper(MessengerService.countUnRead$)

  return (
    <Button
      variant="icon"
      className="relative"
      onClick={eventHandler(ETrackingEvent.BTN_NAV_MESSAGES, () => browserHistory.push(generate(ERoutes.TALENT_MESSAGES)))}
    >
      <IconChat size={20} className="txt-black-01"/>
      <Badge count={count}/>
    </Button>
  )
}
