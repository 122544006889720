import moment from 'moment'
import { FORMAT_DATE_TIME } from 'src/constants'

export const formatSecondsToMinutes = (time: number) => {
  return Math.floor(time / 60).toString().padStart(2, '0') + ':' + Math.floor(time % 60).toString().padStart(2, '0')
}

export const formatDateTime = (
  date: string,
  format = FORMAT_DATE_TIME,
  timeZone?: string
): string => {
  if (timeZone && ['utc', 'UTC'].includes(timeZone)) {
    return moment.utc(date).format(format)
  }

  return moment(date).format(format)
}

export const formatDate = (d: Date |string) => {
  const now = moment()
  const date = moment(d)

  if (date.isSame(now, 'day')) {
    return date.format('HH:mm A')
  }

  if (date.isSame(now, 'week')) {
    return date.format('dddd HH:mm A')
  }

  return date.format('HH:mm A D MMM')
}
