import { MenuItem } from '@mui/material'
import clsx from 'clsx'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { VideoApi } from 'src/api'
import { Button, Input, StyledMenu, useAnalytic } from 'src/components'
import { useClickOutside, useUnsubscribe, useValidation } from 'src/hooks'
import { IconChecked, IconMore, IconPencilStroke } from 'src/icons'
import { IVideoModel } from 'src/interfaces'
import { ModalMyVideoDetail } from 'src/partials/modal-my-video-detail'
import { VideoPlayerTracking } from 'src/partials/video-player-tracking'
import { DialogService, SnackbarService } from 'src/services'
import { EventUtils, StyleUtils, VideoUtils } from 'src/utils'
import { object, string } from 'yup'
import { ETrackingEvent } from '../../../enums'
import Style from './style.module.scss'

interface IProps {
  video: IVideoModel & { usedForCampaigns?: number }
  onChange?: (values: Partial<IVideoModel>) => void
  onSelectVideo?: (video: IVideoModel) => void
}

export const VVCCard: FC<IProps> = ({ video, onChange, onSelectVideo }) => {
  const unsubscribe$ = useUnsubscribe()
  const { eventHandler } = useAnalytic('video_library')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    EventUtils.preventDefault(event)
    EventUtils.stopPropagation(event)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e?: any) => {
    EventUtils.preventDefault(e)
    EventUtils.stopPropagation(e)
    setAnchorEl(null)
  }

  const [editing, setEditing] = useState(false)
  const [formData, setFormData] = useState<Partial<typeof video>>({})
  const [loading, setLoading] = useState(false)

  const schema = useMemo(() => object().shape({
    title: string().required().label('Title')
  }), [])

  const { errors, validate, reset } = useValidation({
    data: formData,
    schema
  })

  const onFormChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const onSubmit = useCallback(async (e?: any) => {
    EventUtils.preventDefault(e)
    EventUtils.stopPropagation(e)

    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    if (!formData.title?.trim() || formData.title?.trim() === video.title?.trim()) {
      return
    }

    setLoading(true)
    const newTitle = formData.title?.trim()
    from(VideoApi.update(video.id, { title: newTitle }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        video.title = newTitle
        onChange?.({ title: newTitle })
        setEditing(false)
        SnackbarService.success('Video updated successfully')
      })
  }, [formData.title, onChange, unsubscribe$, validate, video])

  const handleRename = useCallback((e: any) => {
    EventUtils.preventDefault(e)
    EventUtils.stopPropagation(e)
    setAnchorEl(null)
    setEditing(prev => !prev)
  }, [])

  useEffect(() => {
    if (!editing) {
      reset()
      setLoading(false)
      setFormData({})
    } else {
      setFormData({ title: video.title })
    }
  }, [editing, video.title, reset])

  const renameInputRef = useClickOutside<HTMLInputElement>(() => {
    setTimeout(() => {
      setEditing(false)
    })
  })

  return (
    <div className={Style.vccContainer}>
      <div
        className="w-full round-3 pointer"
        style={{
          aspectRatio: '9/16',
          ...StyleUtils.backgroundCover(video?.urlVideoImageThumbnail)
        }}
      >
        <VideoPlayerTracking
          videoId={video.id}
          url={VideoUtils.getVideoSource(video) ?? ''}
          tracks={video?.tracks}
          image={video?.urlVideoImageThumbnail}
        />
      </div>

      <div className={Style.vvcInfo}>
        <div className="fx fx-column gap-1">
          {editing
            ? (
              <div
                ref={renameInputRef}
                className="fx"
              >
                <Input
                  autoFocus
                  innerError
                  name="title"
                  placeholder="e.g., VVC 1"
                  disabled={loading}
                  value={formData?.title}
                  error={errors?.getError('title')}
                  onChange={onFormChange}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit(e)
                    }
                  }}
                  suffix={(
                    <Button
                      variant="icon"
                      onClick={onSubmit}
                      disabled={loading || !formData.title?.trim() || formData.title?.trim() === video.title?.trim()}
                    >
                      <IconChecked size={16} color="#AFDA11"/>
                    </Button>
                  )}
                />
              </div>
            )
            : (
              <div className="fs-20 fw-600 lh-24 txt-black-01 three-dot-1">
                {video.title || '---'}
              </div>
            )}
          <div className="fs-12 fw-400 lh-16 txt-grey-01">
            Used for {video.usedForCampaigns || 0} {(video.usedForCampaigns || 0) > 1 ? 'jobs' : 'job'}
          </div>
        </div>
        <div className="fx fx-ai-center gap-2">
          {onSelectVideo && (
            <Button
              variant="label"
              className="w-fit"
              onClick={() => onSelectVideo?.(video)}
            >
              Pick video
            </Button>
          )}

          <Button
            className="w-fit"
            onClick={eventHandler(ETrackingEvent.BTN_VIDEO_DETAILS, () => DialogService.open(ModalMyVideoDetail, { video, onChange }))}
          >
            Details
          </Button>

          {/* {video?.urlVideoSource && (
            <Button
              variant="icon"
              onClick={() => FileUtils.download(video?.urlVideoSource ?? '', video.title)}
            >
              <IconDownload/>
            </Button>
          )} */}

          <Button
            variant="icon"
            onClick={handleOpenMenu}
          >
            <IconMore size={16}/>
          </Button>

          <StyledMenu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            className={clsx(Style.menu, Style.menuOverride)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            disableEnforceFocus
            disableRestoreFocus
          >
            <MenuItem
              disableRipple
              className={clsx(Style.menuItem, Style.menuItemOverride)}
              onClick={eventHandler(ETrackingEvent.BTN_VIDEO_RENAME, handleRename)}
            >
              <IconPencilStroke size={20} color="currentColor"/>
              <span className="body-14">Rename</span>
            </MenuItem>
          </StyledMenu>
        </div>
      </div>
    </div>
  )
}
