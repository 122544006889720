import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconChat: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9361 10.7501H7.56259L7.48609 10.7554C7.34491 10.7748 7.21636 10.8471 7.12642 10.9577C7.03648 11.0682 6.99186 11.2088 7.00157 11.351C7.01129 11.4932 7.07461 11.6264 7.17875 11.7237C7.28289 11.821 7.42008 11.8751 7.56259 11.8751H10.9361L11.0126 11.8699C11.1538 11.8505 11.2823 11.7782 11.3723 11.6676C11.4622 11.5571 11.5068 11.4165 11.4971 11.2743C11.4874 11.1321 11.4241 10.9989 11.3199 10.9016C11.2158 10.8043 11.0786 10.7502 10.9361 10.7501Z" fill={color}/>
        <path d="M12.4413 8.12514H7.56259L7.48609 8.13039C7.34491 8.14981 7.21636 8.22211 7.12642 8.33266C7.03648 8.44322 6.99186 8.58379 7.00157 8.72598C7.01129 8.86816 7.07461 9.00136 7.17875 9.09866C7.28289 9.19595 7.42008 9.25009 7.56259 9.25014H12.4413L12.5171 9.24489C12.6582 9.22547 12.7867 9.15323 12.8767 9.04276C12.9666 8.93228 13.0113 8.7918 13.0016 8.64967C12.992 8.50754 12.9288 8.37435 12.8249 8.277C12.7209 8.17964 12.5838 8.12537 12.4413 8.12514Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.3501 9.99899C2.3501 5.77442 5.77511 2.3501 10.0001 2.3501C14.2251 2.3501 17.6501 5.77442 17.6501 9.99899C17.6501 14.2234 14.2254 17.6476 10.0006 17.6479C8.84807 17.6497 7.71126 17.3901 6.6753 16.8901L3.90936 17.6101C3.69951 17.6645 3.47834 17.6633 3.26918 17.6063C3.06003 17.5494 2.86937 17.4388 2.71607 17.2855C2.56277 17.1322 2.45215 16.9416 2.39516 16.7324C2.33818 16.5233 2.3368 16.3028 2.39118 16.093L3.11073 13.3274C2.61287 12.2997 2.3501 11.167 2.3501 9.99899ZM10.0001 3.6501C6.49289 3.6501 3.6501 6.49258 3.6501 9.99899C3.6501 11.0434 3.90229 12.0501 4.37667 12.9505L4.49408 13.1734L3.67324 16.3283L6.82808 15.507L7.05065 15.6238C7.96003 16.101 8.97201 16.3495 9.99905 16.3479L10.0001 16.3479C13.5073 16.3479 16.3501 13.5054 16.3501 9.99899C16.3501 6.49258 13.5073 3.6501 10.0001 3.6501Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
