import qs from 'qs'
import { FC, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { VibeApi } from 'src/api'
import { axiosHiringApi } from 'src/api/axios'
import { BreadcrumbHeading, Button, InputDebounce, Spinner } from 'src/components'
import { useBehaviorMapper, useDebouncedLoading } from 'src/hooks'
import { IconSearch, IconSuitcase } from 'src/icons'
import { ICampaignModel } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { CampaignCard } from 'src/partials/card/campaign-card'
import { ModalCampaignDetail } from 'src/partials/modal-campaign-detail'
import { ERoutes, browserHistory, generate } from 'src/router'
import { DialogService, LoadMoreService } from 'src/services'
import { AuthModule } from 'src/store'
import Style from './style.module.scss'

export const ClaimJobs: FC = () => {
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const profile = useBehaviorMapper(AuthModule.profile$)
  const [keyword, setKeyword] = useState<string>()

  const _loadMoreService = useMemo(() => new LoadMoreService<ICampaignModel>({
    axiosInstance: axiosHiringApi,
    endpoint: VibeApi._vibeCampaign + '?' + qs.stringify({
      keyword
    })
  }), [keyword])

  const { ref: refLoadMore, inView } = useInView({ threshold: 0 })
  const loading = useBehaviorMapper(_loadMoreService.loading$)
  const items = useBehaviorMapper(_loadMoreService.items$)

  useEffect(() => {
    if (inView && !loading && _loadMoreService.hasMore) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, inView, loading])

  useEffect(() => {
    _loadMoreService.loadMore()
  }, [_loadMoreService])

  const debounceLoading = useDebouncedLoading(!items.length && loading)

  return (
    <>
      <Breadcrumbs>
        <div className="fx fx-ai-center gap-6">
          <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>

          <InputDebounce
            prefix={<IconSearch size={20}/>}
            style={{ width: 350 }}
            placeholder="Search jobs, skills, locations..."
            value={keyword}
            onChange={setKeyword}
          />
        </div>
      </Breadcrumbs>

      <div className="fx-1 fx-column gap-5 p-8 bg-neutral-20">
        <div className="fx-column gap-3">
          <div className="fx fx-ai-center gap-4">
            <div className="semibold-32 txt-black-01">
              Found Job Listings
            </div>
            {!!items.length && (
              <Button
                className="px-6 py-2"
                onClick={() => browserHistory.push(generate(isAuthenticated ? ERoutes.CREATE_CAMPAIGN : ERoutes.SIGN_IN))}
              >
                Skip Step
              </Button>
            )}
          </div>
          {!!items.length && (
            <div className="heading-16 txt-grey-01">
              Claim a listing or select skip to create a new
            </div>
          )}
        </div>

        <div className="fx-1 fx fx-column gap-4 overflow-auto relative">
          {!!items.length && (
            <div className={Style.jobsGrid}>
              {items.map((campaign) => (
                <CampaignCard
                  key={campaign.id}
                  campaign={campaign as unknown as ICampaignModel}
                  onViewDetails={() => DialogService.open(ModalCampaignDetail, { campaign: { ...campaign, author: profile } })}
                />
              ))}
            </div>
          )}
          {!items.length && !debounceLoading && (
            <EmptyState/>
          )}
          {debounceLoading && (
            <div className="fx-1 fx fx-center">
              <Spinner/>
            </div>
          )}
          <div ref={refLoadMore}/>
        </div>
      </div>
    </>
  )
}

const EmptyState: FC = () => {
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  return (
    <div className="fx-1 fx-column fx-center gap-5 text-center txt-black-01">
      <NoResultsIllustration/>
      <div className="fx-column fx-center gap-10">
        <div className="fx-column gap-5">
          <div className="semibold-24">No Listed Jobs from Your Company</div>
          <div className="body-14" style={{ maxWidth: 390 }}>
            We couldn’t find any listed jobs from your company in our database. Click below to create a new job listing instead:
          </div>
        </div>
        <Button
          className="px-6 py-3"
          onClick={() => browserHistory.push(generate(isAuthenticated ? ERoutes.CREATE_CAMPAIGN : ERoutes.SIGN_IN))}
        >
          <IconSuitcase/>
          Create a listing
        </Button>
      </div>
    </div>
  )
}

const NoResultsIllustration: FC = () => (
  <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11342_227886)">
      <path d="M3.11619 39.7949C8.64691 49.5674 23.9902 69.6583 41.1178 71.8427C62.5273 74.5731 74.5027 69.6493 80.6173 62.2431C86.7319 54.8369 86.5409 40.6762 76.5163 36.8768C66.4918 33.0773 53.0852 38.2669 50.3213 48.7088C47.5573 59.1506 49.5104 73.4196 54.4498 79.3826C59.3893 85.3457 76.5109 94.5334 95.5991 88.9855C110.87 84.5472 130.962 75.7124 139.099 71.8498" stroke="black" stroke-width="3.33333" stroke-linecap="round" stroke-dasharray="8 8"/>
      <path d="M140.054 151.08C136.321 154.813 130.281 154.813 126.547 151.08L97.0405 121.573C96.0672 120.6 96.0672 119.027 97.0405 118.053L107.027 108.067C108.001 107.093 109.574 107.093 110.547 108.067L140.054 137.573C143.787 141.307 143.787 147.347 140.054 151.08Z" fill="#AFF6C5"/>
      <path d="M62.5471 11.1865C35.0671 11.1865 12.7871 33.4665 12.7871 60.9465C12.7871 88.4265 35.0671 110.707 62.5471 110.707C90.0271 110.707 112.307 88.4265 112.307 60.9465C112.307 33.4665 90.0271 11.1865 62.5471 11.1865ZM62.5471 100.88C40.4804 100.88 22.6004 82.9999 22.6004 60.9332C22.6004 38.8665 40.4804 20.9865 62.5471 20.9865C84.6138 20.9865 102.494 38.8665 102.494 60.9332C102.494 82.9999 84.6138 100.88 62.5471 100.88Z" fill="#AFF6C5"/>
      <path d="M103.574 61.1599C103.094 82.5999 85.8139 100.427 63.8806 99.4532C43.4673 98.5466 26.8406 81.1466 26.8139 60.7332C26.7739 39.2799 44.9606 21.4932 66.5073 22.4399C88.0539 23.3866 103.121 40.7199 103.574 61.1599C103.614 63.1199 106.734 62.3599 106.707 60.7332C106.307 43.0799 95.4273 27.1332 78.3739 21.6132C62.3339 16.4266 44.3339 21.6932 33.4939 34.5466C22.3739 47.7466 20.5606 67.0399 29.3073 81.9732C38.0539 96.9066 56.7073 105.16 74.0006 101.267C93.0273 96.9732 106.281 80.0399 106.721 60.7199C106.761 58.7733 103.627 59.5199 103.587 61.1466L103.574 61.1599Z" fill="black"/>
      <path d="M113.388 61.1598C113.015 81.2265 100.988 99.5865 81.8146 106.466C62.6413 113.346 40.7346 107.133 27.9346 91.3731C15.1346 75.6131 13.4946 53.1865 23.8279 35.8265C34.1613 18.4665 55.4679 9.31979 75.3879 13.7731C97.5613 18.7198 112.975 38.6798 113.388 61.1598C113.428 63.1198 116.548 62.3598 116.521 60.7331C116.121 39.1465 102.841 19.5865 82.1346 12.5198C61.9213 5.62646 38.9746 12.3065 25.4946 28.8131C12.0146 45.3065 10.0946 69.1331 21.1213 87.4398C32.1479 105.746 54.7879 115.573 75.9879 110.893C99.5213 105.693 116.095 84.6265 116.535 60.7331C116.575 58.7731 113.441 59.5331 113.401 61.1598H113.388Z" fill="black"/>
      <path d="M139.36 148.307C130.613 155.813 116.613 133.547 112.133 128.32C108.733 124.36 104.8 120.507 101.773 116.253C98.7468 112 101.907 111.067 105.467 108.027C109.16 104.853 110.44 102.08 113.893 106.107C117.347 110.133 121.067 114.467 124.653 118.653C128.56 123.213 132.48 127.773 136.387 132.333C140.293 136.893 145.307 142.2 139.36 148.32C137.84 149.88 140.08 151.333 141.427 149.947C150.547 140.587 140.427 131.973 134.24 124.76C129.827 119.613 125.413 114.48 121.013 109.333C119.053 107.053 117.04 103.68 114.6 101.867C110.733 98.9866 107.8 102.613 104.88 105.12C102.547 107.133 95.9735 110.813 96.6935 114.707C97.2268 117.56 101.213 120.653 102.96 122.693C107.333 127.8 111.72 132.907 116.093 138C122.12 145.013 130.827 159 141.413 149.92C143.067 148.493 140.787 147.053 139.347 148.293L139.36 148.307Z" fill="black"/>
      <path d="M92.8675 102.2C95.2408 105.027 97.6142 107.867 100.001 110.693C101.094 112 103.908 110.213 102.828 108.933C100.454 106.107 98.0808 103.267 95.6942 100.44C94.6008 99.1333 91.7875 100.92 92.8675 102.2Z" fill="black"/>
      <path d="M99.1077 96.8667C101.481 99.6934 103.854 102.533 106.241 105.36C107.334 106.667 110.148 104.88 109.068 103.6C106.694 100.773 104.321 97.9334 101.934 95.1067C100.841 93.8001 98.0277 95.5867 99.1077 96.8667Z" fill="black"/>
      <path d="M31.5342 130.8C30.8542 123.733 40.8142 123.373 41.7608 129.933C42.1742 132.787 40.1608 135.507 37.2408 135.667C34.3208 135.827 32.0942 133.56 31.5342 130.8C31.1742 129.027 28.1208 130.32 28.4408 131.92C29.3742 136.467 33.6542 139.307 38.2408 138.253C42.4408 137.28 45.5342 133.133 44.8542 128.8C44.1742 124.467 39.7475 121.56 35.4142 122.387C30.8408 123.267 28.0142 127.387 28.4408 131.92C28.6142 133.68 31.6808 132.413 31.5342 130.8Z" fill="black"/>
      <path d="M81.2819 139.24C80.9486 135.787 85.5886 136.2 85.9619 139.2C86.1086 140.427 85.3886 141.653 84.0953 141.733C82.5886 141.84 81.5486 140.587 81.2686 139.24C80.9086 137.467 77.8553 138.76 78.1753 140.36C79.5753 147.187 90.1219 144.667 89.0286 138.067C88.5619 135.2 85.7086 133.48 82.9353 133.987C79.8553 134.547 77.8819 137.307 78.1753 140.36C78.3486 142.12 81.4153 140.853 81.2686 139.24H81.2819Z" fill="black"/>
      <path d="M133.761 34.08C133.587 37.4134 129.267 36.56 129.174 33.6534C129.067 30.3067 133.614 31.2 133.761 34.08C133.867 36.0267 136.974 35.2667 136.894 33.6534C136.734 30.76 134.614 28.4934 131.601 28.6267C128.587 28.76 126.121 31.1334 126.054 34.08C125.987 37.0267 128.374 39.1334 131.161 39.12C134.187 39.1067 136.747 36.6667 136.907 33.6534C137.014 31.7067 133.867 32.4667 133.774 34.08H133.761Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_11342_227886">
        <rect width="160" height="160" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)
