import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ViewApi } from 'src/api'
import { useBehaviorMapper } from 'src/hooks'
import { IShareModel } from 'src/interfaces'
import { AuthModule } from 'src/store'

export const Home: FC = () => {
  const history = useHistory()

  const { sharingToken } = useParams<{sharingToken: string}>()
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  const profile = useBehaviorMapper(AuthModule.profile$)
  const [shareData, setSharedData] = useState<IShareModel>()

  const loadSharedEmail = async (sharingToken: string) => {
    try {
      const { data } = await ViewApi.share(sharingToken)
      setSharedData(data)
    } catch (error) {
      console.error('not be able to get shared video data', error)
    }
  }

  /**
   * TODO: cache sharing token for redirecting after login
   */
  // useEffect(() => {
  //   dispatch(setLayoutSharingToken(sharingToken))
  // }, [dispatch, sharingToken])

  useEffect(() => {
    if (!shareData) {
      loadSharedEmail(sharingToken)
    }
  }, [shareData, sharingToken])

  useEffect(() => {
    if (!shareData) {
      return
    }

    if (isAuthenticated && profile?.email && profile?.email === shareData.email) {
      // TODO: is introduction video still be viewed in the likes page?
      return history.push({
        pathname: '/likes',
        search: `?detailCandidateId=${shareData.candidateId}&detailTab=1`
      })
    }

    if (isAuthenticated) {
      // different shared user -> reset layout and redirect to home
      // dispatch(setLayoutSharingToken(null))
      return history.push('/')
    }

    return history.push(`/home/${sharingToken}`)
  }, [profile?.email, history, isAuthenticated, shareData, sharingToken])

  return null
}
