import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { abbreviateFullName, StyleUtils } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  fullName?: string
  avatarUrl?: string
  size?: number
  className?: string
  onClick?: () => void
}

export const UserAvatar: FC<IProps> = ({
  size = 44,
  fullName = '',
  avatarUrl,
  className,
  ...props
}) => {
  const abbreviation = useMemo(() => {
    return abbreviateFullName(fullName)
  }, [fullName])

  return avatarUrl
    ? (
      <div
        {...props}
        className={clsx(Style.avatar, className)}
        style={{
          ...StyleUtils.backgroundCover(avatarUrl),
          width: size,
          minWidth: size,
          height: size
        }}
      />
    )
    : (
      <div
        {...props}
        className={clsx(Style.avatar, 'fx fx-center', className)}
        style={{ minWidth: size, height: size }}
      >
        <span style={{ transform: `scale(${size / 40})` }}>
          {abbreviation}
        </span>
      </div>
    )
}
