import { Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import { ChangeEventHandler, FC, FormEventHandler, MouseEventHandler, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AnalyticApi, AuthApi, CampaignApi } from 'src/api'
import { Button, Checkbox, Input } from 'src/components'
import { PRIVACY_POLICY_URL, TERMS_AND_SERVICE_URL } from 'src/constants'
import { useUnsubscribe, useValidation } from 'src/hooks'
import { IOverlayProps } from 'src/interfaces/overlay-props.interface'
import { ERoutes, browserHistory, generate } from 'src/router'
import { OverlayService, SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { getApiErrorMessage } from 'src/utils'
import { signInSchema, workEmailSchema } from 'src/validation'
import Style from './style.module.scss'

interface IProps {
  hashId: string
  campaignId: number
  name?: string
}

/**
 * @deprecated
 */
export const JobClaimedRestrictModal: FC<IProps> & IOverlayProps = (props) => {
  const theme = useTheme()
  const unsubscribe$ = useUnsubscribe()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [stage, setStage] = useState<'email_verify' | 'sign_in'>('email_verify')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [rememberMe, setRememberMe] = useState(false)
  const [sameCompany, setSameCompany] = useState(false)
  const { errors, validate } = useValidation({
    data: useMemo(() => ({ email, password }), [email, password]),
    schema: stage === 'email_verify' ? workEmailSchema : signInSchema
  })

  const previousVerifiedEmailRef = useRef<string>('')

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.persist()
    const { name, value } = e.target

    if (stage === 'email_verify') {
      if (name === 'email' && value && previousVerifiedEmailRef.current && value === previousVerifiedEmailRef.current) {
        setEmail(value)
        setStage('sign_in')
        return
      }
      setEmail(value)
      return
    }

    if (stage === 'sign_in') {
      if (name === 'email') {
        setStage('email_verify')
        return
      }
      setPassword(value)
    }
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> & MouseEventHandler = async (e) => {
    e.preventDefault()

    if (stage === 'email_verify') {
      const { isValid, value } = await validate()
      if (!isValid || !value) {
        return
      }

      setLoading(true)
      from(CampaignApi.verifyAccount(props.hashId, { email, sessionId: AnalyticApi._session }))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            SnackbarService.error(getApiErrorMessage(error))
            return EMPTY
          }),
          finalize(() => {
            setLoading(false)
          })
        )
        .subscribe((response) => {
          const data = response.data
          if (!data) {
            OverlayService.reset()
            history.push(generate(ERoutes.SIGN_UP), email)
            return
          }

          setSameCompany(data.sameCompany)
          previousVerifiedEmailRef.current = email
          setStage('sign_in')
        })
    }

    if (stage === 'sign_in') {
      const isValid = await validate({ schema: signInSchema, data: { email, password } })
      if (!isValid) {
        return
      }

      setLoading(true)
      from(AuthApi.login({ email, password }))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            SnackbarService.error(getApiErrorMessage(error))
            return EMPTY
          }),
          finalize(() => setLoading(false))
        )
        .subscribe(({ data }) => {
          OverlayService.reset()
          AuthModule.authenticated(data.profile)
          AnalyticApi.resetSession()
          if (sameCompany && props.campaignId) {
            browserHistory.push(generate([ERoutes.CAMPAIGN_SUBMISSIONS, { id: props.campaignId }]))
          }
        })
    }
  }

  return (
    <div className={Style.container}>
      <div className="fx flex-column fx-ai-center gap-14px">
        <div className="fx flex-column">
          <Typography
            variant="body2-bold"
            align="center"
            color={theme.colors['--color-neutral-theme-300']}
          >
            This job is managed by {props.name ? (<Typography variant="body2-bold" textTransform="capitalize" color={theme.colors['--color-neutral-theme-900']}>{props.name}</Typography>) : 'someone else'}. If you work in the same company as them, verify your email to access.
          </Typography>
        </div>
      </div>

      <form className="fx flex-column fx-ai-center gap-4" onSubmit={handleSubmit}>
        <Input
          label="Email"
          name="email"
          autoComplete="new-password"
          placeholder="name@company.com"
          type="email"
          disabled={loading}
          required
          onChange={handleChangeInput}
          error={errors.getError('email')}
        />

        {stage === 'sign_in' && (
          <Input
            label="Password"
            name="password"
            placeholder="Enter your password"
            type="password"
            autoFocus
            disabled={loading}
            required
            onChange={handleChangeInput}
            error={errors.getError('password')}
          />
        )}

        <Button
          className="w-100-p"
          type="submit"
          disabled={loading || errors.hasError()}
        >
          <span className="body1-bold">
            {stage === 'email_verify' ? 'Sign Up' : 'Sign In'}
          </span>
        </Button>

        {stage === 'email_verify' && (
          <Typography
            align="center"
            variant="body2-regular"
            color={theme.colors['--color-neutral-theme-300']}
          >
            By signing up, you agree to KnowMe’s <a href={PRIVACY_POLICY_URL} target="_blank" className="text-decorator-underline color-neutral-theme-300 font-medium" rel="noreferrer">Privacy Policy</a> & <a href={TERMS_AND_SERVICE_URL} className="color-neutral-theme-300 text-decorator-underline font-medium">Terms of Service</a>
          </Typography>
        )}

        {stage === 'sign_in' && (
          <div className="fx flex-row fx-jc-space-between fx-ai-center w-100">
            <Checkbox
              checked={rememberMe}
              onChange={() => { setRememberMe(!rememberMe) }}
              label="Remember me"
            />

            <a
              href={generate(ERoutes.FORGOT_PASSWORD)}
              target="_blank"
              className="body2-regular text-decorator-underline pointer hover-color-cyan-500"
              rel="noreferrer"
            >Forgot password
            </a>
          </div>
        )}
      </form>
    </div>
  )
}

JobClaimedRestrictModal.getOverlayState = (hashId: string, campaignId: number, name?: string) => ({
  content: <JobClaimedRestrictModal hashId={hashId} campaignId={campaignId} name={name}/>,
  open: true,
  blank: true,
  overlayBackground: 'rgba(0, 0, 0, 0.25)',
  disabled: true
})
