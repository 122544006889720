import qs from 'qs'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { ReactionApi } from 'src/api'
import { axiosHiringApi } from 'src/api/axios'
import { Button, Spinner } from 'src/components'
import { EReaction } from 'src/enums'
import { useAsRef, useBehaviorMapper, useDebouncedLoading } from 'src/hooks'
import { IconEmployersStroke, IconZeroMatches } from 'src/icons'
import { IReactionModel } from 'src/interfaces'
import { ReactionCard } from 'src/partials/card/reaction-card'
import { ModalMatchesDetail } from 'src/partials/modal-matches-detail'
import { browserHistory, ERoutes, generate } from 'src/router'
import { DialogService, LoadMoreService } from 'src/services'

const Empty = () => (
  <div className="fx-1 fx-column fx-center gap-5 heading-24 txt-black-01">
    <IconZeroMatches/>

    <span>No matches yet!</span>

    <Button onClick={() => browserHistory.push(generate(ERoutes.TALENT_VIBE_TALENT))}>
      <IconEmployersStroke/>
      Explore Talent
    </Button>
  </div>
)

interface IProps {
  keyword?: string
}

export const Matches: FC<IProps> = ({ keyword }) => {
  const _loadMoreService = useMemo(() => new LoadMoreService<IReactionModel>({
    axiosInstance: axiosHiringApi,
    endpoint: ReactionApi._prefix + '?' + qs.stringify({
      keyword,
      reaction: [EReaction.SUPER_LIKE, EReaction.SUPER_NOVA] // Both like each other
    })
  }), [keyword])

  const { ref: refLoadMore, inView } = useInView({ threshold: 0 })
  const loading = useBehaviorMapper(_loadMoreService.loading$)
  const items = useBehaviorMapper(_loadMoreService.items$)

  useEffect(() => {
    if (inView && !loading && _loadMoreService.hasMore) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, inView, loading])

  useEffect(() => {
    _loadMoreService.loadMore()
  }, [_loadMoreService])

  const debounceLoading = useDebouncedLoading(!items.length && loading)

  const [currentIndex, setCurrentIndex] = useState<number>(-1)
  const canPreviousProps = useMemo(() => !currentIndex, [currentIndex])
  const canNextProps = useMemo(() => currentIndex + 1 === items?.length, [currentIndex, items?.length])

  const onPrevious = useCallback(() => setCurrentIndex(prev => prev - 1), [])

  const onNext = useCallback(() => {
    setCurrentIndex(prev => prev + 1)

    if (currentIndex === items.length - 2) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, currentIndex, items.length])

  const onDetail = useCallback((index: number) => {
    setCurrentIndex(index)
  }, [])

  const propsRef = useAsRef({
    currentIndex,
    onPrevious,
    onNext,
    canPreviousProps,
    canNextProps,
    reaction: items?.[currentIndex]
  })
  const modalRef = useRef<ReturnType<typeof DialogService.open>>()
  useEffect(() => {
    if (currentIndex <= -1) {
      if (modalRef.current) {
        modalRef.current.unmount()
        modalRef.current = undefined
      }
      return
    }

    const reaction = propsRef.current.reaction
    const user = reaction?.user || reaction?.toUser
    const props = {
      ...propsRef.current,
      talent: {
        ...user,
        reaction
      },
      lookupId: user?.lookupId,
      pfv: user?.pfv
    }
    if (modalRef.current) {
      modalRef.current.props(props)
      return
    }
    modalRef.current = DialogService.open(ModalMatchesDetail, {
      ...propsRef.current,
      talent: {
        ...user,
        reaction
      },
      lookupId: user?.lookupId,
      pfv: user?.pfv,
      onUnmounted: () => {
        setCurrentIndex(-1)
        modalRef.current = undefined
      }
    })
  }, [currentIndex, propsRef])

  if (debounceLoading) {
    return (
      <div className="fx-1 fx fx-center">
        <Spinner/>
      </div>
    )
  }

  if (!items?.length) {
    return <Empty/>
  }

  return (
    <section className="fx fx-wrap-wrap gap-4">
      {items?.map((item, index) => (
        <ReactionCard
          key={item.id}
          reaction={item}
          onDetail={() => onDetail(index)}
        />
      ))}
      <div ref={refLoadMore}/>
    </section>
  )
}
