import { BehaviorSubject } from 'rxjs'
import { WithOutNextComplete } from 'types/rxjs'

const _loading$ = new BehaviorSubject<boolean>(false)

export class LoadingModule {
  static get loading$(): WithOutNextComplete<typeof _loading$> {
    return _loading$
  }

  static toggle(val?: boolean) {
    const value = val ?? !_loading$.value
    if (value !== _loading$.value) {
      _loading$.next(value)
    }
  }
}
