import { IRouterOption } from 'src/router'
import { RedirectNotification } from './components/redirect'
import { ERedirectNotificationRoutes } from './routes.enum'

export const RedirectNotificationRoutes: IRouterOption[] = [
  {
    path: '/redirect-notification',
    component: RedirectNotification,
    name: ERedirectNotificationRoutes.REDIRECT_NOTIFICATION,
    exact: true,
    meta: {
      requireAuth: false
    }
  }
]
