import clsx from 'clsx'
import moment from 'moment'
import { ComponentProps, FC, FocusEvent, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { IconWarning } from 'src/icons'
import { DatePickerHeader } from './header'

import 'react-datepicker/dist/react-datepicker.css'
import { Input } from '../input'
import { DatePickerInput } from './input'
import Style from './style.module.scss'

interface IProps extends Omit<ComponentProps<typeof ReactDatePicker>, 'value' | 'onChange'> {
  value?: Date
  onChange?: (date: string) => void
  placeholder?: string
  className?: string
  wrapperClassName?: string
  innerError?: string
  error?: boolean | string
  disabled?: boolean
  minDate?: Date
  maxDate?: Date
  inputProps?: ComponentProps<typeof Input>
}

export const DatePicker: FC<IProps> = ({
  value,
  onChange,
  placeholder = 'Select a date',
  inputProps,
  ...props
}) => {
  const [focus, setFocus] = useState(false)

  return (
    <div className={clsx('fx-column gap-2', props.className)}>
      <div
        className={clsx(Style.datepicker, props.wrapperClassName, {
          [Style.error]: props.error,
          [Style.disabled]: props.disabled,
          [Style.focus]: focus
        })}
      >
        <ReactDatePicker
          selected={value}
          onChange={(date: Date | null) => onChange?.(date ? moment(date).format('YYYY-MM-DD') : '')}
          dateFormat="MM/dd/yyyy"
          placeholderText={placeholder}
          onFocus={(e: FocusEvent<HTMLInputElement, Element>) => {
            props?.onFocus?.(e)
            setFocus(true)
          }}
          onBlur={(e: FocusEvent<HTMLInputElement, Element>) => {
            setFocus(false)
            props?.onBlur?.(e)
          }}
          popperClassName={clsx(Style.datePickerPopper)}
          customInput={<DatePickerInput {...inputProps} dateFormat={props.dateFormat}/>}
          renderCustomHeader={(headerProps) => <DatePickerHeader {...headerProps} minDate={props.minDate} maxDate={props.maxDate}/>}
          showPopperArrow={false}
          {...props}
        />
      </div>

      {props.error && (
        <span className={clsx({ [Style.innerError]: props.innerError, meta: !props.innerError }, 'fx fx-ai-center gap-1 txt-negative-500')}>
          <IconWarning size={16}/>
          {props.error}
        </span>
      )}
    </div>
  )
}
