import { Box, Dialog } from '@mui/material'
import clsx from 'clsx'
import { FC } from 'react'
import {
  useBehaviorMapper,
  useIfMobileScreen
} from 'src/hooks'
import { IconClose } from 'src/icons'
import { OverlayService } from 'src/services'
import { LoadingModule } from 'src/store'
import Style from './style.module.scss'

export const Overlay: FC = () => {
  const isMobile = useIfMobileScreen()
  const isLayoutLoading = useBehaviorMapper(LoadingModule.loading$)
  const appOverlay = useBehaviorMapper(OverlayService.overlay$)

  const handleClose = () => {
    if (isLayoutLoading || appOverlay.disabled) return

    return OverlayService.reset()
  }

  return (
    <Dialog
      onClose={() => !appOverlay.disabled && handleClose()}
      open={!!appOverlay.open}
      className={clsx(Style.overlay, { [Style.hasContent]: !appOverlay.blank })}
      style={{
        ...(appOverlay.overlayBackground ? { background: appOverlay.overlayBackground } : {}),
        ...(appOverlay.isHiddenBackdropFilter ? { backdropFilter: 'unset' } : { backdropFilter: 'blur(16px)' })
      }}
    >
      <Box
        position="relative"
        m={isMobile ? 1 : 2}
        sx={{ maxHeight: 'calc(100% - 32px)' }}
        style={appOverlay.overlayStyle}
      >
        {!appOverlay.disabled && (
          <div
            className={clsx(Style.closeIcon, {
              'fx fx-center': appOverlay.open,
              'd-none': !appOverlay.open
            })}
            style={appOverlay.closeStyle || {}}
            onClick={handleClose}
          >

            <IconClose style={{ ...(appOverlay.closeStyle || {}), ...(appOverlay.closeIconStyle || {}) }}/>

          </div>
        )}

        {appOverlay.content}
      </Box>
    </Dialog>
  )
}
