import { ComponentProps, FC, useCallback, useMemo, useState } from 'react'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi, CampaignInviteApplyApi } from 'src/api'
import { Button, Checkbox, ModalCenter, Spinner } from 'src/components'
import { useBehaviorMapper, useDebouncedLoading, useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { IconLinking } from 'src/icons'
import { ICampaignModel, IReactionModel, IUserModel } from 'src/interfaces'
import { browserHistory, ERoutes, generate } from 'src/router'
import { DialogService, SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { ModalCampaignDetail } from '../modal-campaign-detail'
import Style from './style.module.scss'

interface IProps {
  talent?: Partial<IUserModel> & { reaction?: IReactionModel; createdReaction?: IReactionModel }
}

export const ModalInviteJob: FC<ComponentProps<typeof ModalCenter> & IProps> = ({ talent, onClose, ...props }) => {
  const unsubscribe$ = useUnsubscribe()
  const profile = useBehaviorMapper(AuthModule.profile$)
  const [campaigns, setCampaigns] = useState<(ICampaignModel & { checked?: boolean })[]>([])
  const [loading, setLoading] = useState(false)

  const campaignIds = useMemo(() => {
    const updatedCampaigns = campaigns.reduce((result: number[], item) => {
      if (item.checked) {
        result = [...result, item.id]
      }

      return result
    }, [])

    return updatedCampaigns
  }, [campaigns])

  const onChange = useCallback((id: number) => {
    const updatedCampaigns = campaigns?.map(item => {
      if (item.id === id) {
        item.checked = !item.checked
      }
      return item
    })

    setCampaigns(updatedCampaigns)
  }, [campaigns])

  const onSubmit = useCallback(async () => {
    if (!campaignIds?.length || !talent?.id) return
    setLoading(true)

    from(CampaignInviteApplyApi.send({ campaignIds, inviteeIds: [talent.id] }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        onClose?.()
        SnackbarService.success('The invitation to the candidate has been sent successfully')
      })
  }, [campaignIds, onClose, talent?.id, unsubscribe$])

  useUnsubscribeEffect((unsubscribe$) => {
    setLoading(true)
    from(CampaignApi.paginate())
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          browserHistory.push(generate(ERoutes.ERROR_404))
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        setCampaigns(data)
      })
  }, [])

  const debounceLoading = useDebouncedLoading(!campaigns.length && loading)

  return (
    <ModalCenter {...props}>
      <div className={Style.modalInviteJob}>
        <div className="fx fx-column gap-2">
          <div className="fs-24 fw-600 lh-28 txt-black-01 text-center">Invite Candidate to Apply</div>
          <div className="fs-14 fw-400 lh-20 txt-black-02 text-center">Select Job(s)</div>
        </div>

        {debounceLoading
          ? (
            <div className="fx-1 fx fx-center">
              <Spinner/>
            </div>
          )
          : (
            <div className="fx fx-column gap-4 w-full">
              {campaigns?.map(item => (
                <div
                  key={item.id}
                  className="fx fx-ai-center fx-jc-space-between gap-3 round-3 px-4 py-3 bg-neutral-100 pointer"
                  onClick={() => onChange(item.id)}
                >
                  <div className="fx fx-ai-center gap-3">
                    <Checkbox
                      checked={item.checked}
                      onClick={e => e.stopPropagation()} // fix parent div onClick trigger twice
                    />
                    <div className="fs-12 fw-700 txt-black-01">{item.jobTitle}</div>
                  </div>
                  <div
                    className="fx fx-ai-center gap-2 pointer"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      DialogService.open(ModalCampaignDetail, {
                        campaign: {
                          ...item,
                          author: profile
                        }
                      })
                    }}
                  >
                    <div className="fs-12 fw-500 lh-16 txt-blue-1000">View</div>
                    <IconLinking color="#0066AD"/>
                  </div>
                </div>
              ))}
            </div>
          )}

        <Button
          className="w-full"
          disabled={loading || !campaignIds?.length}
          onClick={onSubmit}
        >
          Confirm
        </Button>
      </div>
    </ModalCenter>
  )
}
