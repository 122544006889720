import { Avatar, Box, ClickAwayListener, StyledEngineProvider, Typography } from '@mui/material'
import clsx from 'clsx'
import { uniqWith } from 'lodash'
import { FC, SVGProps, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { CandidateApi, NotificationApi } from 'src/api'
import { Button, NoTabletOrMobile, Spinner, TabletAndMobile, TimeAgo, useAnalytic, UserAvatar } from 'src/components'
import { ENotificationAction, ETrackingEvent } from 'src/enums'
import { useBehaviorMapper } from 'src/hooks'
import { IconBell, IconNotificationComment, IconNotificationCredit, IconNotificationSuperNova } from 'src/icons'
import { INotificationModel, IPaginationResponse } from 'src/interfaces'
import { browserHistory, ERoutes, generate } from 'src/router'
import { SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { ensureArray, getApiErrorMessage, getAvatar, getFullName } from 'src/utils'
import { NotPortalIfDesktop } from './not-portal-if-desktop'
import { NoticeBadge } from './notice-badge'
import { NotificationDialog } from './preview'
import Style from './style.module.scss'

export const NotificationBadge: FC = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const profile = useBehaviorMapper(AuthModule.profile$)
  const { eventHandler } = useAnalytic('top_nav')
  // const [newNotification, setNewNotification] = useState(false)
  const [data, setData] = useState<IPaginationResponse<INotificationModel>>({
    page: 1,
    pages: 1,
    total: 0,
    rows: []
  })

  const IconAction: Record<Partial<ENotificationAction>, FC<SVGProps<SVGSVGElement>>> = {
    [ENotificationAction.SUPER_NOVA]: IconNotificationSuperNova,
    [ENotificationAction.COMMENT_CANDIDATE_VIDEO]: IconNotificationComment,
    [ENotificationAction.INVITATION_BONUS]: IconNotificationCredit,
    [ENotificationAction.FIRST_TIME_UPLOADED_PFV]: IconNotificationCredit,
    [ENotificationAction.NEW_CAMPAIGN_SUBMISSION]: IconNotificationComment,
    [ENotificationAction.GO_TO_VIBE_CHECK]: IconNotificationComment,
    [ENotificationAction.GO_TO_VIBE_TUTORIAL]: IconNotificationComment,
    [ENotificationAction.GO_TO_QUIZ]: IconNotificationComment,
    [ENotificationAction.GO_TO_DASHBOARD]: IconNotificationComment,
    [ENotificationAction.GO_TO_AUDIO_VIBE_CHECK_SCREEN]: IconNotificationComment,
    [ENotificationAction.GO_TO_PROFILE_SCREEN]: IconNotificationComment,
    [ENotificationAction.GO_TO_SUMMARY_SCREEN]: IconNotificationComment
  }

  const handleOpenDropdown = () => setIsOpen(!isOpen)

  const handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    const element = (e.target as HTMLElement)
    const scrollBottom = element.scrollHeight - element.scrollTop === element.clientHeight

    if (data.page === data.pages) return
    if (data.page && scrollBottom) {
      return loadNotifications(data.page + 1)
    }
  }

  const loadNotification = async (option: INotificationModel) => {
    try {
      if (!option.seenAt) {
        await NotificationApi.detail(option.id)

        setData(prev => ({
          ...prev,
          rows: prev.rows?.map(item => {
            if (item.id === option.id) {
              item.seenAt = new Date().toISOString()
            }

            return item
          })
        }))
      }

      if (!option.action) {
        return
      }

      if (option.action === ENotificationAction.NEW_CAMPAIGN_SUBMISSION) {
        setIsOpen(false)
        browserHistory.push({
          pathname: '/campaigns',
          search: `?campaignId=${option.data?.campaignId}`
        })
        return
      }

      // comment on an job applicant's video
      if (option.action === ENotificationAction.COMMENT_CANDIDATE_VIDEO && option?.data?.campaignId) {
        browserHistory.push(generate([ERoutes.CAMPAIGN_SUBMISSIONS, {
          id: option?.data?.campaignId
        }], { detailSubmissionId: option?.data?.campaignSubmissionId, detailTab: 1 }))

        return
      }

      if ([ENotificationAction.INVITATION_BONUS, ENotificationAction.FIRST_TIME_UPLOADED_PFV].includes(option.action)) {
        return
      }

      const id: number = option.action === ENotificationAction.COMMENT_CANDIDATE_VIDEO
        ? option.data?.candidateId
        : option.data?.creator
      const { data } = await CandidateApi.detail(id)
      setIsOpen(false)

      const state = option.action === ENotificationAction.COMMENT_CANDIDATE_VIDEO ? 1 : 0
      const location = data.interviewed
        ? {
          pathname: '/intros',
          search: `?detailCandidateId=${id}&detailTab=${state}`,
          state
        }
        : {
          pathname: '/likes',
          search: `?detailCandidateId=${id}&detailTab=${state}`,
          state
        }

      browserHistory.push(location)
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    }
  }

  const loadNotifications = async (page = 1, limit = 10) => {
    try {
      const params = {
        page,
        limit
      }

      const { data } = await NotificationApi.pagination(params)

      setData(prev => ({
        ...prev,
        page: data.page,
        pages: data.pages,
        total: data.total,
        rows: uniqWith(
          page === 1
            ? data.rows
            : [...ensureArray(prev.rows), ...ensureArray(data.rows)],
          (a, b) => a.id === b.id
        )
      }))
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      loadNotifications()
    }
  }, [isOpen])

  // const handleNewNotificationChange = useCallback((newNotification: boolean) => {
  //   setNewNotification(newNotification)
  // }, [setNewNotification])

  const renderView = useMemo(() => {
    switch (true) {
      case loading:
        return <div className={Style.STLoading}><Spinner/></div>

      case !data.rows?.length:
        return (
          <div className={Style.STEmpty}>
            <Typography variant="body2-regular">
              You don’t have any notifications
            </Typography>
          </div>
        )

      default: return (
        <div className={Style.STDropdownItemWrapped} onScroll={handleScroll}>
          {data.rows?.map(item => {
            const Icon = IconAction[item.action as ENotificationAction]
            if (item.action === ENotificationAction.SUPER_NOVA) {
              return (
                <div
                  key={item.id}
                  className={clsx(Style.STDropdownItem, Style.STDropdownItem_isSupernova)}
                  onClick={() => loadNotification(item)}
                >
                  <div className={clsx(Style.STInfo, { [Style.STInfo_isSeen]: !!item.seenAt })}>
                    <StyledEngineProvider injectFirst>
                      <div className={Style.STMultiAvatar}>
                        {Icon && <Icon className={Style.supernova}/>}
                        <Avatar className={Style.avatar1} sx={{ width: 40, height: 40 }} src={item.imageUrl}/>
                        <div className={Style.bgAvatar1}/>
                        <div className={Style.bgAvatar2}/>
                        <UserAvatar
                          size={40}
                          className={Style.avatar2}
                          fullName={getFullName(profile)}
                          avatarUrl={getAvatar(profile)}
                        />
                      </div>
                    </StyledEngineProvider>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2-regular">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {item.body || ''}
                        </ReactMarkdown>
                      </Typography>
                      <Typography variant="meta-regular"><TimeAgo date={item.createdAt}/></Typography>
                    </Box>
                  </div>
                  <div className={clsx(Style.STStatus, { [Style.STStatus_isShow]: !item.seenAt })}/>
                  <div className={Style.STBackgroundSupernova}/>
                </div>
              )
            }

            return (
              <div
                className={Style.STDropdownItem}
                key={item.id}
                onClick={() => loadNotification(item)}
              >
                <div className={clsx(Style.STInfo, { [Style.STInfo_isSeen]: !!item.seenAt })}>
                  <div className={Style.STAvatar}>
                    {Icon && <Icon/>}
                    <Avatar sx={{ width: 56, height: 56 }} src={item.imageUrl}/>
                  </div>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2-regular">
                      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {item.body || ''}
                      </ReactMarkdown>
                    </Typography>
                    <Typography variant="meta-regular"><TimeAgo date={item.createdAt}/></Typography>
                  </Box>
                </div>
                <div className={clsx(Style.STStatus, { [Style.STStatus_isShow]: !item.seenAt })}/>
              </div>
            )
          })}
        </div>
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading])

  return (
    <div className={Style.STNoti}>
      <Button
        className="relative"
        variant="icon"
        black={isOpen}
        // emphasis={!isOpen && newNotification}
        onClick={eventHandler(ETrackingEvent.BTN_NAV_NOTIFICATIONS, handleOpenDropdown)}
      >
        <IconBell size={20} className={isOpen ? 'txt-neutral-white' : 'txt-black-01'}/>
        <NoticeBadge/>
      </Button>

      {isOpen && (
        <>
          <NoTabletOrMobile>
            <NotPortalIfDesktop>
              <ClickAwayListener onClickAway={handleOpenDropdown}>
                <div className={clsx(Style.STDropdown, { [Style.STDropdown_largeDataSize]: (data.rows?.length || 0) > 10 })}>
                  <Box mb={2} ml={2}>
                    <Typography variant="h4">Notification</Typography>
                  </Box>
                  {renderView}
                </div>
              </ClickAwayListener>
            </NotPortalIfDesktop>
          </NoTabletOrMobile>
          <TabletAndMobile>
            <NotificationDialog open onClose={handleOpenDropdown}>
              {renderView}
            </NotificationDialog>
          </TabletAndMobile>
        </>
      )}
    </div>
  )
}
