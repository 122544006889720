import { FC } from 'react'
import {
  Button
} from 'src/components'
import { IconPencilStroke } from 'src/icons'
import { TARGET_PUBLIC_GUEST_EDIT_JOB } from 'src/services/tour/guest-manage-job-tour.service'
import Style from './style.module.scss'

interface IProps {
  onClick?: () => void
}

/**
 * @deprecated
 */
export const ButtonEdit: FC<IProps> = (props) => {
  return (
    <Button
      type="button"
      variant="secondary"
      className={Style.button}
      onClick={props.onClick}
      id={TARGET_PUBLIC_GUEST_EDIT_JOB.getValue()}
    >
      <IconPencilStroke/>
    </Button>
  )
}
