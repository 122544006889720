import clsx from 'clsx'
import { ComponentProps, ForwardedRef, forwardRef } from 'react'
import Style from './style.module.scss'

interface IProps {
  size?: number
  color?: string
  /**
   * @deprecated
   */
  scale?: number
  bg?: boolean
  disabled?: boolean
  rotate?: number
  rotateX?: number
  rotateY?: number
  rotateZ?: number
  className?: string
}

function _IconWrapper({
  title,
  children,
  size = 16,
  scale = 1,
  color,
  bg,
  disabled,
  className,
  rotate,
  rotateX,
  rotateY,
  rotateZ,
  ...props
}: Omit<ComponentProps<'span'>, keyof IProps> & IProps, ref: ForwardedRef<HTMLSpanElement>) {
  return (
    <span
      {...props}
      ref={ref}
      data-tooltip={title}
      className={clsx(Style.icon, className, {
        [Style.bg]: bg,
        [Style.disabled]: disabled,
        pointer: title
      })}
      style={{
        ...props.style,
        width: `${size}px`,
        minWidth: `${size}px`,
        height: `${size}px`,
        minHeight: `${size}px`,
        transform: rotate
          ? `rotate(${rotate}deg)`
          : rotateX || rotateY || rotateZ
            ? `rotateX(${rotateX || 0}deg) rotateY(${rotateY || 0}deg) rotateZ(${rotateZ || 0}deg)`
            : props.style?.transform
      }}
      onClick={disabled ? undefined : props.onClick}
    >
      <span
        className={Style.wrapper}
      // style={{ transform: `scale(${scale})` }}
      >
        {children}
      </span>
    </span>
  )
}

export const IconWrapper = forwardRef(_IconWrapper)
