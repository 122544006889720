import { BehaviorSubject, tap } from 'rxjs'
import { CampaignApi } from 'src/api'
import { WithOutNextComplete } from 'types/rxjs'
import { AuthModule } from './auth.module'

const _counts$ = new BehaviorSubject<Awaited<ReturnType<typeof CampaignApi.count>>['data']>({})

export class CampaignModule {
  static get counts$(): WithOutNextComplete<typeof _counts$> {
    return _counts$
  }

  static fetchCounts() {
    return CampaignApi.count().then((response) => {
      _counts$.next(response.data)
      return response
    })
  }
}

AuthModule.isAuthenticated$
  .pipe(tap(() => _counts$.next({})))
  .subscribe((isAuthenticated) => {
    if (isAuthenticated) {
      CampaignModule.fetchCounts()
    }
  })
