import { Box, Divider, Fade, Typography, useTheme } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import clsx from 'clsx'
import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { CampaignApi } from 'src/api'
import { Button, NoTabletOrMobile, OnlyMobile, TabletAndMobile, Tabs, Textarea, VideoPlayerOld, useAnalytic } from 'src/components'
import { ESubmissionReaction, ETrackingEvent } from 'src/enums'
import { useBehaviorMapper, useElementSize, useUnsubscribeCallback, useUserOtherInfo } from 'src/hooks'
import { IconEmail, IconFilePdf, IconLikes, IconLikesCyan, IconLinkedInInfo, IconPhone } from 'src/icons'
import { ICampaignSubmissionModel, ITab } from 'src/interfaces'
import { CandidateInfoEducationSection, CandidateInfoExperiencesSection, CandidateSkillsNChars } from 'src/partials'
import { TourPopper } from 'src/partials/tour'
import { ERoutes, generate } from 'src/router'
import { SnackbarService } from 'src/services'
import { GuestViewJobTourService, TARGET_PUBLIC_GUEST_VIDEO } from 'src/services/tour/guest-view-job-tour.service'
import { LoadingModule } from 'src/store'
import { LocalStorage, getApiErrorMessage, getVideoSource, safeParseUrl, shortenFilename } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  submissionId: number
  hashId: string
}

export const GuestSubmissionDetail: FC<IProps> = ({ submissionId, hashId }) => {
  const theme = useTheme()
  const tourEnabling = useBehaviorMapper(GuestViewJobTourService.enableTour$)
  const currentStep = useBehaviorMapper(GuestViewJobTourService.currentStep$)
  const containerRef = useRef<HTMLDivElement>(null)
  const [elWidth, elHeight] = useElementSize(containerRef.current)
  const contentStyle = useMemo(() => ({ [(elWidth / (elHeight - 60)) > (1076 / 766) ? 'height' : 'width']: '100%' }), [elHeight, elWidth])
  const router = useHistory()
  const { eventHandler } = useAnalytic(router.location.pathname)

  const [privateNotes, setPrivateNotes] = useState<string>('')
  const [tab, setTab] = useState<number>(0)
  const [data, setData] = useState<ICampaignSubmissionModel>()
  const [isLiked, setIsLiked] = useState<boolean>(false)
  const [isPrivateNotesSaved, setIsPrivateNotesSaved] = useState<boolean>(false)

  const linkedinWorkingExperiences = useMemo(() => data?.author.linkedinWorkingExperiences || [], [data])
  const linkedinEducations = useMemo(() => data?.author.linkedinEducations || [], [data])
  const otherInfo = useUserOtherInfo(data?.author)

  const tabs: ITab[] = [
    { title: 'Profile' },
    { title: 'Activity' }
  ]

  const handleChangeInput: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    e.persist()
    const value = e.target.value
    setPrivateNotes(value)
    setIsPrivateNotesSaved(false)
    eventHandler({
      key: ETrackingEvent.INPUT_PRIVATE_NOTES,
      contextData: {
        candidateId: submissionId
      }
    })()
  }

  // const handleOpenShare = () => {
  //   router.push(generate(ERoutes.SIGN_UP))
  // }

  const handleChangeReaction = useUnsubscribeCallback((unsubscribe$) => {
    if (!data?.campaignId) {
      return
    }

    const currentReactions = LocalStorage.getItem(`guess.${data.campaignId}.like`) || []
    const existedReaction = currentReactions.find((reaction: { id: number }) => reaction.id === submissionId)

    if (existedReaction) {
      currentReactions.splice(currentReactions.indexOf(existedReaction), 1)
      LocalStorage.setItem(`guess.${data.campaignId}.like`, currentReactions)
      setIsLiked(isLiked => !isLiked)
      return
    }

    currentReactions.push({
      id: submissionId,
      createdAt: new Date()
    })

    LocalStorage.setItem(`guess.${data.campaignId}.like`, currentReactions)
    setIsLiked(isLiked => !isLiked)
  }, [data, submissionId])

  const loadDetailSubmission = useCallback(async () => {
    try {
      LoadingModule.toggle(true)
      const { data } = await CampaignApi.publicGetDetailSubmission(hashId, +submissionId)
      const likes = LocalStorage.getItem(`guess.${data.campaignId}.like`) || []

      setData({
        ...data,
        video: {
          ...(data?.video || {}),
          createdReaction: {
            reaction: likes?.find((liked: { id: number }) => liked.id === submissionId) ? ESubmissionReaction.LIKE : undefined
          }
        }
      })
      setPrivateNotes('')
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    } finally {
      LoadingModule.toggle(false)
    }
  }, [hashId, submissionId])

  useEffect(() => {
    if (data?.video.createdReaction) {
      setIsLiked(data?.video.createdReaction?.reaction === ESubmissionReaction.LIKE)
    }
  }, [data?.video.createdReaction])

  useEffect(() => {
    if (hashId && submissionId) {
      loadDetailSubmission()
    }
  }, [hashId, loadDetailSubmission, submissionId])

  useEffect(() => {
    if (tab === 1) {
      router.push(generate(ERoutes.SIGN_UP))
    }
  }, [tab, router])

  return (
    <>
      <StyledEngineProvider injectFirst>
        <div className={clsx(Style.STContainer)} ref={containerRef}>
          {data?.author && (
            <div
              className={clsx(Style.submissionDetailContent, Style.submissionDetailContentGuest)}
              style={contentStyle}
            >
              <div className={clsx('p-4 fx fx-column gap-4 relative', Style.videoContainer)}>
                <div id={TARGET_PUBLIC_GUEST_VIDEO.getValue()} className={Style.targetVideo}/>
                {tourEnabling && currentStep === 3 && (<TourPopper {...GuestViewJobTourService.getStepConfig()}/>)}
                <VideoPlayerOld
                  id="video"
                  className={Style.videoPlayer}
                  videoId={data?.video?.id}
                  tracks={data?.video?.tracks}
                  image={data?.video?.urlVideoImageThumbnail}
                  animatedImage={data?.video?.urlVideoAnimatedImage}
                  url={getVideoSource(data?.video)}
                  style={{ borderRadius: '16px' }}
                  mimeType={data?.video?.internalSourceMetadata?.mimeType}
                />
                <TabletAndMobile>
                  <Box className={Style.reactionAndShare}>
                    <Button onClick={handleChangeReaction}>
                      {isLiked
                        ? (
                          <div className={Style.STLike}>
                            <Box><IconLikesCyan/></Box>
                          </div>
                        )
                        : (
                          <div className={Style.STUnLike}>
                            <Box><IconLikes className={Style.STUnLikeIcon}/></Box>
                          </div>
                        )}
                    </Button>

                    {/* <div className={Style.STButtonShare} onClick={handleOpenShare}>
                      <IconShareFilled/>
                      <Typography variant="body2-bold">Share</Typography>
                    </div> */}
                  </Box>
                </TabletAndMobile>
              </div>

              <OnlyMobile>
                <div className={Style.mobileDivider}/>
              </OnlyMobile>

              <div className={clsx('fx fx-column', Style.infoContainer)}>
                <div className={clsx('fx fx-ai-center fx-jc-space-between px-6', Style.tabSpacing)}>
                  <NoTabletOrMobile>
                    <Box className={Style.reactionAndShare}>
                      <Button onClick={handleChangeReaction}>
                        {isLiked
                          ? (
                            <div className={Style.STLike}>
                              <Box><IconLikesCyan/></Box>
                            </div>
                          )
                          : (
                            <div className={Style.STUnLike}>
                              <Box><IconLikes className={Style.STUnLikeIcon}/></Box>
                            </div>
                          )}
                      </Button>

                      {/* <div className={Style.STButtonShare} onClick={handleOpenShare}>
                        <IconShareFilled/>
                        <Typography variant="body2-bold">Share</Typography>
                      </div> */}
                    </Box>
                  </NoTabletOrMobile>
                  <Tabs tab={tab} tabs={tabs} setTab={setTab}/>
                </div>

                <Divider/>

                <div style={{ overflow: 'auto' }}>
                  <div className={Style.STSubmissionInfoContainer}>
                    <Box>
                      <h5 className={Style.FullNameTitle}>{data?.author.fullName}</h5>
                      {/* <p className={Style.LabelMethod}>Meet {data?.author.fullName} using these methods: </p> */}
                      <div className={Style.STPersonal}>
                        <Box>
                          <IconPhone/>
                          <Typography variant="body1-regular">{data?.author.userPhoneNo ? <a className={Style.ALink} href={`tel:+${data?.author.userPhoneNo}`}>{data?.author.userPhoneNo}</a> : 'Working On It'}</Typography>
                        </Box>
                        {data?.author.email && (
                          <Box>
                            <IconEmail width={20} height={20}/>
                            <Typography variant="body1-regular"><a className={Style.ALink} href={`mailto:${data.author.email}`}>{data.author.email}</a></Typography>
                          </Box>
                        )}
                        {data?.author.linkedInUrl && (
                          <Box>
                            <IconLinkedInInfo/><Typography variant="body1-regular"><a className={Style.ALink} target="_blank" href={data.author.linkedInUrl} rel="noreferrer">{data.author.linkedInUrl}</a></Typography>
                          </Box>
                        )}
                      </div>

                    </Box>
                  </div>
                  {(data.resumeFileName || data.portfolioUrl) && (
                    <>
                      <Divider/>
                      <div className={Style.STSubmissionInfoContainer}>
                        {data.resumeFileName && (
                          <div className={Style.STSubmissionInfoSection}>
                            <span className={Style.STSubmissionInfoLabel}>Resumé / portfolio</span>
                            <div
                              className={Style.STResumeFile}
                              onClick={() => data.resumeFileUrl && window.open(data.resumeFileUrl, '_blank', 'noreferrer')}
                            >
                              <IconFilePdf/>
                              <span>{(shortenFilename(data.resumeFileName) || 'No file uploaded')}</span>
                            </div>
                          </div>
                        )}

                        {data.portfolioUrl && (
                          <div className={Style.STSubmissionInfoSection}>
                            <span className={Style.STSubmissionInfoLabel}>Portfolio links </span>
                            <a className={Style.STPorfolioLink} target="_blank" href={safeParseUrl(data.portfolioUrl)} rel="noreferrer">{data.portfolioUrl}</a>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {(otherInfo.shouldShowOtherInfo) && (
                    <>
                      <Divider/>
                      <div className={Style.STSubmissionInfoContainer}>
                        <div className="fx flex-column gap-1">
                          <span className={Style.STSubmissionInfoLabel}>Others</span>
                          <div className={Style.STSubmissionInfoUL}>
                            {otherInfo.workInterestsText && (
                              <span className="body2-regular color-neutral-theme-400">{otherInfo.workInterestsText}</span>
                            )}

                            {otherInfo.willingToRelocateText && (
                              <span className="body2-regular color-neutral-theme-400">{otherInfo.willingToRelocateText}</span>)}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <Divider/>
                  <div className={Style.STSubmissionInfoContainer}>
                    <div className={Style.STNotes}>
                      <span className={Style.STSubmissionInfoLabel}>Private Notes</span>
                      <Textarea
                        className={Style.STPrivateNotes}
                        label=""
                        placeholder="Only you can see your private notes"
                        value={privateNotes}
                        onChange={handleChangeInput}
                        autoComplete="off"
                        disableResize
                        minRows={6}
                        maxRows={6}
                      />
                      <Fade in={isPrivateNotesSaved}>
                        <Typography mt="4px" variant="body2" color={theme.colors['--color-neutral-theme-300']}>Note saved</Typography>
                      </Fade>
                    </div>
                  </div>

                  {linkedinWorkingExperiences.length > 0 && (
                    <CandidateInfoExperiencesSection linkedinWorkingExperiences={linkedinWorkingExperiences}/>
                  )}

                  {linkedinEducations.length > 0 && (
                    <CandidateInfoEducationSection linkedinEducations={linkedinEducations}/>
                  )}

                  <CandidateSkillsNChars personalAttributes={data?.author?.personalAttributes}/>
                </div>
              </div>
            </div>
          )}
        </div>
      </StyledEngineProvider>
    </>
  )
}
