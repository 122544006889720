import {
  ComponentProps,
  FC
} from 'react'
import { Button, ModalCenter } from 'src/components'
import { EEnv } from 'src/constants'

export const ModalSwitchToDesktop: FC<ComponentProps<typeof ModalCenter>> = (props) => {
  return (
    <ModalCenter
      {...props}
      noKeyboard
      className="sm-px-2"
      contentClass="fx-column sm-w-100-p"
    >
      <div className="fx-column gap-8 py-10 px-4 txt-center">
        <svg className="fx-as-center" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M94.6272 88.0641C95.7368 93.262 104.307 89.5377 104.854 87.3497C105.948 84.6148 103.261 79.1447 103.121 76.3471C102.927 72.4605 102.953 68.5897 102.65 64.7006C102.313 60.3747 101.463 56.187 101.403 51.8307C101.318 45.6215 100.362 37.9036 95.4271 33.4317C89.0239 27.6288 77.6417 29.4053 69.7579 28.279C63.4805 27.3822 56.8964 26.1317 50.5353 27.0791C48.4595 27.3882 48.0681 28.4674 48.0413 30.4201C47.9652 35.9748 48.4028 42.6848 45.8297 47.8309C44.5443 50.4016 34.9015 48.6781 32.4186 49.125C27.1391 50.0753 25.3577 56.8345 25.2425 61.5008C25.0889 67.72 26.0645 74.7401 27.3365 80.8175C28.1356 84.6351 30.7858 86.0628 34.2538 87.3112C40.5041 89.5613 57.4105 94.4535 59.9231 85.2408C61.0308 81.1791 60.762 75.5579 59.2407 71.5473C58.3971 69.3231 56.3296 67.6786 55.1468 65.6182C53.0919 62.0387 51.1583 55.0961 57.782 55.2188C61.253 55.2831 63.0285 59.4925 65.2169 61.6184C67.6095 63.9427 69.9954 63.7543 73.0989 63.4772C76.413 63.1813 80.0188 62.9618 83.3336 63.3595C86.1024 63.6918 88.1538 66.032 89.9686 67.9475C95.6783 73.9745 93.0624 80.7345 94.6272 88.0641Z" fill="white"/>
          <path d="M26.0558 21.3204C28.8426 18.5653 28.6631 19.0907 32 18.9999C32 18.9999 33.8008 19.3007 34 19.4999C35.5 20.4999 35.5 20.4999 38 22.9999C39.7385 24.7384 40.3172 25.5543 41.5 26.9999C43.2659 29.1582 44.5593 30.5261 46.1612 32.8144C49.0261 36.9071 52.2157 43.0052 48.8262 47.6804C47.9614 48.8732 46.6442 49.8271 45.4722 50.6959C44.2289 51.6177 42.9877 52.3083 41.5201 52.817C38.899 53.7257 36.235 54.4044 33.5734 55.1739C31.4571 55.7857 29.5902 55.9953 27.3853 55.9836C24.7123 55.9695 22.3527 55.8618 20.1214 54.2734C19.1227 53.5625 18.2208 52.6356 17.4867 51.6568C16.7464 50.6698 16.2962 49.2801 16.1209 48.0672C15.7056 45.1934 16.4355 41.8886 17.1664 39.1899C18.4481 34.4575 19.769 30.718 22.303 26.4389C23.381 24.6186 24.5513 22.8078 26.0558 21.3204Z" fill="#AFF6C5"/>
          <path d="M18.4844 60.8678C17.8532 69.698 17.8425 78.5282 18.4309 87.3657C18.5058 88.432 20.9984 88.0157 20.9449 87.132C20.3458 78.3018 20.3672 69.4643 20.9984 60.6341C21.0733 59.5677 18.5486 59.984 18.4844 60.8678Z" fill="black"/>
          <path d="M47.1417 30.505C56.8617 30.165 66.5817 29.825 76.3117 29.625C84.1817 29.465 97.7217 26.365 100.252 36.345C102.312 44.475 101.542 54.105 101.802 62.455C102.082 71.405 102.222 80.365 102.252 89.325C102.252 90.795 104.602 90.225 104.602 89.005C104.572 78.125 104.372 67.255 103.952 56.385C103.752 51.225 103.562 46.065 103.232 40.915C103.032 37.805 102.772 34.405 101.112 31.675C97.0917 25.085 86.4217 27.375 80.0417 27.485C69.1417 27.675 51.5917 28.045 40.6917 28.425C39.3617 28.475 38.8817 30.565 40.5017 30.505H47.1417Z" fill="black"/>
          <g clip-path="url(#clip0_13109_70364)">
            <path d="M15.2847 61.99C11.2394 62.1617 10.3616 60.0937 11.3999 56.5004C12.2548 53.4255 13.1628 50.3638 14.1058 47.3138C17.1784 37.3778 20.1622 27.3577 24.3822 17.8336C25.8981 14.3951 28.849 16.5068 30.708 18.3829C33.8411 21.6205 36.9874 24.8492 39.8483 28.3357C45.2114 35.3378 51.4291 42.1403 54.9541 50.2607C55.5875 54.4892 49.5027 54.6537 46.6382 55.7106C36.2557 58.0704 25.9503 61.2138 15.2847 61.99ZM14.7506 60.0251C24.0529 58.9972 33.1415 56.7317 42.2987 54.8723C45.6663 54.0192 49.2088 53.5439 52.3671 52.0561C53.8843 51.4733 52.9419 50.0511 52.3608 49.0768C44.6085 38.495 37.9633 26.3964 27.6338 18.0893C26.0764 17.1101 25.6185 19.7937 25.0457 20.7465C21.4355 30.4647 18.456 40.3989 15.2188 50.2426C14.4094 53.2113 13.1316 56.1314 12.8614 59.2063C12.7347 60.2413 14.1322 59.9419 14.7506 60.0251Z" fill="black"/>
            <path d="M28.0619 27.5967C28.0016 26.6803 27.9098 23.7018 29.4492 25.212C30.8231 29.2653 30.9875 33.6754 31.6812 37.89C31.5841 39.3713 33.0532 43.5638 31.5929 44.2319C29.0602 44.1139 28.3151 30.1685 28.0619 27.5967Z" fill="black"/>
            <path d="M32.44 47.0016C34.4169 46.9158 34.5774 49.9878 32.5623 49.9984C30.5799 50.0759 30.4242 47.024 32.44 47.0016Z" fill="black"/>
          </g>
          <path d="M100.01 32.1849C107.65 31.4449 106.51 42.1049 106.5 46.7149C106.49 51.7049 106.47 56.7049 106.46 61.6949C106.43 71.3449 106.41 81.0049 106.38 90.6549C106.38 92.1249 108.72 91.5549 108.73 90.3349C108.77 76.6849 108.81 63.0349 108.85 49.3849C108.87 42.9749 110.48 29.1049 100.22 30.1049C98.8998 30.2349 98.4098 32.3449 100.03 32.1849H100.01Z" fill="black"/>
          <path d="M102.162 40.825C103.712 41.275 105.182 41.885 106.592 42.665C107.652 43.255 109.192 41.405 107.912 40.695C106.502 39.915 105.032 39.305 103.482 38.855C102.872 38.675 102.202 38.935 101.852 39.465C101.572 39.895 101.562 40.645 102.172 40.815L102.162 40.825Z" fill="black"/>
          <path d="M102.659 49.7449L106.849 51.8049C107.939 52.3449 109.479 50.4849 108.169 49.8349L103.979 47.7749C102.889 47.2349 101.349 49.0949 102.659 49.7449Z" fill="black"/>
          <path d="M102.689 58.7449L106.079 61.0849C107.089 61.7849 108.959 60.1349 107.849 59.3749L104.459 57.0349C103.449 56.3349 101.579 57.9849 102.689 58.7449Z" fill="black"/>
          <path d="M103.241 68.345L106.391 70.665C107.381 71.395 109.251 69.755 108.161 68.955L105.011 66.635C104.021 65.905 102.151 67.545 103.241 68.345Z" fill="black"/>
          <path d="M103.161 76.805L106.391 79.005C106.911 79.355 107.621 79.185 108.051 78.765C108.411 78.415 108.691 77.655 108.161 77.295L104.931 75.095C104.411 74.745 103.701 74.915 103.271 75.335C102.911 75.685 102.631 76.445 103.161 76.805Z" fill="black"/>
          <path d="M103.471 85.675L105.841 86.945C106.381 87.235 107.171 86.785 107.471 86.335C107.811 85.815 107.691 85.265 107.151 84.985L104.781 83.715C104.241 83.425 103.451 83.875 103.151 84.325C102.811 84.845 102.931 85.395 103.471 85.675Z" fill="black"/>
          <path d="M14.8018 89.355C30.5918 88.775 46.4018 88.655 62.2018 89.055C70.1518 89.255 78.0918 89.575 86.0318 90.025C89.6718 90.225 93.3018 90.465 96.9418 90.715C101.062 91.005 112.882 92.045 103.082 97.675C97.0418 101.145 86.8718 99.145 80.2118 99.015C72.5618 98.865 64.9218 98.795 57.2718 98.785C49.6218 98.775 41.9818 98.835 34.3318 98.975C30.8218 99.035 27.1618 99.415 23.6718 99.025C19.5118 98.565 12.3418 95.075 16.1818 89.535C17.1118 88.195 14.9818 87.715 14.2318 88.795C12.0618 91.925 12.7518 95.935 15.6218 98.485C20.0018 102.365 27.2518 101.185 32.5618 101.095C49.5118 100.785 66.4718 100.825 83.4218 101.205C89.3418 101.335 95.7618 102.115 101.532 100.385C104.832 99.395 109.632 96.965 109.212 92.815C108.682 87.625 100.272 88.815 96.7218 88.565C88.1318 87.965 79.5218 87.515 70.9218 87.215C52.2818 86.555 33.6218 86.605 14.9818 87.295C13.6518 87.345 13.1718 89.435 14.7918 89.375L14.8018 89.355Z" fill="black"/>
          <path d="M46.6499 88.785C48.7499 95.125 52.8899 94.485 58.8799 94.385C61.9699 94.335 67.4399 94.965 70.1999 93.515C71.9299 92.605 73.0799 90.685 74.2699 89.225C75.2999 87.965 73.1399 87.485 72.3199 88.485C69.3399 92.135 66.2799 92.095 61.8999 92.235C57.1499 92.385 50.8399 93.575 48.9799 87.945C48.5499 86.645 46.2799 87.625 46.6599 88.785H46.6499Z" fill="black"/>
          <defs>
            <clipPath id="clip0_13109_70364">
              <rect width="44" height="46" fill="white" transform="translate(11 15.9999)"/>
            </clipPath>
          </defs>
        </svg>

        <div className="fx-column gap-4">
          <div className="heading-24 txt-black-01">
            Please switch to <br/>
            a desktop device.
          </div>
          <div className="heading-14 txt-black-02">
            KNOWME<sup className="fs-10">TM</sup> is not optimized <br/>
            for Hiring Managers on mobile.
          </div>
        </div>

        <div className="fx-column gap-4">
          <Button variant="label" onClick={() => { window.location.href = EEnv.REACT_APP_CANDIDATE_WEB_DNS }}>
            Visit KNOWME Candidate
          </Button>
          <Button onClick={props.onClose}>Continue Anyway</Button>
        </div>
      </div>
    </ModalCenter>
  )
}
