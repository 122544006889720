import { useCallback, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { Divider, Textarea } from 'src/components'
import { useBehaviorMapper, useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { IconEmailFilled, IconLinkedInStroke, IconLocationStroke, IconPhoneStroke } from 'src/icons'
import { ICandidateModel } from 'src/interfaces'
import { TalentImageCard } from 'src/partials/card/talent-image-card'
import { SnackbarService } from 'src/services'
import { MessengerService } from 'src/services/messenger.service'
import { LoadingModule } from 'src/store'
import { convertPhoneNumToInternationalFormat, getFullName, getLocation, removePrefixPhoneNumber } from 'src/utils'
import Style from './style.module.scss'

export const ContactUser = () => {
  const unsubscribe$ = useUnsubscribe()
  const loading = useBehaviorMapper(LoadingModule.loading$)
  const [candidate, setCandidate] = useState<ICandidateModel>()
  const [privateNotes, setPrivateNotes] = useState<string>('')

  const conversation = useBehaviorMapper(MessengerService.conversation$)
  const authorLookupId = useMemo(
    () => conversation && MessengerService.getAuthorLookupId(conversation),
    [conversation]
  )

  const handleSavePrivateNotes = useCallback(() => {
    if (!candidate?.id) return

    from(CandidateApi.note(candidate.id, { content: privateNotes }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        })
      )
  }, [candidate?.id, privateNotes, unsubscribe$])

  useUnsubscribeEffect((unsubscribe$) => {
    if (!authorLookupId) return

    LoadingModule.toggle(true)
    from(CandidateApi.detail(authorLookupId))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => LoadingModule.toggle(false))
      )
      .subscribe(({ data }) => {
        setCandidate(data)
        data?.privateNote?.content && setPrivateNotes(data?.privateNote?.content)
      })
  }, [authorLookupId])

  if (loading) {
    return null
  }

  return (
    <div className={Style.contactUserContainer}>
      <div className="fx gap-8">
        <div className={Style.videoContainer}>
          {candidate && (
            <div className="fx fx-column gap-6">
              <TalentImageCard
                inlinePlayer
                talent={candidate}
              />
              {candidate?.pfv?.title && (
                <div className="fw-600 fs-20 lh-24 txt-black-01 three-dot-1">{candidate?.pfv?.title}</div>
              )}
            </div>
          )}
        </div>

        <div className="w-full">
          <div className="fx fx-column gap-1 mb-3">
            <div className="fs-24 fw-500 txt-black-01 three-dot-1">{getFullName(candidate)}</div>
            <div className="fs-12 txt-grey-01 three-dot-1">{candidate?.username ? `@${candidate?.username}` : ''}</div>
          </div>

          <Divider color="#EFEFEF"/>

          {/* TODO: clear duplicate */}
          <div className="fx fx-column gap-3 mt-6 txt-grey-01">
            <div className="fw-500 txt-black-01">Contact</div>
            <div className="fx fx-ai-center gap-2">
              <IconPhoneStroke/>
              <div className="fs-12 three-dot-1">
                {candidate?.userPhoneNo ? convertPhoneNumToInternationalFormat(removePrefixPhoneNumber(candidate?.userPhoneNo)) : '---'}
              </div>
            </div>
            <div className="fx fx-ai-center gap-2">
              <IconEmailFilled/>
              <div className="fs-12 three-dot-1">{candidate?.email || '---'}</div>
            </div>
            <div className="fx fx-ai-center gap-2">
              <IconLinkedInStroke/>
              <div className="fs-12 three-dot-1">{candidate?.linkedInUrl || '---'}</div>
            </div>
            <div className="fx fx-ai-center gap-2">
              <IconLocationStroke/>
              <div className="fs-12 three-dot-1">{getLocation(candidate) || '---'}</div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="fs-16 fw-500 txt-black-01 mb-3">Private Notes</div>
        <Textarea
          label=""
          placeholder="Only you can see your private notes"
          value={privateNotes}
          autoComplete="off"
          disableResize
          minRows={4}
          maxRows={6}
          style={{ marginTop: 8, fontSize: '14px' }}
          onChange={(event) => setPrivateNotes(event.target.value)}
          onBlur={handleSavePrivateNotes}
        />
      </div>
    </div>
  )
}
