import clsx from 'clsx'
import moment from 'moment'
import { ComponentProps, FC, useMemo } from 'react'
import sanitizeHtml from 'sanitize-html'
import { FilePreview, InfiniteScroll, UserAvatar } from 'src/components'
import { useBehaviorMapper, useDebouncedLoading } from 'src/hooks'
import { IconChatIllustration } from 'src/icons'
import { IMessageModel } from 'src/interfaces'
import { MessengerService } from 'src/services/messenger.service'
import { AuthModule } from 'src/store'
import { formatDate, getAvatar, getFullName } from 'src/utils'
import Style from './style.module.scss'

const Message: FC<{ message: IMessageModel }> = ({ message }) => {
  const profile = useBehaviorMapper(AuthModule.profile$)
  const conversation = useBehaviorMapper(MessengerService.conversation$)

  const isSender = message.userId === profile?.id
  const avatar = useMemo(
    () => {
      if (isSender) {
        return getAvatar(profile)
      }

      if (conversation) {
        return MessengerService.getConversationAvatar(conversation)
      }
    },
    [conversation, isSender, profile]
  )
  const title = useMemo(
    () => moment(message.createdAt).format('dddd ⋅ MMM DD, YYYY ⋅ HH:mm A'),
    [message.createdAt]
  )
  const fullname = useMemo(
    () => {
      if (isSender) {
        return 'Me'
      }

      if (conversation) {
        return MessengerService.getConversationUserFullName(conversation)
      }
    },
    [conversation, isSender]
  )
  const userCompany = useMemo(
    () => {
      if (isSender) {
        return profile?.email
      }

      if (conversation) {
        return MessengerService.getConversationUserCompany(conversation)?.CompanyUser?.userType
      }
    },
    [conversation, isSender, profile?.email]
  )

  const renderContents = useMemo(() => {
    const contents = []

    if (message.attachments?.length) {
      contents.push(
        <span title={title} className={clsx(Style.message, Style.attachments)}>
          <div>
            {message.attachments.map((attachment) => (
              <FilePreview
                key={attachment.id}
                src={attachment.url}
                fileName={attachment.filename}
                mimeType={attachment.contentType}
                allowInteraction
                size={
                  attachment.contentType?.startsWith('video/')
                    ? 'unset'
                    : '100%'
                }
                style={{
                  overflow: 'hidden',
                  maxHeight: '400px',
                  borderRadius: '10px'
                }}
              />
            ))}
          </div>
        </span>
      )
    }

    if (message.content) {
      contents.push(
        <span
          title={title}
          className={Style.message}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(message.content) }}
        />
      )
    }

    return contents
  }, [message, title])

  return (
    <>
      {renderContents.map((renderContent, index) => (
        <div
          key={index}
          className="px-6 pt-3 pb-2 fx gap-3"
        >
          <UserAvatar
            size={40}
            className="round-2"
            fullName={isSender ? getFullName(profile) : fullname}
            avatarUrl={avatar}
          />

          <div className="fx fx-column gap-2">
            <div className="fx fx-ai-end gap-2">
              <div className="fs-16 fw-500 lh-20 txt-black-01 three-dot-1">{fullname}</div>
              {userCompany && (
                <div className="fs-10 lh-16 txt-grey-01">{userCompany}</div>
              )}
              {message.createdAt && (
                <div className="fs-10 lh-16 txt-grey-01" style={{ minWidth: 'fit-content' }}>{formatDate(message.createdAt)}</div>
              )}
            </div>
            <div className="">{renderContent}</div>
          </div>
        </div>
      ))}
    </>
  )
}

export const Messages: FC<Pick<ComponentProps<typeof InfiniteScroll>, 'hasMore' | 'next'> & {
  messages: IMessageModel[]
  loading?: boolean
}> = ({
  messages,
  hasMore,
  next,
  loading
}) => {
  const conversation = useBehaviorMapper(MessengerService.conversation$)
  const firstName = useMemo(
    () => conversation && MessengerService.getConversationUser(conversation)?.user?.firstName,
    [conversation]
  )
  const debounceLoading = useDebouncedLoading(!messages?.length && !!loading, 500)

  const view = useMemo(() => {
    if (debounceLoading) {
      return ''
    }

    if (!messages?.length) {
      return (
        <div className="fx fx-column fx-ai-center fx-jc-center gap-4">
          <IconChatIllustration/>
          <div className="heading-5 txt-black-01 three-dot-1">Send {firstName} a Message!</div>
        </div>
      )
    }

    return messages.map((item) => (
      <Message
        key={item.id}
        message={item}
      />
    ))
  }, [debounceLoading, firstName, messages])

  return (
    <InfiniteScroll
      className="fx-1 fx"
      style={{ flexDirection: 'column-reverse' }}
      loader={null}
      inverse
      scrollThreshold={0.95} // 95% scrollHeight
      dataLength={messages.length}
      hasMore={hasMore}
      next={next}
      containerProps={{
        className: clsx('fx-1 fx fx-column gap-1', {
          'fx-jc-center': !messages?.length
        }),
        style: {
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse'
        }
      }}
    >
      <div
        className="fx-1 fx fx-column gap-1 p-1"
        style={{ flexDirection: 'column-reverse' }}
      >
        {view}
      </div>
    </InfiniteScroll>
  )
}
