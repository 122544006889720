import qs from 'qs'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useHistory, useParams } from 'react-router'
import { catchError, EMPTY, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { axiosHiringApi } from 'src/api/axios'
import {
  BreadcrumbDivider,
  BreadcrumbHeading,
  Button,
  Checkbox,
  InputDebounce,
  Spinner
} from 'src/components'
import { ECampaignStatus, ETrackingEvent } from 'src/enums'
import {
  useAsRef,
  useBehaviorMapper,
  useDebounceAnalyticInput,
  useDebouncedLoading,
  useUnsubscribeEffect
} from 'src/hooks'
import { IconChat, IconEmployersStroke, IconPencilStroke, IconSearch, IconZeroApplicant } from 'src/icons'
import { ICampaignModel, ICampaignSubmissionModel } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { CampaignApplicantCard } from 'src/partials/card/campaign-applicant-card'
import { ModalApplicantDetail } from 'src/partials/modal-applicant-detail'
import { ModalSendMessage } from 'src/partials/modal-send-message'
import { browserHistory, ERoutes, generate } from 'src/router'
import { DialogService, LoadMoreService, SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { ECampaignRoutes } from '../../routes.enum'
import Style from './style.module.scss'

const Empty = ({ isJobPublished }: { isJobPublished: boolean }) => (
  <div className="fx-1 fx-column fx-center gap-10 heading-24 txt-black-01 pt-15">
    <IconZeroApplicant/>

    {isJobPublished
      ? (
        <span> You have no Applicants Yet</span>
      )
      : (
        <span className="text-center">
          Once Verified, You'll See Applicants <br/>
          in this Dashboard
        </span>
      )}

    <Button className="fx gap-2 mt-1" onClick={() => browserHistory.push(generate(ERoutes.TALENT_VIBE_TALENT))}>
      <IconEmployersStroke/>
      View Talent Marketplace
    </Button>
  </div>
)

export const Applicants: FC = () => {
  const history = useHistory()
  const [keyword, setKeyword] = useState<string>()
  const { campaignId } = useParams<{ campaignId: string }>()
  const [campaign, setCampaign] = useState<ICampaignModel>()
  const [isWriteMessage, setIsWriteMessage] = useState(false)
  const [selectedItems, setSelectedItems] = useState<
    ICampaignSubmissionModel[]
  >([])

  const { analyticInput } = useDebounceAnalyticInput('top_nav')

  const handleSetKeyword = (value?: string) => {
    setKeyword(value)
    analyticInput(ETrackingEvent.INPUT_NAV_SEARCH_APPLICANTS, value)
  }

  const _loadMoreService = useMemo(
    () =>
      new LoadMoreService<ICampaignSubmissionModel>({
        axiosInstance: axiosHiringApi,
        endpoint:
          `${CampaignApi._prefix}/${campaignId}/submission?` +
          qs.stringify({
            keyword,
            limit: 100
          })
      }),
    [campaignId, keyword]
  )

  const { ref: refLoadMore } = useInView({ threshold: 0 })
  const loading = useBehaviorMapper(_loadMoreService.loading$)
  const items = useBehaviorMapper(_loadMoreService.items$)

  useUnsubscribeEffect(
    (unsubscribe$) => {
      if (!campaignId) return

      from(CampaignApi.getOne(Number(campaignId)))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error) => {
            SnackbarService.axiosError(error)
            history.push(generate(ERoutes.ERROR_404))
            return EMPTY
          })
        )
        .subscribe(({ data }) => {
          setCampaign(data)
        })
    },
    [campaignId]
  )

  useEffect(() => {
    if (campaignId && !loading) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, campaignId, loading])

  const debounceLoading = useDebouncedLoading(!items.length && loading)
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  const [currentIndex, setCurrentIndex] = useState(-1)
  const canPreviousProps = useMemo(() => !currentIndex, [currentIndex])
  const canNextProps = useMemo(
    () => currentIndex + 1 === items?.length,
    [currentIndex, items?.length]
  )

  const onDetail = useCallback((index: number) => {
    setCurrentIndex(index)
  }, [])

  const onPrevious = useCallback(() => setCurrentIndex((prev) => prev - 1), [])

  const onNext = useCallback(() => {
    setCurrentIndex((prev) => prev + 1)

    if (currentIndex === items.length - 2 && isAuthenticated) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, currentIndex, isAuthenticated, items.length])

  const onSelectMultiMessage = useCallback(
    (campaignSubmission: ICampaignSubmissionModel) => {
      if (selectedItems.includes(campaignSubmission)) {
        setSelectedItems(
          selectedItems.filter(({ id }) => id !== campaignSubmission.id)
        )
        return
      }

      setSelectedItems([...selectedItems, campaignSubmission])
    },
    [selectedItems]
  )

  const propsRef = useAsRef({
    isSlide: true,
    submission: items[currentIndex],
    canPreviousProps,
    canNextProps,
    onPrevious,
    onNext,
    campaign,
    onChange: (...[id, values]: Parameters<typeof _loadMoreService.updateOneItem>) => id && _loadMoreService.updateOneItem(id, values)
  })
  const modalRef = useRef<ReturnType<typeof DialogService.open>>()
  useEffect(() => {
    if (currentIndex <= -1) {
      if (modalRef.current) {
        modalRef.current.unmount()
        modalRef.current = undefined
      }
      return
    }
    if (modalRef.current) {
      modalRef.current.props(propsRef.current)
      return
    }
    modalRef.current = DialogService.open(ModalApplicantDetail, {
      ...propsRef.current,
      onUnmounted: () => {
        setCurrentIndex(-1)
        modalRef.current = undefined
      }
    })
  }, [currentIndex, propsRef])

  return (
    <>
      <Breadcrumbs>
        <div className="fx fx-ai-center gap-6">
          <div className="fx fx-ai-center gap-3">
            <BreadcrumbHeading
              onClick={() =>
                history.push(generate(ECampaignRoutes.CAMPAIGN_MY_JOBS))}
              className="pointer"
            >
              My Jobs
            </BreadcrumbHeading>
            <BreadcrumbDivider/>
            <BreadcrumbHeading active>{campaign?.jobTitle}</BreadcrumbHeading>
          </div>
          <InputDebounce
            prefix={<IconSearch size={20}/>}
            style={{ width: 350 }}
            placeholder="Search applicants…"
            value={keyword}
            onChange={handleSetKeyword}
          />
        </div>
      </Breadcrumbs>

      {debounceLoading && (
        <div className="fx-1 fx fx-center">
          <Spinner/>
        </div>
      )}

      <section
        className="p-8 "
        style={{
          height: debounceLoading ? '0' : undefined,
          opacity: debounceLoading ? 0 : undefined
        }}
      >
        <div className="flex fx-jc-space-between fx-ai-center mb-8">
          <div className="fs-28 fw-600 txt-black-01">{campaign?.jobTitle}</div>

          {!!items.length && (
            <div
              className="fw-500 txt-blue-1000 fx fx-ai-center gap-2 pointer"
              onClick={() => setIsWriteMessage(true)}
            >
              Multi-Message
              <IconChat size={16} color="#0066AD"/>
            </div>
          )}
        </div>

        {!items.length
          ? <Empty isJobPublished={campaign?.status === ECampaignStatus.PUBLISHED}/>
          : (
            <div className="fx fx-wrap-wrap gap-4">
              {items?.map((item, index) => (
                <CampaignApplicantCard
                  key={item.id}
                  submission={item}
                  onChange={(id, values) =>
                    id && _loadMoreService.updateOneItem(id, values)}
                  onDetail={() => onDetail(index)}
                  isWriteMessage={isWriteMessage}
                  isSelectedMultiMessage={selectedItems.includes(item)}
                  onSelectMultiMessage={() => onSelectMultiMessage(item)}
                />
              ))}
              <div ref={refLoadMore}/>
            </div>
          )}
      </section>

      {isWriteMessage && (
        <div className={Style.action}>
          <div className="fx fx-ai-center gap-3">
            <Checkbox
              className="mr-4"
              label="Select All"
              checked={selectedItems.length === items.length}
              onChange={() =>
                selectedItems.length === items.length
                  ? setSelectedItems([])
                  : setSelectedItems(items)}
            />

            <Button
              variant="secondary"
              disabled={loading}
              onClick={() => {
                setIsWriteMessage(false)
                setSelectedItems([])
              }}
            >
              Cancel
            </Button>
            <Button
              className="fx fx-ai-center gap-2"
              disabled={loading || !selectedItems.length}
              onClick={() =>
                DialogService.open(ModalSendMessage, {
                  initialCampaignSubmissions: selectedItems,
                  onRemoveWrapperMultiMessage: (campaignSubmissions: ICampaignSubmissionModel[]) => setSelectedItems(campaignSubmissions)
                })}
            >
              <IconPencilStroke color="#fff"/>
              Write Message
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
