import { ComponentProps, FC } from 'react'
import { VideoPlayer, VideoPlayerOld } from 'src/components'
import { useImageFromFile } from 'src/hooks'
import { LikesIllustration, StarIllustrations } from './components/illustrations'
import Style from './style.module.scss'

interface IProps {
  thumbnail?: ComponentProps<typeof VideoPlayer>['image']
  uploadVideoUrl?: string
  recruiterName?: string
}

export const VideoSection: FC<IProps> = (props) => {
  const imageSrcFromFile = useImageFromFile(props.thumbnail)

  return (
    <div className={Style.hmInfo}>
      <LikesIllustration className={Style.likeIllustration}/>
      <StarIllustrations className={Style.startIllustration}/>
      <div className={Style.hmVideoWrapper}>
        <span className={Style.hmName}>Meet {props.recruiterName}</span>
        <div className={Style.hmVideo}>
          <VideoPlayerOld
            hideCC
            hideStartEnd
            mini
            forceRender={!props.thumbnail}
            url={props.uploadVideoUrl}
            image={imageSrcFromFile || undefined}
            className={Style.hmVideoPlayer}
          />
        </div>
      </div>
    </div>
  )
}
