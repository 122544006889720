import { BehaviorSubject } from 'rxjs'
import { WithOutNextComplete } from 'types/rxjs'

export class SidebarModule {
  private static readonly _on$ = new BehaviorSubject<boolean>(true)
  static get on$(): WithOutNextComplete<typeof SidebarModule._on$> {
    return SidebarModule._on$
  }

  static toggle(val?: boolean) {
    const value = val ?? !SidebarModule._on$.value
    if (value !== SidebarModule._on$.value) {
      SidebarModule._on$.next(value)
    }
  }

  static on() {
    SidebarModule.toggle(true)
  }

  static off() {
    SidebarModule.toggle(false)
  }
}
