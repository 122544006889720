import clsx from 'clsx'
import moment from 'moment'
import {
  ChangeEvent,
  ComponentProps,
  useState
} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { IconCalendar, IconChevronFilled } from 'src/icons'
import { Input } from '../input'

interface IProps extends ComponentProps<typeof Input> {
  dateFormat: string | string[] | undefined
}

export const DatePickerInput = (
  {
    onClick,
    value,
    dateFormat,
    ...props
  }: IProps
) => {
  const [inputValue, setInputValue] = useState<string | null>(null)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)

    if (
      typeof dateFormat === 'string' &&
      (!e.target.value ||
        moment(e.target.value, dateFormat.toUpperCase(), true).isValid())
    ) {
      props.onChange && props.onChange(e)
      setInputValue('')
    }
  }

  const handleInputBlur = () => {
    setInputValue('')
  }

  return (
    <div onClick={onClick} className="position-relative">
      <Input
        prefix={(
          <IconCalendar
            size={20}
            color="#959595"
            className="pointer-event-none"
          />
        )}
        suffix={(
          <IconChevronFilled
            size={16}
            color="#959595"
            className="pointer-event-none"
            style={{
              transform: 'rotate(90deg)'
            }}
          />
        )}
        onChange={handleInputChange}
        value={inputValue || value}
        onBlur={handleInputBlur}
        autoComplete="off"
        {...props}
        wrapperClassName={clsx(props.wrapperClassName, {
          pointer: !props.disabled
        })}
      />
    </div>
  )
}
