import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconVideoIllustration: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 160 } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="160" height="160" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10160_159937)">
          <path d="M80.9712 72.4269C79.8245 71.6936 77.9712 70.9869 76.6112 71.5069C74.4512 72.3202 73.3845 74.5069 73.0779 76.6802C72.3845 81.4269 73.6112 89.2269 78.3312 91.8802C85.2912 95.7736 86.2645 75.5736 80.6245 72.4402" stroke="#AFF6C5" strokeWidth="4" strokeLinecap="round"/>
          <path d="M78.6755 65.7733C71.5822 66.5733 71.3422 76.84 72.1822 82.12C72.9955 87.2266 74.9555 93.52 81.3288 93.0133C86.6222 92.5866 88.2888 86.7866 88.4488 82.3333C88.6622 76.6 86.5288 64.8933 78.6622 65.7733H78.6755ZM85.0888 88.0933C82.9688 92.5733 77.9155 92.6266 75.4888 88.3866C73.6088 85.12 73.0622 78.5466 73.3155 74.8133C73.5155 71.7866 75.7155 67.0933 79.2088 66.9333C81.2755 66.84 83.3555 68.7733 84.3688 70.4C86.8755 74.4266 87.1021 83.84 85.0888 88.0933Z" fill="black"/>
          <path d="M79.6499 71.04C79.0233 71.1333 78.4099 71.72 78.0099 72.1733C75.8633 74.6933 75.6233 80.2666 76.3966 83.3466C76.9566 85.5733 79.8766 88.12 81.8099 85.8C83.9566 83.2266 83.2633 78.4266 82.5699 75.3866C82.2499 73.9733 81.5966 70.7466 79.6366 71.0266L79.6499 71.04ZM81.6766 83.2533C81.5033 84.0933 81.1166 85.5733 80.1433 85.7466C78.6766 86.0133 77.9966 84.4533 77.7433 83.2666C77.2766 81.0666 77.1166 75.8933 78.1299 73.9066C78.3299 73.5066 79.3699 72.2 79.7966 72.2133C80.1299 72.2266 80.3299 72.9466 80.4499 73.2266C81.3299 75.44 82.1166 80.96 81.6633 83.24L81.6766 83.2533Z" fill="black"/>
          <path d="M36.2761 7.61326C37.9694 55.8133 38.3827 104.053 37.5294 152.28C37.4894 154.24 40.6227 153.48 40.6627 151.853C41.5161 103.627 41.1027 55.3866 39.4094 7.18659C39.3427 5.22659 36.2227 5.99992 36.2761 7.61326Z" fill="black"/>
          <path d="M51.93 7.94669C51.7033 56.2934 51.4767 104.64 51.25 152.987C51.25 154.947 54.37 154.187 54.3833 152.56C54.61 104.213 54.8367 55.8667 55.0633 7.52003C55.0633 5.56003 51.9433 6.32003 51.93 7.94669Z" fill="black"/>
          <path d="M104.704 7.94669C104.104 56.2934 104.118 104.64 104.758 152.987C104.784 154.947 107.904 154.187 107.891 152.56C107.251 104.213 107.238 55.8667 107.838 7.52003C107.864 5.56003 104.731 6.32003 104.704 7.94669Z" fill="black"/>
          <path d="M119.342 7.24021C121.075 56.0402 120.822 104.88 118.582 153.654C118.489 155.6 121.635 154.84 121.715 153.227C123.955 104.454 124.209 55.6135 122.475 6.81354C122.409 4.85354 119.289 5.62687 119.342 7.24021Z" fill="black"/>
          <path d="M38.6773 154.187C43.1707 155.28 47.6507 155.507 52.224 154.773C53.9573 154.493 54.624 151.667 52.4773 152C48.4373 152.64 44.424 152.52 40.4373 151.56C38.904 151.187 36.8373 153.733 38.6773 154.187Z" fill="black"/>
          <path d="M38.3046 7.93316C42.9046 8.73316 47.5179 9.13316 52.1846 9.1465C53.8112 9.1465 55.2912 6.35983 53.2246 6.35983C48.5579 6.35983 43.9446 5.95983 39.3446 5.1465C37.7046 4.8665 36.2512 7.57316 38.3046 7.93316Z" fill="black"/>
          <path d="M105.755 9.42644C110.435 9.67977 115.075 9.49311 119.715 8.83977C121.449 8.58644 122.129 5.75977 119.969 6.06644C115.582 6.69311 111.209 6.87977 106.782 6.63977C105.142 6.54644 103.675 9.31977 105.742 9.42644H105.755Z" fill="black"/>
          <path d="M106.117 154.627C110.17 154.827 114.21 155.013 118.264 155.213C119.904 155.293 121.37 152.52 119.304 152.427C115.25 152.227 111.21 152.04 107.157 151.84C105.517 151.76 104.05 154.533 106.117 154.627Z" fill="black"/>
          <path d="M54.276 15.9068L103.663 16.1868C105.289 16.1868 106.769 13.4135 104.703 13.4001L55.316 13.1201C53.6894 13.1201 52.2094 15.8935 54.276 15.9068Z" fill="black"/>
          <path d="M54.2767 149.067C70.9701 148.907 87.6634 148.733 104.357 148.573C105.97 148.56 107.45 145.76 105.397 145.787C88.7034 145.947 72.01 146.12 55.3167 146.28C53.7034 146.293 52.2234 149.093 54.2767 149.067Z" fill="black"/>
          <path d="M57.6248 22.1999L57.2248 51.7599C57.1981 53.7199 60.3314 52.9599 60.3581 51.3332L60.7581 21.7732C60.7848 19.8132 57.6514 20.5732 57.6248 22.1999Z" fill="black"/>
          <path d="M98.4089 21.8535C98.7689 31.6002 98.9955 41.3335 99.0889 51.0802C99.1022 53.0402 102.236 52.2802 102.222 50.6535C102.129 40.9068 101.902 31.1602 101.542 21.4268C101.476 19.4668 98.3555 20.2402 98.4089 21.8535Z" fill="black"/>
          <path d="M58.4505 53.5336C72.0239 53.5069 85.5839 53.3869 99.1439 53.1736C100.757 53.1469 102.237 50.3469 100.184 50.3869C86.6239 50.6002 73.0505 50.7202 59.4905 50.7469C57.8772 50.7469 56.3972 53.5336 58.4505 53.5336Z" fill="black"/>
          <path d="M59.1425 22.4001C72.6092 23.1734 86.0759 23.1867 99.5425 22.4534C101.316 22.3601 101.969 19.5601 99.7959 19.6801C86.5825 20.4001 73.3825 20.3734 60.1825 19.6134C58.5425 19.5201 57.0759 22.2801 59.1425 22.4001Z" fill="black"/>
          <path d="M58.8096 60.8C58.8762 73.3333 58.5162 85.8533 57.7162 98.3733C57.5962 100.32 60.7429 99.56 60.8496 97.9466C61.6362 85.4266 62.0096 72.9066 61.9429 60.3733C61.9429 58.4133 58.8096 59.1733 58.8096 60.8Z" fill="black"/>
          <path d="M98.3693 61.5065C98.5826 74.0265 98.6226 86.5465 98.4893 99.0665C98.4626 101.027 101.596 100.267 101.623 98.6398C101.756 86.1198 101.716 73.5998 101.503 61.0798C101.476 59.1198 98.3426 59.8798 98.3693 61.5065Z" fill="black"/>
          <path d="M59.8496 100.133C72.9562 100.84 86.0629 101 99.1962 100.653C100.97 100.6 101.61 97.8268 99.4496 97.8801C86.5829 98.2268 73.7429 98.0401 60.8896 97.3468C59.2496 97.2535 57.7829 100.027 59.8496 100.133Z" fill="black"/>
          <path d="M60.9296 61.6533C73.5562 61.1066 86.1696 61.0799 98.7962 61.5733C100.423 61.6399 101.903 58.8666 99.8362 58.7866C86.9429 58.2933 74.0629 58.3333 61.1829 58.8799C59.4096 58.9599 58.7696 61.7466 60.9296 61.6533Z" fill="black"/>
          <path d="M59.1576 110.253C72.4909 110 85.8242 109.76 99.1576 109.507C100.771 109.48 102.251 106.68 100.198 106.72C86.8642 106.973 73.5309 107.213 60.1976 107.467C58.5842 107.493 57.1042 110.293 59.1576 110.253Z" fill="black"/>
          <path d="M59.849 141.907C73.209 141.84 86.5556 141.627 99.9023 141.267C101.676 141.213 102.316 138.44 100.156 138.493C87.0623 138.853 73.9823 139.053 60.889 139.12C59.2756 139.12 57.7956 141.92 59.849 141.907Z" fill="black"/>
          <path d="M58.0763 110.56L58.5697 140.12C58.5963 142.08 61.7297 141.32 61.703 139.693L61.2097 110.133C61.183 108.173 58.0497 108.933 58.0763 110.56Z" fill="black"/>
          <path d="M98.0239 109.16C97.6639 119.507 97.8772 129.813 98.6372 140.133C98.7706 141.893 101.851 140.627 101.731 139.013C100.984 128.92 100.811 118.84 101.157 108.733C101.224 106.787 98.0906 107.533 98.0239 109.16Z" fill="black"/>
          <path d="M42.9567 14.3467L42.9967 20.9067C44.93 20.7733 46.8634 20.64 48.7967 20.5067C49.0367 18.48 48.9434 16.4 48.53 14.4C46.6634 14.2667 44.7967 14.2933 42.9434 14.48" fill="black"/>
          <path d="M42.9567 14.3467L42.9967 20.9067C44.93 20.7733 46.8634 20.64 48.7967 20.5067C49.0367 18.48 48.9434 16.4 48.53 14.4C46.6634 14.2667 44.7967 14.2933 42.9434 14.48" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M43.7965 110.374L43.8365 116.934C45.7699 116.8 47.7032 116.667 49.6365 116.534C49.8765 114.507 49.7832 112.427 49.3699 110.427C47.5032 110.294 45.6365 110.32 43.7832 110.507" fill="black"/>
          <path d="M43.7965 110.374L43.8365 116.934C45.7699 116.8 47.7032 116.667 49.6365 116.534C49.8765 114.507 49.7832 112.427 49.3699 110.427C47.5032 110.294 45.6365 110.32 43.7832 110.507" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M42.9684 27.5598C42.8884 29.8931 43.035 32.2398 43.4217 34.5465C45.355 34.4131 47.2884 34.2798 49.2084 34.1465C49.0617 31.9865 48.7417 29.8531 48.275 27.7331C46.635 27.9465 44.6084 27.6665 42.955 27.5598H42.9684Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M44.0237 123.213C43.9437 125.546 43.7437 128.386 44.1304 130.693C46.0637 130.56 47.9971 130.693 49.9171 130.546C49.9171 128.493 50.0237 125.626 49.7704 123.213C48.1304 123.426 45.6637 123.32 44.0104 123.213H44.0237Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M42.996 41.5332C42.836 43.9599 42.916 46.3332 43.236 48.7465C45.3293 48.5465 47.4227 48.3599 49.5293 48.1599C49.356 46.0799 49.2493 43.7999 49.0627 41.7199C47.3693 41.7865 45.0093 41.7065 42.996 41.5332Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M43.609 56.12C43.7024 58.44 43.5157 60.2134 43.6757 62.32C45.3824 62.32 47.449 62.32 49.1557 62.32C49.3424 60.08 49.2757 57.8133 48.9557 55.5867C47.1824 55.5067 45.4224 55.4267 43.649 55.3467L43.5957 56.1333L43.609 56.12Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M43.6227 137.8C43.716 140.12 43.5294 141.893 43.6894 144C45.396 144 47.4627 144 49.1694 144C49.356 141.76 49.2894 139.493 48.9694 137.267C47.196 137.187 45.436 137.107 43.6627 137.027L43.6094 137.813L43.6227 137.8Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M42.9551 68.6L43.0084 75.5333C45.0751 75.3867 47.2084 75.7867 49.2751 75.6533C49.1551 73.5467 49.2484 70.84 49.0217 68.6933C47.2351 68.5733 44.8084 68.48 42.9684 68.6H42.9551Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M43.3184 82.5333C43.4384 84.8266 43.585 87.1199 43.7584 89.4133C44.985 89.4533 46.2117 89.4933 47.4384 89.5333C47.7317 89.5333 48.3717 89.5866 48.585 89.4133C48.8784 89.1733 48.7984 88.4133 48.7717 87.8399C48.6517 85.9599 48.5317 84.08 48.425 82.2C46.8517 82.2933 44.905 82.4266 43.3317 82.5199L43.3184 82.5333Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M43.6504 95.96C43.6637 98.4933 43.6904 100.8 43.7037 103.333C45.6237 103.333 47.3304 103.36 49.2504 103.373C49.2504 101.147 49.2504 98.64 49.1971 96.1333C47.3437 96.16 45.4904 96.0933 43.6504 95.96Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M110.623 15.32L110.663 21.88C112.596 21.7467 114.529 21.6134 116.463 21.48C116.703 19.4534 116.609 17.3734 116.196 15.3734C114.329 15.24 112.463 15.2667 110.609 15.4534" fill="black"/>
          <path d="M110.623 15.32L110.663 21.88C112.596 21.7467 114.529 21.6134 116.463 21.48C116.703 19.4534 116.609 17.3734 116.196 15.3734C114.329 15.24 112.463 15.2667 110.609 15.4534" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M111.465 111.347L111.505 117.907C113.438 117.773 115.371 117.64 117.305 117.507C117.545 115.48 117.451 113.4 117.038 111.4C115.171 111.267 113.305 111.293 111.451 111.48" fill="black"/>
          <path d="M111.465 111.347L111.505 117.907C113.438 117.773 115.371 117.64 117.305 117.507C117.545 115.48 117.451 113.4 117.038 111.4C115.171 111.267 113.305 111.293 111.451 111.48" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M110.623 28.5332C110.543 30.8665 110.689 33.2132 111.076 35.5199C113.009 35.3865 114.943 35.2532 116.863 35.1199C116.716 32.9599 116.396 30.8265 115.929 28.7065C114.289 28.9199 112.263 28.6399 110.609 28.5332H110.623Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M111.676 124.2C111.596 126.533 111.396 129.373 111.783 131.68C113.716 131.547 115.649 131.68 117.569 131.533C117.569 129.48 117.676 126.613 117.423 124.2C115.783 124.413 113.316 124.307 111.663 124.2H111.676Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M110.664 42.5198C110.504 44.9464 110.584 47.3198 110.904 49.7331C112.997 49.5331 115.091 49.3464 117.197 49.1464C117.024 47.0664 116.917 44.7864 116.731 42.7064C115.037 42.7731 112.677 42.6931 110.664 42.5198Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M111.277 57.0934C111.37 59.4134 111.184 61.1867 111.344 63.2934C113.05 63.2934 115.117 63.2934 116.824 63.2934C117.01 61.0534 116.944 58.7867 116.624 56.5601C114.85 56.4801 113.09 56.4001 111.317 56.3201L111.264 57.1067L111.277 57.0934Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M111.277 138.787C111.37 141.107 111.184 142.88 111.344 144.987C113.05 144.987 115.117 144.987 116.824 144.987C117.01 142.747 116.944 140.48 116.624 138.253C114.85 138.173 113.09 138.093 111.317 138.013L111.264 138.8L111.277 138.787Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M110.623 69.5734L110.676 76.5067C112.743 76.3601 114.876 76.7601 116.943 76.6267C116.823 74.5201 116.916 71.8134 116.69 69.6667C114.903 69.5467 112.476 69.4534 110.636 69.5734H110.623Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M110.969 83.5064C111.089 85.7998 111.235 88.0931 111.409 90.3864C112.635 90.4264 113.862 90.4664 115.089 90.5064C115.382 90.5064 116.022 90.5598 116.235 90.3864C116.529 90.1464 116.449 89.3864 116.422 88.8131C116.302 86.9331 116.182 85.0531 116.075 83.1731C114.502 83.2664 112.555 83.3998 110.982 83.4931L110.969 83.5064Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
          <path d="M111.303 96.9333C111.316 99.4667 111.343 101.773 111.356 104.307C113.276 104.307 114.983 104.333 116.903 104.347C116.903 102.12 116.903 99.6134 116.849 97.1067C114.996 97.1334 113.143 97.0667 111.303 96.9333Z" fill="black" stroke="black" strokeWidth="0.333333" strokeMiterlimit="10"/>
        </g>
        <defs>
          <clipPath id="clip0_10160_159937">
            <rect width="160" height="160" fill="white" transform="translate(0.25)"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  )
}
