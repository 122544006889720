import clsx from 'clsx'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Mask } from 'src/components'
import { TMaskStyles } from 'src/components/mask/styles'
import { useAppDispatch, useIfMobileL, usePopper } from 'src/hooks'
import { IconArrowLeft } from 'src/icons'
import { Arrow } from '../arrow'
import { StepIndicator } from '../indicator'
import Style from './style.module.scss'

const maskStyle: TMaskStyles = {
  maskWrapper: (defaultStyle) => ({
    ...defaultStyle,
    opacity: '1',
    zIndex: 999
  }),
  svgWrapper: (defaultStyle) => ({
    ...defaultStyle,
    color: 'rgba(0, 0, 0, 0.15)'
  }),
  maskArea: (defaultStyle) => ({
    ...defaultStyle,
    rx: 16
  }),
  clickArea: (defaultStyle) => ({
    ...defaultStyle,
    fill: 'transparent'
  })
}

interface IProps {
  title: string
  content: string | ReactNode
  /**
   * 0-based index
   */
  currentStep: number
  maxStep: number

  tip?: string
  arrowPosition: 'left' | 'right' | 'bottom' | 'top'
  targetSelector: string
  shouldScroll?: boolean
  onExitClick?: () => void
  onBack?: (history?: ReturnType<typeof useHistory>) => void
  ctaText?: string
  onCtaClick?: (history?: ReturnType<typeof useHistory>, dispatch?: ReturnType<typeof useAppDispatch>) => void
  showMask?: boolean

  // styling section==========
  width?: string
  zIndex?: number
}

export const TourPopper: FC<IProps> = (props) => {
  const isMobile = useIfMobileL()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const position = isMobile ? 'top' : props.arrowPosition === 'left' ? 'right' : props.arrowPosition === 'right' ? 'left' : 'top'
  const [arrowPosition, setArrowPosition] = useState(props.arrowPosition)
  const [targetBoundingClientRect, setTargetBoundingClientRect] = useState<DOMRect>()

  useEffect(() => {
    if (!props.showMask) {
      return
    }
    const target = document.querySelector(props.targetSelector)
    if (!target) {
      return
    }

    setTargetBoundingClientRect(target.getBoundingClientRect())
  }, [props.showMask, props.targetSelector])

  const { setPopperElement, currentPopperInstance, placementPosition } = usePopper({
    selector: props.targetSelector,
    position,
    shouldScroll: props.shouldScroll
  })

  useEffect(() => {
    if (isMobile) {
      setArrowPosition('bottom')
      return
    }

    if (placementPosition === 'right') {
      setArrowPosition('left')
      return
    }

    if (placementPosition === 'left') {
      setArrowPosition('right')
      return
    }

    if (placementPosition === 'bottom') {
      setArrowPosition('top')
    }
  }, [currentPopperInstance, placementPosition, currentPopperInstance?.state.placement, position, isMobile, props.arrowPosition])

  const containerStyle = useMemo(() => {
    if (props.width) {
      return {
        width: props.width
      }
    }

    return {}
  }, [props.width])

  const wrapperStyle = useMemo(() => {
    if (props.zIndex !== undefined) {
      return {
        zIndex: props.zIndex
      }
    }
  }, [props.zIndex])

  return (
    <>
      <div className={Style.wrapper} ref={setPopperElement} {...currentPopperInstance?.state.attributes} style={wrapperStyle}>
        <div className={Style.container} style={containerStyle}>
          <StepIndicator maxStep={props.maxStep} currentStep={props.currentStep}/>

          <div className={Style.content}>
            <div className={Style.innerContent}>
              <span className="subtitle-bold color-neutral-theme-900">
                {props.title}
              </span>
              <span className="body2-regular color-neutral-theme-900">
                {props.content}
              </span>
            </div>

            {props.tip && (
              <div className={Style.tip}>
                <span className="body2-regular color-neutral-theme-900">Try it: <span className="body2-bold">{props.tip}</span></span>
              </div>
            )}

            {props.ctaText && (
              <div className={Style.cta}>
                {props.onBack && (
                  <Button
                    variant="secondary"
                    className={Style.buttonCta}
                    onClick={() => props.onBack?.(history)}
                  >
                    <IconArrowLeft/>
                  </Button>
                )}
                <Button onClick={() => props.onCtaClick?.(history, dispatch)} className={clsx('fx-1', Style.buttonCta)}>
                  {props.ctaText}
                </Button>
              </div>
            )}
          </div>

          <Arrow direction={arrowPosition}/>

          {props.onExitClick && (
            <span onClick={props.onExitClick} className={clsx(Style.exit, arrowPosition === 'bottom' ? Style.exitTop : Style.exitBottom)}>Exit</span>
          )}
        </div>
      </div>
      {targetBoundingClientRect && (
        <Mask
          padding={0}
          sizes={targetBoundingClientRect}
          styles={maskStyle}
        />
      )}
    </>
  )
}
