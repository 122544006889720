import { createTheme, ThemeProvider } from '@mui/material'
import { FC, useEffect, useMemo } from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { genThemeWithPaletteMode } from './constants'
import { AppLayout } from './layouts'
import { browserHistory } from './router/history'
import { SettingsService } from './services'
import { store } from './store'

const AppTheme: FC = () => {
  // Update the theme only if the mode changes
  const theme = useMemo(() => createTheme(genThemeWithPaletteMode()), [])

  return (
    <ThemeProvider theme={theme}>
      <AppLayout/>
    </ThemeProvider>
  )
}

const App: FC = () => {
  useEffect(() => {
    SettingsService.load()
  }, [])

  return (
    <Provider store={store}>
      <Router history={browserHistory}>
        <AppTheme/>
      </Router>
    </Provider>
  )
}

export default App
