import { faker } from '@faker-js/faker/locale/en'
import { FC, useCallback, useState } from 'react'
import {
  Button,
  InputSearch,
  Select,
  Tooltip
} from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { IconLink, IconPencilStroke, IconTrashCan } from 'src/icons'
import { TourPopper } from 'src/partials/tour'
import { ShareJobTourService, TARGET_JOB_EDIT_BUTTON, TARGET_JOB_SEARCH_SORT, TARGET_JOB_SHARE_BUTTON, TARGET_JOB_TITLE } from 'src/services/tour/share-job-tour.service'
import { defaultFilterOptions, defaultViewOptions } from '../../list/constants'
import Style from '../../list/style.module.scss'
import { VideoCard } from '../video-card'

const TITLE = 'Customer Success'
const applicants = Array.from({ length: 12 }).map((_, index) => ({
  url: `https://knowme.github.io/images/hiring-manager/${index + 1}.png`,
  name: faker.person.fullName()
}))

export const ListSandBox: FC = () => {
  const currentStep = useBehaviorMapper(ShareJobTourService.currentStep$)

  const [, setIsShowSearchBar] = useState(false)
  const [sortValue, setSortValue] = useState(defaultFilterOptions[0].value)
  const [viewValue, setViewValue] = useState(defaultViewOptions[0].value)

  const handleShowSearchBarChanged = useCallback((isShowing: boolean) => {
    setIsShowSearchBar(isShowing)
  }, [])

  return (
    <div className={Style.STContainer}>
      <div className={Style.listCampaignContent}>
        <div className={Style.STCampaignHeader}>
          <div className={Style.STCampaignHeaderLeft}>

            <Tooltip title={TITLE}>
              <span onClick={() => ShareJobTourService.nextStep()} id={TARGET_JOB_TITLE.getValue()} className={Style.STCampaignHeaderTitle}>
                {TITLE}
              </span>
            </Tooltip>
            {currentStep === 1 && <TourPopper {...ShareJobTourService.getStepConfig()}/>}

            <Button
              id={TARGET_JOB_EDIT_BUTTON.getValue()}
              type="button"
              variant="secondary"
              className={Style.STCampaignHeaderAction}
              onClick={() => ShareJobTourService.nextStep()}
            >
              <IconPencilStroke size={24}/>
            </Button>
            {currentStep === 2 && <TourPopper {...ShareJobTourService.getStepConfig()}/>}

            <Button
              type="button"
              variant="secondary"
              className={Style.STCampaignHeaderAction}
            >
              <IconTrashCan size={24}/>
            </Button>

            <Button
              id={TARGET_JOB_SHARE_BUTTON.getValue()}
              type="button"
              variant="secondary"
              className={Style.STCampaignHeaderAction}
              onClick={() => ShareJobTourService.nextStep()}
            >
              <IconLink size={24}/>
            </Button>
            {currentStep === 3 && <TourPopper {...ShareJobTourService.getStepConfig()}/>}
          </div>

          <div className={Style.STCampaignHeaderRight}>
            <InputSearch handleChange={() => {}} onShowSearchBarChanged={handleShowSearchBarChanged}/>

            <div id={TARGET_JOB_SEARCH_SORT.getValue()} style={{ width: 0, height: 0, position: 'absolute', left: '50px', bottom: 0 }}/>
            {currentStep === 4 && <TourPopper {...ShareJobTourService.getStepConfig()}/>}
            <Select
              options={defaultFilterOptions.map((option) => ({
                label: option.label,
                value: option.value
              }))}
              onChange={(value) => setSortValue(value as string)}
              value={sortValue}
              className={Style.FilterOption}
            />
            <Select
              options={defaultViewOptions.map((option) => ({
                label: option.label,
                value: option.value
              }))}
              onChange={(value) => setViewValue(value as string)}
              value={viewValue}
              className={Style.ViewOption}
            />
            {currentStep === 5 && <TourPopper {...ShareJobTourService.getStepConfig()}/>}
          </div>
        </div>

        <div className={Style.campaignContainerWrapper}>
          <div className={Style.campaignContainerData}>
            {applicants.map((applicant, index) => (
              <VideoCard key={index} name={applicant.name} url={applicant.url}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
