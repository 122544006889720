import qs from 'qs'
import { FC, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useHistory } from 'react-router'
import { VideoApi } from 'src/api'
import { axiosHiringApi } from 'src/api/axios'
import { BreadcrumbHeading, Button, InputDebounce, Spinner } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useBehaviorMapper, useDebounceAnalyticInput, useDebouncedLoading } from 'src/hooks'
import { IconSearch, IconVideoIllustration } from 'src/icons'
import { IVideoModel } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { VVCCard } from 'src/partials/card/vvc-card'
import { ERoutes, generate } from 'src/router'
import { LoadMoreService } from 'src/services'

interface IProps {
  onSelectVideo?: (video?: IVideoModel) => void
}

export const Videos: FC<IProps> = ({ onSelectVideo }) => {
  const history = useHistory()
  const [keyword, setKeyword] = useState<string>()
  const { analyticInput } = useDebounceAnalyticInput('top_nav')

  const _loadMoreService = useMemo(() => new LoadMoreService<IVideoModel & { usedForCampaigns?: number }>({
    axiosInstance: axiosHiringApi,
    endpoint: VideoApi._prefix + '?' + qs.stringify({
      keyword
    })
  }), [keyword])

  const { ref: refLoadMore, inView } = useInView({ threshold: 0 })
  const loading = useBehaviorMapper(_loadMoreService.loading$)
  const items = useBehaviorMapper(_loadMoreService.items$)

  const handleSetKeyword = (value?: string) => {
    setKeyword(value)
    analyticInput(ETrackingEvent.INPUT_NAV_SEARCH_VIDEOS, value)
  }

  useEffect(() => {
    if (inView && !loading && _loadMoreService.hasMore) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, inView, loading])

  useEffect(() => {
    _loadMoreService.loadMore()
  }, [_loadMoreService])

  const debounceLoading = useDebouncedLoading(!items.length && loading)

  const view = useMemo(() => {
    if (debounceLoading) {
      return (
        <div className="fx-1 fx fx-center">
          <Spinner/>
        </div>
      )
    }

    if (!items?.length) {
      return (
        <div className="fx-1 fx-column fx-center gap-5">
          <IconVideoIllustration/>

          <div className="fs-24 fw-700 txt-center txt-black-01">You haven’t recorded a Vibe Check yet!</div>
          {onSelectVideo && (
            <Button variant="secondary" className="mt-6" onClick={() => onSelectVideo()} style={{ width: 400 }}>
              Close
            </Button>
          )}

          {!onSelectVideo && (
            <>
              <div className="txt-center txt-black-02">
                Claim or Create a job to record a Vibe Check.
              </div>

              <Button className="mt-6" onClick={() => history.push(generate(ERoutes.CLAIM_JOBS))}>
                View Available Jobs to Claim
              </Button>
            </>
          )}
        </div>
      )
    }

    return (
      <section className="p-8 fx fx-wrap-wrap gap-4">
        {items?.map(item => (
          <VVCCard key={item.id} video={item} onChange={(values) => item.id && _loadMoreService.updateOneItem(item.id, values)} onSelectVideo={onSelectVideo}/>
        ))}
        <div ref={refLoadMore}/>
      </section>
    )
  }, [_loadMoreService, debounceLoading, history, items, onSelectVideo, refLoadMore])

  return (
    <>
      {!onSelectVideo && (
        <Breadcrumbs>
          <div className="fx fx-ai-center gap-6">
            <BreadcrumbHeading active>My Videos</BreadcrumbHeading>
            <InputDebounce
              prefix={<IconSearch size={20}/>}
              style={{ width: 350 }}
              placeholder="Search jobs, skills, locations…"
              value={keyword}
              onChange={handleSetKeyword}
            />
          </div>
        </Breadcrumbs>
      )}

      {view}
    </>
  )
}
