import clsx from 'clsx'
import React, { forwardRef, ReactNode, useEffect, useRef, useState } from 'react'
import Style from './style.module.scss'

interface TextareaProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  label?: string
  error?: string
  value?: string
  defaultContent?: ReactNode
  onChange?: (event: { target: { value: string } }) => void
}

export const TextareaNew = forwardRef<HTMLDivElement, TextareaProps & { disabled?: boolean }>(
  ({ label, error, className, disabled, value, onChange, defaultContent, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false)
    const contentRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
      if (contentRef.current && contentRef.current.textContent !== value) {
        contentRef.current.textContent = value || ''
      }
    }, [value])

    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)

    const handleInput = (e: React.FormEvent<HTMLSpanElement>) => {
      const content = e.currentTarget.textContent || ''
      onChange?.({ target: { value: content } })
    }

    return (
      <div className={className}>
        {label && <label className={Style.label}>{label}</label>}
        <div
          className={clsx(Style.textareaContainer, {
            [Style.focused]: isFocused,
            [Style.error]: error,
            [Style.disabled]: disabled
          })}
        >
          <div
            ref={ref}
            className={Style.textarea}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
          >
            {defaultContent}
            <span
              ref={contentRef}
              contentEditable={!disabled}
              className={Style.contentEditable}
              onInput={handleInput}
              suppressContentEditableWarning
            />
          </div>
        </div>
        {error && <div className={Style.innerError}>{error}</div>}
      </div>
    )
  }
)
