import clsx from 'clsx'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { Button, CardReactionAndResume, FlipCard, FlipCardBack, InputSearch, Select, Tooltip, VideoPlayerOld } from 'src/components'
import { useAppDispatch, useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { IconLink, IconPencilStroke, IconTrashCan } from 'src/icons'
import { ICampaignSubmissionModel } from 'src/interfaces'
import { STFlipFront } from 'src/modules/intros/components/list/styled'
import { SignUpModal } from 'src/partials'
import { TourPopper } from 'src/partials/tour'
import { OverlayService } from 'src/services'
import { GuestOnboardingVibeApplicantTourService, TARGET_GUEST_ONBOARDING_APPLICANT } from 'src/services/tour/guest-onboarding-vibe-applicant.service'
import { LoadingModule, SidebarModule } from 'src/store'
import { setLayoutPageTitle } from 'src/store/actions'
import { getVideoSource } from 'src/utils'
import { defaultFilterOptions, defaultViewOptions } from './constants'
import Style from './style.module.scss'

const TITLE = 'Customer Success'

export const PublicGuestOnboardingSubmissions: FC = () => {
  const dispatch = useAppDispatch()
  const unsubscribe$ = useUnsubscribe()
  const currentTourStep = useBehaviorMapper(GuestOnboardingVibeApplicantTourService.currentStep$)
  const tourEnabled = useBehaviorMapper(GuestOnboardingVibeApplicantTourService.enableTour$)

  const flippedMapRef = useRef<Map<number, boolean>>(new Map())
  const [isChangeView, setIsChangeView] = useState(false)

  const [seeds, setSeeds] = useState<ICampaignSubmissionModel[]>([])
  const [, setIsShowSearchBar] = useState(false)

  const [sortValue, setSortValue] = useState(defaultFilterOptions[0].value)
  const [viewValue, setViewValue] = useState(defaultViewOptions[0].value)

  useEffect(() => {
    SidebarModule.on()
  }, [])

  useEffect(() => {
    LoadingModule.toggle(true)
    from(CampaignApi.getSeedSubmissions())
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => LoadingModule.toggle(false))
      )
      .subscribe((res) => {
        setSeeds(res.data)
      })
  }, [unsubscribe$])

  const handleShowSearchBarChanged = useCallback((isShowing: boolean) => {
    setIsShowSearchBar(isShowing)
  }, [])

  const showSignUpModal = useCallback(() => {
    return OverlayService.setOverlay({
      content: <SignUpModal/>,
      open: true
    })
  }, [])

  const handleFlipButtonClick = (flipFunc: () => void, candidateId: number, videoId?: number) => {
    flipFunc()

    if (videoId) {
      const isFlipped = flippedMapRef.current.get(videoId)
      flippedMapRef.current.set(videoId, !isFlipped)
    }
  }

  useEffect(() => {
    dispatch(setLayoutPageTitle('My Jobs / Customer Success'))
  }, [dispatch])

  return (
    <div className={Style.STContainerGuess}>
      <div className={Style.STContainer}>
        <div className={Style.listCampaignContent}>
          <div className={Style.STCampaignHeader}>
            <div className={Style.STCampaignHeaderLeft}>

              <Tooltip title={TITLE}>
                <span className={Style.STCampaignHeaderTitle}>
                  {TITLE}
                </span>
              </Tooltip>

              <Button
                type="button"
                variant="secondary"
                className={Style.STCampaignHeaderAction}
              >
                <IconPencilStroke size={24}/>
              </Button>

              <Button
                type="button"
                variant="secondary"
                className={Style.STCampaignHeaderAction}
              >
                <IconTrashCan/>
              </Button>

              <Button
                type="button"
                variant="secondary"
                className={Style.STCampaignHeaderAction}
              >
                <IconLink size={24}/>
              </Button>
            </div>

            <div className={Style.STCampaignHeaderRight}>
              <InputSearch handleChange={() => {}} onShowSearchBarChanged={handleShowSearchBarChanged}/>

              <Select
                options={defaultFilterOptions.map((option) => ({
                  label: option.label,
                  value: option.value
                }))}
                onChange={(value) => setSortValue(value as string)}
                value={sortValue}
                className={Style.FilterOption}
              />
              <Select
                options={defaultViewOptions.map((option) => ({
                  label: option.label,
                  value: option.value
                }))}
                onChange={(value) => {
                  setViewValue(value as string)
                  setIsChangeView(true)
                }}
                value={viewValue}
                className={Style.ViewOption}
              />
            </div>
          </div>

          <div className={Style.campaignContainerWrapper}>
            <div className={Style.campaignContainerData}>
              {seeds.map((item) => (
                <div key={item.id} className={Style.STLikesItem}>
                  <FlipCard flipped={isChangeView || !!flippedMapRef.current.get(item.video.id)}>
                    {(flipFunc) => [
                      (
                        <STFlipFront key={'front_' + item.id}>
                          <div className={clsx(Style.STVideo, Style.STVideoGuest)}>
                            <VideoPlayerOld
                              id={'video' + item.id}
                              videoId={item?.video?.id}
                              tracks={item?.video?.tracks}
                              image={item?.video?.urlVideoImageThumbnail}
                              animatedImage={item?.video?.urlVideoAnimatedImage}
                              url={getVideoSource(item?.video)}
                              style={{ borderRadius: '16px 16px 0 0' }}
                              onDetailClicked={() => null}
                              isMuted={false}
                              mini
                              author={item?.author}
                              hideSpeaker
                              hideStartEnd
                              mimeType={item?.video?.internalSourceMetadata?.mimeType}
                              action={(
                                <CardReactionAndResume
                                  item={item}
                                  newCandidateIds={[]}
                                  onFlip={() => handleFlipButtonClick(flipFunc, item.id, item.video?.id)}
                                  onShare={showSignUpModal}
                                  isSubmission
                                />
                              )}
                              showSpeed
                            />
                          </div>
                        </STFlipFront>
                      ),
                      <FlipCardBack
                        key={'back_' + item.id}
                        onFlip={() => { flipFunc(); flippedMapRef.current.set(item.video.id, false) }}
                        educations={item?.author?.linkedinEducations}
                        workingExperiences={item.author?.linkedinWorkingExperiences}
                        video={item?.video}
                        author={item?.author}
                        showFullName
                      />
                    ]}
                  </FlipCard>
                </div>
              )
              )}
            </div>
          </div>
        </div>
      </div>

      <div id={TARGET_GUEST_ONBOARDING_APPLICANT.getValue()} className={Style.target}/>

      {tourEnabled && currentTourStep === 0 && <TourPopper {...GuestOnboardingVibeApplicantTourService.getStepConfig()}/>}
      {tourEnabled && currentTourStep === 1 && <TourPopper {...GuestOnboardingVibeApplicantTourService.getStepConfig()}/>}
    </div>
  )
}
