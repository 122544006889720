import React, { useEffect, useState } from 'react'
import Style from './style.module.scss'

interface IProps {
  name: string
  options: { label: string; value: string | boolean }[]
  onChange: (value: string | boolean) => void
  defaultValue?: string | boolean
}

export const GroupRadio: React.FC<IProps> = ({ name, options, onChange, defaultValue }) => {
  const [selectedValue, setSelectedValue] = useState<string | boolean>()

  const handleChange = (value: string | boolean) => {
    setSelectedValue(value)
    onChange(value)
  }

  useEffect(() => {
    setSelectedValue(defaultValue)
  }, [defaultValue])

  return (
    <div className={Style.groupRadio}>
      {options.map((option, index) => (
        <label key={index} className={Style.radioLabel}>
          <input
            type="radio"
            name={name}
            value={option.value?.toString()}
            checked={selectedValue === option.value}
            onChange={() => handleChange(option.value)}
            className={Style.radioInput}
          />
          {option.label}
        </label>
      ))}
    </div>
  )
}
