import { Box, Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import {
  ChangeEventHandler,
  ComponentProps,
  FC,
  FormEventHandler,
  useCallback,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AuthApi } from 'src/api'
import { Button, Input, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import {
  useUnsubscribe,
  useValidation
} from 'src/hooks'
import { Logo } from 'src/images'
import { ERoutes, generate } from 'src/router'
import { SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { getApiErrorMessage } from 'src/utils'
import { signInSchema } from 'src/validation'
import { ModalAuthentication } from '../modal-authentication'
import Style from './style.module.scss'

interface IProps {
  onSwitch?: (stage: ComponentProps<typeof ModalAuthentication>['stage']) => void
}

export const SignInForm: FC<IProps> = ({ onSwitch }) => {
  const theme = useTheme()
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const { eventHandler } = useAnalytic('signin')
  const [formValues, setFormValues] = useState<{
    email?: string
    password?: string
  }>({})

  const { errors, validate } = useValidation({
    data: formValues,
    schema: signInSchema
  })

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = e => {
    e.persist()
    const { name, value } = e.target
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const [loading, setLoading] = useState(false)
  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async e => {
      e.preventDefault()
      const { isValid, value: payload } = await validate()
      if (!isValid || !payload) {
        return
      }

      setLoading(true)
      from(AuthApi.login(payload))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            SnackbarService.error(getApiErrorMessage(error))
            return EMPTY
          }),
          finalize(() => setLoading(false))
        )
        .subscribe(({ data }) => {
          AuthModule.authenticated(data.profile)
          // TODO: navigate to the correct page after login
          // Only navigate to the talent page if the onSwitch prop is not a function (from Modal)
          if (typeof onSwitch !== 'function') {
            history.push(generate(ERoutes.TALENT_VIBE_TALENT))
          }
        })
    },
    [history, onSwitch, unsubscribe$, validate]
  )

  const goSignUp = useCallback(() => {
    if (onSwitch) {
      return onSwitch('sign-up')
    }
    history.push(generate(ERoutes.SIGN_UP))
  }, [history, onSwitch])

  const goForgotPassword = useCallback(() => {
    if (onSwitch) {
      return onSwitch('forgot-password')
    }
    history.push(generate(ERoutes.FORGOT_PASSWORD))
  }, [history, onSwitch])

  return (
    <form className={Style.signInForm} onSubmit={onSubmit} autoComplete="off">
      <div className="mb-4">
        <img src={Logo} alt="logo"/>
      </div>

      <div className={Style.LoginHeader}>
        <Typography className={Style.Title}>
          Sign In to KNOWME<span>TM</span>
        </Typography>
        <Typography align="center" className={Style.Description}>
          Hiring
        </Typography>
      </div>

      <div className={Style.FormInput}>
        <Input
          label="Work Email"
          id="email"
          type="email"
          name="email"
          placeholder="name@company.com"
          disabled={loading}
          value={formValues.email || ''}
          onChange={handleChangeInput}
          error={errors.getError('email')}
          onClick={eventHandler(ETrackingEvent.INPUT_EMAIL)}
        />

        <div className="fx-column gap-2">
          <div className="fx fx-ai-center fx-jc-space-between">
            <span className="body-2 fw-bold txt-neutral-500">Password</span>
            <span className="body-2 txt-blue-400 pointer" onClick={goForgotPassword}>
              Forgot password
            </span>
          </div>
          <Input
            // label="Password"
            id="password"
            name="password"
            type="password"
            placeholder="Enter your password"
            disabled={loading}
            value={formValues.password || ''}
            onChange={handleChangeInput}
            error={errors.getError('password')}
            onClick={eventHandler(ETrackingEvent.INPUT_SIGN_IN_PSWD)}
          />
        </div>
      </div>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box className={Style.Box}>
          <Typography
            variant="body2-medium"
            color={theme.colors['--color-neutral-theme-400']}
          >
            New to KNOWME?
          </Typography>
          <Typography
            variant="body2-medium"
            component="a"
            className="color-blue-500"
            onClick={goSignUp}
            sx={{ cursor: 'pointer' }}
          >
            Create account
          </Typography>
        </Box>
        <Button className="round-16" type="submit" disabled={loading}>
          Sign in
        </Button>
      </Box>
    </form>
  )
}
