import { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import {
  EMPTY,
  catchError,
  finalize,
  from,
  takeUntil
} from 'rxjs'
import { PeopleApi } from 'src/api'
import { Button, Checkbox, DialogConfirmUnMatch, UserAvatar, useAnalytic } from 'src/components'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { IconChat, IconThumbsUp, IconThumbsUpDup, IconThumbsUpFilled } from 'src/icons'
import { ICampaignSubmissionModel } from 'src/interfaces'
import { mustVerifiedCompany } from 'src/partials/modal-verify-company'
import { ModalViewVideo } from 'src/partials/modal-view-video'
import { VideoPlayerTracking } from 'src/partials/video-player-tracking'
import { ERoutes, generate } from 'src/router'
import { DialogService, SnackbarService } from 'src/services'
import {
  ReactionUtils,
  StyleUtils,
  VideoUtils,
  getAvatar,
  getFullName,
  renderVideoTitle
} from 'src/utils'
import { ETrackingEvent } from '../../../enums'
import Style from './style.module.scss'

interface IProps {
  submission: ICampaignSubmissionModel
  onChange?: (id: number, values: Partial<ICampaignSubmissionModel>) => void
  onDetail?: () => void
  onSelectMultiMessage?: () => void
  isSelectedMultiMessage?: boolean
  isWriteMessage?: boolean
}

export const CampaignApplicantCard: FC<IProps> = ({ submission, onChange, onDetail, onSelectMultiMessage, isSelectedMultiMessage, isWriteMessage }) => {
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const onChangeRef = useAsRef(onChange)
  const { eventHandler } = useAnalytic('job_detail')

  const [loading, setLoading] = useState(false)
  const [showPreviewVideo, setShowPreviewVideo] = useState(false)

  const video = useMemo(() => submission?.video, [submission?.video])
  const reaction = useMemo(() => submission.peopleReaction, [submission.peopleReaction])
  const lookupId = useMemo(() => submission?.author?.lookupId, [submission?.author?.lookupId])
  const isEmphasis = useMemo(() => ReactionUtils.isEmphasis(reaction), [reaction])

  const onVideo = useCallback(() => {
    if (onDetail) {
      return onDetail()
    }

    video && DialogService.open(ModalViewVideo, { video })
  }, [onDetail, video])

  const onToggleLike = useCallback(() => {
    if (!submission.authorId) return

    setLoading(true)
    from(PeopleApi.toggleLike({ id: submission.authorId }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        onChangeRef.current?.(submission?.id, { peopleReaction: data || null })
      })
  }, [submission.authorId, submission?.id, unsubscribe$, onChangeRef])

  return (
    <div key={submission.id} className={Style.campaignContainer}>
      <div className="w-full relative bg-neutral-900 fx-ai-center fx-jc-center fx-row round-4 overflow-hidden">
        <div className="absolute b-6 z-3 w-full fx fx-ai-center fx-jc-space-between gap-4 px-4">
          <div className="fx fx-ai-center gap-4">
            <UserAvatar
              size={32}
              className="round-10"
              fullName={getFullName(submission?.author)}
              avatarUrl={getAvatar(submission?.author)}
            />

            {submission?.author?.username && (
              <div className="fs-12 fw-400 txt-neutral-white three-dot-1">
                @{submission?.author?.username}
              </div>
            )}
          </div>

          {onToggleLike && (
            <div className="fx fx-ai-center gap-3">
              <Button
                size={32}
                variant="icon"
                green={!!reaction}
                emphasis={isEmphasis}
                // disabled={loading}
                onClick={eventHandler(ETrackingEvent.BTN_TALENT_LIKE, () => !loading && (
                  isEmphasis
                    ? DialogService.open(DialogConfirmUnMatch, {
                      name: getFullName(submission?.author),
                      onConfirm: onToggleLike
                    })
                    : mustVerifiedCompany(onToggleLike)
                ))}
              >
                {!reaction
                  ? <IconThumbsUp size={20}/>
                  : isEmphasis
                    ? <IconThumbsUpDup size={20}/>
                    : <IconThumbsUpFilled size={20}/>}
              </Button>

              {lookupId && (
                <Button
                  size={32}
                  variant="icon"
                  onClick={eventHandler(ETrackingEvent.BTN_TALENT_MESSAGE, () => history.push(generate(ERoutes.TALENT_MESSAGES, { lookupId })))}
                >
                  <IconChat size={20}/>
                </Button>
              )}
            </div>
          )}
        </div>

        {isWriteMessage && (
          <div className="absolute z-3" style={{ top: 8, right: 8 }}>
            <Checkbox checked={isSelectedMultiMessage} onClick={onSelectMultiMessage}/>
          </div>
        )}

        <div
          className="w-full pointer"
          style={{
            aspectRatio: '9/16',
            ...StyleUtils.backgroundCover(video?.urlVideoImageThumbnail)
          }}
          onMouseEnter={() => setShowPreviewVideo(true)}
          onMouseLeave={() => setShowPreviewVideo(false)}
          onClick={onVideo}
        >
          {showPreviewVideo && (
            <VideoPlayerTracking
              videoId={video?.id}
              mini
              autoplay
              hideControl
              hidePlayIcon
              hideStartEnd
              url={VideoUtils.getVideoSource(video) ?? ''}
              tracks={video?.tracks}
              image={video?.urlVideoImageThumbnail}
            />
          )}
        </div>
      </div>

      <div className="bold-14 txt-black-01 three-dot-1 text-center">{renderVideoTitle(submission?.video)}</div>

      {onDetail && (
        <Button
          className="w-fit"
          onClick={eventHandler(ETrackingEvent.BTN_TALENT_DETAILS, onVideo)}
        >
          Details
        </Button>
      )}
    </div>
  )
}
