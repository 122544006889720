import clsx from 'clsx'
import { EmojiClickData } from 'emoji-picker-react'
import {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useState
} from 'react'
import { EMPTY, catchError, finalize, from, switchMap, takeUntil } from 'rxjs'
import { CampaignApi, UploadApi } from 'src/api'
import {
  Button,
  EmojiPicker,
  FilePicker,
  Modal,
  ModalCenter,
  UserAvatar
} from 'src/components'
import { TextareaNew } from 'src/components/textarea-new'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { IconCloseDark, IconFile } from 'src/icons'
import { ICampaignSubmissionModel } from 'src/interfaces'
import { DialogService, SnackbarService } from 'src/services'
import { getAvatar, getFullName } from 'src/utils'
import { ModalApplicantDetail } from '../modal-applicant-detail'

interface IProps extends Pick<ComponentProps<typeof Modal>, 'open' | 'onClose'> {
  initialCampaignSubmissions: ICampaignSubmissionModel[]
  onRemoveWrapperMultiMessage?: (campaignSubmissions: ICampaignSubmissionModel[]) => void
}

export const ModalSendMessage: FC<IProps> = ({
  initialCampaignSubmissions,
  onRemoveWrapperMultiMessage,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [campaignSubmissions, setCampaignSubmissions] = useState<ICampaignSubmissionModel[]>(initialCampaignSubmissions)
  const [message, setMessage] = useState<string>('')
  const [attachments, setAttachments] = useState<
    Array<{
      file: File
      uploading?: boolean
    }>
  >([])

  const onRemoveMultiMessage = useCallback((id: number) => {
    setCampaignSubmissions((prev) => prev.filter((submission) => submission.id !== id))
  }, [])

  const insertEmoji = useCallback((data: EmojiClickData) => {
    setMessage((message) => message + data.emoji)
  }, [])

  const onChooseFiles = useCallback((files: FileList) => {
    // const formattedFiles = Array.from(files).map((file) => ({ file }))
    // setAttachments((prev) => [...prev, ...formattedFiles])
    setAttachments([{ file: files[0] }]) // only allow 1 file
  }, [])

  const onCloseRef = useAsRef(props.onClose)
  const onSubmit = useCallback(() => {
    setLoading(true)

    const payload = {
      campaignId: campaignSubmissions[0].campaignId,
      submissionIds: campaignSubmissions.map((submission) => submission.id),
      message
    } as Parameters<typeof CampaignApi.batchMessages>[0]
    const promise = attachments?.length
      ? UploadApi.uploadF({
        entity: 'Message',
        file: attachments[0].file,
        filename: attachments[0].file.name
      }).then((url) => {
        payload.fileUrl = url
        payload.fileName = attachments[0].file.name
        payload.fileType = attachments[0].file.type
      })
      : Promise.resolve()

    from(promise)
      .pipe(
        switchMap(() => CampaignApi.batchMessages(payload)),
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        setMessage('')
        setAttachments([])
        onCloseRef.current?.()
        SnackbarService.success('Message sent successfully')
      })
  }, [attachments, campaignSubmissions, message, onCloseRef, unsubscribe$])

  useEffect(() => {
    return () => {
      onRemoveWrapperMultiMessage?.(campaignSubmissions)
    }
  }, [onRemoveWrapperMultiMessage, campaignSubmissions])

  return (
    <ModalCenter
      {...props}
      contentStyles={{ width: 650 }}
    >
      <div className="fx-column gap-6">
        <div className="fs-16 fw-600 txt-black-01">
          Send Message To Candidates
        </div>

        <div className="fx gap-2 overflow-auto">
          {campaignSubmissions.map((submission) => (
            <UserAvatar
              key={submission.id}
              size={32}
              fullName={getFullName(submission.author)}
              avatarUrl={getAvatar(submission.author)}
              className={clsx('round-1', { pointer: !loading })}
              onClick={() => !loading && DialogService.open(ModalApplicantDetail, {
                submission,
                onRemoveMultiMessage
              })}
            />
          ))}
        </div>

        <div className="fx-column gap-3">
          <TextareaNew
            value={message}
            disabled={loading}
            onChange={(event) => setMessage(event.target.value)}
            defaultContent={(
              <>
                Hi
                <span
                  contentEditable={false}
                  className="txt-neutral-800 bg-neutral-100 px-3 py-2 round-2 mx-2"
                >
                  Candidate Name
                </span>
              </>
            )}
          />
          <div className="fx fx-ai-center fx-jc-space-between gap-4">
            <div className="fx fx-ai-center gap-3">
              <FilePicker
                className={clsx({ pointer: !loading })}
                style={{ pointerEvents: attachments?.length ? 'none' : 'all' }}
                inputProps={{ accept: 'image/*,video/*,application/pdf' }}
                disabled={loading}
                onChoose={onChooseFiles}
              >
                <Button variant="icon" size={32}>
                  <IconFile size={20}/>
                </Button>
              </FilePicker>
              <EmojiPicker
                disabled={loading}
                previewConfig={{ showPreview: false }}
                onEmojiClick={insertEmoji}
                onReactionClick={insertEmoji}
              />
              {attachments?.map((item) => (
                <div
                  key={item.file.name}
                  className="relative fx fx-ai-center gap-2 px-4 py-2 bg-neutral-white-02 round-2"
                >
                  <IconFile size={16}/>
                  <span className="fs-12 fw-500 txt-grey-01 three-dot-1">
                    {item.file.name}
                  </span>
                  <div
                    className={clsx('absolute', { pointer: !loading })}
                    style={{ right: -12, top: -8 }}
                    onClick={() => !loading && setAttachments([])}
                  >
                    <IconCloseDark/>
                  </div>
                </div>
              ))}
            </div>

            <Button
              disabled={loading || (!message && !attachments?.length)}
              onClick={onSubmit}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </ModalCenter>
  )
}
