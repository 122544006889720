import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconZeroMatches = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 160, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="161" height="160" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10160_168268)">
          <path d="M123.084 29.9734C121.938 29.2401 120.084 28.5334 118.724 29.0534C116.564 29.8667 115.498 32.0534 115.191 34.2267C114.498 38.9734 115.724 46.7734 120.444 49.4267C127.404 53.3201 128.378 33.1201 122.738 29.9867" stroke="#AFF6C5" strokeWidth="4" strokeLinecap="round"/>
          <path d="M143.325 24.24C138.645 17.6267 132.819 12.92 124.232 13.6267C115.405 14.36 105.925 17.28 100.899 25.04C99.9388 26.52 99.1254 28.2 98.5654 29.8934C98.4988 30.2934 98.4054 30.68 98.3254 31.08C97.9921 32.1067 97.7388 33.1334 97.5521 34.1867C97.4588 34.68 97.3921 35.1734 97.3254 35.6667C97.2988 35.8267 97.2588 35.96 97.2188 36.1067C97.1921 36.4 97.1788 36.6934 97.1654 36.9867C97.1654 37.16 97.1388 37.3334 97.1388 37.5067C97.1388 37.8134 97.1388 38.12 97.1388 38.4267C97.1388 38.9067 97.0988 39.3867 97.1121 39.88C97.1121 40 97.1521 40.0934 97.1921 40.2C97.3121 41.9467 97.6054 43.68 98.0854 45.36C99.3388 51.0267 102.405 56.1467 107.499 58.5067C107.739 58.64 107.952 58.76 108.045 58.8C110.379 59.8534 113.045 60.36 115.632 60.48C116.592 60.4667 117.539 60.4934 118.485 60.5334C118.539 60.5334 118.579 60.52 118.632 60.5067C121.872 60.7334 125.312 59.92 128.512 58.9467C129.045 58.8134 129.579 58.6934 130.112 58.5334C130.285 58.48 130.419 58.4 130.539 58.3067C130.832 58.2134 131.139 58.1067 131.419 58.0134C131.499 57.9867 131.552 57.9334 131.619 57.9067C136.312 56.16 140.579 53.48 143.512 49.4267C149.552 41.08 149.205 32.4934 143.352 24.2267L143.325 24.24ZM137.392 51.68C130.979 57.0934 118.259 58.96 110.432 56.2534C108.299 55.52 105.232 54.0667 103.885 52.24C98.0054 44.2667 98.8454 32.4134 104.752 24.6934C108.939 19.2267 118.285 16.7467 124.912 16.4667C129.979 16.2534 133.805 18 137.392 21.4267C146.645 30.2534 147.525 43.12 137.392 51.68Z" fill="black"/>
          <path d="M120.789 23.32C113.695 24.12 113.455 34.3867 114.295 39.6667C115.109 44.7734 117.069 51.0667 123.442 50.56C128.735 50.1334 130.402 44.3334 130.562 39.88C130.775 34.1467 128.642 22.44 120.775 23.32H120.789ZM127.215 45.64C125.095 50.12 120.042 50.1734 117.615 45.9334C115.735 42.6667 115.189 36.0934 115.442 32.36C115.642 29.3334 117.842 24.64 121.335 24.48C123.402 24.3867 125.482 26.32 126.495 27.9467C129.002 31.9734 129.229 41.3867 127.215 45.64Z" fill="black"/>
          <path d="M121.781 28.5866C121.154 28.6799 120.541 29.2666 120.141 29.7199C117.994 32.2399 117.754 37.8133 118.527 40.8933C119.087 43.1199 122.007 45.6666 123.941 43.3466C126.087 40.7733 125.394 35.9733 124.701 32.9333C124.381 31.5199 123.727 28.2933 121.767 28.5733L121.781 28.5866ZM123.807 40.7999C123.634 41.6399 123.247 43.1199 122.274 43.2933C120.807 43.5599 120.127 41.9999 119.874 40.8133C119.407 38.6133 119.247 33.4399 120.261 31.4533C120.461 31.0533 121.501 29.7466 121.927 29.7599C122.261 29.7733 122.461 30.4933 122.581 30.7733C123.461 32.9866 124.247 38.5066 123.794 40.7866L123.807 40.7999Z" fill="black"/>
          <path d="M12.8477 48.68C13.3677 70.04 14.581 82.3467 21.861 84.5067C29.141 86.6667 30.6877 81.8667 37.1143 83C43.541 84.1334 54.101 88.1067 68.101 88.1067C82.101 88.1067 94.7543 60.8534 79.4477 44.8C75.6477 40.8267 68.701 42.0267 66.9277 39.2534C61.5543 30.8267 66.0743 19.2667 55.981 19.6534C42.5543 20.16 52.5677 35.64 35.9277 39.1334C19.2877 42.6267 12.661 40.7734 12.861 48.68H12.8477Z" fill="#AFF6C5"/>
          <path d="M14.9409 41.9866C14.4742 56.2666 14.4075 70.5599 14.7542 84.8532C14.7942 86.8132 17.9142 86.0532 17.8875 84.4266C17.5542 70.1332 17.6075 55.8532 18.0742 41.5599C18.1409 39.6132 14.9942 40.3599 14.9409 41.9866Z" fill="black"/>
          <path d="M16.0712 86.1732C20.8845 86.7599 28.0712 88.0132 31.4845 83.5999C35.0979 78.9199 33.7512 69.9199 33.5645 64.5466C33.4445 60.7332 33.2445 56.9332 32.9912 53.1332C32.7912 50.0399 33.3779 45.3732 31.5779 42.6532C28.8445 38.4932 21.2179 39.4799 17.1112 40.0532C15.3779 40.2932 14.6979 43.1332 16.8579 42.8266C22.2179 42.0666 28.7779 41.5332 29.4312 48.1199C29.9379 53.3066 30.1912 58.5332 30.3779 63.7466C30.5245 67.5599 30.7645 71.4532 30.4712 75.2666C30.1779 79.2932 30.3779 82.4932 25.3112 83.7999C22.9112 84.4132 19.4845 83.6799 17.0712 83.3866C15.4312 83.1866 13.9645 85.9199 16.0312 86.1732H16.0712Z" fill="black"/>
          <path d="M31.8767 48.4267C37.6634 47.8801 42.85 45.2534 46.57 40.7334C48.45 38.4401 49.8767 35.7601 50.7967 32.9467C51.5034 30.7601 52.3567 19.2267 55.1967 20.1067C56.73 20.5867 58.7967 18.0534 56.9567 17.4801C52.57 16.1201 49.7967 19.3334 49.17 23.4801C48.4367 28.3067 48.53 32.5601 45.9434 36.9601C42.9434 42.0667 38.0234 45.1067 32.1434 45.6534C30.3834 45.8134 29.73 48.6267 31.89 48.4267H31.8767Z" fill="black"/>
          <path d="M55.3274 20C65.2341 22.68 62.0341 34.3067 59.4474 41.1734C58.7141 43.12 61.8207 43.0134 62.4074 41.4667C65.4207 33.44 68.2474 20.4 57.0741 17.3734C55.5407 16.96 53.4741 19.4934 55.3141 20H55.3274Z" fill="black"/>
          <path d="M55.8452 43.7067C60.9252 43.3467 66.0052 43.0134 71.0985 43.0134C75.0052 43.0134 82.4985 42.2534 85.4452 45.5467C86.5785 46.8134 89.3785 45.0134 88.2719 43.7867C85.2452 40.4 80.9119 40.4934 76.6052 40.32C69.7652 40.0534 62.9252 40.4534 56.0985 40.9467C54.3252 41.0667 53.6719 43.8667 55.8452 43.72V43.7067Z" fill="black"/>
          <path d="M85.3393 45.3599C85.7793 47.7066 86.1259 50.5333 83.0593 50.9866C80.5926 51.3599 77.9793 51.1866 75.5126 51.0266C73.8726 50.9199 72.4059 53.6799 74.4726 53.8133C78.0326 54.0533 83.1126 54.6933 86.2459 52.5599C89.0459 50.6399 88.9926 47.2266 88.4326 44.2266C88.0993 42.4533 85.0459 43.7333 85.3393 45.3466V45.3599Z" fill="black"/>
          <path d="M80.8607 53.7999C82.7674 56.0666 84.6341 59.5599 80.8874 61.1866C78.3141 62.2933 75.0741 62.2266 72.3407 62.0666C70.7007 61.9733 69.2341 64.7333 71.3007 64.8533C75.4207 65.1066 80.6874 65.1066 84.0074 62.2533C87.5007 59.2399 86.3274 55.1866 83.6874 52.0399C82.5941 50.7333 79.7807 52.5199 80.8607 53.7999Z" fill="black"/>
          <path d="M77.9384 65.1334C81.1651 66.5734 81.1918 70.9467 77.5518 71.8534C75.0318 72.48 72.1518 72.3067 69.5784 72.3334C67.9651 72.3467 66.4851 75.1467 68.5384 75.12C72.8051 75.0667 78.8184 75.76 81.9518 72.1467C84.8051 68.8534 83.5518 64.2267 79.6984 62.4934C78.2184 61.8267 76.1651 64.32 77.9384 65.12V65.1334Z" fill="black"/>
          <path d="M75.2862 75.4266C77.2196 76.5066 78.4862 78.8533 77.5529 81.0133C76.4996 83.4266 73.0329 83.1866 70.8329 83.0533C69.1929 82.9466 67.7262 85.7066 69.7929 85.8399C73.7262 86.0933 78.4596 85.7066 80.3529 81.6666C81.9929 78.1599 80.2329 74.5999 77.0329 72.8133C75.6196 72.0266 73.5662 74.4799 75.2729 75.4399L75.2862 75.4266Z" fill="black"/>
          <path d="M71.1009 83.24C65.3942 83.2934 59.6875 83.36 53.9942 83.4C48.3009 83.44 41.5275 84.0534 38.5409 78.1067C37.7809 76.6 34.9409 78.3467 35.7142 79.8667C37.8609 84.1334 41.8875 85.7334 46.4742 86.1334C54.2209 86.8134 62.3009 86.0934 70.0742 86.0134C71.6875 86 73.1675 83.2 71.1142 83.2267L71.1009 83.24Z" fill="black"/>
          <path d="M37.5685 77.7999L32.6485 77.6666C31.8218 77.6399 30.9418 78.1066 30.6485 78.9199C30.3951 79.6133 30.8085 80.4399 31.6085 80.4533L36.5285 80.5866C37.3551 80.6133 38.2351 80.1466 38.5285 79.3333C38.7818 78.6399 38.3685 77.8133 37.5685 77.7999Z" fill="black"/>
          <path d="M146.687 98.1733C146.167 119.533 144.953 131.84 137.673 134C130.407 136.16 128.847 127.36 122.42 128.493C115.993 129.627 109.247 131.053 95.2465 131.053C81.2465 131.053 64.1265 114.227 79.4332 98.1733C83.2332 94.1999 88.6865 89.5199 90.4599 86.7466C95.8332 78.3199 93.4599 68.7599 103.553 69.1333C116.98 69.6399 108.3 81.7466 124.94 85.24C141.58 88.7333 146.873 90.2666 146.687 98.1733Z" fill="#AFF6C5"/>
          <path d="M141.457 91.4801C141.924 105.76 141.991 120.053 141.644 134.347C141.604 136.293 144.737 135.547 144.777 133.92C145.111 119.627 145.057 105.347 144.591 91.0534C144.524 89.0934 141.417 89.8667 141.457 91.4801Z" fill="black"/>
          <path d="M143.061 132.893C137.114 133.627 130.648 134.387 129.341 127.267C128.488 122.613 128.954 117.52 129.128 112.813C129.274 108.707 129.501 104.613 129.808 100.52C130.048 97.2666 129.581 94.2666 133.101 92.3066C135.434 91 139.541 91.9866 142.021 92.3333C143.661 92.5599 145.128 89.84 143.061 89.5466C138.794 88.9466 131.488 88.0666 128.394 92.0533C127.008 93.8533 127.061 96.3466 126.874 98.48C126.488 102.973 126.234 107.493 126.061 112C125.821 118 124.461 126.76 127.314 132.347C130.168 137.933 137.914 136.253 142.821 135.653C144.568 135.44 145.234 132.613 143.074 132.88L143.061 132.893Z" fill="black"/>
          <path d="M128.048 95.1467C117.208 94.1333 111.541 85.1733 110.581 75.1067C110.141 70.4933 108.968 65.2133 103.181 67C101.341 67.5733 101.661 70.2133 103.741 69.5733C107.768 68.3333 107.634 79 108.194 81.2133C108.981 84.3466 110.354 87.36 112.314 89.9333C115.941 94.68 121.101 97.3733 126.994 97.9333C128.634 98.0933 130.101 95.3333 128.034 95.1467H128.048Z" fill="black"/>
          <path d="M103.046 66.9066C91.6055 69.9999 93.8855 82.9866 97.0589 91.3866C97.6989 93.0933 100.712 91.7733 100.152 90.2666C97.7122 83.7733 94.0455 72.0666 103.619 69.4799C105.486 68.9733 105.152 66.3466 103.059 66.9066H103.046Z" fill="black"/>
          <path d="M104.07 90.4268C97.243 89.9334 90.403 89.5468 83.563 89.8134C79.2563 89.9868 74.6963 89.9201 71.6296 93.3468C70.1896 94.9601 72.403 96.4268 73.6963 94.9734C76.9896 91.2934 85.3763 92.4801 89.6296 92.5334C94.1096 92.5868 98.5763 92.8934 103.043 93.2134C104.683 93.3334 106.15 90.5734 104.083 90.4268H104.07Z" fill="black"/>
          <path d="M71.073 94.5066C70.5396 97.3333 70.4196 100.72 73.2063 102.4C76.2196 104.227 81.0463 103.52 84.4063 103.307C86.1796 103.187 86.833 100.4 84.6596 100.533C82.073 100.707 78.7796 101.093 76.2463 100.293C73.4196 99.4 73.753 96.4533 74.2063 94.08C74.553 92.2 71.3663 92.9333 71.073 94.5066Z" fill="black"/>
          <path d="M75.9524 101.92C73.539 104.8 71.8324 108.88 75.099 111.853C78.3657 114.827 83.619 114.573 87.579 114.347C89.3524 114.24 90.0057 111.44 87.8324 111.573C85.0457 111.747 81.6324 111.773 79.019 110.6C75.3124 108.933 76.4057 105.48 78.5524 102.92C79.939 101.267 77.059 100.613 75.9524 101.933V101.92Z" fill="black"/>
          <path d="M80.4312 112.04C76.8846 113.627 74.5912 117.68 76.8979 121.32C79.4446 125.333 85.9246 124.587 89.9512 124.64C91.5779 124.653 93.0579 121.88 90.9912 121.853C88.4179 121.827 85.6979 121.92 83.1379 121.48C81.8179 121.253 80.4846 120.72 79.7246 119.56C78.6312 117.88 79.0846 115.48 80.9912 114.627C82.7112 113.853 82.4312 111.16 80.4312 112.053V112.04Z" fill="black"/>
          <path d="M82.6874 122.52C79.5674 124.267 77.5807 127.907 79.0207 131.4C80.6607 135.387 85.3807 135.56 89.0874 135.32C90.8607 135.213 91.514 132.413 89.3407 132.547C86.954 132.693 83.6474 132.787 82.2474 130.453C80.9407 128.28 81.9807 125.853 84.0474 124.693C85.8741 123.667 84.5007 121.507 82.6874 122.52Z" fill="black"/>
          <path d="M88.4342 135.533C96.6076 135.613 105.301 136.56 113.434 135.533C118.128 134.933 121.741 132.933 123.901 128.653C124.821 126.813 121.674 126.92 120.941 128.36C117.794 134.6 108.341 132.933 102.914 132.88C98.4342 132.84 93.9543 132.8 89.4743 132.76C87.8476 132.747 86.3676 135.533 88.4342 135.547V135.533Z" fill="black"/>
          <path d="M122.351 130.08L127.271 129.947C129.045 129.893 129.685 127.12 127.525 127.173L122.605 127.307C120.831 127.36 120.191 130.133 122.351 130.08Z" fill="black"/>
        </g>
        <defs>
          <clipPath id="clip0_10160_168268">
            <rect width="160" height="160" fill="white" transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  )
})
