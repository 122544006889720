import { BehaviorSubject } from 'rxjs'
import { IOverlayState } from 'src/interfaces/overlay-props.interface'
import { BuyMoreCredit } from 'src/partials'

export const OverlayService = new (
  class {
    private readonly _overlay$ = new BehaviorSubject<IOverlayState>({})

    get overlay$() {
      return this._overlay$
    }

    reset = () => {
      const currentOverlay = this._overlay$.getValue()
      currentOverlay?.onClose?.()

      this._overlay$.next({
        open: false,
        content: '',
        disabled: false,
        blank: false,
        action: () => {}
      })
    }

    /**
     * @deprecated
     */
    setOverlay = (overlay: IOverlayState) => {
      this._overlay$.next(overlay)
    }

    insufficientCredits = () => {
      this.setOverlay({
        open: true,
        content: <BuyMoreCredit/>
      })
    }
  }
)()
