import { AxiosRequestConfig } from 'axios'
import { ICampaignModel, IPaginationQuery, IVideoModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class CampaignClaimApi {
  static readonly _prefix = '/campaign-claim'

  static paginate(
    params?: IPaginationQuery & {
      status?: 'PENDING' | 'PUBLISHED'
    },
    config?: AxiosRequestConfig
  ): TAxiosResponsePagination<ICampaignModel> {
    return axiosHiringApi.get(this._prefix, {
      ...config,
      params: params || config?.params
    })
  }

  static create(
    payload: {
      campaignId: Required<ICampaignModel>['id']
      uploadVideoId: Required<IVideoModel>['id']
      requiredResume?: boolean
      canTrade?: boolean
      specifyTrade?: string
      closedAt?: string
      companyPhoneNo?: string
      linkedIn?: string
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<ICampaignModel> {
    return axiosHiringApi.post(this._prefix, payload, config)
  }

  static show(
    id: Required<ICampaignModel>['id'],
    config?: AxiosRequestConfig
  ): TAxiosResponseData<ICampaignModel> {
    return axiosHiringApi.get(`${this._prefix}/${id}`, config)
  }
}
