import { ComponentProps, FC } from 'react'
import { DialogConfirm } from 'src/components'
import { IconUnlikeIllustration } from 'src/icons'

export const DialogConfirmUnMatch: FC<Omit<ComponentProps<typeof DialogConfirm>, 'title' | 'description'> & {
  name: string
}> = ({ name, ...props }) => {
  return (
    <DialogConfirm
      {...props}
      confirmLabel={props.confirmLabel || 'Unmatch'}
      title={`Unmatch ${name}`}
      description={(
        <div className="fx-column fx-ai-center gap-8">
          <div>
            Unmatching will remove them from your Matches,
            <br/>
            but you can still find them in Liked My Vibe.
          </div>
          <IconUnlikeIllustration/>
        </div>
      )}
    />
  )
}
