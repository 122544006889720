import { FC } from 'react'
import { useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { IconTalent } from 'src/icons'
import { ERoutes } from 'src/router'
import { NavItem } from '../nav-item'

interface IProps {
  expanded: boolean
}

export const PublicTalentNav: FC<IProps> = ({ expanded: sidebarExpanded }) => {
  const { eventHandler } = useAnalytic('side_nav')

  return (
    <nav className="fx-column px-6 relative">
      <NavItem
        mini={!sidebarExpanded}
        route={ERoutes.TALENT_VIBE_TALENT}
        icon={<IconTalent className="svg-current-color"/>}
        label="Talent"
        onClick={eventHandler(ETrackingEvent.BTN_NAV_VIBE_TALENT)}
      />
    </nav>
  )
}
