import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconExplanation: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 24, color = '#0C0C0C' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.45 2.55078C16.6692 2.55078 20.9 6.78249 20.9 12.0008C20.9 17.2191 16.6692 21.4508 11.45 21.4508C6.23076 21.4508 2 17.2191 2 12.0008C2 6.78249 6.23076 2.55078 11.45 2.55078ZM11.45 4.1261C7.10772 4.1261 3.57531 7.65851 3.57531 12.0008C3.57531 16.3431 7.10772 19.8755 11.45 19.8755C15.7923 19.8755 19.3247 16.3431 19.3247 12.0008C19.3247 7.65851 15.7923 4.1261 11.45 4.1261Z" fill={color}/>
        <path d="M9.88655 10.4639L11.6185 7L13.3504 10.4639L16.2369 12.1958L13.3504 13.9277L11.6185 17.3916L9.88655 13.9277L7 12.1958L9.88655 10.4639Z" fill="#CEFF3F"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 8.55172L10.5806 11.0469C10.4894 11.2943 10.2943 11.4894 10.0469 11.5806L7.55172 12.5L10.0469 13.4194C10.2943 13.5106 10.4894 13.7057 10.5806 13.9531L11.5 16.4483L12.4194 13.9531C12.5106 13.7057 12.7057 13.5106 12.9531 13.4194L15.4483 12.5L12.9531 11.5806C12.7057 11.4894 12.5106 11.2943 12.4194 11.0469L11.5 8.55172ZM10.697 6.25219C10.9326 6.08802 11.2128 6 11.5 6C11.7872 6 12.0674 6.08802 12.303 6.25219C12.5386 6.41636 12.7182 6.6488 12.8177 6.91819L13.9659 10.0341L17.0813 11.1822C17.3507 11.2816 17.5836 11.4614 17.7478 11.697C17.912 11.9326 18 12.2128 18 12.5C18 12.7872 17.912 13.0674 17.7478 13.303C17.5836 13.5386 17.3512 13.7182 17.0818 13.8177L13.9659 14.9659L12.8178 18.0813C12.7184 18.3507 12.5386 18.5836 12.303 18.7478C12.0674 18.912 11.7872 19 11.5 19C11.2128 19 10.9326 18.912 10.697 18.7478C10.4614 18.5836 10.2818 18.3512 10.1823 18.0818L9.03414 14.9659L5.91866 13.8178C5.64927 13.7184 5.41636 13.5386 5.25219 13.303C5.08802 13.0674 5 12.7872 5 12.5C5 12.2128 5.08802 11.9326 5.25219 11.697C5.41636 11.4614 5.6488 11.2818 5.91819 11.1823L9.03414 10.0341L10.1822 6.91866C10.2816 6.64927 10.4614 6.41636 10.697 6.25219Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
