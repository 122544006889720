import { Avatar, AvatarGroup, MenuItem, Typography } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, StyledMenu, Tooltip } from 'src/components'
import { IconDocument, IconMore, IconPencilStroke, IconTrashCan } from 'src/icons'
import { ICampaignModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { OverlayService } from 'src/services'
import { GuestViewJobTourService } from 'src/services/tour/guest-view-job-tour.service'
import { getAvatar, getFullName, stringAvatar, stringGrayAvatar } from 'src/utils'
import { JobDetailPopup } from '../../../job-detail-popup'
import Style from './style.module.scss'

const avatarCompanyStyle = { width: '32px', height: '32px', borderRadius: '6px', fontSize: '10px' }

interface IProps {
  htmlId?: string
  hashId?: string
  jobTitle?: string
  logoUrl?: string
  submissions: ICampaignModel['submissions']
  countSubmission?: number
  onDelete?: () => void
  onEdit?: () => void
  companyName?: string
}

const avatarSize = { height: '32px', width: '32px' }
const logoCompany = { height: '32px', width: '32px', borderRadius: '6px' }

export const PublicCardJob: FC<IProps> = ({
  hashId,
  htmlId,
  jobTitle,
  companyName,
  submissions = [],
  countSubmission = 0,
  logoUrl,
  onEdit,
  onDelete
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const history = useHistory()

  const goToJob = useCallback((e: any) => {
    e.stopPropagation()
    setAnchorEl(null)
    onEdit?.()
  }, [onEdit])

  const handleClickCard = useCallback(() => {
    history.push(generate([ERoutes.CAMPAIGNS_PUBLIC_SUBMISSIONS, { hashId }]))
    GuestViewJobTourService.nextStep()
  }, [history, hashId])

  const handleDelete = useCallback((e: any) => {
    e.stopPropagation()
    setAnchorEl(null)

    onDelete?.()
  }, [onDelete])

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e: any) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const openDetailJobPopup = useCallback((e: any) => {
    e.stopPropagation()
    if (!hashId) return

    OverlayService.setOverlay({
      open: true,
      content: (
        <JobDetailPopup
          hashId={hashId}
        />
      )
    })
  }, [hashId])

  const [reached, setReached] = useState(false)

  const handleReached = useCallback((node: HTMLSpanElement) => {
    try {
      const lineHeight = parseInt(window.getComputedStyle(node).lineHeight.slice(0, -2)) // to remove px

      const lineCount = Math.ceil(node.offsetHeight / lineHeight)
      const reached = lineCount > 2
      setReached(reached)
    } catch (error) {
      console.log('[card job] error: ', error)
      setReached(true)
    }
  }, [])

  return (
    <div id={htmlId} className={clsx(Style.container)} onClick={handleClickCard}>
      <div className={Style.header}>
        {logoUrl
          ? (
            <Avatar
              variant="square"
              src={logoUrl}
              sx={logoCompany}
            />
          )
          : (
            <Avatar
              {...stringGrayAvatar(companyName, avatarCompanyStyle)}
            />
          )}

        <Button
          variant="secondary"
          className={Style.buttonMore}
          onClick={handleOpenMenu}
        >
          <IconMore size={16}/>
        </Button>
        <StyledMenu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          className={Style.menu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem disableRipple className={Style.menuItem} onClick={goToJob}>
            <IconPencilStroke size={24}/>
            <Typography variant="body2-regular">Edit</Typography>
          </MenuItem>
          <MenuItem disableRipple className={Style.menuItem} onClick={handleDelete}>
            <IconTrashCan size={24}/>
            <Typography variant="body2-regular">Delete</Typography>
          </MenuItem>
        </StyledMenu>
      </div>

      <Tooltip
        title={jobTitle || 'Untitled'}
        placement="top"
        disableFocusListener={!reached}
        disableTouchListener={!reached}
        arrow
      >
        <div className={Style.content}>
          <span ref={handleReached} className={clsx('body1-bold color-neutral-theme-900', reached && 'twoline-text')}>
            {jobTitle || 'Untitled'}
          </span>

          <div>
            <div className={Style.chip} onClick={openDetailJobPopup}>
              <IconDocument width={9}/> Job Details
            </div>
          </div>

          <div className={clsx(Style.footer, 'fx flex-row fx-ai-center fx-jc-space-between')}>
            <span className="body2-regular color-neutral-theme-300">{countSubmission} {(countSubmission === 0 || countSubmission > 1) ? 'candidates' : 'candidate'}</span>

            {/* TODO: replace with UserAvatar */}
            <AvatarGroup max={3} total={submissions.length} sx={avatarSize} className={Style.avatarGroup}>
              {submissions.slice(0, 3).map((submission, index) => (
                <Avatar
                  key={index}
                  alt="avatar"
                  src={getAvatar(submission.author)}
                  {...stringAvatar(getFullName(submission.author), avatarSize)}
                />
              ))}
            </AvatarGroup>
          </div>
        </div>
      </Tooltip>
    </div>
  )
}
