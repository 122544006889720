import { EEnv } from 'src/constants'
import { ELocationType, ESalaryRange } from 'src/enums'
import { ICampaignModel } from 'src/interfaces'

const MAP_LOCATION = {
  [ELocationType.HYBRID]: 'Hybrid',
  [ELocationType.ON_SITE]: 'Onsite',
  [ELocationType.REMOTE]: 'Remote'
}

export const formatJobLocation = (data?: { location?: string; locationType?: ELocationType }) => {
  if (!data?.location) {
    if (data?.locationType && MAP_LOCATION[data.locationType]) {
      return [MAP_LOCATION[data.locationType], '']
    }

    return ['Not Provided', '']
  }

  if (data?.locationType && MAP_LOCATION[data.locationType]) {
    return [data.location, `(${MAP_LOCATION[data.locationType]})`]
  }

  return [data.location, '']
}

// export const getCampaignShareLink = (slug?: string) => `${EEnv.REACT_APP_LANDING_WEB_DNS}/campaigns/${slug}`
export const getCampaignShareLink = (slug?: string) => `${EEnv.REACT_APP_CANDIDATE_WEB_DNS}/campaigns/${slug}`

export const emptyCampaign: ICampaignModel = {
  id: 1,
  jobTitle: '',
  slug: '',
  authorId: 0,
  companyName: '',
  logoUrl: '',
  jdFileUrl: '',
  uploadVideoId: 0,
  question: '',
  location: '',
  salaryRange: ESalaryRange.CUSTOM,
  salaryValue: '',
  allowRemote: false,
  locationType: ELocationType.REMOTE,
  shareOnMarket: false,
  requireZipCode: false,
  requiredResume: false,
  requireLinkedInUrl: false,
  questionIds: [],
  emails: [],
  message: '',
  companyDomain: '',
  motivator: {},
  author: {
    firstName: '',
    lastName: '',
    fullName: '',
    pfp: {}
  }
}
