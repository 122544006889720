import { Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { Button, Upgrade, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { PaymentCardEmptyImg } from 'src/images'
import { OverlayService } from 'src/services'
import { STContainer } from './styled'

export const Empty: FC = () => {
  const theme = useTheme()
  const { eventHandler } = useAnalytic()

  const handleBuyMoreUnit = () => {
    OverlayService.setOverlay({
      open: true,
      content: <Upgrade/>
    })
  }

  return (
    <STContainer>
      <img src={PaymentCardEmptyImg} alt="card"/>
      <Typography variant="h5" color={theme.colors['--color-neutral-theme-250']}>
        Looks like you don't have any invoices yet
      </Typography>
      <Button
        style={{ width: 336 }}
        onClick={eventHandler(ETrackingEvent.BTN_STORE, handleBuyMoreUnit)}
      >
        Buy Credits
      </Button>
    </STContainer>
  )
}
