import { useCallback } from 'react'
import { AnalyticApi } from 'src/api'
import { useAsRef } from 'src/hooks'
import { _Window } from 'src/window'

type TContextData = Record<string, unknown>

type TEventContext = {
  key: string
  contextData: TContextData | ((...args: any[]) => TContextData)
}

type TEventHandler = {
  (event: string, handler?: (...args: any[]) => any): (...args: any[]) => any
  (event: TEventContext, handler?: (...args: any[]) => any): (...args: any[]) => any
}

export const useAnalytic = (screen?: string, context?: TContextData) => {
  const contextRef = useAsRef(context)
  const analytic = useCallback((event: string, data?: TContextData) => AnalyticApi.create({
    screen: screen || [_Window?.location?.pathname, _Window?.location?.search].filter(Boolean).join(''),
    event,
    data: (data || contextRef.current)
      ? {
        ...contextRef.current,
        ...data
      }
      : undefined
  }), [contextRef, screen])

  const eventHandler: TEventHandler = useCallback((event: string | TEventContext, handler?: (...args: any[]) => any) => {
    return (...args: any[]) => {
      // asynchronous
      if (typeof event === 'string') {
        analytic(event)
      } else {
        if (event.contextData instanceof Function) {
          analytic(event.key, event.contextData(...args))
        } else {
          analytic(event.key, event.contextData)
        }
      }

      if (typeof handler === 'function') {
        return handler(...args)
      }
    }
  }, [analytic])

  return {
    analytic,
    eventHandler
  }
}

/**
 * @unused
 */
// export const AnalyticProvider: FC<{ children?: ReactNode }> = (props) => {
//   const { pathname } = useLocation()
//   const [screen, setScreen] = useState('')
//   const [rootContextData, setRootContextData] = useState<TContextData | undefined>(undefined)

//   useEffect(() => {
//     if (screen) {
//       AnalyticApi.create({
//         screen,
//         event: 'enter'
//       })
//     }
//     return () => {
//       if (screen) {
//         AnalyticApi.create({
//           screen,
//           event: 'leave'
//         })
//       }
//     }
//   }, [screen])

//   useEffect(() => {
//     const interceptorId = axiosHiringApi.interceptors.response.use(undefined, (error) => {
//       const errorCode = getApiErrorCode(error)
//       if (errorCode) {
//         AnalyticApi.create({
//           screen,
//           event: errorCode
//         })
//       }
//       throw error
//     })
//     return () => {
//       axiosHiringApi.interceptors.response.eject(interceptorId)
//     }
//   }, [screen])

//   const screenRef = useAsRef(screen)
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       if (!screenRef.current) {
//         setScreen(pathname)
//       }
//     }, 500)

//     const currentScreen = screenRef.current
//     return () => {
//       clearTimeout(timer)
//       if (currentScreen) {
//         setScreen('')
//       }
//     }
//   }, [pathname, screenRef])

//   return (
//     <AnalyticContext.Provider value={{ screen, setScreen, rootContextData, setRootContextData }}>
//       {props.children}
//     </AnalyticContext.Provider>
//   )
// }
