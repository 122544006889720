import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconSuitcase: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'white' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.19836 2.55005C7.75728 2.55005 7.33268 2.71803 7.01835 3.01951C6.70374 3.32126 6.52536 3.73234 6.52536 4.16284L6.52536 5.37563L5.25499 5.37563C4.55347 5.37563 3.87909 5.64284 3.38061 6.12095C2.88183 6.59934 2.6 7.24999 2.6 7.93028L2.59961 8.87023L2.60038 8.88265C2.62607 9.29559 2.81514 9.6817 3.12621 9.9628C3.43705 10.2437 3.84641 10.3989 4.27037 10.3989L8.16202 10.3989L8.16202 10.1989C8.16202 10.0049 8.2423 9.81706 8.38809 9.67723C8.53416 9.53713 8.73399 9.45703 8.94402 9.45703L11.0534 9.45703C11.2634 9.45703 11.4632 9.53713 11.6093 9.67723C11.7551 9.81706 11.8353 10.0049 11.8353 10.1989L11.8353 10.3989L15.727 10.3989C16.1681 10.3989 16.5927 10.2309 16.907 9.92943C17.2216 9.62768 17.4 9.21659 17.4 8.7861L17.4 7.93028C17.4 7.24999 17.1182 6.59934 16.6194 6.12095C16.1209 5.64284 15.4465 5.37563 14.745 5.37563L13.472 5.37563L13.472 4.16284C13.472 3.94986 13.4283 3.73918 13.3435 3.54289C13.2587 3.34663 13.1347 3.16886 12.979 3.01951C12.8233 2.87016 12.6389 2.75212 12.4365 2.67172C12.2342 2.59133 12.0176 2.55005 11.799 2.55005L8.19836 2.55005ZM7.98961 3.97418C8.04361 3.9224 8.11855 3.89191 8.19836 3.89191L11.799 3.89191C11.8788 3.89191 11.9538 3.9224 12.0078 3.97418C12.0615 4.0257 12.09 4.09374 12.09 4.16284L12.09 5.37563L7.90736 5.37563L7.90736 4.16284C7.90736 4.09374 7.9359 4.0257 7.98961 3.97418Z" fill={color}/>
        <path d="M17.4 13.8954L17.4 11.0787L17.0829 11.3077C16.6931 11.5891 16.2169 11.7416 15.727 11.7407L11.8353 11.7407L11.8353 12.2547C11.8353 12.4487 11.7551 12.6365 11.6093 12.7764C11.4632 12.9165 11.2634 12.9966 11.0534 12.9966L8.94402 12.9966C8.73399 12.9966 8.53416 12.9165 8.38809 12.7764C8.2423 12.6365 8.16202 12.4487 8.16202 12.2547L8.16202 11.7407L4.27037 11.7407C3.7618 11.7407 3.29315 11.5798 2.9167 11.3093L2.6 11.0817L2.6 13.8954C2.6 14.5757 2.88183 15.2263 3.38061 15.7047C3.87909 16.1828 4.55347 16.45 5.25499 16.45L14.745 16.45C15.4465 16.45 16.1209 16.1828 16.6194 15.7047C17.1182 15.2263 17.4 14.5757 17.4 13.8954Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
