import { JsonUtils } from './json.utils'

const FakeStorage = {
  length: 0,
  clear: () => null,
  key: () => null,
  getItem: () => null,
  setItem: () => null,
  removeItem: () => null
} as Storage

class StorageUtils {
  static readonly _storage: Storage = typeof window === 'undefined'
    ? FakeStorage
    : window?.localStorage || window?.sessionStorage

  /**
   * Get storage item
   */
  static getItem <T = any>(key: string) {
    const val = this._storage.getItem(key)
    return JsonUtils.parse<T>(val)
  }

  /**
   * Set storage item
   */
  static setItem(key: string, value?: any) {
    return this._storage.setItem(
      key,
      JSON.stringify(value)
    )
  }

  static clear() {
    return this._storage.clear()
  }

  /**
   * Remove Storage item
   */
  static removeItem(key: string) {
    return this._storage.removeItem(key)
  }
}

export class LocalStorage extends StorageUtils {
  static readonly _storage = typeof window === 'undefined'
    ? FakeStorage
    : window?.localStorage
}

export class SessionStorage extends StorageUtils {
  static readonly _storage = typeof window === 'undefined'
    ? FakeStorage
    : window?.sessionStorage
}
