import { ComponentProps, forwardRef } from 'react'
import { IconWrapper } from '../wrapper'

export const IconMotivatorIndividualistic = forwardRef(function (
  props: ComponentProps<typeof IconWrapper>,
  ref: ComponentProps<typeof IconWrapper>['ref']
) {
  const { size = 300 } = props
  return (
    <IconWrapper {...props} ref={ref} size={size}>
      <svg width="300" height="257" viewBox="0 0 300 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="299.33" height="257" transform="translate(0 0.00195312)"/>
        <path d="M84.5225 43.1176C84.5225 43.1176 98.4092 37.485 114.554 31.4708C130.699 25.4566 144.132 19.8398 144.132 19.8398C144.132 19.8398 156.223 23.5065 170.917 27.9909C185.611 32.4753 196.946 36.1683 196.946 36.1683C196.946 36.1683 199.494 44.1047 202.91 55.1907C206.325 66.2767 210.257 79.1617 210.257 79.1617C210.257 79.1617 203.752 88.0195 198.706 95.3122C193.66 102.605 185.422 113.871 185.422 113.871C185.422 113.871 172.074 117.977 161.521 122.413C150.967 126.85 135.119 132.971 135.119 132.971C135.119 132.971 118.965 130.322 106.683 128.181C94.4014 126.039 81.0204 123.08 81.0204 123.08C81.0204 123.08 73.0267 108.797 67.8161 100.627C62.6055 92.4573 58.7305 85.74 58.7305 85.74C58.7305 85.74 66.6099 72.1885 73.1115 62.1113C79.613 52.034 84.5225 43.1176 84.5225 43.1176Z" fill="black" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M85.7515 44.083C85.7515 44.083 103.929 45.4399 106.119 45.9704C108.309 46.501 121.378 52.9888 123.801 54.7737C126.224 56.5586 132.452 65.9313 132.452 65.9313" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M59.9434 85.7102C59.9434 85.7102 84.2454 75.2847 86.8669 74.4468C89.4884 73.6088 108.156 70.4631 111.868 69.5506C115.579 68.6382 131.545 66.916 131.545 66.916" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M81.6069 122.028C81.6069 122.028 97.7591 101.872 99.6207 100.132C101.482 98.3917 112.471 85.0801 114.574 83.3133C116.677 81.5465 131.944 68.4863 131.944 68.4863" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M144.363 20.7822C144.363 20.7822 137.491 32.6028 137.206 33.7235C136.922 34.8441 134.351 50.4842 134.233 52.3092C134.114 54.1343 133.774 65.638 133.774 65.638" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M196.247 36.5264C196.247 36.5264 173.623 48.6414 172.329 49.8337C171.035 51.026 162.984 55.7028 162.984 55.7028" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M209.198 78.9021C209.198 78.9021 183.885 73.6473 180.172 72.9649C177.029 72.3874 166.523 71.832 166.523 71.832" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M112.911 69.3271L129.129 70.7573" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M123.946 54.7852L128.82 66.8023" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M134.717 48.4873L136.764 66.0824" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M162.123 50.5472C162.123 50.5472 166.788 38.7566 154.832 39.4376C142.876 40.1186 148.204 50.4586 148.204 50.4586C148.204 50.4586 151.983 48.7756 154.972 48.7756C157.961 48.7756 162.123 50.5472 162.123 50.5472Z" fill="white" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M154.983 48.7432C154.983 48.7432 144.918 49.4247 141.589 56.2344C138.259 63.0442 137.957 72.7298 141.211 82.6418C144.465 92.5538 152.714 92.6284 154.757 92.6284C156.8 92.6284 164.29 90.8132 167.242 82.7928C170.193 74.7724 169.93 67.5077 169.249 62.5896C168.568 57.6714 165.728 49.1215 154.983 48.7432Z" fill="white" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M138.988 68.6465L141.61 69.5025" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M169.38 69.1279L165.848 69.7165" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M156.11 67.7366C156.11 67.7366 157.982 66.292 161.032 66.506C164.082 66.72 165.848 68.2716 165.848 68.2716C165.848 68.2716 166.116 74.0499 160.872 74.2104C155.628 74.3709 156.11 67.7366 156.11 67.7366Z" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M151.297 67.7902C151.297 67.7902 149.421 66.3457 146.366 66.5597C143.311 66.7737 141.542 68.3251 141.542 68.3251C141.542 68.3251 141.274 74.1029 146.527 74.2634C151.779 74.4239 151.297 67.7902 151.297 67.7902Z" stroke="black" strokeWidth="1.00081" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M151.294 69.9309C151.294 69.9309 151.722 69.3424 153.488 69.2889C155.254 69.2354 156.11 69.9844 156.11 69.9844" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M161.625 70.1443C161.625 70.4147 161.517 70.674 161.326 70.8653C161.135 71.0566 160.876 71.1643 160.606 71.1646C160.335 71.1649 160.076 71.0578 159.884 70.867C159.693 70.6761 159.585 70.417 159.584 70.1466C159.583 69.8763 159.69 69.6167 159.881 69.4249C160.071 69.2332 160.33 69.125 160.601 69.124C160.871 69.1231 161.131 69.2295 161.323 69.4199C161.515 69.6104 161.623 69.8692 161.625 70.1396" fill="black"/>
        <path d="M161.625 70.1443C161.625 70.4147 161.517 70.674 161.326 70.8653C161.135 71.0566 160.876 71.1643 160.606 71.1646C160.335 71.1649 160.076 71.0578 159.884 70.867C159.693 70.6761 159.585 70.417 159.584 70.1466C159.583 69.8763 159.69 69.6167 159.881 69.4249C160.071 69.2332 160.33 69.125 160.601 69.124C160.871 69.1231 161.131 69.2295 161.323 69.4199C161.515 69.6104 161.623 69.8692 161.625 70.1396" stroke="black" strokeWidth="0.866796" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M148.075 70.2419C148.075 70.5123 147.968 70.7717 147.776 70.963C147.585 71.1543 147.326 71.2619 147.056 71.2622C146.785 71.2625 146.526 71.1555 146.334 70.9646C146.143 70.7738 146.035 70.5147 146.034 70.2443C146.034 69.9739 146.14 69.7143 146.331 69.5226C146.522 69.3308 146.781 69.2226 147.051 69.2217C147.321 69.2208 147.581 69.3272 147.773 69.5176C147.965 69.708 148.074 69.9669 148.075 70.2372" fill="black"/>
        <path d="M148.075 70.2419C148.075 70.5123 147.968 70.7717 147.776 70.963C147.585 71.1543 147.326 71.2619 147.056 71.2622C146.785 71.2625 146.526 71.1555 146.334 70.9646C146.143 70.7738 146.035 70.5147 146.034 70.2443C146.034 69.9739 146.14 69.7143 146.331 69.5226C146.522 69.3308 146.781 69.2226 147.051 69.2217C147.321 69.2208 147.581 69.3272 147.773 69.5176C147.965 69.708 148.074 69.9669 148.075 70.2372" stroke="black" strokeWidth="0.866796" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M153.755 72.0713C153.755 72.0713 153.916 74.7999 154.237 77.2075C154.558 79.6152 154.879 80.8457 154.879 80.8457" fill="black"/>
        <path d="M153.755 72.0713C153.755 72.0713 153.916 74.7999 154.237 77.2075C154.558 79.6152 154.879 80.8457 154.879 80.8457" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M148.886 83.6807C148.886 83.6807 151.615 85.7673 154.611 85.3393C157.608 84.9112 159.534 82.9316 159.534 82.9316" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M139.255 64.6872C139.255 64.6872 144.445 64.2592 150.598 61.9586C156.752 59.658 160.283 57.0898 160.283 57.0898C160.283 57.0898 163.065 59.765 164.671 60.835C166.276 61.9051 169.058 63.2961 169.058 63.2961" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M154.756 97.1689C154.756 97.1689 124.337 93.0831 117.224 107.308C110.111 121.533 103.031 131.566 102.603 139.056C102.175 146.546 104.529 150.398 107.097 151.896C109.666 153.395 115.873 152.325 118.869 151.04C121.865 149.756 123.578 148.365 123.578 148.365L118.655 213.317C118.655 213.317 137.703 215.672 154.826 215.458C171.948 215.244 185.646 212.033 185.646 212.033L183.077 152.11C183.077 152.11 190.996 160.671 197.845 154.037C204.694 147.402 196.989 126.001 192.923 116.157C188.856 106.312 185.004 100.962 177.941 98.1799C170.878 95.3977 154.756 97.1689 154.756 97.1689Z" fill="white" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M155.093 104.333C163.494 103.959 174.035 101.23 173.5 96.2541C172.965 91.2784 171.359 92.8835 170.824 91.3319C170.289 89.7803 171.788 87.6402 168.524 86.5167C165.26 85.3931 162.959 87.6937 155.307 87.8007C147.656 87.9077 141.503 84.002 139.737 87.5867C137.971 91.1714 139.737 90.2618 138.774 91.9204C137.811 93.579 136.473 92.3484 136.045 94.9701C135.617 97.5917 137.03 99.9807 143.482 102.193C147.228 103.477 150.438 104.387 155.093 104.333Z" fill="white" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M143.101 93.1095C143.101 93.1095 146.454 96.9668 156.443 96.8911C166.431 96.8153 169.231 93.0322 169.231 93.0322" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M142.733 142.053C142.733 142.733 142.463 143.386 141.982 143.867C141.501 144.349 140.849 144.62 140.168 144.621C139.487 144.621 138.834 144.352 138.352 143.872C137.87 143.391 137.598 142.739 137.597 142.058C137.595 141.378 137.864 140.724 138.344 140.242C138.824 139.759 139.475 139.487 140.156 139.484C140.837 139.482 141.491 139.75 141.974 140.229C142.457 140.709 142.73 141.36 142.733 142.041" fill="black"/>
        <path d="M142.733 142.053C142.733 142.733 142.463 143.386 141.982 143.867C141.501 144.349 140.849 144.62 140.168 144.621C139.487 144.621 138.834 144.352 138.352 143.872C137.87 143.391 137.598 142.739 137.597 142.058C137.595 141.378 137.864 140.724 138.344 140.242C138.824 139.759 139.475 139.487 140.156 139.484C140.837 139.482 141.491 139.75 141.974 140.229C142.457 140.709 142.73 141.36 142.733 142.041" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M142.525 166.992C142.525 167.673 142.255 168.325 141.774 168.807C141.293 169.288 140.641 169.559 139.96 169.56C139.279 169.561 138.626 169.291 138.144 168.811C137.662 168.331 137.39 167.678 137.389 166.998C137.387 166.317 137.656 165.664 138.136 165.181C138.616 164.699 139.267 164.426 139.948 164.424C140.629 164.421 141.283 164.689 141.766 165.169C142.249 165.648 142.522 166.3 142.525 166.98" fill="black"/>
        <path d="M142.525 166.992C142.525 167.673 142.255 168.325 141.774 168.807C141.293 169.288 140.641 169.559 139.96 169.56C139.279 169.561 138.626 169.291 138.144 168.811C137.662 168.331 137.39 167.678 137.389 166.998C137.387 166.317 137.656 165.664 138.136 165.181C138.616 164.699 139.267 164.426 139.948 164.424C140.629 164.421 141.283 164.689 141.766 165.169C142.249 165.648 142.522 166.3 142.525 166.98" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M142.309 192.508C142.309 193.188 142.038 193.841 141.557 194.322C141.076 194.804 140.424 195.075 139.743 195.076C139.063 195.076 138.409 194.807 137.927 194.327C137.445 193.846 137.173 193.194 137.172 192.513C137.17 191.833 137.439 191.18 137.919 190.697C138.399 190.214 139.051 189.942 139.731 189.939C140.412 189.937 141.066 190.205 141.549 190.684C142.032 191.164 142.305 191.815 142.309 192.496" fill="black"/>
        <path d="M142.309 192.508C142.309 193.188 142.038 193.841 141.557 194.322C141.076 194.804 140.424 195.075 139.743 195.076C139.063 195.076 138.409 194.807 137.927 194.327C137.445 193.846 137.173 193.194 137.172 192.513C137.17 191.833 137.439 191.18 137.919 190.697C138.399 190.214 139.051 189.942 139.731 189.939C140.412 189.937 141.066 190.205 141.549 190.684C142.032 191.164 142.305 191.815 142.309 192.496" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M164.794 192.148C164.794 192.829 164.524 193.482 164.043 193.963C163.562 194.445 162.91 194.716 162.229 194.716C161.548 194.717 160.895 194.448 160.413 193.967C159.931 193.487 159.659 192.835 159.658 192.154C159.656 191.474 159.925 190.82 160.405 190.338C160.885 189.855 161.537 189.582 162.217 189.58C162.898 189.578 163.552 189.846 164.035 190.325C164.518 190.804 164.791 191.456 164.794 192.136" fill="black"/>
        <path d="M164.794 192.148C164.794 192.829 164.524 193.482 164.043 193.963C163.562 194.445 162.91 194.716 162.229 194.716C161.548 194.717 160.895 194.448 160.413 193.967C159.931 193.487 159.659 192.835 159.658 192.154C159.656 191.474 159.925 190.82 160.405 190.338C160.885 189.855 161.537 189.582 162.217 189.58C162.898 189.578 163.552 189.846 164.035 190.325C164.518 190.804 164.791 191.456 164.794 192.136" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M163.732 166.823C163.732 167.504 163.462 168.156 162.981 168.638C162.5 169.119 161.847 169.39 161.167 169.391C160.486 169.392 159.833 169.123 159.351 168.642C158.868 168.162 158.597 167.51 158.595 166.829C158.594 166.148 158.862 165.495 159.342 165.012C159.822 164.53 160.474 164.257 161.155 164.255C161.835 164.253 162.489 164.52 162.972 165C163.456 165.479 163.729 166.131 163.732 166.811" fill="black"/>
        <path d="M163.732 166.823C163.732 167.504 163.462 168.156 162.981 168.638C162.5 169.119 161.847 169.39 161.167 169.391C160.486 169.392 159.833 169.123 159.351 168.642C158.868 168.162 158.597 167.51 158.595 166.829C158.594 166.148 158.862 165.495 159.342 165.012C159.822 164.53 160.474 164.257 161.155 164.255C161.835 164.253 162.489 164.52 162.972 165C163.456 165.479 163.729 166.131 163.732 166.811" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M132.505 67.0146L141.411 112.048L143.8 111.483L134.646 66.4646C134.646 66.4646 134.132 66.0745 133.415 66.2334C132.697 66.3922 132.505 67.0146 132.505 67.0146Z" fill="white" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M140.512 112.272C140.512 112.272 141.721 111.641 142.84 111.495C143.96 111.349 144.981 111.474 144.981 111.474L148.736 131.632C148.736 131.632 148.517 133.032 146.71 133.416C144.903 133.801 144.173 132.809 144.173 132.809L140.512 112.272Z" fill="white" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M169.166 131.191C169.166 131.191 166.651 131.405 164.297 130.496C161.942 129.586 160.123 127.339 158.304 126.911C156.484 126.483 154.826 126.537 152.097 126.055C149.368 125.573 148.672 125.306 148.191 124.289C147.709 123.273 147.709 116.371 150.331 115.622C152.953 114.873 158.143 113.642 162.584 116.157C167.025 118.672 174.142 123.808 174.142 123.808C174.142 123.808 173.793 127.111 172.082 128.944C171.333 129.747 169.166 131.191 169.166 131.191Z" fill="white" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M130.693 125.165C130.693 125.165 133.583 118.565 137.168 115.301C140.753 112.037 140.967 111.609 142.519 111.556C144.071 111.502 145.943 111.609 147.549 112.037C149.154 112.465 150.384 113.321 150.277 114.124C150.17 114.926 149.796 115.408 149.796 115.408C149.796 115.408 152.739 115.836 152.846 117.441C152.953 119.046 151.508 118.993 151.508 118.993C151.508 118.993 153.702 119.849 152.899 121.454C152.097 123.059 150.277 122.149 150.277 122.149C150.277 122.149 151.08 123.219 150.598 123.968C150.117 124.717 148.672 124.396 147.602 124.129C146.532 123.861 145.301 123.808 144.659 124.343C144.017 124.878 143.964 126.162 142.198 127.928C140.432 129.693 138.613 130.228 138.078 130.763C137.543 131.298 136.633 132.743 136.633 132.743C136.633 132.743 133.316 131.352 132.085 129.64C130.854 127.928 130.693 125.165 130.693 125.165Z" fill="white" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M152.902 117.561C152.902 117.561 153.735 117.107 156.118 117.182C158.502 117.258 159.751 117.901 159.751 117.901" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M153.016 120.285C153.016 120.285 153.999 119.982 156.156 120.209C158.313 120.436 159.448 120.89 159.448 120.89" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M150.897 123.085C150.897 123.085 153.697 122.668 155.778 123.009C157.859 123.349 158.956 123.728 158.956 123.728" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M138.298 117.599C138.298 117.599 140.984 114.686 142.687 114.421C144.389 114.156 149.724 115.366 149.724 115.366" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M139.509 120.058C139.509 120.058 141.476 116.842 144.238 116.842C147 116.842 151.276 118.885 151.276 118.885" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M141.362 122.403C141.362 122.403 142.762 120.398 144.881 120.323C147 120.247 150.14 121.987 150.14 121.987" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M170.075 120.758C170.075 120.758 171.841 118.779 174.142 119.581C176.443 120.384 195.437 138.896 195.437 138.896" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M164.082 130.603C164.082 130.603 163.333 132.208 164.564 133.759C165.794 135.311 182.97 152.057 182.97 152.057" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M171.177 121.495C171.177 121.495 172.35 120.209 173.485 120.776C174.62 121.344 174.09 123.879 174.09 123.879" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M165.274 131.028C165.274 131.028 165.009 131.634 165.804 131.898C166.599 132.163 169.133 131.18 169.133 131.18" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M132.246 121.4C132.246 121.4 131.657 121.079 130.962 121.614C130.266 122.149 130.7 125.297 130.7 125.297" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M136.558 132.769C136.558 132.769 137.295 132.618 138.279 131.937C139.263 131.256 139.13 130.234 139.13 130.234" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M133.19 119.868C133.19 119.868 132.736 119.187 131.677 119.49C130.617 119.792 121.386 125.391 116.921 128.721C112.456 132.05 109.203 135.001 109.203 135.001" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M123.578 148.366C123.578 148.366 129.41 143.069 133.583 139.163C137.757 135.258 140.325 132.475 140.753 131.191C141.181 129.907 140.727 129.292 140.727 129.292" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M171.627 100.106C171.627 100.106 172.483 106.848 172.804 112.519C173.125 118.19 173.182 119.339 173.182 119.339" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M155.134 104.509C155.134 104.509 155.21 106.173 155.361 109.351C155.513 112.529 155.513 114.421 155.513 114.421" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M155.361 126.644C155.361 126.644 155.254 132.636 155.254 142.373C155.254 152.111 154.933 158.531 155.361 159.28C155.789 160.029 160.283 160.029 164.136 160.029C167.988 160.029 172.804 159.815 173.553 159.28C174.302 158.745 173.553 143.069 173.553 143.069" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M151.348 104.386C151.348 104.386 151.455 108.291 151.562 111.127C151.669 113.963 151.722 114.979 151.722 114.979" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M151.502 125.997C151.502 125.997 151.502 134.017 151.124 143.778C150.746 153.539 150.519 155.582 150.821 156.338C151.124 157.095 154.681 157.171 154.681 157.171" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M182.215 31.366C182.215 31.366 182.452 28.4654 182.3 27.6331C182.149 26.8008 181.922 26.3468 181.922 26.3468C181.922 26.3468 188.959 22.4123 192.818 21.807C196.678 21.2017 204.85 21.9583 204.85 21.9583" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M193.197 21.5045C193.197 21.5045 198.796 16.8133 202.353 16.435C205.909 16.0566 207.347 17.1916 210.525 17.4186C213.703 17.6456 214.687 16.5863 215.595 17.1159C216.503 17.6456 220.927 22.6967 220.927 22.6967" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M213.854 22.4882C213.854 22.4882 220.513 21.2019 225.356 24.6825C230.199 28.163 231.94 30.9626 231.94 30.9626C231.94 30.9626 231.713 32.4759 231.713 33.9891C231.713 35.5024 231.713 36.4861 231.713 36.4861" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M209.352 22.867C209.352 22.867 210.563 16.2463 211.093 16.3598C211.622 16.4733 211.13 23.2074 211.13 23.2074C211.13 23.2074 210.828 23.3966 210.184 23.3966C209.541 23.3966 209.352 22.867 209.352 22.867Z" fill="white" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M213.148 23.918C213.148 23.918 214.967 24.239 216.572 25.095C218.178 25.9511 219.676 26.9141 219.676 26.9141" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M222.779 29.1621C222.779 29.1621 224.92 30.7672 226.846 32.6398C228.772 34.5124 229.842 36.4385 229.842 36.4385" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M211.811 24.8809C211.811 24.8809 212.881 26.9675 213.79 29.0006C214.7 31.0337 215.289 33.0668 215.289 33.0668" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M216.787 37.7217L218.324 41.0006" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M208.921 25.042C208.921 25.042 208.172 26.3261 207.423 27.7706C206.674 29.2152 205.978 30.7133 205.978 30.7133" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M204.694 33.5488C204.694 33.5488 203.998 34.9399 203.303 36.652C202.607 38.3641 199.888 45.1987 199.888 45.1987" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M203.464 16.5352C203.464 16.5352 204.695 17.2842 205.711 18.3542C206.728 19.4243 207.37 20.5479 207.37 20.5479" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M207.53 23.3828C207.53 23.3828 205.925 23.8108 203.464 24.5064C201.002 25.2019 199.397 26.272 199.397 26.272" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M195.277 28.4658C195.277 28.4658 193.297 29.5894 191.692 30.6594C190.086 31.7295 188.428 33.4416 188.428 33.4416" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M264.138 43.334C264.138 43.334 259.484 40.6479 256.76 39.2859C254.036 37.924 249.268 34.5191 247.604 34.2921C245.939 34.0651 243.139 35.2001 235.723 36.0324C228.308 36.8647 224.978 35.8811 223.692 36.4107C222.405 36.9404 220.362 40.3452 215.519 43.3718C210.677 46.3984 204.32 48.5926 204.32 48.5926L201.727 52.352" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M255.625 42.3125C255.625 42.3125 263.267 42.3882 269.851 45.2634C276.434 48.1386 279.839 50.4086 279.839 50.4086C279.839 50.4086 279.309 55.4781 279.763 62.2878C280.217 69.0976 282.412 75.529 281.958 75.8317C281.504 76.1344 275.98 78.7826 267.883 84.9114C259.786 91.0402 255.624 95.9584 255.095 96.4123C254.565 96.8663 251.387 95.2017 234.967 97.0176C218.546 98.8336 216.427 99.8172 215.444 99.8172C214.46 99.8172 212.568 96.7907 208.936 94.1424C205.304 91.4942 202.958 89.9809 202.958 89.9809" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M239.794 47.6738C239.794 47.6738 246.108 49.5999 252.315 52.5961C258.522 55.5922 255.311 54.2011 255.311 54.2011" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M262.803 58.4805C262.803 58.4805 267.832 61.0486 271.685 64.2587C275.537 67.4689 281.851 75.5478 281.851 75.5478" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M238.778 44.0352C238.778 44.0352 240.704 43.6072 244.236 43.0721C247.767 42.5371 248.891 42.5371 248.891 42.5371" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M236.424 41.5205C236.424 41.5205 240.223 38.6314 242.577 37.1868C244.931 35.7423 244.343 36.0098 244.343 36.0098" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M229.307 42.5911L225.883 38.8994" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M227.4 44.9229C227.4 44.9229 226.075 44.9229 223.957 44.9607C221.838 44.9985 219.492 45.1498 219.492 45.1498" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M227.969 47.0312C227.969 47.0312 224.17 49.0643 220.157 50.8834C216.144 52.7025 211.971 55.5917 211.971 55.5917" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M230.11 48.958C230.11 48.958 228.612 51.5796 226.579 54.8968C224.545 58.214 222.458 62.5477 222.458 62.5477" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M233.749 48.7969C233.749 48.7969 234.979 50.9905 235.835 53.0236C236.691 55.0567 237.762 57.5178 237.762 57.5178" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M231.287 45.7206C231.287 45.7206 229.735 37.5614 230.752 37.3207C231.769 37.0799 233.16 45.239 233.16 45.239C233.16 45.239 232.942 45.7268 232.299 45.8308C231.656 45.9349 231.287 45.7206 231.287 45.7206Z" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M215.292 99.5897C215.292 99.5897 215.822 89.7533 216.882 83.7002C217.941 77.6471 219.757 70.459 219.757 70.459" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M242.307 69.0225C242.307 69.0225 246.544 77.1942 249.344 83.4743C252.144 89.7544 254.792 96.3372 254.792 96.3372" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M206.513 92.6162C206.513 92.6162 203.517 102.996 205.015 110.593C206.513 118.19 210.58 124.825 210.58 124.825" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M213.022 98.0771C213.022 98.0771 211.963 108.367 211.358 115.556C210.752 122.744 210.979 127.586 210.979 127.586C210.979 127.586 218.244 130.991 228.081 131.142C237.918 131.294 245.787 129.251 245.787 129.251C245.787 129.251 245.56 116.993 245.182 108.67C244.804 100.347 244.198 96.1855 244.198 96.1855" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M245.466 110.699C245.466 110.699 249.105 111.127 250.764 106.74C252.422 102.353 250.496 96.2002 250.496 96.2002" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M210.955 127.66V132.582C210.955 132.582 216.576 135.353 227.116 135.674C237.657 135.995 245.948 134.08 245.948 134.08L245.718 129.054" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M210.903 132.807C210.903 132.807 210.071 137.422 209.011 141.13C207.952 144.837 206.741 147.788 206.741 147.788C206.741 147.788 212.492 152.177 228.383 152.101C244.274 152.025 249.949 148.847 249.949 148.847C249.949 148.847 247.83 143.702 246.695 139.692C245.56 135.682 245.409 134.169 245.409 134.169" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M228.837 152.177C228.837 152.177 228.686 158.911 229.064 163.375C229.442 167.839 230.275 172.984 230.275 172.984" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M241.777 151.269C241.777 151.269 240.339 159.365 240.339 163.299C240.339 167.234 240.415 165.569 240.718 168.974C241.02 172.379 241.096 173.968 241.096 173.968" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M215.897 151.269L218.395 162.996C218.395 162.996 218.016 163.753 217.714 166.553C217.411 169.352 217.562 170.336 217.562 170.336" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M228.989 164.283L228.156 170.336" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M229.064 173.514C229.064 173.514 231.94 172.303 235.572 172.682C239.204 173.06 241.474 174.346 241.474 174.346C241.474 174.346 240.758 202.082 240.544 205.078C240.33 208.074 240.865 210.321 240.437 212.675C240.009 215.029 237.762 216.421 235.835 216.421C233.909 216.421 231.448 216.1 230.806 210.535C230.164 204.971 230.913 204.008 230.592 196.732C230.271 189.455 229.064 173.514 229.064 173.514Z" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M228.986 173.672L228.665 170.836C228.665 170.836 226.792 169.873 222.833 169.82C218.873 169.766 215.396 171.264 215.396 171.264C215.396 171.264 220.589 200.148 220.968 205.217C221.346 210.287 220.816 213.54 226.038 213.313C231.259 213.086 230.54 207.449 230.54 207.449" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M233.106 216.046L233.266 219.577C233.266 219.577 234.123 219.952 235.995 219.898C237.868 219.845 238.457 219.47 238.457 219.47V215.832" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M223.154 213.157L223.796 216.742C223.796 216.742 224.92 217.116 226.792 216.956C228.665 216.795 229.307 216.046 229.307 216.046L228.879 212.408" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M98.9965 35.3651C98.9965 35.3651 80.1454 24.7793 79.011 24.4019C77.8766 24.0244 70.7032 25.3798 64.3639 26.2033C58.0246 27.0269 52.853 26.8725 51.4517 27.7646C50.0504 28.6568 44.9789 34.5931 37.305 38.1102C29.6311 41.6274 18.9211 53.7744 18.9211 53.7744C18.9211 53.7744 18.821 60.4141 17.5865 67.2083C16.352 74.0024 13.9497 81.2083 13.9497 81.2083C13.9497 81.2083 22.4577 85.3774 27.9629 88.5857C33.4681 91.7941 40.6082 100.39 42.1429 100.167C43.6777 99.9436 51.7186 97.3357 57.8577 96.924C63.9968 96.5122 71.0702 96.5465 71.0702 96.5465" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M60.3937 38.0243C60.3937 38.0243 58.1583 28.914 59.0592 28.6738C59.96 28.4336 62.8961 37.1493 62.8961 37.1493C62.8961 37.1493 62.5691 37.7463 61.9953 38.0243C61.2289 38.3956 60.3937 38.0243 60.3937 38.0243Z" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.4751 81.1156C14.4751 81.1156 18.946 71.1474 22.3158 66.395C25.6857 61.6425 31.2909 55.1572 31.2909 55.1572" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M39.2651 48.9634C39.2651 48.9634 43.8695 45.7894 48.1068 43.6276C52.3441 41.4659 57.8493 39.2012 57.8493 39.2012" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M41.7008 99.9026C41.7008 99.9026 41.6007 91.3928 42.4014 85.3879C43.2022 79.383 45.2374 72.5889 45.2374 72.5889" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M49.1743 60.0811C49.1743 60.0811 51.8101 53.836 53.8454 50.0958C55.8806 46.3556 58.9836 41.7061 58.9836 41.7061" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M63.3877 41.415C63.3877 41.415 66.1903 45.9959 67.7918 49.3587C69.3933 52.7214 71.462 58.6062 71.462 58.6062" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M67.2246 38.8408C67.2246 38.8408 72.2293 40.7109 75.4323 42.032C78.6353 43.3531 81.7383 44.7943 81.7383 44.7943" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M71.2617 35.2893C71.2617 35.2893 78.1015 34.6888 83.0061 34.6373C87.9107 34.5859 98.821 35.804 98.821 35.804" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M67.6919 31.3947C67.6919 31.3947 69.527 29.3873 72.6966 27.6545C75.8663 25.9216 79.0693 24.3604 79.0693 24.3604" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M51.8438 27.5352C51.8438 27.5352 53.3452 29.1136 54.8466 30.4004C56.348 31.6871 57.4824 33.4714 57.4824 33.4714" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M37.4634 37.9317C37.4634 37.9317 42.3013 37.1768 46.0715 37.1597C49.8417 37.1425 55.1801 37.5714 55.1801 37.5714" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M32.005 91.6816C32.005 91.6816 29.6457 102.891 31.2972 111.82C32.9487 120.749 37.3369 128.902 37.3369 128.902" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M40.4036 99.5918L34.2695 163.186C34.2695 163.186 47.8588 167.481 61.4952 166.438C75.1317 165.394 83.7193 162.677 83.7193 162.677L75.3676 105.124" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M76.9248 114.247C76.9248 114.247 77.0192 114.417 79.0481 113.495C81.0771 112.573 82.5398 110.851 82.5398 110.851" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M56.5879 166.823C56.5879 166.823 57.0597 170.05 57.2485 174.126C57.4372 178.203 54.1343 223.139 54.1343 223.139C54.1343 223.139 59.3246 226.147 64.0431 225.978C68.7616 225.808 74.2822 223.187 74.2822 223.187C74.2822 223.187 73.3857 199.239 72.5364 187.18C71.6871 175.121 72.3005 165.392 72.3005 165.392" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M44.6975 165.609C44.6975 165.609 46.2074 171.797 46.0659 175.266C45.9243 178.736 39.46 214.525 39.46 214.525C39.46 214.525 44.9334 217.024 48.9441 217.218C52.9548 217.412 54.5119 216.272 54.5119 216.272" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M57.9983 225.226C57.9983 225.226 57.1254 228.429 57.5029 230.783C57.8804 233.136 58.423 234.907 58.423 234.907L58.2814 239.687C58.2814 239.687 60.1453 240.536 63.2595 240.634C66.3737 240.731 69.0632 240.148 69.0632 240.148L68.7801 233.961C68.7801 233.961 69.6294 231.692 69.771 229.97C69.9125 228.247 69.134 225.093 69.134 225.093" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M59.7441 235.223C59.7441 235.223 61.6551 235.939 63.6369 235.757C65.6187 235.575 67.3645 234.871 67.3645 234.871" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M47.8061 217.184C47.8061 217.184 47.2399 221.042 48.16 223.104C49.0801 225.166 51.5338 226.319 53.9638 226.501C56.3938 226.683 57.3375 226.598 57.3375 226.598" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M49.2456 225.021L49.6231 231.693C49.6231 231.693 51.1802 232.372 53.4923 232.3C55.8043 232.227 57.5501 232.057 57.5501 232.057" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M121.44 213.746L120.005 239.276" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M182.865 212.545L184.459 238.714" stroke="black" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M119.049 79.5403L134.227 71.6807" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M134.451 97.2742L134.422 72.2334" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M134.972 71.3877L137.841 71.4093" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M134.332 67.6309L138.088 67.9495" stroke="white" strokeWidth="0.999999" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M107.331 22.7041C107.331 22.7041 110.381 21.5115 113.123 22.3134C115.865 23.1154 118.481 26.6421 118.481 26.6421" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M123.908 24.8328C123.908 24.8328 123.017 21.9642 125.302 19.0853C127.587 16.2064 131.049 16.6793 131.049 16.6793" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M261.758 36.5965C261.758 36.5965 262.25 33.8924 260.57 31.3836C258.891 28.8749 255.578 27.4766 255.578 27.4766" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M263.872 38.7354C263.872 38.7354 265.163 36.3911 267.551 35.877C269.939 35.3629 273.332 36.6379 273.332 36.6379" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M279.387 111.35L272.246 119.987" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M281.261 123.448L276.641 129.177" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M288.209 81.2803L282.974 87.8236" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.9392 127.432L11.1211 134.964" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.2029 138.6L13.8354 147.12" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M104.184 168.088L98.0444 175.824" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M105.638 178.848L100.436 185.856" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M88.7059 177.219L84.6021 182.744" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </IconWrapper>
  )
})
