import { ComponentProps, FC, useCallback, useEffect, useRef } from 'react'
import { CandidateApi } from 'src/api'
import { IProgress, VideoPlayer } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { AuthModule } from 'src/store'

export const VideoPlayerTracking: FC<ComponentProps<typeof VideoPlayer> & { videoId?: number }> = (props) => {
  const refPlaySent = useRef(false)
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  const onProgress = useCallback((progress: IProgress) => {
    if (isAuthenticated && !refPlaySent.current && props.videoId && (progress.played >= 0.5 || progress.playedSeconds >= 5)) {
      refPlaySent.current = true
      CandidateApi.play(props.videoId).catch(() => {})
    }
  }, [isAuthenticated, props.videoId])

  useEffect(() => {
    refPlaySent.current = false
  }, [props.videoId])

  return (
    <VideoPlayer
      {...props}
      onProgress={(progress) => {
        onProgress(progress)
        props.onProgress?.(progress)
      }}
    />
  )
}
