import { FC, useEffect, useState } from 'react'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { PeopleApi } from 'src/api'
import { Spinner } from 'src/components'
import { useDebouncedLoading, useUnsubscribeEffect } from 'src/hooks'
import { MessagesBox } from 'src/partials/message-box'
import { SnackbarService } from 'src/services'
import { MessengerService } from 'src/services/messenger.service'

interface IProps {
  lookupId?: string
}

export const Message: FC<IProps> = ({ lookupId }) => {
  const [loading, setLoading] = useState(true)

  useUnsubscribeEffect((unsubscribe$) => {
    if (!lookupId) return

    from(PeopleApi.getConversation(lookupId as string))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        })
      )
      .subscribe(({ data }) => {
        if (data.id) {
          MessengerService.setConversation(data)
        }
        setLoading(false)
      })
  }, [lookupId])

  useEffect(() => {
    return () => {
      MessengerService.setConversation()
      MessengerService._loadMoreConversations.reset()
    }
  }, [])

  const debounceLoading = useDebouncedLoading(loading)

  if (debounceLoading) {
    return (
      <div className="fx fx-1 fx-center">
        <Spinner/>
      </div>
    )
  }

  return (
    <div className="fx-column" style={{ height: 600 }}>
      <MessagesBox className="round-4" style={{ height: 600 }}/>
    </div>
  )
}
