import { Box, Typography, useTheme } from '@mui/material'
import clsx from 'clsx'
import { ComponentProps, FC, useCallback, useEffect } from 'react'
import { Button, Input, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useAsRef, useBehaviorMapper } from 'src/hooks'
import { Logo } from 'src/images'
import { browserHistory, ERoutes, generate } from 'src/router'
import { AuthModule } from 'src/store'
import Style from './style.module.scss'

interface IProps {
  noNavigation?: boolean
  email: string
  error?: string
  disabled?: boolean
  onChange: ComponentProps<typeof Input>['onChange']
  onSubmit: ComponentProps<'form'>['onSubmit']
  onGoToSignup?: () => void
}

export const Form: FC<IProps> = ({
  noNavigation,
  email,
  error,
  disabled,
  onChange,
  onSubmit,
  onGoToSignup
}) => {
  const theme = useTheme()
  const { eventHandler } = useAnalytic('pswd_reset1')
  const currentProfileEmail = useBehaviorMapper(AuthModule.profile$)?.email

  const goToSignup = useCallback(() => {
    if (onGoToSignup) {
      return onGoToSignup()
    }
    browserHistory.push(generate(ERoutes.SIGN_UP))
  }, [onGoToSignup])

  const onChangeRef = useAsRef(onChange)
  useEffect(() => {
    if (currentProfileEmail) {
      onChangeRef.current?.({
        persist: () => {},
        preventDefault: () => {},
        stopPropagation: () => {},
        target: {
          name: 'email',
          value: currentProfileEmail
        }
      } as Parameters<Exclude<typeof onChange, undefined>>[0])
    }
  }, [currentProfileEmail, onChangeRef])

  return (
    <form
      className={(clsx(Style.STForm, 'fx-column gap-4'))}
      autoComplete="off"
      onSubmit={onSubmit}
    >
      <div className={Style.Logo}>
        <img src={Logo} alt="logo"/>
      </div>

      <Typography variant="h5" color={theme.colors['--color-neutral-theme-700']}>Reset your password</Typography>
      <Typography variant="body2-regular" color={theme.colors['--color-neutral-theme-400']}>
        Enter the email address associated with your account and we'll send you a link to reset your password.
      </Typography>

      <Input
        label="Email"
        id="email"
        type="email"
        name="email"
        placeholder="name@company.com"
        disabled={disabled}
        readOnly={!!currentProfileEmail}
        value={currentProfileEmail || email || ''}
        onChange={onChange}
        error={error}
        onClick={eventHandler(ETrackingEvent.INPUT_EMAIL)}
        required
      />

      <Box display="flex" alignItems="center" justifyContent="space-between">
        {!noNavigation && (
          <Box className={Style.Box}>
            <Typography
              variant="body2-medium"
              color={theme.colors['--color-neutral-theme-400']}
            >
              New to KNOWME?
            </Typography>
            {' '}
            <Typography
              variant="body2-medium"
              component="a"
              className="color-blue-500"
              onClick={goToSignup}
              sx={{ cursor: 'pointer' }}
            >
              Create account
            </Typography>
          </Box>
        )}
        <Button
          className="round-16 px-20 ml-auto"
          type="submit"
          disabled={disabled}
          onClick={eventHandler(ETrackingEvent.BTN_SEND_RESET_PSWD_LINK)}
        >
          Send
        </Button>
      </Box>
    </form>
  )
}
